import React, { useState } from 'react';

// Controls the rendering, message, and duration of the popup alert.
const SnackbarAlertContext = React.createContext({
	isSnackbarOpen: false,
	autoHide: undefined,
	msg: undefined,
	severity: undefined,
});

// SnackbarAlertContextProvider is used to give all children components access to the SnackbarAlertContext context.
// Wrap components that need access to the SnackbarAlertContext with the SnackbarAlertContextProvider context.
export const SnackbarAlertContextProvider = (props) => {
	const [snackbarAlert, setSnackbarAlert] = useState({
		isSnackbarOpen: false,
		autoHide: undefined,
		msg: undefined,
		severity: undefined,
	});

	return (
		<SnackbarAlertContext.Provider value={{ snackbarAlert, setSnackbarAlert }}>
			{props.children}
		</SnackbarAlertContext.Provider>
	);
};

export default SnackbarAlertContext;
