import { useContext, useState } from 'react';
import { Button, makeStyles, CircularProgress, Backdrop } from '@material-ui/core';
import MyDataSpacesViewContext from '../../context/MyDataSpacesViewContext';
import CreateEditDataSpaceContext from '../../context/CreateEditDataSpaceContext';
import {
	createDataSpaceSchemaWithName,
	updateDataSpaceSelectedFields,
	updateDataSpaceJoins,
	generateJoinedDataSpace,
	updateDataSpaceSelections,
	yahooUploadUserSelectedData,
	updateDataSpaceName,
} from '../../../../api_helper/api';
import SnackbarAlertContext from '../../../../context/SnackbarAlertContext';
import { appInsights } from '../../../../utils/ApplicationInsights';
import { UserProfileContext } from '../../../../UserProfileContext';
import { updateDataSpaceViews } from '../../../../api_helper/api';
import { trackEvent } from '../../../../utils/eventTracking';
import { identifySegment } from '../../../../utils/segmentTracking';
import { identify } from '../../../../utils/eventTracking';

const useStyles = makeStyles((theme) => ({
	loadingOverlay: {
		width: '100vw',
		height: '100vh',
		zIndex: '100',
		color: '#FFFFFF',
	},
}));

// Button which creates dataspace when clicked.
const CreateDataSpaceButton = () => {
	const classes = useStyles();

	// User Profile Data
	const userProfile = useContext(UserProfileContext)[0];

	const myDataSpacesViewContext = useContext(MyDataSpacesViewContext);
	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);
	const snackbarAlertContext = useContext(SnackbarAlertContext);

	// Used to determine when to render circular loading animation overlay.
	const [isLoading, setIsLoading] = useState(false);

	const validateNewDataSpace = () => {
		// Check that an anchor is selected for the dataspace.
		if (createEditDataSpaceContext.newDataSpace.joinData.every((dataSource) => dataSource.anchor === false)) {
			return {
				isNewDataSpaceValid: false,
				msg: 'Please select an anchor for your data space.',
			};
		}

		// If there is more than one selected datasource, check that all selected datasources have at least one join.
		if (createEditDataSpaceContext.newDataSpace.selectedDatasets.length > 1) {
			for (let datasource of createEditDataSpaceContext.newDataSpace.joinData) {
				if (datasource.joins.length === 0) {
					return {
						isNewDataSpaceValid: false,
						msg: 'Please create a join for "' + datasource.primarySource + '" or remove the datasource.',
					};
				}
			}
		}

		return {
			isNewDataSpaceValid: true,
		};
	};

	const createDataSpaceClickHandler = async () => {
		const validateNewDataSpaceRes = validateNewDataSpace();

		if (!validateNewDataSpaceRes.isNewDataSpaceValid) {
			snackbarAlertContext.setSnackbarAlert({
				isSnackbarOpen: true,
				autohide: 5000,
				severity: 'error',
				msg: validateNewDataSpaceRes.msg,
			});
			return;
		}

		// Render circular loading animation overlay while dataspace is being created.
		setIsLoading(true);

		try {
			const dataSpaceId = await createDataSpaceSchemaWithName(
				localStorage.getItem('jwt'),
				createEditDataSpaceContext.newDataSpace.name,
			);
			const updateDataSpaceSelectionsPayload = {
				dataSpaceId,
				selectedLeague: createEditDataSpaceContext.newDataSpace.fantasyLeague?.displayName || 'None',
				selectedLeagueId: createEditDataSpaceContext.newDataSpace.fantasyLeague?.league_key || '',
				lmapiObjectTypes: createEditDataSpaceContext.newDataSpace.fantasyLeague
					? createEditDataSpaceContext.newDataSpace.selectedDatasets.map((dataset) => dataset.dataSourceObjectType)
					: [],
				lmapiName: createEditDataSpaceContext.newDataSpace.fantasyLeague?.api || '',
				selectedSport:
					createEditDataSpaceContext.newDataSpace.fantasyLeague?.sport ||
					createEditDataSpaceContext.newDataSpace.sportsLeague,
			};

			await updateDataSpaceSelections(updateDataSpaceSelectionsPayload);

			if (createEditDataSpaceContext.newDataSpace.fantasyLeague) {
				try {
					await yahooUploadUserSelectedData({ dataSpaceId });
				} catch (e) {
					snackbarAlertContext.setSnackbarAlert({
						isSnackbarOpen: true,
						autohide: 5000,
						severity: 'error',
						msg: 'There was an error retrieving your personal league data. Please try again later.',
					});
					setIsLoading(false);
					return;
				}
			}

			// Create an array of the extended names of all selected fields.
			const selectedFields = [];
			for (let selectedDataset of createEditDataSpaceContext.newDataSpace.selectedDatasets) {
				for (let field of selectedDataset.dataSpaceFields) {
					if (field.isSelected) {
						selectedFields.push(field.extendedFieldName);
					}
				}
			}

			await updateDataSpaceSelectedFields({ dataSpaceId, selectedFields });

			await updateDataSpaceJoins({
				dataSpaceId,
				joinData: createEditDataSpaceContext.newDataSpace.joinData,
			});

			await generateJoinedDataSpace(dataSpaceId, 'save');

			myDataSpacesViewContext.setMyDataSpacesView((prevState) => ({
				...prevState,
				selectedDataSpace: { name: createEditDataSpaceContext.newDataSpace.name, id: dataSpaceId },
			}));

			createEditDataSpaceContext.resetNewDataSpace();
			setIsLoading(false);
			myDataSpacesViewContext.changeViewHandler('dataSpaceView');
		} catch (e) {
			snackbarAlertContext.setSnackbarAlert({
				isSnackbarOpen: true,
				autohide: 5000,
				severity: 'error',
				msg: 'Error generating dataspace. Please try again later. If this issue persists, please contact support@rolling-insights.com',
			});
			setIsLoading(false);
			return;
		}
	};

	// When save dataspace is clicked.
	const updateDataSpaceClickHandler = async () => {
		const validateNewDataSpaceRes = validateNewDataSpace();

		if (!validateNewDataSpaceRes.isNewDataSpaceValid) {
			snackbarAlertContext.setSnackbarAlert({
				isSnackbarOpen: true,
				autohide: 5000,
				severity: 'error',
				msg: validateNewDataSpaceRes.msg,
			});
			return;
		}

		// Render circular loading animation overlay while dataspace is being created.
		setIsLoading(true);

		try {
			const dataSpaceId = createEditDataSpaceContext.newDataSpace._id;
			updateDataSpaceName(createEditDataSpaceContext.newDataSpace._id, createEditDataSpaceContext.newDataSpace.name);

			const updateDataSpaceSelectionsPayload = {
				dataSpaceId,
				selectedLeague: createEditDataSpaceContext.newDataSpace.fantasyLeague?.displayName || 'None',
				selectedLeagueId: createEditDataSpaceContext.newDataSpace.fantasyLeague?.league_key || '',
				lmapiObjectTypes: createEditDataSpaceContext.newDataSpace.fantasyLeague
					? createEditDataSpaceContext.newDataSpace.selectedDatasets.map((dataset) => dataset.dataSourceObjectType)
					: [],
				lmapiName: createEditDataSpaceContext.newDataSpace.fantasyLeague?.api || '',
				selectedSport:
					createEditDataSpaceContext.newDataSpace.fantasyLeague?.sport ||
					createEditDataSpaceContext.newDataSpace.sportsLeague,
			};

			await updateDataSpaceSelections(updateDataSpaceSelectionsPayload);

			if (createEditDataSpaceContext.newDataSpace.fantasyLeague) {
				try {
					await yahooUploadUserSelectedData({ dataSpaceId });
				} catch (e) {
					snackbarAlertContext.setSnackbarAlert({
						isSnackbarOpen: true,
						autohide: 5000,
						severity: 'error',
						msg: 'There was an error retrieving your personal league data. Please try again later.',
					});
					setIsLoading(false);
					return;
				}
			}

			// Create an array of all selected datasource fields.
			const selectedFields = [];
			for (let selectedDataset of createEditDataSpaceContext.newDataSpace.selectedDatasets) {
				for (let field of selectedDataset.dataSpaceFields) {
					if (field.isSelected) {
						selectedFields.push(field.extendedFieldName);
					}
				}
			}

			await updateDataSpaceSelectedFields({ dataSpaceId, selectedFields });

			await updateDataSpaceJoins({
				dataSpaceId,
				joinData: createEditDataSpaceContext.newDataSpace.joinData,
			});

			await updateDataSpaceViews({ dataSpaceId, views: createEditDataSpaceContext.newDataSpace.views });

			await generateJoinedDataSpace(dataSpaceId, 'save');

			myDataSpacesViewContext.setMyDataSpacesView((prevState) => ({
				...prevState,
				selectedDataSpace: { name: createEditDataSpaceContext.newDataSpace.name, id: dataSpaceId },
			}));

			createEditDataSpaceContext.resetNewDataSpace();
			setIsLoading(false);
			myDataSpacesViewContext.changeViewHandler('dataSpaceView');

			// Event tracking for successful dataspace creation.
			const eventName =
				createEditDataSpaceContext.createEditDataSpaceMode === 'edit'
					? 'Successfully Created Dataspace in Edit Dataspace Form'
					: 'Successfully Created Dataspace in New Dataspace Form';
			trackEvent({
				userDetails: { userId: userProfile._id, email: userProfile.email },
				eventDetails: { types: ['KissMetrics', 'Segment', 'Encharge', 'AppInsights'], eventName },
			});

			// Sends user tag to Encharge for which sport you created the dataspace with (e.g., usesNBA).
			identify(
				userProfile.email,
				userProfile.username,
				userProfile.firstname,
				userProfile.lastname,
				`uses${createEditDataSpaceContext.newDataSpace.selectedDatasets[0].sport}`,
			);

			// Sends user trait to Segment for which sport you created the dataspace with (e.g., usesNBA).
			const userTraits = {
				userDetails: {
					email: userProfile.email,
					userId: userProfile._id,
				},
			};
			userTraits[`uses${createEditDataSpaceContext.newDataSpace.selectedDatasets[0].sport}`] = true;
			identifySegment(userProfile._id, userTraits);
		} catch (e) {
			snackbarAlertContext.setSnackbarAlert({
				isSnackbarOpen: true,
				autohide: 5000,
				severity: 'error',
				msg: 'Error generating dataspace. Please try again later. If this issue persists, please contact support@rolling-insights.com',
			});
			appInsights.trackException(e);
			setIsLoading(false);

			// Event tracking for failed dataspace creation.
			const eventName =
				createEditDataSpaceContext.createEditDataSpaceMode === 'edit'
					? 'Dataspace Save Failed in Edit Dataspace Form'
					: 'Dataspace Save Failed in New Dataspace Form';
			trackEvent({
				userDetails: { userId: userProfile._id, email: userProfile.email },
				eventDetails: { types: ['KissMetrics', 'Segment', 'Encharge', 'AppInsights'], eventName },
			});
			return;
		}
	};

	return (
		<>
			<Button
				variant="contained"
				margin="normal"
				color="primary"
				onClick={
					createEditDataSpaceContext.createEditDataSpaceMode === 'edit'
						? updateDataSpaceClickHandler
						: createDataSpaceClickHandler
				}
			>
				{createEditDataSpaceContext.createEditDataSpaceMode === 'edit' ? 'Save DataSpace' : 'Create DataSpace'}
			</Button>
			<Backdrop open={isLoading} className={classes.loadingOverlay}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	);
};

export default CreateDataSpaceButton;
