import React, { useState, createContext } from 'react';

export const UserProfileContext = createContext();

export const UserProfileProvider = (props) => {
	const [userProfile, setUserProfile] = useState({});
	return (
		<UserProfileContext.Provider value={[userProfile, setUserProfile]}>{props.children}</UserProfileContext.Provider>
	);
};
