/*
This component renders a CSV icon which when clicked will initiate a download of a CSV 
of the user's DataSpace. A circular progress indicator appears to the left of the CSV button
during processing.
*/

import { React, useState, useContext } from 'react';
import fileDownload from 'js-file-download';
import { SvgIcon, IconButton, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as ExportCsvIcon } from '../../../assets/icons/export-csv.svg';
import { getDataSpaceCSV } from '../../../api_helper/api';
import { trackEvent } from '../../../utils/eventTracking';
import { UserProfileContext } from '../../../UserProfileContext';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'flex-start',
	},
}));

export default function CSVExporter({ dataSpaceId, dataSpaceName, displayAlert }) {
	const classes = useStyles();

	const [loading, setLoading] = useState(false);

	const userProfile = useContext(UserProfileContext)[0];

	async function onClick() {
		setLoading(true);
		getDataSpaceCSV(dataSpaceId)
			.then((result) => {
				trackEvent({
					userDetails: { userId: userProfile._id, email: userProfile.email },
					eventDetails: {
						types: ['Segment', 'Encharge', 'GA4', 'AppInsights'],
						eventName: 'User exported CSV from their DataSpace.',
					},
				});
				fileDownload(result.data, `${dataSpaceName}.csv`); // Maybe get name of dataspace
			})
			.catch((err) => {
				displayAlert(
					'error',
					<div>
						There was an error processing your CSV. Please try again later.
						<br />
						If the issue persists please report it with our&nbsp;
						<a
							href="https://rolling-wave.atlassian.net/servicedesk/customer/portal/1/group/1/create/1"
							rel="noreferrer"
							target="_blank"
						>
							service desk
						</a>
						.<br />
						<br />
						<b>
							<i>Error details: </i>
						</b>
						{err}
					</div>,
				);
			})
			.finally(() => setLoading(false));
	}

	return (
		<div className={classes.container}>
			{loading && <CircularProgress />}
			<IconButton aria-label="download csv" onClick={onClick}>
				<SvgIcon>
					<ExportCsvIcon />
				</SvgIcon>
			</IconButton>
		</div>
	);
}
