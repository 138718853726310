import React, { useState, useContext } from 'react';
import { TextField, Button, Grid, Box } from '@material-ui/core';
import logo from '../../assets/img/SportWiseLogoLarge.png';
import { withRouter } from 'react-router-dom';
import { signIn } from '../../api_helper/api';
import { identify, trackEvent } from '../../utils/eventTracking';
import { GlobalAlertContext } from '../../GlobalAlertContext';
import './MainLogin.css';
import SnackbarAlertContext from '../../context/SnackbarAlertContext';

const MainLogin = (props) => {
	// Global Alert
	const [alertOpen, setAlertOpen] = useContext(GlobalAlertContext).globalAlertOpen;
	const [globalAlertSettings, setGlobalAlertSettings] = useContext(GlobalAlertContext).globalAlertSettings;
	const snackbarAlertContext = useContext(SnackbarAlertContext);

	const [formInputs, setFormInputs] = useState({ email: '', password: '' });

	const inputChangeHandler = ({ target }) => {
		const { name, value } = target;
		setFormInputs((prevState) => ({ ...prevState, [name]: value }));
	};

	const formSubmitHandler = async (e) => {
		try {
			e.preventDefault();
			trackEvent({
				userDetails: { userId: undefined, email: formInputs.email },
				eventDetails: {
					types: ['Segment', 'Encharge', 'GA4', 'AppInsights'],
					eventName: 'Clicked on Login Form Submit Button',
				},
			});

			if (!formInputs.email || !formInputs.password) {
				snackbarAlertContext.setSnackbarAlert({
					isSnackbarOpen: true,
					autohide: 5000,
					severity: 'error',
					msg: 'Please enter email and/or password.',
				});
				setFormInputs((prevState) => ({
					...prevState,
					password: '',
				}));
				return;
			}

			const signInResult = await signIn({ email: formInputs.email, password: formInputs.password });

			if (!signInResult.data.token) {
				snackbarAlertContext.setSnackbarAlert({
					isSnackbarOpen: true,
					autohide: 5000,
					severity: 'error',
					msg: 'Incorrect email and/or password',
				});
				setFormInputs((prevState) => ({
					...prevState,
					password: '',
				}));
				return;
			}

			trackEvent({
				userDetails: { userId: null, email: formInputs.email },
				eventDetails: {
					types: ['KissMetrics', 'Segment', 'Encharge', 'GA4', 'AppInsights'],
					eventName: 'Login Activated',
				},
			});

			// Push user to protected page
			localStorage.setItem('jwt', signInResult.data.token);
			props.history.push('/app');
		} catch (e) {
			snackbarAlertContext.setSnackbarAlert({
				isSnackbarOpen: true,
				autohide: 5000,
				severity: 'error',
				msg: 'There was an error signing in to your account. Please try again.',
			});
			return;
		}
	};

	return (
		<Grid container direction="column" className="main-login--page-wrapper">
			<Grid item align="center" className="main-login--logo-wrapper">
				<img src={logo} alt="SportWise logo." className="logo" />
			</Grid>

			<Box component="form" onSubmit={formSubmitHandler}>
				<Grid item align="center">
					<TextField label="E-Mail" name="email" variant="outlined" margin="normal" onChange={inputChangeHandler} />
				</Grid>

				<Grid item align="center">
					<TextField
						label="Password"
						name="password"
						variant="outlined"
						margin="normal"
						type="password"
						onChange={inputChangeHandler}
					/>
				</Grid>

				<Box m={2} pt={3}>
					<Grid item align="center">
						<Button variant="contained" color="secondary" margin="normal" type="submit">
							Log In
						</Button>
					</Grid>
				</Box>

				<Box m={2} pt={3}>
					<Grid item align="center">
						<Button
							variant="contained"
							color="secondary"
							margin="normal"
							onClick={() => {
								props.history.push('/register');
							}}
						>
							Sign Up
						</Button>
					</Grid>
				</Box>

				<Grid item align="center">
					<Button
						onClick={() => {
							trackEvent({
								userDetails: { userId: null, email: null },
								eventDetails: {
									types: ['Segment', 'Encharge', 'GA4', 'AppInsights'],
									eventName: 'Forgot Password Clicked',
								},
							});
							props.nextView('forgottenPassword');
						}}
					>
						Forgot Password?
					</Button>
				</Grid>
			</Box>

			<Grid item align="center" style={{ marginTop: '2rem' }}>
				<em>
					This site is protected by reCAPTCHA and the Google{' '}
					<a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
					<a href="https://policies.google.com/terms">Terms of Service</a> apply.
				</em>
				<p>
					<em>
						For the best experience please turn off AdBlock on this page in your browser settings for quick and easy
						access to onboarding, FAQs, and tech support.
					</em>
				</p>
			</Grid>
		</Grid>
	);
};

export default withRouter(MainLogin);
