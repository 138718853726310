import { useContext, useEffect, useState } from 'react';
import { Grid, makeStyles, Typography, Switch } from '@material-ui/core';
import { SvgIcon } from '@mui/material';
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';
import LooksTwoOutlinedIcon from '@mui/icons-material/LooksTwoOutlined';
import NameDataSpaceInput from './NameDataSpaceInput';
import SelectFantasyLeague from './SelectFantasyLeague';
import SelectSportsLeague from './SelectSportsLeague';
import NextButton from './NextButton';
import { ReactComponent as OrIcon } from '../../../../assets/icons/or.svg';
import NewDataSpacePageContentWrapper from '../ui_components/NewDataSpacePageContentWrapper';
import CreateEditDataSpaceContext from '../../context/CreateEditDataSpaceContext';
import { UserProfileContext } from '../../../../UserProfileContext';
import { trackEvent } from '../../../../utils/eventTracking';
import { SelectButton } from 'primereact/selectbutton';

const useStyles = makeStyles((theme) => ({
	nameInputWrapper: {
		marginBottom: '2rem',
	},
	leagueSelectButton: {
		'& .p-button.p-highlight': {
			backgroundColor: '#EE9D34 !important',
			borderColor: '#EE9D34 !important',
		},
		'& .p-button:focus': {
			boxShadow: '0 0 0 0.2rem #ffb759',
		},
	},
	stepIconGrid: {
		marginRight: '0.8rem',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRight: '3px solid #D5D5D5',
	},
	stepIcon: {
		height: '100%',
		fontSize: '2.5rem',
		color: '#D5D5D5',
	},
	orIcon: {
		fontSize: '4.5rem',
	},
	sportsLeagueSelectImage: {
		maxWidth: '25%',
		maxHeight: '100%',
		'&:hover': {
			borderRadius: '5px',
			boxShadow: 'inset 0 0 0 1px #000',
		},
	},
}));

// First page of the new dataspace form.
// The components collects the dataspace name, and the league selected (either a sports or fantasy league) and stores this data in the CreateEditDataSpaceContext context.
const ConfigureDataSpaceNew = () => {
	const classes = useStyles();

	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);
	const userProfile = useContext(UserProfileContext)[0];

	const [leagueType, setLeagueType] = useState(
		createEditDataSpaceContext.newDataSpace.fantasyLeague ? 'Fantasy' : 'Sport',
	);

	const leagueSelectChangeHandler = (e) => {
		setLeagueType(e.value);
	};

	const leagueToggleChangeHandler = (e) => {
		if (e.target.checked) {
			createEditDataSpaceContext.setNewDataSpace({
				...createEditDataSpaceContext.newDataSpace,
				sportsLeague: undefined,
			});
			setLeagueType('Fantasy');
		} else {
			createEditDataSpaceContext.setNewDataSpace({
				...createEditDataSpaceContext.newDataSpace,
				fantasyLeague: undefined,
			});
			setLeagueType('Sport');
		}
	};

	useEffect(() => {
		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: {
				types: ['KissMetrics', 'AppInsights', 'Segment', 'GA4'],
				eventName:
					createEditDataSpaceContext.createEditDataSpaceMode === 'edit'
						? 'WebApp_User Visits Configure DataSpace Page In Edit DataSpace Form'
						: 'WebApp_User Visits Configure DataSpace Page In Create DataSpace Form',
			},
		});
	}, []);

	return (
		<NewDataSpacePageContentWrapper
			header={
				createEditDataSpaceContext.createEditDataSpaceMode === 'edit'
					? 'Edit DataSpace Name and Sport'
					: 'Set DataSpace Name and Sport'
			}
		>
			<Grid item container xs={12} className={classes.nameInputWrapper} justifyContent="start">
				<Grid item container xs sm={9} md={5}>
					<NameDataSpaceInput />
				</Grid>
			</Grid>

			<Grid item container xs={12} justifyContent="start" style={{ marginBottom: '1rem' }}>
				<SelectSportsLeague leagueType={leagueType} />
			</Grid>

			<Grid item container xs={12} alignItems={'center'} style={{ marginBottom: '1rem' }}>
				<Typography variant="h8" align="left">
					Use Fantasy League
				</Typography>
				<Switch checked={leagueType === 'Fantasy'} onChange={leagueToggleChangeHandler} />
			</Grid>

			<SelectFantasyLeague style={{ display: leagueType === 'Fantasy' ? 'block' : 'none' }} />

			<Grid item container xs={12} display="flex" justifyContent="end">
				<NextButton />
			</Grid>
		</NewDataSpacePageContentWrapper>
	);
};

export default ConfigureDataSpaceNew;
