import React, { useState, useContext } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as ListIcon } from '../../assets/icons/list.svg';
import { MenuItem, Menu, Divider, CircularProgress, Backdrop, makeStyles } from '@material-ui/core';
import './ListItem.css';
import { trackEvent, trackException } from '../../utils/eventTracking';
import { UserProfileContext } from '../../UserProfileContext';
import MyDataSpacesViewContext from './context/MyDataSpacesViewContext';
import {
	duplicateDataSpace,
	duplicateDataSpaceNew,
	getDataSpaces,
	updateDataSpaceStructure,
} from '../../api_helper/api';
import SnackbarAlertContext from '../../context/SnackbarAlertContext';
import CreateEditDataSpaceContext from './context/CreateEditDataSpaceContext';
import getEditDataSpaceData from '../../utils/GetEditDataSpaceData';
import getEditDataSpaceDataNew from '../../utils/GetEditDataSpaceDataNew';

const useStyles = makeStyles(() => ({
	loadingOverlay: {
		width: '100vw',
		height: '100vh',
		zIndex: '1500',
		color: '#FFFFFF',
	},
	listItem: {
		padding: '0.5rem 0.25rem',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	listItemInfo: {
		cursor: 'pointer',
	},
	dataSpaceType: { fontSize: '0.7rem', margin: '0' },
	menu: {
		zIndex: '10',
	},
}));

export default function ListItem(props) {
	const classes = useStyles();

	//Gets the userProfile currently being used
	const userProfile = useContext(UserProfileContext)[0];
	const snackbarAlertContext = useContext(SnackbarAlertContext);

	const [isLoading, setIsLoading] = useState(false);

	const myDataSpacesViewContext = useContext(MyDataSpacesViewContext);
	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);

	const [anchorEl, setAnchorEl] = useState(null);
	const handleClickContextMenu = (event) => {
		setAnchorEl(event.currentTarget);
		// Set ID of list item currently being interacted with
		props.setSelectedListItemDataSpace(props.dataSpace);
	};
	const handleCloseContextMenu = () => setAnchorEl(null);

	const handleClickViewEdit = async () => {
		setAnchorEl(null);

		setIsLoading(true);
		// If old DataSpace structure, update DataSpace before opening it.
		if (props.type) {
			try {
				await updateDataSpaceStructure(props.dataSpaceId);

				myDataSpacesViewContext.setMyDataSpacesView((prevState) => ({
					...prevState,
					selectedDataSpace: { name: props.title, id: props.dataSpaceId },
				}));
				myDataSpacesViewContext.changeViewHandler('dataSpaceViewNew');
				return;
			} catch (e) {
				setIsLoading(false);
				snackbarAlertContext.setSnackbarAlert({
					isSnackbarOpen: true,
					autohide: 5000,
					severity: 'error',
					msg: 'There was an error updating your DataSpace. Please try again later.',
				});
				return;
			}
		}

		myDataSpacesViewContext.setMyDataSpacesView((prevState) => ({
			...prevState,
			selectedDataSpace: { name: props.title, id: props.dataSpaceId },
		}));
		myDataSpacesViewContext.setSelectedDataSpace(props.dataSpace);
		myDataSpacesViewContext.changeViewHandler('dataSpaceViewNew');
	};

	const handleClickDeleteDataSpace = () => {
		setAnchorEl(null);
		props.handleDeleteDialogOpen();
	};

	const listItemOnClick = async () => {
		handleClickViewEdit();
		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: {
				types: ['KissMetrics', 'Segment', 'Encharge', 'GA4', 'AppInsights'],
				eventName: 'Existing DataSpace Opened Via List',
			},
		});
	};
	const viewEditButtonOnClick = async () => {
		handleClickViewEdit();
		const eventName = 'Existing DataSpace Opened Via Icon';
		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: { types: ['KissMetrics', 'AppInsights', 'Segment', 'Encharge', 'GA4'], eventName },
		});
	};

	const editDataSpaceButtonOnClick = async () => {
		try {
			trackEvent({
				userDetails: { userId: userProfile._id, email: userProfile.email },
				eventDetails: {
					types: ['KissMetrics', 'Encharge', 'GA4', 'AppInsights'],
					eventName: 'Edit Dataspace Button Clicked from DataSpaces List Item',
				},
			});

			setIsLoading(true);

			// If old DataSpace structure, update DataSpace before editing it.
			if (props.type) {
				try {
					await updateDataSpaceStructure(props.dataSpaceId);
				} catch (e) {
					setIsLoading(false);
					snackbarAlertContext.setSnackbarAlert({
						isSnackbarOpen: true,
						autohide: 5000,
						severity: 'error',
						msg: 'There was an error updating your DataSpace. Please try again later.',
					});
					return;
				}
			}

			const editDataSpaceData = await getEditDataSpaceDataNew(props.dataSpaceId, userProfile._id);

			createEditDataSpaceContext.setNewDataSpace({ ...editDataSpaceData });
			createEditDataSpaceContext.setCreateEditDataSpaceMode('edit');
			myDataSpacesViewContext.changeViewHandler('configureNewDataSpaceNew');
			setIsLoading(false);
		} catch (e) {
			trackException(e);
		}
	};

	const duplicateDataSpaceButtonOnClick = async () => {
		setIsLoading(true);
		try {
			props.type ? await duplicateDataSpace(props.dataSpaceId) : await duplicateDataSpaceNew(props.dataSpaceId);
			props.populateDataSpaces();
			props.setDataSpacesListTabsValue('Private DataSpaces');
			props.setPage(1);
			snackbarAlertContext.setSnackbarAlert({
				msg: 'Successfully duplicated DataSpace.',
				severity: 'success',
				autoHide: 5000,
				isSnackbarOpen: true,
			});
		} catch (e) {
			snackbarAlertContext.setSnackbarAlert({
				isSnackbarOpen: true,
				autohide: 5000,
				severity: 'error',
				msg: 'There was an error duplicating your DataSpace. Please try again later.',
			});
		}
		handleCloseContextMenu();
		setIsLoading(false);
	};

	const communityDataSpaceButtonOnClick = async () => {
		try {
			handleCloseContextMenu();
			// If old DataSpace structure, update DataSpace before editing it.
			if (props.type) {
				try {
					setIsLoading(true);
					await updateDataSpaceStructure(props.dataSpaceId);
					setIsLoading(false);
				} catch (e) {
					setIsLoading(false);
					snackbarAlertContext.setSnackbarAlert({
						isSnackbarOpen: true,
						autohide: 5000,
						severity: 'error',
						msg: 'There was an error updating your DataSpace. Please try again later.',
					});
					return;
				}
			}

			const dataSpaceRes = (await getDataSpaces(props.dataSpaceId)).data.dataSpace;
			myDataSpacesViewContext.setSelectedDataSpace(dataSpaceRes);
			myDataSpacesViewContext.setIsShareDataSpaceDialogOpen(true);
		} catch (e) {
			snackbarAlertContext.setSnackbarAlert({
				isSnackbarOpen: true,
				autoHide: 5000,
				severity: 'error',
				msg: 'There was an error opening your DataSpace. Please try again later.',
			});
		}
	};

	const deleteButtonOnClick = async () => {
		handleClickDeleteDataSpace();
		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: {
				types: ['KissMetrics', 'Segment', 'Encharge', 'GA4', 'AppInsights'],
				eventName: 'Selected Delete DataSpace',
			},
		});
	};

	return (
		<>
			<div className={classes.listItem}>
				<div onClick={listItemOnClick} className={classes.listItemInfo}>
					<p className={classes.dataSpaceType}>{props.type || 'My DataSpace'}</p>
					<p style={{ margin: '0' }}>{props.title}</p>
				</div>

				<SvgIcon id="icon" onClick={handleClickContextMenu}>
					<ListIcon />
				</SvgIcon>

				<Menu
					id="simple-menu"
					className={classes.menu}
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleCloseContextMenu}
				>
					<MenuItem onClick={viewEditButtonOnClick}>View</MenuItem>
					<MenuItem onClick={editDataSpaceButtonOnClick}>Edit</MenuItem>
					<MenuItem onClick={duplicateDataSpaceButtonOnClick}>Duplicate</MenuItem>
					<MenuItem onClick={communityDataSpaceButtonOnClick}>Share</MenuItem>
					<MenuItem onClick={deleteButtonOnClick}>Delete</MenuItem>
				</Menu>
			</div>
			<Divider style={{ width: '100%' }} id="divider" />

			<Backdrop open={isLoading} className={classes.loadingOverlay}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	);
}

// These will be added to the list when functionality is available:
//<MenuItem onClick={handleCloseContextMenu}>Add to Community</MenuItem>
//<MenuItem onClick={handleCloseContextMenu}>Share with...</MenuItem>
