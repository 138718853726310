import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { GlobalAlertProvider } from './GlobalAlertContext';
import { SnackbarAlertContextProvider } from './context/SnackbarAlertContext';
import { PageContextProvider } from './context/PageContext';

ReactDOM.render(
	<GlobalAlertProvider>
		<PageContextProvider>
			<SnackbarAlertContextProvider>
				<App />
			</SnackbarAlertContextProvider>
		</PageContextProvider>
	</GlobalAlertProvider>,
	document.getElementById('root'),
);
