import React, { useContext } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './utils/ApplicationInsights';
import { LoginContainer } from './components/login_registration/LoginContainer';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Resetpassword from './components/login_registration/ResetPassword';
import ForgottenPassword from './components/login_registration/ForgottenPassword';
import Authorization from './components/WithAuthorization';
import Register from './components/login_registration/Register';
import AppContainer from './components/app_container/AppContainer';
import { GlobalAlertContext } from './GlobalAlertContext';
import { Snackbar, Alert } from '@material-ui/core';
import { HeaderProvider } from './HeaderContext';
import { PreloadedDataProvider } from './PreloadedDataContext';
import { UserProfileProvider } from './UserProfileContext';
import { CreateEditDataSpaceContextProvider } from './components/my_dataspaces/context/CreateEditDataSpaceContext';
import { MyDataSpacesViewContextProvider } from './components/my_dataspaces/context/MyDataSpacesViewContext';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import SnackbarAlert from './components/ui_elements/SnackbarAlert';
import SharedDataSpaceView from './components/shared_dataspace_view';

const ROLES = require('./api_helper/roles').ROLES;
const browserHistory = createBrowserHistory();
const Admin = Authorization([ROLES.user, ROLES.admin]);

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#009CC6',
		},
		secondary: {
			main: '#FFA939',
			contrastText: '#fff',
		},
	},
});

const App = () => {
	return (
		<ThemeProvider theme={theme}>
			<Router history={browserHistory}>
				<HeaderProvider>
					<Route exact path="/" component={LoginContainer} />
					<Route exact path="/register" component={Register} />
					<Route exact path="/resetpassword/:resettoken" component={Resetpassword} />
					<Route exact path="/ForgottenPassword" component={ForgottenPassword} />
					<PreloadedDataProvider>
						<UserProfileProvider>
							<MyDataSpacesViewContextProvider>
								<CreateEditDataSpaceContextProvider>
									<Route exact path="/app" component={Admin(AppContainer)} />
								</CreateEditDataSpaceContextProvider>
								<Route path="/app/dataSpaces/:dataSpaceNameAndId" component={SharedDataSpaceView} />
							</MyDataSpacesViewContextProvider>
						</UserProfileProvider>
					</PreloadedDataProvider>
				</HeaderProvider>
			</Router>
			<SnackbarAlert />
		</ThemeProvider>
	);
};

export default withAITracking(reactPlugin, App, 'ai-tracker', 'ai-tracker');
