/*
This file represents a generic confirmation dialog. The onClick to the "OK"
button is a callback passed down from the parent component. The message is
customizable.
*/

import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

export default function ConfirmationDialog({ open, handleClose, dialogContent, affirmativeOnClick }) {
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">{dialogContent}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Cancel
				</Button>
				<Button onClick={affirmativeOnClick} color="primary" autoFocus>
					OK
				</Button>
			</DialogActions>
		</Dialog>
	);
}
