import { useContext, useState } from 'react';
import { Grid, makeStyles, FormControlLabel, Checkbox, Card } from '@material-ui/core';
import CreateEditDataSpaceContext from '../../context/CreateEditDataSpaceContext';
import JoinCard from './JoinCard';

const useStyles = makeStyles((theme) => ({
	joinCardsList: {
		width: 'fit-content',
		maxWidth: '100%',
		minHeight: '10rem',
		marginBottom: '2rem',
		display: 'flex',
		justifyContent: 'start',
		backgroundColor: 'transparent',
		overflowX: 'scroll',
	},
	joinCardColumn: {
		minWidth: '17rem',
		padding: '0 1rem',
		'& > h5': {
			margin: '0 0 0.8rem 0',
		},
		'& > h5': {
			color: '#009cc6',
		},
	},
	anchorCheckboxWrapper: {
		margin: '0',
		display: 'flex',
		flexDirection: 'row',
		'& > span': {
			paddingLeft: '0',
		},
	},
}));

// List of join cards showing joins, one card is rendered for each join.
// Dataspace anchor selection is also rendered as checkboxes, one for each selected datasource.
const JoinCardsList = () => {
	const classes = useStyles();
	const [selectedJoinId, setSelectedJoinId] = useState();

	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);

	// For each selected datasource, create a column of cards, one for each join to display the join data.
	const joinCardsColumnComponents = createEditDataSpaceContext.newDataSpace.joinData.map((dataset) => {
		const joinCardComponents = dataset.joins.map((join) => (
			<JoinCard
				key={join.primarySourceId}
				dataset={dataset}
				join={join}
				selectedJoinId={selectedJoinId}
				setSelectedJoinId={setSelectedJoinId}
			/>
		));

		// When the checkbox is clicked, set its respective datasource as the anchor for the dataspace.
		const anchorCheckboxClickHandler = () => {
			createEditDataSpaceContext.setNewDataSpace((prevState) => {
				const updatedJoinData = prevState.joinData.map((joinsDataset) => {
					if (joinsDataset._id === dataset._id) {
						return {
							...joinsDataset,
							isAnchor: true,
						};
					} else {
						return {
							...joinsDataset,
							isAnchor: false,
						};
					}
				});

				return {
					...prevState,
					joinData: updatedJoinData,
				};
			});
		};

		return (
			<div className={classes.joinCardColumn} style={{ display: 'flex', flexDirection: 'column' }}>
				<h5>
					{`${dataset.displayName} ${dataset.tags.timeFrame || ''}` +
						(createEditDataSpaceContext.newDataSpace.selectedDatasets.some(
							(selectedDataset) => dataset.name === selectedDataset.name && dataset._id !== selectedDataset._id,
						)
							? ` (${dataset._id.substring(21)})`
							: '') +
						` (${dataset.tags.api === 'DataFeedsGraphQL' ? 'DataFeeds' : dataset.tags.api})`}
				</h5>
				<FormControlLabel
					className={classes.anchorCheckboxWrapper}
					control={<Checkbox onChange={anchorCheckboxClickHandler} checked={dataset.isAnchor} />}
					label="Anchor"
					labelPlacement="start"
				/>
				{joinCardComponents}
			</div>
		);
	});

	return (
		<>
			<h5>Your Joins</h5>
			<Grid item xs={12}>
				<Card className={classes.joinCardsList}>{joinCardsColumnComponents}</Card>
			</Grid>
		</>
	);
};

export default JoinCardsList;
