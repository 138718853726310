import React, { useState } from 'react';

// Controls the data used to create the new dataspace throughout the dataspace creation pages.
const CreateEditDataSpaceContext = React.createContext({
	id: undefined,
	name: undefined,
	fantasyLeague: undefined,
	sportsLeague: undefined,
	selectedDatasets: [],
	joinData: [],
	views: [],
	allDatasets: [],
});

// CreateEditDataSpaceContextProvider is used to give all children components access to the CreateEditDataSpaceContext context.
// Wrap components that need access to the CreateEditDataSpaceContext with the CreateEditDataSpaceContextProvider context.
export const CreateEditDataSpaceContextProvider = (props) => {
	const [newDataSpace, setNewDataSpace] = useState({
		_id: undefined,
		name: undefined,
		fantasyLeague: undefined,
		sportsLeague: undefined,
		selectedDatasets: [],
		joinData: [],
		views: [],
		allDatasets: [],
	});

	const [createEditDataSpaceMode, setCreateEditDataSpaceMode] = useState();

	const resetNewDataSpace = (props) => {
		setNewDataSpace({
			_id: undefined,
			name: undefined,
			fantasyLeague: undefined,
			sportsLeague: undefined,
			selectedDatasets: [],
			joinData: [],
			views: [],
			allDatasets: [],
			calculatedColumns: [],
			headerNamesState: [],
			...props,
		});
	};

	const [connectedFantasyLeagues, setConnectedFantasyLeagues] = useState();

	return (
		<CreateEditDataSpaceContext.Provider
			value={{
				newDataSpace,
				setNewDataSpace,
				resetNewDataSpace,
				createEditDataSpaceMode,
				setCreateEditDataSpaceMode,
				connectedFantasyLeagues,
				setConnectedFantasyLeagues,
			}}
		>
			{props.children}
		</CreateEditDataSpaceContext.Provider>
	);
};

export default CreateEditDataSpaceContext;
