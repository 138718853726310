import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { useContext, useState } from 'react';
import MyDataSpacesViewContext from '../context/MyDataSpacesViewContext';
import { updateDataSpace_v2 } from '../../../api_helper/api';
import SnackbarAlertContext from '../../../context/SnackbarAlertContext';

const RenameViewDialog = (props) => {
	const myDataSpacesViewContext = useContext(MyDataSpacesViewContext);
	const snackbarAlertContext = useContext(SnackbarAlertContext);

	const [formInputs, setFormInputs] = useState({
		name: props.editDeleteSelectedView.name,
	});

	const [isInputDisabled, setIsInputDisabled] = useState(false);

	const renameViewClickHandler = async () => {
		try {
			setIsInputDisabled(true);

			const isNameValid = props.isViewNameValid(formInputs.name);

			if (!isNameValid) {
				setIsInputDisabled(false);
				snackbarAlertContext.setSnackbarAlert({
					isSnackbarOpen: true,
					autoHide: 5000,
					severity: 'error',
					msg: 'View name is invalid or is already taken please select a different name.',
				});
				return;
			}

			// Update selected view with new name.
			let updatedView;

			const updatedViews = myDataSpacesViewContext.selectedDataSpace.views.map((view) => {
				if (view.id === props.editDeleteSelectedView.id) {
					updatedView = { ...view, name: formInputs.name };
					return updatedView;
				}
				return view;
			});

			// Update views property of DataSpace document.
			await updateDataSpace_v2({
				dataSpaceId: myDataSpacesViewContext.selectedDataSpace._id,
				updatedFields: {
					views: updatedViews,
				},
			});

			// Update selected view value for dropdown if we are updating the selected view.
			if (updatedView.id === props.selectedView.id) {
				props.setSelectedView(updatedView);
			}

			setIsInputDisabled(false);
			props.setIsRenameViewDialogOpen(false);

			props.setViewListRefreshRequired(true);
		} catch (e) {
			props.setIsRenameViewDialogOpen(false);
			snackbarAlertContext.setSnackbarAlert({
				isSnackbarOpen: true,
				autoHide: 5000,
				severity: 'error',
				msg: 'There was an error renaming your view. Please try again later.',
			});
		}
	};

	const formInputChangeHandler = (e) => {
		setFormInputs((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	return (
		<Dialog
			open={props.isRenameViewDialogOpen}
			onClose={() => {
				props.setIsRenameViewDialogOpen(false);
			}}
		>
			<DialogTitle>Rename View</DialogTitle>
			<DialogContent>
				<TextField
					value={formInputs.name}
					onChange={formInputChangeHandler}
					autoFocus
					margin="dense"
					label="Name"
					name="name"
					fullWidth
					variant="standard"
					disabled={isInputDisabled}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						props.setIsRenameViewDialogOpen(false);
					}}
				>
					Cancel
				</Button>
				<Button onClick={renameViewClickHandler}>Rename</Button>
			</DialogActions>
		</Dialog>
	);
};

export default RenameViewDialog;
