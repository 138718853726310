import { useContext, useState } from 'react';
import { makeStyles, Card, Typography, Grid, Button, SvgIcon } from '@material-ui/core';
import SingleSelect from '../../../ui_elements/SingleSelect';
import CreateEditDataSpaceContext from '../../context/CreateEditDataSpaceContext';
import SnackbarAlertContext from '../../../../context/SnackbarAlertContext';
import { ReactComponent as LinkIcon } from '../../../../assets/icons/link.svg';
import { ReactComponent as KeyIcon } from '../../../../assets/icons/key.svg';
import { v1 as uuidv1 } from 'uuid';
import JoinAnimationGIF from '../../../../assets/img/JoinAnimation.gif';
import { trackEvent } from '../../../../utils/eventTracking';
import { UserProfileContext } from '../../../../UserProfileContext';

const useStyles = makeStyles((theme) => ({
	createJoinCard: {
		width: '100%',
		height: 'fit-content',
		padding: '1rem',
	},
	joinAnimationWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'& > img': {
			width: '100%',
			maxWidth: '10rem',
		},
	},
	dataSelectCardsWrapper: {
		marginBottom: '1rem',
		justifyContent: 'space-between',
	},
	dataSelectCard: {
		padding: '1rem',
		marginBottom: '1rem',
	},
	dataSelectHeader: {
		display: 'flex',
		alignItems: 'center',
		'& > svg': {
			marginRight: '0.4rem',
			verticalAlign: 'middle',
		},
	},
	submitButtom: {},
}));

// Form for new join. Inputs for primary datasource, primary field, foreign datasource, and foreign field.
const CreateJoinCard = () => {
	const classes = useStyles();

	// User Profile Data
	const userProfile = useContext(UserProfileContext)[0];

	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);
	const snackbarAlertContext = useContext(SnackbarAlertContext);

	const [newJoinData, setNewJoinData] = useState({
		primaryDataset: undefined,
		primaryField: undefined,
		foreignDataset: undefined,
		foreignField: undefined,
	});

	const primaryDatasetSelectChangeHandler = (e) => {
		setNewJoinData((prevState) => ({
			...prevState,
			primaryDataset: e.target.value,
			primaryField: undefined,
		}));
	};

	const primaryFieldSelectChangeHandler = (e) => {
		setNewJoinData((prevState) => ({
			...prevState,
			primaryField: e.target.value,
		}));
	};

	const foreignDatasetSelectChangeHandler = (e) => {
		setNewJoinData((prevState) => ({
			...prevState,
			foreignDataset: e.target.value,
			foreignField: undefined,
		}));
	};

	const foreignFieldSelectChangeHandler = (e) => {
		setNewJoinData((prevState) => ({
			...prevState,
			foreignField: e.target.value,
		}));
	};

	const validateNewJoinForm = () => {
		// Validate that all input fields are filled.
		if (
			!newJoinData.primaryDataset ||
			!newJoinData.primaryField ||
			!newJoinData.foreignDataset ||
			!newJoinData.foreignField
		) {
			throw 'Please make a selection for all fields.';
		}
		// Validate that primary and foreign datasets are different.
		else if (newJoinData.primaryDataset._id === newJoinData.foreignDataset._id) {
			throw 'You cannot make a join with one source. Please choose another source.';
		}

		// Validate that join does not already exist.
		for (let datasource of createEditDataSpaceContext.newDataSpace.joinData) {
			for (let join of datasource.joins) {
				if (
					datasource.primarySource === newJoinData.primaryDataset.name &&
					join.primaryFieldPropertyName === newJoinData.primaryField.datasetId &&
					join.foreignSource === newJoinData.foreignDataset.name &&
					join.foreignFieldPropertyName === newJoinData.foreignField.datasetId
				) {
					throw 'Join already exists.';
				}
			}
		}
		return;
	};

	const newJoinButtonClickHandler = () => {
		const id = uuidv1();

		try {
			validateNewJoinForm();
		} catch (e) {
			snackbarAlertContext.setSnackbarAlert({
				isSnackbarOpen: true,
				severity: 'error',
				autoHide: 5000,
				msg: e,
			});
			return;
		}

		const primaryDatasetJoin = {
			foreignField: newJoinData.foreignField.displayId,
			foreignFieldId: newJoinData.foreignField._id,
			foreignFieldPropertyName: newJoinData.foreignField.datasetId,
			foreignSource: newJoinData.foreignDataset.name,
			foreignSourceId: newJoinData.foreignDataset._id,
			id,
			primaryField: newJoinData.primaryField.displayId,
			primaryFieldId: newJoinData.primaryDataset._id,
			primaryFieldPropertyName: newJoinData.primaryField.datasetId,
		};

		const foreignDatasetJoin = {
			foreignField: newJoinData.primaryField.displayId,
			foreignFieldId: newJoinData.primaryField._id,
			foreignFieldPropertyName: newJoinData.primaryField.datasetId,
			foreignSource: newJoinData.primaryDataset.name,
			foreignSourceId: newJoinData.primaryDataset._id,
			id,
			primaryField: newJoinData.foreignField.displayId,
			primaryFieldId: newJoinData.foreignDataset._id,
			primaryFieldPropertyName: newJoinData.foreignField.datasetId,
		};

		// Finds the dataspace respective to the primary dataset in joinData and adds the join object to its joins property.
		createEditDataSpaceContext.setNewDataSpace((prevState) => {
			prevState.joinData[
				createEditDataSpaceContext.newDataSpace.joinData.findIndex(
					(dataset) => dataset.primarySourceId === newJoinData.primaryDataset._id,
				)
			].joins.push(primaryDatasetJoin);

			prevState.joinData[
				createEditDataSpaceContext.newDataSpace.joinData.findIndex(
					(dataset) => dataset.primarySourceId === newJoinData.foreignDataset._id,
				)
			].joins.push(foreignDatasetJoin);

			return {
				...prevState,
			};
		});

		// Reset inputs.
		setNewJoinData({
			primaryDataset: undefined,
			primaryField: undefined,
			foreignDataset: undefined,
			foreignField: undefined,
		});

		// Event tracking for creating a join.
		const eventName =
			createEditDataSpaceContext.createEditDataSpaceMode === 'edit'
				? 'Successfully Created New Join in Edit Dataspace Form'
				: 'Successfully Created New Join in New Dataspace Form';
		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: { types: ["KissMetrics", 'Segment', 'Encharge'], eventName },
		});
	};

	return (
		<Card className={classes.createJoinCard}>
			<Grid container>
				<Grid container xs={12} className={classes.dataSelectCardsWrapper}>
					<Grid item direction="column" xs={12} sm={4.4}>
						<Card className={classes.dataSelectCard}>
							<Typography variant="h6" className={classes.dataSelectHeader}>
								Connect
							</Typography>
							<SingleSelect
								mode="labelValue"
								currentSelection={newJoinData.primaryDataset || ''}
								options={createEditDataSpaceContext.newDataSpace.selectedDatasets.map((datasource) => ({
									label: `${datasource.name}${datasource.season ? ` ${datasource.season}` : ''} (${datasource.api})`,
									value: datasource,
								}))}
								placeHolderText="Select Primary Data Set"
								handleChange={primaryDatasetSelectChangeHandler}
							/>
							<Typography variant="h6" className={classes.dataSelectHeader}>
								<SvgIcon>
									<KeyIcon />
								</SvgIcon>
								Using
							</Typography>
							<SingleSelect
								mode="labelValue"
								currentSelection={newJoinData.primaryField || ''}
								options={
									newJoinData.primaryDataset?.fields.map((field) => ({ label: field.displayId, value: field })) || []
								}
								placeHolderText="Select Primary Data Field"
								handleChange={primaryFieldSelectChangeHandler}
							/>
						</Card>
					</Grid>

					<Grid item xs={12} sm={3} className={classes.joinAnimationWrapper}>
						<img src={JoinAnimationGIF} alt="" />
					</Grid>

					<Grid item direction="column" xs={12} sm={4.4}>
						<Card className={classes.dataSelectCard}>
							<Typography variant="h6" className={classes.dataSelectHeader}>
								<SvgIcon>
									<LinkIcon />
								</SvgIcon>
								To
							</Typography>
							<SingleSelect
								mode="labelValue"
								currentSelection={newJoinData.foreignDataset || ''}
								options={createEditDataSpaceContext.newDataSpace.selectedDatasets.map((datasource) => ({
									label: `${datasource.name}${datasource.season ? ` ${datasource.season}` : ''} (${datasource.api})`,
									value: datasource,
								}))}
								placeHolderText="Select Foreign Data Set"
								handleChange={foreignDatasetSelectChangeHandler}
							/>
							<Typography variant="h6" className={classes.dataSelectHeader}>
								<SvgIcon>
									<KeyIcon />
								</SvgIcon>
								Using
							</Typography>
							<SingleSelect
								mode="labelValue"
								currentSelection={newJoinData.foreignField || ''}
								options={
									newJoinData.foreignDataset?.fields.map((field) => ({ label: field.displayId, value: field })) || []
								}
								placeHolderText="Select Foreign Data Field"
								handleChange={foreignFieldSelectChangeHandler}
							/>
						</Card>
					</Grid>
				</Grid>

				<Grid xs={12} display="flex" justifyContent="flex-end">
					<Button
						variant="contained"
						margin="normal"
						color="primary"
						className={classes.submitButton}
						onClick={newJoinButtonClickHandler}
					>
						Create Join
					</Button>
				</Grid>
			</Grid>
		</Card>
	);
};

export default CreateJoinCard;
