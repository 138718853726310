/*
This component is triggered after a user selects a plan in Pricing. The user is 
shown their selected plan and presented with a ChargifyJS form to input their payment info.
*/

import React, { useState, useEffect, useRef, useContext } from 'react';
import { UserProfileContext } from '../../../UserProfileContext';
import { lite, fan, pro } from './Tiers.js';
import TierCard from './TierCard';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, Alert, Snackbar } from '@material-ui/core';
import { chargifyJSConfig } from './ChargifyJSConfig';
import { purchasePlanOnChargify, getUserProfile } from '../../../api_helper/api';
import ConfirmationDialog from '../../../utils/ConfirmationDialog';

import './ChargifyForm.css';

const useStyles = makeStyles((theme) => ({
	'@global': {
		ul: {
			margin: 5,
			padding: 0,
			listStyle: 'none',
		},
	},
	card: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	cardHeader: {
		backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
	},
	buttonsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		margin: '10px',
	},
}));

export default function ChargifyForm(props) {
	const classes = useStyles();

	const setUserProfile = useContext(UserProfileContext)[1];
	const [userCanProceed, setUserCanProceed] = useState(false); // If User can proceed to RI
	const [processing, setProcessing] = useState(false); // True when payment is processing

	const showChargifyFields = props.chosenTier !== 'LITE'; // Boolean to disable CC form when free plan selected.

	// Select correct tier object
	const tierDictionary = {
		LITE: lite,
		FAN: fan,
		PRO: pro,
	};
	const tier = tierDictionary[props.chosenTier];

	const chargifyForm = useRef();
	const chargify = useRef(new window.Chargify());

	// Confirmation dialog props
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const handleConfirmationClose = () => {
		setConfirmationOpen(false);
	};
	const confirmationDialogContent = `You are about to purchase the ${tier.altTitle} Plan. Do you wish to proceed?`;
	const confirmationAffirmativeOnClick = async () => {
		// Action to take on confirmation
		// On confirmation, we purchase the plan. A snackbar is used to display any issues
		// encountered to the user.

		handleConfirmationClose();
		setProcessing(true);

		let token = '';
		if (props.chosenTier !== 'LITE') {
			// No token required for free plan.
			try {
				token = await submitChargifyFormForToken();
			} catch (e) {
				setProcessing(false);
				displaySnackBar('info', 'Your payment info appears to be incorrect. Please review and try again.');
				return;
			}
		}
		try {
			const res = await purchasePlanOnChargify(token, tier.title);

			if (res.active) {
				setUserCanProceed(true);
			} else {
				// In this scenario the plan was purchased properly but the change was not reflected in the user's RI account.
				displaySnackBar('error', 'There was an issue updating your plan in SportWise. Please contact support.');
			}
		} catch (err) {
			// In this severity there was an error during the Chargify purchasing process.
			displaySnackBar('error', 'There was an issue purchasing your plan. Please contact support.');
		}

		setProcessing(false);
	};

	// Snackbar
	const [snackOpen, setSnackOpen] = useState(false);
	const [snackSeverity, setSnackSeverity] = useState('error');
	const [snackMessage, setSnackMessage] = useState('');
	function displaySnackBar(severity, message) {
		setSnackSeverity(severity); // info, warning, or success
		setSnackMessage(message);
		setSnackOpen(true);
	}

	// Submites the ChargifyJS form. Returns a token representing the user's
	// payment info.
	const submitChargifyFormForToken = () => {
		return new Promise((resolve, reject) => {
			chargify.current.token(
				chargifyForm.current,
				(token) => {
					resolve(token);
				},
				(error) => {
					reject(error);
				},
			);
		});
	};

	const purchaseOnClick = async () => {
		setConfirmationOpen(true);
	};

	// Allows user back into SportWise
	const returnToRollingInsightsOnClick = async () => {
		// Updates user object in context including new active status
		// UseEffect in AppContainer closes modal.
		const user = await getUserProfile();
		setUserProfile(user);
	};

	// Load ChargifyJS config
	useEffect(() => {
		chargify.current.load(chargifyJSConfig);
		return undefined;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			{userCanProceed ? (
				<div>
					<p>Your purchase was successful.</p>
					<Button variant="contained" color="primary" onClick={() => returnToRollingInsightsOnClick()}>
						Return to SportWise
					</Button>
				</div>
			) : (
				<div class="chargify_form_col_container">
					<div class="chargify_form_row">
						{showChargifyFields && (
							<div class="chargify_form_col">
								<form ref={chargifyForm}>
									<div id="first_name"></div>
									<div id="last_name"></div>
									<div id="cc_number"></div>
									<div id="cc_month"></div>
									<div id="cc_year"></div>
									<div id="cc_cvv"></div>
								</form>
							</div>
						)}
						<div class="chargify_form_col">
							<TierCard tier={tier} />
						</div>
					</div>

					<div className={classes.buttonsContainer}>
						<Button variant="contained" color="primary" disabled={processing} onClick={(e) => purchaseOnClick(e)}>
							Purchase
						</Button>

						{processing && <CircularProgress />}

						<Button variant="contained" color="primary" disabled={processing} onClick={() => props.goBackOnClick()}>
							Choose a different plan
						</Button>
					</div>
				</div>
			)}
			<ConfirmationDialog
				open={confirmationOpen}
				handleClose={handleConfirmationClose}
				dialogContent={confirmationDialogContent}
				affirmativeOnClick={confirmationAffirmativeOnClick}
			/>

			<Snackbar
				open={snackOpen}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				onClose={() => {
					setSnackOpen(false);
				}}
			>
				<Alert severity={snackSeverity}>{snackMessage}</Alert>
			</Snackbar>
		</div>
	);
}
