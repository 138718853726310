import { useContext } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { SvgIcon } from '@mui/material';
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';
import LooksTwoOutlinedIcon from '@mui/icons-material/LooksTwoOutlined';
import NameDataSpaceInput from './NameDataSpaceInput';
import SelectFantasyLeague from './SelectFantasyLeague';
import SelectSportsLeague from './SelectSportsLeague';
import NextButton from './NextButton';
import { ReactComponent as OrIcon } from '../../../../assets/icons/or.svg';
import NewDataSpacePageContentWrapper from '../ui_components/NewDataSpacePageContentWrapper';
import CreateEditDataSpaceContext from '../../context/CreateEditDataSpaceContext';

const useStyles = makeStyles((theme) => ({
	nameInputWrapper: {
		marginBottom: '2rem',
	},
	stepIconGrid: {
		marginRight: '0.8rem',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRight: '3px solid #D5D5D5',
	},
	stepIcon: {
		height: '100%',
		fontSize: '2.5rem',
		color: '#D5D5D5',
	},
	orIcon: {
		fontSize: '4.5rem',
	},
	sportsLeagueSelectImage: {
		maxWidth: '25%',
		maxHeight: '100%',
		'&:hover': {
			borderRadius: '5px',
			boxShadow: 'inset 0 0 0 1px #000',
		},
	},
}));

// First page of the new dataspace form.
// The components collects the dataspace name, and the league selected (either a sports or fantasy league) and stores this data in the CreateEditDataSpaceContext context.
const ConfigureDataSpace = () => {
	const classes = useStyles();

	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);

	return (
		<NewDataSpacePageContentWrapper
			header={createEditDataSpaceContext.createEditDataSpaceMode === 'edit' ? 'Edit DataSpace Name and Sport' : 'Set DataSpace Name and Sport'}
		>
			<Grid item container xs={12} className={classes.nameInputWrapper} justifyContent="start">
				<Grid item xs={1} className={classes.stepIconGrid}>
					<LooksOneOutlinedIcon className={classes.stepIcon} />
				</Grid>
				<Grid item container xs sm={9} md={5}>
					<NameDataSpaceInput />
				</Grid>
			</Grid>

			<Grid item container xs={12}>
				<Grid item xs={1} className={classes.stepIconGrid}>
					<LooksTwoOutlinedIcon className={classes.stepIcon} />
				</Grid>

				<Grid item container xs sm={9} md>
					<SelectFantasyLeague />

					<Grid item xs={6} md={1} display="flex" justifyContent="center" alignItems="center">
						<SvgIcon className={classes.orIcon}>
							<OrIcon />
						</SvgIcon>
					</Grid>

					<SelectSportsLeague />
				</Grid>
			</Grid>

			<Grid item container xs={12} display="flex" justifyContent="end">
				<NextButton />
			</Grid>
		</NewDataSpacePageContentWrapper>
	);
};

export default ConfigureDataSpace;
