import {
	getDataSpaces,
	getUsersLeagues,
	getLeagueObjects,
	getAPIObjectList,
	getDataSourcesAvailableFields,
	getDatasource,
} from '../api_helper/api';
import { trackException } from './eventTracking';

const getEditDataSpaceData = async (dataSpaceId, userId) => {
	try {
		// Initialize object used to populate createEditDataSpaceContext.
		const createEditDataSpaceContextData = { id: dataSpaceId, selectedDatasets: [] };

		// Get dataspace from database and populate dataspace name and views.
		const dataSpace = (await getDataSpaces(dataSpaceId)).res.dataSpace;
		const selectedDatasourcesIds = dataSpace.joinData.map((datasource) => datasource.primarySourceId);
		createEditDataSpaceContextData.name = dataSpace.dataSpaceName;
		createEditDataSpaceContextData.views = dataSpace.views;

		// Populate available datasources to select from and set datasources that have been selected.
		if (dataSpace.selectedLeagueId) {
			let getUserLeaguesRes = await getUsersLeagues(userId);
			getUserLeaguesRes = getUserLeaguesRes.leagues.find(
				(fantasyLeague) => fantasyLeague.league_key === dataSpace.selectedLeagueId,
			);
			createEditDataSpaceContextData.fantasyLeague = getUserLeaguesRes;

			let getLeagueObjectsRes = await getLeagueObjects(dataSpace.lmapiName, dataSpace.selectedSport);
			getLeagueObjectsRes = getLeagueObjectsRes.leagueObjectsFull.filter((league) =>
				selectedDatasourcesIds.includes(league._id),
			);

			createEditDataSpaceContextData.selectedDatasets = [...getLeagueObjectsRes];
		} else {
			createEditDataSpaceContextData.fantasyLeague = undefined;
			createEditDataSpaceContextData.sportsLeague = dataSpace.selectedSport;
		}

		// Get sports league datasets/datasources.
		let getAPIObjectListRes = await getAPIObjectList(dataSpace.selectedSport);
		let availableSportDatasources = getAPIObjectListRes.allData.filter((league) =>
			selectedDatasourcesIds.includes(league._id),
		);

		createEditDataSpaceContextData.selectedDatasets = await Promise.all(
			[...createEditDataSpaceContextData.selectedDatasets, ...availableSportDatasources].map(async (dataset) => {
				const fieldsRes = await getDataSourcesAvailableFields(dataset.dataSourceObjectType);
				return {
					...dataset,
					dataSpaceFields: fieldsRes,
				};
			}),
		);

		const defaultView = dataSpace.views.find((view) => view.name === 'Default');

		const selectedFieldsNames = defaultView.externalProperties.selectedColumns.map((column) => column.field);

		// Add isSelected properties to selected datasource fields.
		createEditDataSpaceContextData.selectedDatasets = createEditDataSpaceContextData.selectedDatasets.map((dataset) => {
			dataset.dataSpaceFields = dataset.dataSpaceFields.map((field) => {
				field.isSelected = selectedFieldsNames.includes(field.extendedFieldName);
				return field;
			});

			return dataset;
		});

		createEditDataSpaceContextData.joinData = await Promise.all(
			dataSpace.joinData.map(async (datasource) => {
				const getDataSourceRes = await getDatasource(datasource.primarySourceId);
				datasource.api = getDataSourceRes.api;
				return datasource;
			}),
		);

		return createEditDataSpaceContextData;
	} catch (e) {
		trackException(e);
		throw e;
	}
};

export default getEditDataSpaceData;
