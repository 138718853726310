import NBALogoPNG from '../assets/img/NBALogoSquare.png';
import MLBLogoPNG from '../assets/img/MLBLogoSquare.png';
import NFLLogoPNG from '../assets/img/NFLLogoSquare.png';
import NHLLogoPNG from '../assets/img/NHLLogoSquare.png';
import YahooFantasyLogoPNG from '../assets/img/YahooFantasyLogo.png';

const getDataSpaceLogo = (dataSpace) => {
	switch (dataSpace.datasets[0].tags.sport) {
		case 'NBA':
			return NBALogoPNG;
		case 'MLB':
			return MLBLogoPNG;
		case 'NFL':
			return NFLLogoPNG;
		case 'NHL':
			return NHLLogoPNG;
		case 'YahooNBA':
		case 'YahooMLB':
		case 'YahooNHL':
		case 'YahooNFL':
			return YahooFantasyLogoPNG;
	}
};

export default getDataSpaceLogo;
