import { makeStyles, Card, Grid } from '@material-ui/core';
import CreateJoinCard from './CreateJoinCard';
import JoinCardsList from './JoinCardsList';
import BackButton from './BackButton';
import CreateDataSpaceButton from '../ui_components/CreateDataSpaceButton';
import NewDataSpacePageContentWrapper from '../ui_components/NewDataSpacePageContentWrapper';

const useStyles = makeStyles((theme) => ({}));

// Join page of new dataspace form.
const DatasetJoin = () => {
	const classes = useStyles();

	return (
		<NewDataSpacePageContentWrapper header="Join Your DataSources">
			<CreateJoinCard />
			<JoinCardsList />
			<Grid item container xs={12} display="flex" justifyContent="space-between">
				<BackButton />
				<CreateDataSpaceButton />
			</Grid>
		</NewDataSpacePageContentWrapper>
	);
};

export default DatasetJoin;
