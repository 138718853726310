import { useContext, useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import { makeStyles, Grid, Typography, IconButton, Button } from '@material-ui/core';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import CreateEditDataSpaceContext from '../../context/CreateEditDataSpaceContext';
import NBALogoPNG from '../../../../assets/img/NBALogoSquare.png';
import MLBLogoPNG from '../../../../assets/img/MLBLogoSquare.png';
import NFLLogoPNG from '../../../../assets/img/NFLLogoSquare.png';
import NHLLogoPNG from '../../../../assets/img/NHLLogoSquare.png';
import YahooFantasyLogoPNG from '../../../../assets/img/YahooFantasyLogo.png';

const useStyles = makeStyles((theme) => ({
	dialogWrapper: {
		width: '100%',
		padding: '1.5rem',
		display: 'flex',
		flexDirection: 'column',
	},
	dialogHeaderWrapper: {
		width: '100%',
		marginBottom: '2rem',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	dialogHeaderText: {
		fontSize: '1.5rem',
		fontWeight: 'bold',
		marginLeft: 'auto',
	},
	dialogExitButton: {
		marginLeft: 'auto',
	},
	datasetWrapper: {
		display: 'flex',
	},
	datasetLogo: {
		height: '100px',
		marginRight: '0.8rem',
		borderRadius: '0.5rem',
	},
	datasetInfoWrapper: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	datasetField: {
		width: '100%',
		height: 'fit-content',
		padding: '0.3rem',
		backgroundColor: '#C4C4C4',
		borderRadius: '0.2rem',
		color: '#000000',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		'&:hover': {
			backgroundColor: '#A2A2A2',
		},
	},
	datasetFieldSelected: {
		backgroundColor: '#0DBC42',
		'&:hover': {
			backgroundColor: '#01982E',
		},
	},
	nextButton: {
		alignSelf: 'end',
	},
}));

// Dialog (modal) allowing user to edit the selected fields of a selected datasource.
const EditSelectedDataSourceFieldsDialog = (props) => {
	const classes = useStyles();
	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);

	const [dataset, setDataset] = useState(props.dataset);

	const getDatasetLogoSource = () => {
		switch (props.dataset.api) {
			case 'Reliant':
				switch (props.dataset.sport) {
					case 'NBA':
						return NBALogoPNG;
					case 'MLB':
						return MLBLogoPNG;
					case 'NFL':
						return NFLLogoPNG;
					case 'NHL':
						return NHLLogoPNG;
					default:
						return NBALogoPNG;
				}
			case 'Yahoo! Fantasy Sports':
				return YahooFantasyLogoPNG;
		}
	};

	const saveButtonClickHandler = () => {
		createEditDataSpaceContext.setNewDataSpace((prevState) => {
			const updatedSelectedDatasets = prevState.selectedDatasets.map((selectedDataset) => {
				if (selectedDataset._id === dataset._id) {
					return dataset;
				} else {
					return selectedDataset;
				}
			});

			return {
				...prevState,
				updatedSelectedDatasets,
			};
		});

		props.setIsEditSelectedDatasetFieldsDialogOpen(false);
	};

	const datasetFieldClickHandler = (e, clickedField) => {
		setDataset((prevState) => {
			const updatedFields = prevState.dataSpaceFields.map((field) => {
				if (field.extendedFieldName === clickedField.extendedFieldName) {
					field.isSelected = !field.isSelected;
				}

				return field;
			});

			return {
				...prevState,
				dataSpaceFields: updatedFields,
			};
		});
	};

	const datasetFieldComponents = dataset.dataSpaceFields.map((field) => (
		<Grid key={props.dataset._id} item xs={12} sm={6}>
			<Button
				className={classes.datasetField + (field.isSelected ? ' ' + classes.datasetFieldSelected : '')}
				onClick={(e) => {
					datasetFieldClickHandler(e, field);
				}}
			>
				{field.header}
			</Button>
		</Grid>
	));

	const dialogCloseButtonClickHandler = () => {
		props.setIsEditSelectedDatasetFieldsDialogOpen(false);
	};

	useEffect(() => {
		setDataset(props.dataset);
	}, [props.dataset]);

	return (
		<Dialog fullWidth open={props.isEditSelectedDatasetFieldsDialogOpen}>
			<div className={classes.dialogWrapper}>
				<div className={classes.dialogHeaderWrapper}>
					<Typography className={classes.dialogHeaderText} variant="h2">
						Select Your Fields
					</Typography>
					<IconButton className={classes.dialogExitButton} size="medium" onClick={dialogCloseButtonClickHandler}>
						<DisabledByDefaultIcon fontSize="inherit" />
					</IconButton>
				</div>
				<div className={classes.datasetWrapper}>
					<img src={getDatasetLogoSource()} alt="Dataset logo." className={classes.datasetLogo} />
					<div className={classes.datasetInfoWrapper}>
						<Typography variant="h6">
							{props.dataset.name}
							{props.dataset.season ? ` (${props.dataset.season})` : ''}
						</Typography>
						<Typography>
							Select the fields from this dataset you would like to include in you dataspace. You can always add more
							from within your dataspace.
						</Typography>
						<Grid container spacing={0.5} className={classes.datasetFieldsWrapper}>
							{datasetFieldComponents}
						</Grid>
					</div>
				</div>
				<Button className={classes.nextButton} onClick={saveButtonClickHandler}>
					Save
				</Button>
			</div>
		</Dialog>
	);
};

export default EditSelectedDataSourceFieldsDialog;
