import { TextField } from '@material-ui/core';
import { useContext } from 'react';
import CommunityDataSpacesContext from '../../context/CommunityDataSpacesContext';
import FilterIcon from '@mui/icons-material/FilterAltOutlined';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';

// Search input for filtering DataSpces list by search term.
const SearchInput = () => {
	const communityDataSpacesContext = useContext(CommunityDataSpacesContext);

	const searchInputChangeHandler = (e) => {
		communityDataSpacesContext.setSearchQuery(e.target.value);
	};

	return (
		<div
			style={{ width: '100%', padding: '0.75rem', display: 'flex', backgroundColor: 'white', borderRadius: '0.2rem' }}
		>
			<SearchIcon style={{ width: '1.2rem', marginRight: '1rem' }} />
			<TextField
				style={{ width: '100%' }}
				onChange={searchInputChangeHandler}
				value={communityDataSpacesContext.searchQuery}
				type="search"
				variant="standard"
			/>
		</div>
	);
};

export default SearchInput;
