import { useContext } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { getDataSpaces } from '../../../../api_helper/api';
import SnackbarAlertContext from '../../../../context/SnackbarAlertContext';
import CreateEditDataSpaceContext from '../../context/CreateEditDataSpaceContext';
import MyDataSpacesViewContext from '../../context/MyDataSpacesViewContext';
import { trackEvent } from '../../../../utils/eventTracking';
import { UserProfileContext } from '../../../../UserProfileContext';

const useStyles = makeStyles((theme) => ({
	nextButton: {},
}));

// The NextButton component renders the "Next" button and when clicked, verifies the data entered and sets the view to the next page of the dataspace creation process.
const NextButton = () => {
	const classes = useStyles();

	// User Profile Data
	const userProfile = useContext(UserProfileContext)[0];

	const snackbarAlertContext = useContext(SnackbarAlertContext);
	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);
	const myDataSpacesViewContext = useContext(MyDataSpacesViewContext);

	const verifyNewDataSpace = async (newDataSpace) => {
		// Check is dataspace name is empty.
		if (!newDataSpace.name) {
			return {
				isValid: false,
				errorMessage: 'Please enter a name for your DataSpace.',
			};
		}

		const dataSpaces = await getDataSpaces(null, userProfile._id);

		// If creating a new dataspace, check if the dataspace name already exists.
		if (
			createEditDataSpaceContext.createEditDataSpaceMode === 'create' &&
			dataSpaces.find((ds) => ds.dataSpaceName === newDataSpace.name)
		) {
			return {
				isValid: false,
				errorMessage: 'You already have a DataSpace with this name. Please choose another name for your DataSpace.',
			};
		}

		// Check if a fantasy league or sports league has been selected.
		if (!newDataSpace.fantasyLeague && !newDataSpace.sportsLeague) {
			return {
				isValid: false,
				errorMessage: 'Please select a fantasy league or a sports league.',
			};
		}

		return { valid: true };
	};

	const nextButtonClickHandler = async () => {
		const isDataSpaceValid = await verifyNewDataSpace(createEditDataSpaceContext.newDataSpace);

		if (!isDataSpaceValid.valid) {
			snackbarAlertContext.setSnackbarAlert({
				msg: isDataSpaceValid.errorMessage,
				severity: 'error',
				autoHide: 5000,
				isSnackbarOpen: true,
			});

			return;
		}

		myDataSpacesViewContext.changeViewHandler('newDataSpaceSelectData');

		// Track successful Aadvance to data selection page of new dataspace form.
		const eventName =
			createEditDataSpaceContext.createEditDataSpaceMode === 'edit'
				? 'Advanced to Data Selection Page in Edit Dataspace Form'
				: 'Advanced to Data Selection Page in New Dataspace Form';
		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: { types: ["KissMetrics", 'Segment', 'Encharge', 'GA4'], eventName },
		});
	};

	return (
		<Button
			variant="contained"
			margin="normal"
			color="primary"
			className={classes.nextButton}
			onClick={nextButtonClickHandler}
		>
			Next
		</Button>
	);
};

export default NextButton;
