import React, { useContext } from 'react';
import NFL from '../../../../assets/img/image_select_images/NFL.png';
import NHL from '../../../../assets/img/image_select_images/NHL.png';
import NBA from '../../../../assets/img/image_select_images/NBA.png';
import MLB from '../../../../assets/img/image_select_images/MLB.png';
import CreateEditDataSpaceContext from '../../context/CreateEditDataSpaceContext';
import { makeStyles, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	sportsLeagueSelectImage: {
		maxWidth: '25%',
		maxHeight: '100%',
		'&:hover': {
			borderRadius: '5px',
			boxShadow: 'inset 0 0 0 1px #000',
		},
	},
	unselected: {
		opacity: '50%',
	},
}));

// Allows user to select a sports league as a datasource by selecting from a set of sports league logos.
const SelectSportsLeague = () => {
	const classes = useStyles();

	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);

	// If the sports league changes, reset all the newDataSpace properties but set the dataspace name as the existing name and sports league as the newly selected sports league.
	const clickHandler = (sportsLeague) => {
		const { name, id } = createEditDataSpaceContext.newDataSpace;
		if (sportsLeague !== createEditDataSpaceContext.newDataSpace.sportsLeague) {
			createEditDataSpaceContext.resetNewDataSpace();

			createEditDataSpaceContext.setNewDataSpace((prevState) => ({
				...prevState,
        id,
				name,
				sportsLeague: sportsLeague,
			}));
		}
	};

	return (
		<Grid item xs={12} md>
			<Typography variant="h6" align="left">
				Select a Sport
			</Typography>
			<Grid item display="flex" justifyContent="space-between" alignItems="center" maxWidth="500px">
				<img
					className={
						classes.sportsLeagueSelectImage +
						(createEditDataSpaceContext.newDataSpace.sportsLeague === 'NFL' ? '' : ' ' + classes.unselected)
					}
					src={NFL}
					alt="NFL"
					onClick={() => {
						clickHandler('NFL');
					}}
				/>
				<img
					className={
						classes.sportsLeagueSelectImage +
						(createEditDataSpaceContext.newDataSpace.sportsLeague === 'NHL' ? '' : ' ' + classes.unselected)
					}
					src={NHL}
					alt="NHL"
					onClick={() => {
						clickHandler('NHL');
					}}
				/>
				<img
					className={
						classes.sportsLeagueSelectImage +
						(createEditDataSpaceContext.newDataSpace.sportsLeague === 'NBA' ? '' : ' ' + classes.unselected)
					}
					src={NBA}
					alt="NBA"
					onClick={() => {
						clickHandler('NBA');
					}}
				/>
				<img
					className={
						classes.sportsLeagueSelectImage +
						(createEditDataSpaceContext.newDataSpace.sportsLeague === 'MLB' ? '' : ' ' + classes.unselected)
					}
					src={MLB}
					alt="MLB"
					onClick={() => {
						clickHandler('MLB');
					}}
				/>
			</Grid>
		</Grid>
	);
};

export default SelectSportsLeague;
