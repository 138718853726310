// In this file we are importing SideNav components, styling them, and re-exporting them.

import styled from 'styled-components';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';

// SideNav
const StyledSideNav = styled(SideNav)`
	position: fixed;
	background-color: #ffffff;
	background-color: #fff;
	border-right: 1px solid #ddd;
`;
StyledSideNav.defaultProps = SideNav.defaultProps;

// Toggle
const StyledToggle = styled(Toggle)`
	background-color: #47a8bd;
`;
StyledToggle.defaultProps = Toggle.defaultProps;

export const LogoWrapper = styled.div`
	display: block;
`;

export const SideNavLogo = styled.img`
	width: 3.875rem;
`;

export const LogoContainer = styled.div`
	display: flex;
	align-items: center;
	& > p {
		margin-left: 5px;
	}
`;

const NavHeader = styled.div`
	display: ${(props) => (props.expanded ? 'block' : 'none')};
	white-space: nowrap;
	background-color: #47a8bd;
	color: #fff;

	> * {
		color: inherit;
		background-color: inherit;
	}
`;

// height: 20px + 10px + 10px = 40px
const NavTitle = styled.div`
	font-size: 2em;
	line-height: 20px;
	padding: 10px 0;
`;

// height: 20px + 4px = 24px;
const NavSubTitle = styled.div`
	font-size: 1em;
	line-height: 20px;
	padding-bottom: 4px;
`;

// Nav
const StyledNav = styled(Nav)`
	///background-color: #fff;

	&& > [class*='sidenav-navitem--'] {
		> [class*='navitem--'] {
			background-color: inherit;
			color: #e6f7fd;
		}
	}
	&& > [class*='sidenav-navitem--']:hover {
		> [class*='navitem--'] {
			background-color: #eee;
		}
	}
	&& > [class*='sidenav-navitem--'],
	&& > [class*='sidenav-navitem--']:hover {
		> [class*='navitem--'] {
			[class*='navicon--'] {
				&,
				> * {
					color: #666;
				}
			}
			[class*='sidenav-nav-text--'] {
				&,
				> * {
					color: #222;
				}
			}
		}
	}
	&& > [class*='sidenav-navitem--'][class*='highlighted--'],
	&& > [class*='sidenav-navitem--'][class*='highlighted--']:hover {
		> [class*='navitem--'] {
			background-color: #e6f7fd;
			cursor: pointer;

			[class*='navicon--'] {
				&,
				> * {
					color: #47a8bd;
				}
			}
			[class*='navtext--'] {
				&,
				> * {
					color: #171717;
				}
			}
			[class*='sidenav-nav-text--'] {
				font-weight: 700;
			}
		}
	}
`;

StyledNav.defaultProps = Nav.defaultProps;

// NavItem
const StyledNavItem = styled(NavItem)`
	&&& [class*='navtext--'] {
		color: #949494;
	}
	&&&:hover {
		[class*='navtext--'] {
			color: #222;
		}
	}
`;
StyledNavItem.defaultProps = NavItem.defaultProps;

// Removed StyledNavIcon and StyledNavText, as additional CSS did not seem to
// change anything. If needed please find in Styled Example on react-sidenav github.
// https://github.com/trendmicro-frontend/react-sidenav/tree/master/examples/Styled

export {
	StyledToggle as Toggle,
	StyledNav as Nav,
	StyledNavItem as NavItem,
	NavIcon,
	NavText,
	NavHeader,
	NavTitle,
	NavSubTitle,
};
export default StyledSideNav;
