import { TextField, InputAdornment, SvgIcon } from '@material-ui/core';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';

// Search input for filtering DataSpces list by search term.
const SearchInput = (props) => {
	const searchInputChangeHandler = (e) => {
		props.setSearchQuery(e.target.value);
	};

	return (
		<div style={{ width: '100%', marginBottom: '0.5rem', display: 'flex' }}>
			<TextField
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SvgIcon fontSize="1rem">
								<SearchIcon />
							</SvgIcon>
						</InputAdornment>
					),
				}}
				style={{ width: '100%' }}
				onChange={searchInputChangeHandler}
				value={props.searchQuery}
				type="search"
				variant="standard"
			/>
		</div>
	);
};

export default SearchInput;
