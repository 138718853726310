import { useState, useEffect, useContext } from 'react';
import { Grid, Button, Typography, makeStyles, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import SingleSelect from '../../../ui_elements/SingleSelect';
import { getUsersLeagues, yahooUpdateLeagues } from '../../../../api_helper/api';
import SnackbarAlertContext from '../../../../context/SnackbarAlertContext';
import CreateEditDataSpaceContext from '../../context/CreateEditDataSpaceContext';
import AddFantasyLeagueDialog from './AddFantasyLeagueDialog';
import { useLocation, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	singleSelect: {
		width: '25rem',
		marginBottom: '0.5rem',
	},
}));

// The SelectFantasyLeague is a dropdown input that allows the user to select a connected fantasy league as a datasource.
const SelectFantasyLeague = () => {
	let history = useHistory();
	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const classes = useStyles();

	// SnackbarAlertContext controls the popup alert.
	const snackbarAlertContext = useContext(SnackbarAlertContext);
	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);
	const [connectedFantasyLeagues, setConnectedFantasyLeagues] = useState(undefined);
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	// This function displays an Alert to the user based on a given severity.
	// Severities include success, info, and error.
	const displayAlert = (alertType, customAlert) => {
		let alertConfigs = {
			refreshSuccess: {
				msg: 'Leagues refreshed successfully.',
				severity: 'success',
				autoHide: 5000,
			},
			refreshInProgress: {
				msg: 'Working on refreshing leagues...',
				severity: 'info',
				autoHide: null,
			},
			refreshError: {
				msg: 'Error refreshing leagues. Please try again later',
				severity: 'error',
				autoHide: 5000,
			},
		};

		if (customAlert) {
			snackbarAlertContext.setSnackbarAlert({ ...customAlert, isSnackbarOpen: true });
		} else {
			snackbarAlertContext.setSnackbarAlert({ ...alertConfigs[alertType], isSnackbarOpen: true });
		}
	};

	// Calls the Azure function GetUsersLeagues and updates the
	const updateLeagues = async () => {
		let res = await getUsersLeagues();
		res.leagues = res.leagues.filter((league) => league.displayName !== 'None');
		setConnectedFantasyLeagues(res.leagues);
	};

	// Handles when a user selects a fantasy league.
	// Resets any selected sports league and sets the fantasy league property of the CreateEditDataSpaceContext.
	const selectedLeagueChangeHandler = (e) => {
		const { name, id } = createEditDataSpaceContext.newDataSpace;
		if (e.target.value !== createEditDataSpaceContext.newDataSpace.fantasyLeague?.displayName) {
			const selectedFantasyLeague = connectedFantasyLeagues.find(
				(connectedFantasyLeague) => connectedFantasyLeague.displayName === e.target.value,
			);

			createEditDataSpaceContext.resetNewDataSpace();
			createEditDataSpaceContext.setNewDataSpace((prevState) => ({
				...prevState,
				id,
				name,
				fantasyLeague: selectedFantasyLeague,
			}));
		}
	};

	const handleClickOpenDialog = () => {
		setIsDialogOpen(true);
	};

	// Stores any newly connected leagues to the database which can then be accessed via the updateLeagues function.
	const handleClickRefreshLeagues = async () => {
		try {
			await yahooUpdateLeagues(localStorage.getItem('jwt'));
		} catch (e) {
			displayAlert('refreshError');
			return; // Do not update leagues
		}

		// Retrieve updated leagues and update dropdown.
		try {
			await updateLeagues();
		} catch (e) {
			displayAlert('refreshError');
		}
	};

	// Populate User League dropdown.
	useEffect(() => {
		if (params.get('route') === 'dataSpaceForm') {
			try {
				handleClickRefreshLeagues();
				history.replace('/app');
			} catch (e) {
				displayAlert(null, { msg: 'Failed to fetch fantasy league(s).', severity: 'error', autoHide: 5000 });
			}
		} else {
			updateLeagues();
		}
	}, []);

	return (
		<Grid item container xs={12} md={6}>
			<AddFantasyLeagueDialog
				isDialogOpen={isDialogOpen}
				setIsDialogOpen={setIsDialogOpen}
				updateLeagues={updateLeagues}
			/>

			<Grid item xs={12}>
				<Typography variant="h6" align="left" marginBottom="0.5rem">
					Select Your Fantasy League
				</Typography>
			</Grid>

			<Grid item container xs={12}>
				<FormControl fullWidth>
					<InputLabel id="dataspace-form-fantasy-league-select">Select Fantasy League</InputLabel>
					<Select
						label="Select Fantasy League"
						labelId="dataspace-form-fantasy-league-select"
						className={classes.singleSelect}
						onChange={selectedLeagueChangeHandler}
						currentSelection={createEditDataSpaceContext.newDataSpace?.fantasyLeague?.name}
					>
						{connectedFantasyLeagues ? (
							connectedFantasyLeagues.length ? (
								connectedFantasyLeagues.map((connectedFantasyLeague) => (
									<MenuItem value={connectedFantasyLeague.displayName}>{connectedFantasyLeague.displayName}</MenuItem>
								))
							) : (
								<MenuItem disabled={true}>No leagues connected</MenuItem>
							)
						) : (
							<MenuItem disabled={true}>Loading...</MenuItem>
						)}
					</Select>
				</FormControl>
			</Grid>

			<Grid item container xs={12} md={12}>
				{createEditDataSpaceContext.createEditDataSpaceMode !== 'edit' ? (
					<Button size="small" variant="contained" color="secondary" margin="normal" onClick={handleClickOpenDialog}>
						Connect New Leagues
					</Button>
				) : null}
				<Button
					margin="normal"
					onClick={async () => {
						// Open info alert to tell user refresh is in progress
						displayAlert('refreshInProgress');
						await handleClickRefreshLeagues();
						displayAlert('refreshSuccess');
					}}
				>
					Refresh Leagues
				</Button>
			</Grid>
		</Grid>
	);
};

export default SelectFantasyLeague;
