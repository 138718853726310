/*
This component provides an interface for the user to save and load views.
*/

import React, { useState, useEffect, useContext } from 'react';
import { Dropdown } from 'primereact/dropdown';
import ViewNamingDialog from './ViewNamingDialog';
import { DateTime } from 'luxon';
import {
	appendToDataSpaceViewsNew,
	getDataSpaceViewsNew,
	getSelectedDataSpaceViewNew,
	updateDataSpace_v2,
} from '../../../api_helper/api';
import { makeStyles, Button, Grid, IconButton } from '@material-ui/core';
import { trackEvent } from '../../../utils/eventTracking';
import { UserProfileContext } from '../../../UserProfileContext';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RenameViewDialog from './RenameViewDialog';
import DeleteViewDialog from './DeleteViewDialog';
import MyDataSpacesViewContext from '../context/MyDataSpacesViewContext';
import { DataSpaceViewContext } from '../context/DataSpaceViewContext';
import SnackbarAlertContext from '../../../context/SnackbarAlertContext';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
	button: {
		height: 'fit-content',
		paddingTop: '0.4rem',
		paddingBottom: '0.4rem',
		minWidth: 'fit-content',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	viewMenuWrapper: {
		display: 'inline-flex',
		alignItems: 'center',
		marginBottom: '0.5rem',
		marginRight: 'auto',
		width: '100%',
		maxWidth: '25rem',
	},
	dropdown: {
		width: 0,
		flexGrow: 1,
	},
}));

export default function ViewManager(props) {
	const classes = useStyles();

	const { dataSpaceId, loadView, setIsViewLoading, getCurrentView } = props;

	const dataSpaceViewContext = useContext(DataSpaceViewContext);
	const myDataSpacesViewContext = useContext(MyDataSpacesViewContext);
	const userProfile = useContext(UserProfileContext)[0];
	const snackbarAlertContext = useContext(SnackbarAlertContext);

	// View Dropdown
	const [availableViews, setAvailableViews] = useState([]);
	const [selectedView, setSelectedView] = useState(null);
	const [isFirstLoad, setIsFirstLoad] = useState(true);

	// View Naming Modal
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [viewNameInput, setViewNameInput] = useState('');
	const [viewListRefreshRequired, setViewListRefreshRequired] = useState(true);
	const [dialogMessage, setDialogMessage] = useState('Please enter a name for your custom view');

	const [isRenameViewDialogOpen, setIsRenameViewDialogOpen] = useState(false);
	const [isDeleteViewDialogOpen, setIsDeleteViewDialogOpen] = useState(false);

	// View selected from dropdown for edit or delete dialogs.
	const [editDeleteSelectedView, setEditDeleteSelectedView] = useState();

	// User Profile Data
	const user = useContext(UserProfileContext)[0];

	const handleChangeViewNameInput = (event) => setViewNameInput(event.target.value);

	const handleDialogClose = () => {
		setIsDialogOpen(false);
		setDialogMessage('Please enter a name for your custom view');
		setViewNameInput('');
	};

	// Triggered when user clicks "Create View" button. Opens naming dialog
	const createViewClickHandler = () => {
		setIsDialogOpen(true);
	};

	const onViewChange = async (e) => {
		// Check view is selected
		if (e.value === null || e.value.length === 0) return;

		// Initiate loading screen
		setIsViewLoading(true);

		// Retrieve view properties
		const res = await getSelectedDataSpaceViewNew({ dataSpaceId: dataSpaceId, viewId: e.value.id }).catch((e) => {
			// On failure, exit load screen and log error
			setIsViewLoading(false);
		});

		// DateTime objects are converted to ISO strings when stored in Mongo.
		// Reconvert ISO strings in filterStates to DateTimes for compatibility with Date/DateTime filter logic
		res.view.externalProperties.filterStates = convertISOStringsToDateTimes(res.view.externalProperties.filterStates);

		// Load view into table
		loadView(res.view);

		// Exit load screen upon successful loading of view
		setIsViewLoading(false);
		setSelectedView(_.cloneDeep(res.view));
	};

	// Update selected view with current view.
	const saveViewClickHandler = async () => {
		const currentView = getCurrentView();
		currentView.name = selectedView.name;
		currentView.id = selectedView.id;

		let updatedView;

		const updatedViews = myDataSpacesViewContext.selectedDataSpace.views.map((view) => {
			if (view.id === selectedView.id) {
				updatedView = { ...view, ...currentView };
				updatedView.internalProperties.state.first = 0;
				return updatedView;
			}
			return view;
		});

		await updateDataSpace_v2({
			dataSpaceId: myDataSpacesViewContext.selectedDataSpace._id,
			updatedFields: {
				views: updatedViews,
			},
		});

		onViewChange({ value: { ...updatedView, id: updatedView.id } });

		myDataSpacesViewContext.setSelectedDataSpace((prevState) => ({
			...prevState,
			views: updatedViews,
			isCurrentViewSaved: true,
		}));

		dataSpaceViewContext.setDataSpace((prevState) => ({
			...prevState,
			views: updatedViews,
			isCurrentViewSaved: true,
		}));

		setSelectedView(_.cloneDeep(currentView));

		setViewListRefreshRequired(true);

		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: {
				types: ['KissMetrics', 'Segment', 'Encharge', 'GA4', 'AppInsights'],
				eventName: 'WebApp_User Saves View',
			},
		});
	};

	// This function detects Date/DateTime filterStates and converts their ISO String value to DateTime objects
	const convertISOStringsToDateTimes = (filterStates) => {
		for (const [key, value] of Object.entries(filterStates)) {
			// filterStates with properties startDate and endDate belong to Date based columns.
			if (value.startDate) {
				if (value.startDate !== null) filterStates[key].startDate = DateTime.fromISO(value.startDate);
				if (value.endDate !== null) filterStates[key].endDate = DateTime.fromISO(value.endDate);
			}
		}

		return filterStates;
	};

	// Triggered when user submits view name
	const onSubmitSaveView = async () => {
		if (isViewNameValid(viewNameInput)) {
			const view = getCurrentView();
			view.name = viewNameInput;
			view.internalProperties.state.first = 0;
			const viewId = await saveView(view);
			handleDialogClose();
			onViewChange({ value: { ...view, id: viewId } });

			myDataSpacesViewContext.setSelectedDataSpace((prevState) => ({
				...prevState,
				views: [...prevState.views, { ...view, id: viewId }],
			}));

			dataSpaceViewContext.setDataSpace((prevState) => ({
				...prevState,
				views: [...prevState.views, { ...view, id: viewId }],
				isCurrentViewSaved: true,
			}));
		}

		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: {
				types: ['KissMetrics', 'Segment', 'Encharge', 'GA4', 'AppInsights'],
				eventName: 'WebApp_User Creates New View',
			},
		});
	};

	// Check user has entered a valid name for their view
	const isViewNameValid = (viewName) => {
		// Check user has entered a value
		if (viewName === null || viewName.length === 0) {
			return false;
		}

		// Check value not already used as a view name
		const existingNames = availableViews.map((av) => av.name.toLowerCase());
		if (existingNames.includes(viewName.toLowerCase())) {
			if (viewName !== 'Default') {
				setDialogMessage(`The name ${viewName} already exists. Please choose a different name.`);
			}
			return false;
		}

		return true;
	};

	// Send view to backend to be saved to database
	const saveView = async (view) => {
		const payload = {
			dataSpaceId: dataSpaceId,
			view: view,
		};

		const appendToDataSpaceViewsRes = await appendToDataSpaceViewsNew(payload);
		setViewListRefreshRequired(true);
		return appendToDataSpaceViewsRes;
	};

	const displayViewChangeHandler = async (view) => {
		let updatedViews = myDataSpacesViewContext.selectedDataSpace.views.map((dataSpaceview) => ({
			...dataSpaceview,
			isDisplayView: dataSpaceview.id === view.id,
		}));

		await updateDataSpace_v2({
			dataSpaceId: myDataSpacesViewContext.selectedDataSpace._id,
			updatedFields: {
				views: updatedViews,
			},
		});

		dataSpaceViewContext.setDataSpace((prevState) => ({
			...prevState,
			views: updatedViews,
			isCurrentViewSaved: true,
		}));

		myDataSpacesViewContext.setSelectedDataSpace((prevState) => ({
			...prevState,
			views: updatedViews,
		}));

		setAvailableViews(
			updatedViews.map((view) => ({ name: view.name, id: view.id, isDisplayView: view.isDisplayView })),
		);

		snackbarAlertContext.setSnackbarAlert({
			isSnackbarOpen: true,
			autoHide: 5000,
			severity: 'success',
			msg: 'Display view changed successfully.',
		});

		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: {
				types: ['KissMetrics', 'Segment', 'Encharge', 'GA4', 'AppInsights'],
				eventName: 'WebApp_User Changes Display View From Select View Dropdown',
			},
		});
	};

	// Options for select view dropdown items
	const viewOptionTemplate = (view) => {
		return (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<span
					style={{
						marginRight: '2rem',
						minWidth: '0',
						width: '100%',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}
				>
					{view.name}
				</span>
				<IconButton
					size="small"
					style={{ marginLeft: 'auto' }}
					onClick={(e) => {
						e.stopPropagation();
						displayViewChangeHandler(view);
					}}
				>
					{view.isDisplayView ? <CheckCircleIcon fontSize="inherit" /> : <CheckCircleOutlinedIcon fontSize="inherit" />}
				</IconButton>
				{view.name != 'Default' && (
					<>
						<IconButton
							size="small"
							onClick={(e) => {
								e.stopPropagation();
								setEditDeleteSelectedView(view);
								setIsRenameViewDialogOpen(true);
							}}
						>
							<EditIcon fontSize="inherit" />
						</IconButton>
						<IconButton
							size="small"
							onClick={(e) => {
								e.stopPropagation();
								setEditDeleteSelectedView(view);
								setIsDeleteViewDialogOpen(true);
							}}
						>
							<DeleteIcon fontSize="inherit" />
						</IconButton>
					</>
				)}
			</div>
		);
	};

	const selectedViewTemplate = (view, props) => {
		if (selectedView) {
			return <span>{selectedView.name}</span>;
		}

		return <span>{props.placeholder}</span>;
	};

	// Reload list of user's saved views if user successfully saves a view
	useEffect(() => {
		const refreshViewList = async () => {
			if (viewListRefreshRequired) {
				try {
					const res = await getDataSpaceViewsNew(dataSpaceId);
					const views = res.views;
					setAvailableViews(views);
					dataSpaceViewContext.setDataSpace((prevState) => ({
						...prevState,
						views,
					}));
					setViewListRefreshRequired(false);
				} catch (e) {
					console.log(e);
				}
			}
		};
		refreshViewList();
	}, [viewListRefreshRequired, dataSpaceId]);

	// When opening the DataSpace, either load or create the Default view.
	useEffect(() => {
		const createDefaultView = async () => {
			const view = getCurrentView();
			view.name = 'Default';
			view.internalProperties.state.filters = {};
			view.internalProperties.state.multiSortMeta = [];
			view.externalProperties.frozenColumns = [];
			const viewId = await saveView(view);

			// Update DataSpace context to inlude new view.
			myDataSpacesViewContext.setSelectedDataSpace((prevState) => ({
				...prevState,
				views: [...prevState.views, { ...view, id: viewId }],
			}));
			onViewChange({ value: { ...view, id: viewId } });
			setIsFirstLoad(false);
		};

		const loadDisplayView = async () => {
			let initialView;

			initialView = availableViews.find((view) => view.isDisplayView);

			if (!initialView) {
				initialView = availableViews.find((view) => view.name === 'Default');
			}

			setIsFirstLoad(false);
			await onViewChange({ value: initialView });
		};

		if (isFirstLoad && !viewListRefreshRequired && isViewNameValid('Default')) {
			createDefaultView().catch(console.error);
		} else if (isFirstLoad && !viewListRefreshRequired) {
			loadDisplayView();
		}
	}, [availableViews, dataSpaceId, viewListRefreshRequired]);

	return (
		<>
			<div className={classes.viewMenuWrapper}>
				{/* Saved Views Dropdown */}
				<Dropdown
					className={classes.dropdown}
					value={selectedView}
					options={dataSpaceViewContext.dataSpace.views.map((view) => ({
						name: view.name,
						id: view.id,
						isDisplayView: view.isDisplayView,
					}))}
					panelStyle={{ width: '100%', maxWidth: '30rem' }}
					placeholder="Select a View"
					onChange={(e) => {
						onViewChange(e);
						trackEvent({
							userDetails: { userId: user._id, email: user.email },
							eventDetails: {
								types: ['KissMetrics', 'Segment', 'AppInsights', 'GA4'],
								eventName: 'WebApp_User Clicks View From Dropdown',
							},
						});
					}}
					itemTemplate={viewOptionTemplate}
					valueTemplate={selectedViewTemplate}
				/>

				{/* Save View Button */}
				{selectedView && (
					<Button
						className={classes.button}
						size="small"
						variant="outlined"
						color="primary"
						onClick={saveViewClickHandler}
						style={{ marginLeft: '0.5rem' }}
					>
						Save View
					</Button>
				)}
				<Button
					className={classes.button}
					size="small"
					variant="outlined"
					color="primary"
					onClick={createViewClickHandler}
					style={{ marginLeft: '0.5rem' }}
				>
					Create View
				</Button>
			</div>

			<ViewNamingDialog
				open={isDialogOpen}
				onClose={handleDialogClose}
				handleChangeNewViewName={handleChangeViewNameInput}
				dialogMessage={dialogMessage}
				onSubmitNewView={onSubmitSaveView}
				nameValue={viewNameInput}
			/>
			{isRenameViewDialogOpen && (
				<RenameViewDialog
					isRenameViewDialogOpen={isRenameViewDialogOpen}
					setIsRenameViewDialogOpen={setIsRenameViewDialogOpen}
					editDeleteSelectedView={editDeleteSelectedView}
					setEditDeleteSelectedView={setEditDeleteSelectedView}
					setViewListRefreshRequired={setViewListRefreshRequired}
					isViewNameValid={isViewNameValid}
					selectedView={selectedView}
					setSelectedView={setSelectedView}
				/>
			)}
			{isDeleteViewDialogOpen && (
				<DeleteViewDialog
					isDeleteViewDialogOpen={isDeleteViewDialogOpen}
					setIsDeleteViewDialogOpen={setIsDeleteViewDialogOpen}
					editDeleteSelectedView={editDeleteSelectedView}
					setEditDeleteSelectedView={setEditDeleteSelectedView}
					setViewListRefreshRequired={setViewListRefreshRequired}
				/>
			)}
		</>
	);
}
