// Dummy placeholder for navigation destination

import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import { trackEvent } from '../../utils/eventTracking';
import { UserProfileContext } from '../../UserProfileContext';

function MyData() {
	const userProfile = useContext(UserProfileContext)[0];
	const myDataButtonOnClick = async () => {
		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: {
				types: ['Segment', 'Encharge', 'GA4', 'AppInsights'],
				eventName: 'Registered Interest in Custom Data Sets',
			},
		});
	};

	return (
		<div>
			<h1>My Data</h1>
			<br />

			<h3>Coming Soon: Upload Your Own Data Sets</h3>
			<br />
			<p>
				Need more data? We're always adding more API data that auto refreshed each day but <br />
				sometimes you need your own data set. We're working on allowing you to upload an <br />
				excel or .csv file to use in your DataSpaces.
			</p>
			<br />
			<p>Want to be the first to try this out? Register your interest with the button below: </p>
			<br />
			<Button variant="contained" color="secondary" margin="normal" onClick={myDataButtonOnClick}>
				Put Me On The List!
			</Button>
			<br />
			<br />
			<p>
				Got an idea for a new feature?{' '}
				<a href="https://app.loopedin.io/sportwise-by-rolling-insights/ideas" target="_blank" rel="noreferrer">
					Tell us about it
				</a>{' '}
				and we might make it happen.
			</p>
		</div>
	);
}

export default MyData;
