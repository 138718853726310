import { useContext, useState, useEffect } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Button,
	Backdrop,
	CircularProgress,
	makeStyles,
	DialogContentText,
	Grid,
} from '@material-ui/core';
import { DataSpaceViewContext } from './context/DataSpaceViewContext';
import MyDataSpacesViewContext from './context/MyDataSpacesViewContext';
import { updateDataSpace_v2 } from '../../api_helper/api';
import SnackbarAlertContext from '../../context/SnackbarAlertContext';
import { trackEvent } from '../../utils/eventTracking';
import { UserProfileContext } from '../../UserProfileContext';
import AccessToggle from './AccessToggle';
import ViewSelector from './ViewSelector';
import SelectDataSpaceImage from './dataspace_view_new/SelectDataSpaceImage';

const useStyles = makeStyles(() => ({
	loadingOverlay: {
		width: '100vw',
		height: '100vh',
		zIndex: '1500',
		color: '#FFFFFF',
	},
	menu: {
		zIndex: '10',
	},
}));

const PublishDataSpaceDialog = (props) => {
	const classes = useStyles();

	const userProfile = useContext(UserProfileContext)[0];
	const myDataSpacesViewContext = useContext(MyDataSpacesViewContext);
	const dataSpaceViewContext = useContext(DataSpaceViewContext);
	const snackbarAlertContext = useContext(SnackbarAlertContext);

	const [isLoading, setIsLoading] = useState(false);

	const [isPermissionsUpdating, setIsPermissionsUpdating] = useState(false);

	const [formInputs, setFormInputs] = useState({
		name: '',
		description: '',
	});

	const formInputChangeHandler = (e) => {
		setFormInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
	};

	const formSubmitHandler = async () => {
		try {
			setIsLoading(true);

			// Validate name
			if (!formInputs.name) {
				snackbarAlertContext.setSnackbarAlert({
					isSnackbarOpen: true,
					autoHide: 5000,
					severity: 'error',
					msg: 'Please enter a Community DataSpace name for your DataSpace',
				});
				setIsLoading(false);
				return;
			}

			// Validate display view
			if (!myDataSpacesViewContext.selectedDataSpace.views.some((view) => view.isDisplayView)) {
				snackbarAlertContext.setSnackbarAlert({
					isSnackbarOpen: true,
					autoHide: 5000,
					severity: 'error',
					msg: 'Please select a view to display when the DataSpace is opened',
				});
				setIsLoading(false);
				return;
			}

			const updatedFields = {
				isPublic: true,
				publicName: formInputs.name,
				description: formInputs.description,
				publishedDate: Date.now(),
				originalAuthor: null,
			};
			// Set likes and impressions to 0 if DataSpace doesn't already have these fields.
			if (!myDataSpacesViewContext.selectedDataSpace.isPublic) {
				updatedFields.likes = 0;
				updatedFields.impressions = 0;
			}

			await updateDataSpace_v2({
				dataSpaceId: myDataSpacesViewContext.selectedDataSpace._id,
				updatedFields,
			});

			myDataSpacesViewContext.setSelectedDataSpace((prevState) => ({
				...prevState,
				...updatedFields,
			}));

			snackbarAlertContext.setSnackbarAlert({
				isSnackbarOpen: true,
				autoHide: 5000,
				severity: 'success',
				msg: 'Your DataSpace has been published!',
			});

			setIsLoading(false);
			shareDataSpaceDialogCloseHandler();

			if (props.populateDataSpaces) {
				await props.populateDataSpaces();
			}

			trackEvent({
				userDetails: { userId: userProfile._id, email: userProfile.email },
				eventDetails: {
					types: ['KissMetrics', 'AppInsights', 'Segment', 'GA4'],
					eventName: 'User published a DataSpace.',
				},
			});
		} catch (e) {
			snackbarAlertContext.setSnackbarAlert({
				isSnackbarOpen: true,
				autoHide: 5000,
				severity: 'error',
				msg: 'There was an error sharing your DataSpace. If this issue persists, please contact support@rolling-insights.com',
			});
			setIsLoading(false);
		}
	};

	const unpublishDataSpaceButtonClickHandler = async () => {
		try {
			setIsLoading(true);
			await updateDataSpace_v2({
				dataSpaceId: myDataSpacesViewContext.selectedDataSpace._id,
				updatedFields: {
					isPublic: false,
					publishedDate: undefined,
				},
			});

			myDataSpacesViewContext.setSelectedDataSpace((prevState) => ({
				...prevState,
				isPublic: false,
			}));

			if (props.populateDataSpaces) {
				await props.populateDataSpaces();
			}

			trackEvent({
				userDetails: { userId: userProfile._id, email: userProfile.email },
				eventDetails: {
					types: ['KissMetrics', 'AppInsights', 'Segment', 'GA4'],
					eventName: 'User unpublished a DataSpace.',
				},
			});
		} catch (e) {
			snackbarAlertContext.setSnackbarAlert({
				isSnackbarOpen: true,
				autoHide: 5000,
				severity: 'error',
				msg: 'There was an error sharing your DataSpace. If this issue persists, please contact support@rolling-insights.com',
			});
		}

		snackbarAlertContext.setSnackbarAlert({
			isSnackbarOpen: true,
			autoHide: 5000,
			severity: 'success',
			msg: 'Your DataSpace has been unpublished.',
		});

		setIsLoading(false);
		shareDataSpaceDialogCloseHandler();
	};

	const shareDataSpaceDialogCloseHandler = () => {
		myDataSpacesViewContext.setIsShareDataSpaceDialogOpen(false);
	};

	useEffect(() => {
		setFormInputs({
			name: myDataSpacesViewContext.selectedDataSpace?.name,
			description: myDataSpacesViewContext.selectedDataSpace?.description || '',
		});
	}, [myDataSpacesViewContext.selectedDataSpace]);

	return (
		<>
			<Dialog
				fullWidth
				open={myDataSpacesViewContext.isShareDataSpaceDialogOpen}
				onClose={shareDataSpaceDialogCloseHandler}
			>
				<DialogContent style={{ padding: '2rem 1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<div
						style={{
							width: '95%',
							marginBottom: '2rem',
							padding: '1rem',
							backgroundColor: '#F8F9FA',
							borderRadius: '6px',
							boxShadow: '0px 0px 6px 1px rgba(0,0,0,0.25)',
						}}
					>
						<h3 style={{ margin: '0', marginBottom: '0.5rem', textAlign: 'center' }}>Select what to share</h3>
						<DialogContentText style={{ textAlign: 'center' }}>
							By Sharing Publicly your DataSpace or selected views will be publicly visible to anyone with the link, but
							they will not be able to copy or edit it.
						</DialogContentText>
						<Grid container style={{ width: '100%', alignItems: 'center' }}>
							<Grid item xs={5}></Grid>
							<Grid
								item
								xs={3}
								style={{ display: 'flex', justifyContent: 'center', fontSize: '0.8rem', color: 'grey' }}
							>
								Active
							</Grid>
							<Grid item xs={4}></Grid>
						</Grid>
						{[
							myDataSpacesViewContext.selectedDataSpace,
							...(myDataSpacesViewContext.selectedDataSpace?.views || []),
						].map((option) => (
							<AccessToggle
								option={option}
								isPermissionsUpdating={isPermissionsUpdating}
								setIsPermissionsUpdating={setIsPermissionsUpdating}
							/>
						))}
					</div>
					<div
						style={{
							width: '95%',
							marginBottom: '2rem',
							padding: '1rem',
							backgroundColor: '#F8F9FA',
							borderRadius: '6px',
							boxShadow: '0px 0px 6px 1px rgba(0,0,0,0.25)',
						}}
					>
						{myDataSpacesViewContext.selectedDataSpace?.isPublic ? (
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								<h3 style={{ margin: '0', marginBottom: '0.5rem', textAlign: 'center' }}>Published DataSpace</h3>
								<DialogContentText style={{ width: '95%', textAlign: 'center' }}>
									This DataSpace is published to the Community Catalogue.
								</DialogContentText>
								<SelectDataSpaceImage />
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<Button variant="contained" onClick={unpublishDataSpaceButtonClickHandler}>
										Unpublish DataSpace
									</Button>
								</div>
							</div>
						) : (
							<>
								<h3 style={{ margin: '0', marginBottom: '0.5rem', textAlign: 'center' }}>Publish to Community</h3>
								<DialogContentText style={{ marginBottom: '1rem', textAlign: 'center' }}>
									By Publishing this DataSpace you are sharing your DataSpace to the Community Catalogue as a template
									for other SportWise users to create a copy and use. Thank you for helping to grow SportWise catalogue!
								</DialogContentText>
								{dataSpaceViewContext && !dataSpaceViewContext.dataSpace.isCurrentViewSaved && (
									<div style={{ color: 'red', marginBottom: '1.5rem', textAlign: 'center' }}>
										Your current view has not been saved. Consider saving your current view if you want to share it.
									</div>
								)}
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<SelectDataSpaceImage />
									<TextField
										style={{ marginBottom: '1rem', width: '100%' }}
										label="Name DataSpace for Community"
										variant="outlined"
										value={formInputs.name}
										name="name"
										onChange={formInputChangeHandler}
									/>

									<ViewSelector />

									<TextField
										multiline
										minRows={3}
										label="Brief Description"
										variant="outlined"
										name="description"
										value={formInputs.description}
										onChange={formInputChangeHandler}
										inputProps={{ maxLength: 300 }}
									/>
									<span style={{ fontSize: '0.8rem', marginLeft: 'auto', color: 'grey', marginTop: '0.25rem' }}>
										{formInputs.description.length}/300
									</span>
									<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
										<Button variant="contained" onClick={formSubmitHandler}>
											Publish
										</Button>
									</div>
								</div>
							</>
						)}
					</div>
					<div style={{ width: '95%' }}>
						<Button variant="outlined" onClick={shareDataSpaceDialogCloseHandler}>
							Close
						</Button>
					</div>
				</DialogContent>
			</Dialog>
			<Backdrop open={isLoading} className={classes.loadingOverlay}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	);
};

export default PublishDataSpaceDialog;
