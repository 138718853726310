/*
This component serves as a modal dialog which allows the user to enter
a name for the view they wish to save.
*/

import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewNamingDialog(props) {
	return (
		<div>
			<Dialog
				open={props.open}
				TransitionComponent={Transition}
				keepMounted
				onClose={props.onClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title">{'Save Your View'}</DialogTitle>

				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">{props.dialogMessage}</DialogContentText>
					<TextField
						autoFocus
						margin="dense"
						id="name"
						label="Name"
						type="text"
						autoComplete="off"
						value={props.nameValue}
						onChange={props.handleChangeNewViewName}
						fullWidth
					/>
				</DialogContent>

				<DialogActions>
					<Button onClick={() => props.onClose()} color="primary">
						Cancel
					</Button>
					<Button onClick={() => props.onSubmitNewView()} color="primary">
						Submit
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
