import { DataTable as PrimeReactDataTable } from 'primereact/datatable';
import { ObjectUtils } from 'primereact/utils';

class DataTable extends PrimeReactDataTable {
	// We need to override the default multisortField function to allow for sorting to handle null/undefined values.
	// This code comes from PrimeReact 7.0.1 so once PrimeReact is updated, this can be removed.
	multisortField(data1, data2, multiSortMeta, index) {
		const value1 = ObjectUtils.resolveFieldData(data1, multiSortMeta[index].field);
		const value2 = ObjectUtils.resolveFieldData(data2, multiSortMeta[index].field);
		let result = null;

		if (value1 === value2) {
			return multiSortMeta.length - 1 > index ? this.multisortField(data1, data2, multiSortMeta, index + 1) : 0;
		}

		if (value1 == null && value2 != null) result = -1;
		else if (value1 != null && value2 == null) result = 1;
		else if (value1 == null && value2 == null) result = 0;
		else if (typeof value1 === 'string' && typeof value2 === 'string')
			result = value1.localeCompare(value2, undefined, { numeric: true });
		else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

		return multiSortMeta[index].order * result;
	}
}

export default DataTable;
