import { useContext } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import MyDataSpacesViewContext from '../../context/MyDataSpacesViewContext';
import { UserProfileContext } from '../../../../UserProfileContext';
import { trackEvent } from '../../../../utils/eventTracking';
import CreateEditDataSpaceContext from '../../context/CreateEditDataSpaceContext';

const BackButton = () => {
	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);
	// User Profile Data
	const userProfile = useContext(UserProfileContext)[0];

	const myDataSpacesViewContext = useContext(MyDataSpacesViewContext);

	const returnToDataDirectory = () => {
		myDataSpacesViewContext.changeViewHandler('configureNewDataSpaceNew');
	};

	return (
		<Button variant="contained" margin="normal" color="primary" onClick={returnToDataDirectory}>
			Back
		</Button>
	);
};

export default BackButton;
