import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import logo from '../../assets/img/SportWiseLogoLarge.png';
import { Alert } from '@material-ui/lab';
import { removeEmptySpacesFromArray, validateEmail } from '../../utils/util';
import { ForgottenPasswordRequest } from '../../api_helper/api';
import './ForgottenPassword.css';

export class ForgottenPassword extends Component {
	//Object to bind the state of the form to
	state = {
		email: '',
		success: '',
		errors: this.props.location?.state?.errors,
	};

	handleChange = ({ target }) => {
		const { name, value } = target;
		this.setState({ [name]: value });
	};

	//If the Forgotten password page is accessed directly then we need to inspect the props for the method
	returnToLogin = () => {
		if (typeof this.props.nextView !== 'undefined') {
			this.props.nextView('mainLogin', 1);
		} else {
			this.props.history.push('/');
		}
	};

	submit = async (e) => {
		e.preventDefault();

		let errors = await this.verifyFields();
		// errors = this.cleanErrors(errors);
		errors = removeEmptySpacesFromArray(errors);

		// Send password reset request to function
		if (errors.length === 0) {
			console.log('Number of errors: ', errors.length);

			const payload = {
				email: this.state.email,
			};

			await ForgottenPasswordRequest(payload)
				.then((result) => {
					this.setState({ success: 'A password reset email has been sent to your email.' });
				})
				.catch((err) => {
					errors.push({ msg: err.data });
					this.setState({ errors });
				});
		} else {
			this.setState({ errors });
		}
	};

	// This function verifies that all fields are entered correctly
	// and that username or email are not previously registered.
	verifyFields = async () => {
		const email = this.state.email;
		const errors = [];

		// Synchronous error checks
		// Check required fields are entered
		if (!email) {
			errors.push({ msg: 'Please enter all required fields.' });
		}

		// Check if valid e-mail
		if (!validateEmail(email)) errors.push({ msg: 'Invalid email.' });
		return errors;
	};
	// This function returns a div containing a list of form errors
	displayErrors = (errors) => {
		if (!errors?.length) return null;

		// index is required for mapping
		return errors.map((error, index) => (
			<div key={index}>
				<p>{error.msg}</p>
			</div>
		));
	};

	//This function identifies an error has been triggered
	isErrors = (errors) => {
		if (errors?.length) return true;
	};

	render() {
		return (
			<Grid container className="forgotten-password--page-wrapper">
				<Grid item className="forgotten-password--logo-wrapper">
					<img src={logo} alt="SportWise logo." />
				</Grid>

				<Grid item>
					<h1 className="forgotten-password--header">Have you forgotten your password?</h1>
				</Grid>
				<Grid item>
					<p className="forgotten-password--secondary-header">
						Enter the email address associated with your account to receive a link by email to reset your password.
					</p>
				</Grid>
				{this.state.success && (
					<Alert variant="filled" severity="success">
						{' '}
						{this.state.success}
					</Alert>
				)}
				<Grid item>
					<form className="forgotten-password--form">
						<TextField
							className="forgotten-password--form-texfield"
							required
							name="email"
							label="Enter Account E-Mail"
							variant="outlined"
							margin="normal"
							onChange={this.handleChange}
							FormHelperTextProps={{
								component: 'div',
							}}
							helperText={this.displayErrors(this.state.errors)}
							error={this.isErrors(this.state.errors)}
						/>
						{/* Submit button for the form */}
						<Box m={2} pt={3}>
							<Grid item align="center">
								<Button variant="contained" color="secondary" margin="normal" onClick={this.submit}>
									Reset Password
								</Button>
							</Grid>
						</Box>

						<Grid item align="center">
							<Button
								onClick={() => {
									this.returnToLogin();
								}}
							>
								Nevermind, go back.
							</Button>
						</Grid>
					</form>
				</Grid>
			</Grid>
		);
	}
}

export default ForgottenPassword;
