import { makeStyles, Card, Grid } from '@material-ui/core';
import CreateJoinCard from './CreateJoinCard';
import JoinCardsList from './JoinCardsList';
import BackButton from './BackButton';
import CreateDataSpaceButton from '../ui_components/CreateDataSpaceButton';
import NewDataSpacePageContentWrapper from '../ui_components/NewDataSpacePageContentWrapper';
import { useContext, useEffect } from 'react';
import { trackEvent } from '../../../../utils/eventTracking';
import { UserProfileContext } from '../../../../UserProfileContext';
import CreateEditDataSpaceContext from '../../context/CreateEditDataSpaceContext';

const useStyles = makeStyles((theme) => ({}));

// Join page of new dataspace form.
const DatasetJoinNew = () => {
	const classes = useStyles();

	const userProfile = useContext(UserProfileContext)[0];
	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);

	useEffect(() => {
		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: {
				types: ['KissMetrics', 'Segment', 'Encharge', 'GA4', 'AppInsights'],
				eventName:
					createEditDataSpaceContext.createEditDataSpaceMode === 'edit'
						? 'WebApp_User Visits Join Page In Edit DataSpace Form'
						: 'WebApp_User Visits Join Page In Create DataSpace Form',
			},
		});
	}, []);

	return (
		<NewDataSpacePageContentWrapper header="Join Your DataSources">
			<CreateJoinCard />
			<JoinCardsList />
			<Grid item container xs={12} display="flex" justifyContent="space-between">
				<BackButton />
				<CreateDataSpaceButton />
			</Grid>
		</NewDataSpacePageContentWrapper>
	);
};

export default DatasetJoinNew;
