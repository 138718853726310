import React, { Component } from 'react';
import MainLogin from './MainLogin';
import ForgottenPassword from './ForgottenPassword';

export class LoginContainer extends Component {
	state = {
		viewId: 'mainLogin',
	};

	// Handles changing to correct view based on user action
	nextView = (viewId) => {
		this.setState({
			viewId: viewId,
		});
	};

	render() {
		const { viewId } = this.state;

		switch (viewId) {
			case 'mainLogin':
				return <MainLogin nextView={this.nextView} />;
			case 'forgottenPassword':
				return <ForgottenPassword nextView={this.nextView} />;
			default:
				return <div></div>;
		}
	}
}
