import axios from 'axios';

// This function retries a promise until it returns without error. An optional delay is
// configurable which adjusts time between attempts
export const retryPromise = (promise, retries = 5, err = null, delayMs = 0) => {
	function delay(time) {
		return new Promise((resolve) => setTimeout(resolve, time));
	}

	if (!retries) {
		return Promise.reject(err);
	}
	return promise().catch(async (err) => {
		await delay(delayMs);
		return retryPromise(promise, retries - 1, err, delayMs);
	});
};

// This function iterates through an array and removes any
// entries that are empty ( "" )
export const removeEmptySpacesFromArray = (array) => {
	for (var i = array.length - 1; i >= 0; i--) {
		if (array[i] === '') {
			array.splice(i, 1);
		}
	}
	return array;
};

// This function validates email and returns a boolean.
// ex: a@b.com returns true     a@.com returns false
export const validateEmail = (email) => {
	const rgx =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (!rgx.test(email)) return false;
	return true;
};

//This function validates if an object is empty and returns a boolean
//@param - object
export const isEmpty = (obj) => {
	for (var key in obj) {
		if (obj.hasOwnProperty(key)) return false;
	}
	return true;
};

/* Function: pollDurableFunction
 *
 *   This function takes a url returned by the inital call to a durable function and polls the url for an eventual result.
 *
 *   @param url {String}: URL returned by Durable function to be polled.
 *   @return {Object}: Returned value from Durable function.
 */
export const pollDurableFunction = async (url, timeInterval) => {
	const wait = (ms) => {
		return new Promise((resolve) => {
			setTimeout(resolve, ms);
		});
	};

	let result = { data: { runtimeStatus: 'Pending' } };
	while (!['Completed', 'Failed'].includes(result.data.runtimeStatus)) {
		await wait(timeInterval);
		result = await axios({
			url: url,
			method: 'GET',
		});
	}

	return result;
};
