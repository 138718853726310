/*
This component represents a card which displays information relating to a tier plan.

Setting the prop "cardOptions" to true enables a purchase/upgrade button at the bottom
of the card. This button receives an onClick the TierCard's parent component.
*/

import React from 'react';
import { Card, CardContent, CardHeader, CardActions, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
	'@global': {
		ul: {
			margin: 5,
			padding: 0,
			listStyle: 'none',
		},
	},
	card: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		margin: '10px',
	},
	cardHeader: {
		backgroundColor: '#1c76c9',
	},
	cardPricing: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'baseline',
		marginBottom: theme.spacing(2),
	},
}));

export default function TierCard({ tier, cardActions, tierOnClick }) {
	const classes = useStyles();

	return (
		<Card className={classes.card}>
			<CardHeader
				title={tier.title}
				subheader={tier.subheader}
				titleTypographyProps={{ align: 'center' }}
				subheaderTypographyProps={{ align: 'center' }}
				action={tier.title === 'FAN' ? <StarIcon /> : null}
				className={classes.cardHeader}
			/>
			<CardContent>
				<div className={classes.cardPricing}>
					<Typography component="h2" variant="h3" color="textPrimary">
						${tier.price}
					</Typography>
					<Typography variant="h6" color="textSecondary">
						/mo
					</Typography>
				</div>
				<ul>
					{tier.description.map((line) => (
						<Typography component="li" align="left" key={line}>
							{line}
						</Typography>
					))}
				</ul>
			</CardContent>
			{cardActions && (
				<CardActions className={classes.cardActions}>
					<Button fullWidth variant={tier.buttonVariant} color="primary" onClick={() => tierOnClick(tier.title)}>
						{tier.buttonText}
					</Button>
				</CardActions>
			)}
		</Card>
	);
}
