import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Button,
	Backdrop,
	CircularProgress,
	makeStyles,
	DialogContentText,
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from '@material-ui/core';
import { useContext, useState } from 'react';
import MyDataSpacesViewContext from './context/MyDataSpacesViewContext';
import { DataSpaceViewContext } from './context/DataSpaceViewContext';
import { UserProfileContext } from '../../UserProfileContext';
import { updateDataSpace_v2 } from '../../api_helper/api';
import SnackbarAlertContext from '../../context/SnackbarAlertContext';
import { trackEvent } from '../../utils/eventTracking';

const ViewSelector = (props) => {
	const myDataSpacesViewContext = useContext(MyDataSpacesViewContext);
	const dataSpaceViewContext = useContext(DataSpaceViewContext);
	const userProfile = useContext(UserProfileContext)[0];
	const snackbarAlertContext = useContext(SnackbarAlertContext);

	const [isSelectorDisabled, setIsSelectorDisabled] = useState(false);

	const viewSelectChangeHandler = async (e) => {
		setIsSelectorDisabled(true);

		let updatedViews = myDataSpacesViewContext.selectedDataSpace.views.map((view) => ({
			...view,
			isDisplayView: view.id === e.target.value.id,
		}));

		await updateDataSpace_v2({
			dataSpaceId: myDataSpacesViewContext.selectedDataSpace._id,
			updatedFields: {
				views: updatedViews,
			},
		});

		myDataSpacesViewContext.setSelectedDataSpace((prevState) => ({
			...prevState,
			views: updatedViews,
		}));

		myDataSpacesViewContext.setSelectedDataSpace((prevState) => ({
			...prevState,
			views: updatedViews,
		}));

		dataSpaceViewContext?.setDataSpace((prevState) => ({
			...prevState,
			views: updatedViews,
		}));

		setIsSelectorDisabled(false);

		snackbarAlertContext.setSnackbarAlert({
			isSnackbarOpen: true,
			autoHide: 5000,
			severity: 'success',
			msg: 'Display view changed successfully.',
		});

		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: {
				types: ['KissMetrics', 'Segment', 'Encharge', 'GA4', 'AppInsights'],
				eventName: 'WebApp_User Changes Display View From Share DataSpace Modal',
			},
		});
	};

	return (
		<FormControl fullWidth style={{ marginBottom: '1rem' }}>
			<InputLabel id="view-select-label">View</InputLabel>
			<Select
				labelId="season-select-label"
				id="view-select-input"
				label="View"
				onChange={viewSelectChangeHandler}
				value={myDataSpacesViewContext.selectedDataSpace?.views.find((view) => view.isDisplayView)}
				renderValue={(selected) => selected.name}
				disabled={isSelectorDisabled}
			>
				{(myDataSpacesViewContext.selectedDataSpace?.views || []).map((view) => (
					<MenuItem key={view._id} value={view}>
						{view.name}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default ViewSelector;
