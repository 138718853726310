import React, { useState, createContext } from 'react';

export const GlobalAlertContext = createContext();

export const GlobalAlertProvider = (props) => {
	const [globalAlertOpen, setGlobalAlertOpen] = useState(false);
	const [globalAlertSettings, setGlobalAlertSettings] = useState({
		autoHideDuration: 5000,
		severity: 'error',
		msg: '',
	});

    const values = {
        globalAlertOpen:[globalAlertOpen, setGlobalAlertOpen],
        globalAlertSettings:[globalAlertSettings, setGlobalAlertSettings]
	};
	
	return (
		<GlobalAlertContext.Provider value={values}>
			{props.children}
		</GlobalAlertContext.Provider>
	);
};
