import { useContext } from 'react';
import { Alert, Snackbar } from '@material-ui/core';

import SnackbarAlertContext from '../../context/SnackbarAlertContext';

// Component is a popup alert used to alert users of errors, successes, and other information.
// Listens to the SnackbarAlertContext so that any component can call it to be rendered via the context value.
// Needs to be called as a child of the SnackbarAlertContextProvider component so it can listen to the context value.
const SnackbarAlert = () => {
	const snackbarAlertContext = useContext(SnackbarAlertContext);

	// When snackbar duration ends, set isSnackbarOpen property of SnackbarAlertContext to false to close the popup on re-render.
	const snackbarCloseHandler = () => {
		snackbarAlertContext.setSnackbarAlert((prevState) => ({ ...prevState, isSnackbarOpen: false }));
	};

	return (
		<Snackbar
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			open={snackbarAlertContext.snackbarAlert.isSnackbarOpen}
			autoHideDuration={snackbarAlertContext.snackbarAlert.autoHide}
			onClose={snackbarCloseHandler}
		>
			<Alert severity={snackbarAlertContext.snackbarAlert.severity}>{snackbarAlertContext.snackbarAlert.msg}</Alert>
		</Snackbar>
	);
};

export default SnackbarAlert;
