import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../utils/TabPanel';
import { getDataForBillingPage } from '../../api_helper/api';
import { UserProfileForm } from './UserProfileForm';
import { InternalResetPasswordForm } from './InternalResetPasswordForm';
import Billing from './Billing';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
}));

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

export default function AccountContainer() {
	const classes = useStyles();
	const [value, setValue] = useState(0);

	const [billingDetails, setBillingDetails] = useState({
		state: '',
		product: '',
		billingPortalURL: '',
	});

	useEffect(() => {
		getDataForBillingPage().then((data) => {
			setBillingDetails(data);
		});
	}, []);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	return (
		<div className={classes.root}>
			<Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
				<Tab label="My Profile" {...a11yProps(0)} />
				<Tab label="Security" {...a11yProps(1)} />
				<Tab label="Billing" {...a11yProps(2)} />
			</Tabs>

			<TabPanel value={value} index={0}>
				<UserProfileForm />
			</TabPanel>

			<TabPanel value={value} index={1}>
				<InternalResetPasswordForm />
			</TabPanel>

			<TabPanel value={value} index={2}>
				<Billing details={billingDetails} />
			</TabPanel>
		</div>
	);
}
