import React, { useState } from 'react';

const CommunityDataSpacesContext = React.createContext();

// Context used for Community DataSpaces page.
// Sets selected DataSpaces, filter values, and sort values.
export const CommunityDataSpacesContextProvider = (props) => {
	const [selectedDataSpace, setSelectedDataSpace] = useState();
	const [dataSpaces, setDataSpaces] = useState();
	const [searchQuery, setSearchQuery] = useState('');
	const [filters, setFilters] = useState({
		sports: [],
		sportOptions: ['MLB', 'NBA', 'NFL', 'NHL'],
		datasets: [],
		datasetOptions: [
			'Player Stats',
			'Player Info',
			'Team Stats',
			'Team Info',
			'Injuries',
			'Schedule',
			'Players',
			'Teams',
			'Standings',
			'Draft Results',
			'Team Rosters',
		],
		fantasyApis: [],
		fantasyApiOptions: ['Yahoo! Fantasy Sports', 'Fantrax'],
		sortValue: 'recent',
	});

	return (
		<CommunityDataSpacesContext.Provider
			value={{
				dataSpaces,
				setDataSpaces,
				selectedDataSpace,
				setSelectedDataSpace,
				searchQuery,
				setSearchQuery,
				filters,
				setFilters,
			}}
		>
			{props.children}
		</CommunityDataSpacesContext.Provider>
	);
};

export default CommunityDataSpacesContext;
