import { useState, useContext } from 'react';
import { Dialog, Button } from '@material-ui/core';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../../utils/cropImage';
import MyDataSpacesViewContext from '../context/MyDataSpacesViewContext';
import { updateDataSpace_v2 } from '../../../api_helper/api';
import { DataSpaceViewContext } from '../context/DataSpaceViewContext';

const ImageCrop = () => {
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

	const dataSpaceViewContext = useContext(DataSpaceViewContext);
	const myDataSpacesViewContext = useContext(MyDataSpacesViewContext);

	const onCropComplete = (croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	};

	const cancelClickHandler = () => {
		myDataSpacesViewContext.setSelectedDataSpace((prevState) => ({
			...prevState,
			uncroppedDataSpaceImage: null,
		}));
		dataSpaceViewContext.setDataSpace((prevState) => ({
			...prevState,
			uncroppedDataSpaceImage: null,
		}));
		setZoom(1);
		setCrop({ x: 0, y: 0 });
	};

	const saveImage = async () => {
		const croppedImage = await getCroppedImg(
			myDataSpacesViewContext.selectedDataSpace.uncroppedDataSpaceImage,
			croppedAreaPixels,
			700,
			140,
		);

		let updatedDataSpace = await updateDataSpace_v2({
			dataSpaceId: myDataSpacesViewContext.selectedDataSpace._id,
			updatedFields: { croppedDataSpaceImage: croppedImage },
		});

		myDataSpacesViewContext.setSelectedDataSpace((prevState) => ({
			...prevState,
			uncroppedDataSpaceImage: undefined,
			dataSpaceImageUrl: updatedDataSpace.dataSpaceImageUrl,
		}));
		dataSpaceViewContext.setDataSpace((prevState) => ({
			...prevState,
			uncroppedDataSpaceImage: undefined,
			dataSpaceImageUrl: updatedDataSpace.dataSpaceImageUrl,
		}));
		setZoom(1);
		setCrop({ x: 0, y: 0 });
	};

	return (
		<Dialog open={myDataSpacesViewContext.selectedDataSpace.uncroppedDataSpaceImage}>
			<div
				style={{
					width: '30rem',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					padding: '3rem',
				}}
			>
				<p style={{ margin: '0 0 1rem 0' }}>Edit DataSpace image</p>
				<div style={{ width: '100%', aspectRatio: '5', position: 'relative' }}>
					<Cropper
						image={myDataSpacesViewContext.selectedDataSpace.uncroppedDataSpaceImage}
						crop={crop}
						zoom={zoom}
						aspect={5}
						onCropChange={setCrop}
						onCropComplete={onCropComplete}
						onZoomChange={setZoom}
						showGrid={false}
						objectFit="cover"
						style={{
							cropAreaStyle: {
								color: 'rgba(255, 255, 255, 0.9)',
								border: '1px solid rgba(0, 0, 0, 0.2)',
							},
						}}
					/>
				</div>
				<div style={{ display: 'flex', width: '80%' }}>
					<span style={{ margin: '0.5rem' }}>-</span>
					<input
						style={{ width: '100%' }}
						type="range"
						value={zoom}
						min={1}
						max={3}
						step={0.1}
						onChange={(e) => {
							setZoom(e.target.value);
						}}
					/>
					<span style={{ margin: '0.5rem' }}>+</span>
				</div>
				<div style={{ display: 'flex' }}>
					<Button
						variant="contained"
						color="primary"
						style={{ marginTop: '1rem', marginRight: '1rem' }}
						onClick={cancelClickHandler}
					>
						Cancel
					</Button>
					<Button variant="contained" color="secondary" style={{ marginTop: '1rem' }} onClick={saveImage}>
						Save
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default ImageCrop;
