import { useContext } from 'react';
import { Grid, Card, makeStyles, SvgIcon, Button } from '@material-ui/core';
import CreateEditDataSpaceContext from '../../context/CreateEditDataSpaceContext';
import { ReactComponent as LinkIcon } from '../../../../assets/icons/link.svg';
import { ReactComponent as KeyIcon } from '../../../../assets/icons/key.svg';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { trackEvent } from '../../../../utils/eventTracking';
import { UserProfileContext } from '../../../../UserProfileContext';

const useStyles = makeStyles((theme) => ({
	joinCard: {
		width: '15rem',
		padding: '1rem',
		marginBottom: '1rem',
		display: 'flex',
		flexDirection: 'column',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	selectedJoinCard: {
		border: '2px solid gold',
	},
	headerWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	joinInfoLine: {
		marginBottom: '0.3rem',
		'& > svg': {
			marginRight: '0.4rem',
			verticalAlign: 'middle',
		},
	},
}));

// Card showing datasources and their join fields for a given join.
// One is rendered for each join to visualize the joins made.
const JoinCard = (props) => {
	const classes = useStyles();

	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);
	// User Profile Data
	const userProfile = useContext(UserProfileContext)[0];

	// If delete join button is clicked, delete the join from joinData.
	const deleteJoinButtonClickHandler = () => {
		createEditDataSpaceContext.setNewDataSpace((prevState) => {
			const updatedJoinData = prevState.joinData.map((dataset) => {
				const updatedJoins = dataset.joins.filter((join) => join.id !== props.join.id);

				return {
					...dataset,
					joins: updatedJoins,
				};
			});

			return {
				...prevState,
				joinData: updatedJoinData,
			};
		});

		const eventName =
			createEditDataSpaceContext.createEditDataSpaceMode === 'edit'
				? 'Successfully Deleted a Join in Edit Dataspace Formm'
				: 'Successfully Deleted a Join in New Dataspace Form';
		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: { types: ["KissMetrics", 'Segment', 'Encharge', 'GA4'], eventName },
		});
	};

	// When a join card is clicked, the join card and the join card it's joined to are both highlighted.
	const highlightJoinButtonClickHandler = () => {
		props.setSelectedJoinId(props.join.id);

		createEditDataSpaceContext.setNewDataSpace((prevState) => {
			const updatedJoinData = prevState.joinData.map((dataset) => {
				const updatedJoins = dataset.joins.map((join) => {
					if (join.id === props.join.id) {
						join.isSelected = !join.isSelected;
					} else {
						join.isSelected = false;
					}

					return join;
				});
				return {
					...dataset,
					joins: updatedJoins,
				};
			});

			return {
				...prevState,
				joinData: updatedJoinData,
			};
		});
	};

	return (
		<Card
			key={props.join.primarySourceId}
			className={classes.joinCard + (props.join.isSelected ? ' ' + classes.selectedJoinCard : '')}
			onClick={highlightJoinButtonClickHandler}
		>
			<div className={classes.headerWrapper}>
				{props.dataset.primarySource}
				<IconButton onClick={deleteJoinButtonClickHandler}>
					<DeleteIcon />
				</IconButton>
			</div>
			<div className={classes.joinInfoLine}>
				<SvgIcon>
					<KeyIcon />
				</SvgIcon>
				{props.join.primaryField}
			</div>
			<div className={classes.joinInfoLine}>
				<SvgIcon>
					<LinkIcon />
				</SvgIcon>
				{props.join.foreignSource}
			</div>
			<div className={classes.joinInfoLine}>
				<SvgIcon>
					<KeyIcon />
				</SvgIcon>
				{props.join.foreignField}
			</div>
		</Card>
	);
};

export default JoinCard;
