import React, { useContext, useEffect, useState } from 'react';
import CommunityDataSpacesContext from '../../context/CommunityDataSpacesContext';
import { Button, Dialog, DialogTitle, DialogContent, IconButton, makeStyles, Icon, SvgIcon } from '@material-ui/core';
import NBALogoPNG from '../../assets/img/NBALogoSquare.png';
import MLBLogoPNG from '../../assets/img/MLBLogoSquare.png';
import NFLLogoPNG from '../../assets/img/NFLLogoSquare.png';
import NHLLogoPNG from '../../assets/img/NHLLogoSquare.png';
import YahooFantasyLogoPNG from '../../assets/img/YahooFantasyLogo.png';
import DataSpacePreviewDialog from './DataSpacePreviewDialog';
import CreateDataSpaceButton from './CreateDataSpaceButton';
import SelectFantasyLeagueDialog from './SelectFantasyLeagueDialog';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { useLocation } from 'react-router-dom';
import { trackEvent } from '../../utils/eventTracking';
import { UserProfileContext } from '../../UserProfileContext';
import getLogo from '../../utils/getLogo';
import TwitterIcon from '@material-ui/icons/Twitter';
import WebsiteIcon from '@material-ui/icons/Language';
import RedditIcon from '@material-ui/icons/Reddit';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import { ReactComponent as TikTokIcon } from '../../assets/icons/tiktok.svg';
import getDataSpaceImageLink from '../../utils/getDataSpaceImageLink';
import DefaultProfilePng from '../../assets/img/DefaultProfile.png';

const useStyles = makeStyles((theme) => ({
	dialog: {
		overflow: 'auto',
		'& .MuiDialog-container': {
			minHeight: '40rem',
			padding: '2rem 0',
		},
		'& .MuiDialog-paper': {
			width: '95%',
			maxWidth: '40rem',
			height: '90%',
			maxHeight: '35rem',
			margin: 0,
		},
	},
}));

const getDataSpaceLogo = (dataSpace) => {
	switch (dataSpace.datasets[0].tags.sport) {
		case 'NBA':
			return NBALogoPNG;
		case 'MLB':
			return MLBLogoPNG;
		case 'NFL':
			return NFLLogoPNG;
		case 'NHL':
			return NHLLogoPNG;
		case 'YahooNBA':
		case 'YahooMLB':
		case 'YahooNHL':
		case 'YahooNFL':
			return YahooFantasyLogoPNG;
	}
};

const DataSpaceInfoDialog = () => {
	const classes = useStyles();

	const { search } = useLocation();
	const params = new URLSearchParams(search);

	const communityDataSpacesContext = useContext(CommunityDataSpacesContext);
	const userProfile = useContext(UserProfileContext)[0];

	const [isDataSpacePreviewDialogOpen, setIsDataSpacePreviewDialogOpen] = useState(false);

	const selectDataSpaceButtonClickHandler = () => {
		setIsDataSpacePreviewDialogOpen(true);

		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: {
				types: ['KissMetrics', 'AppInsights', 'Segment', 'GA4'],
				eventName: 'WebApp_User Previews A Community DataSpace',
			},
		});
	};

	useEffect(() => {
		const route = params.get('route');
		if (route?.startsWith('communityDataSpacePreview') && route.includes('dataSpaceId')) {
			// Parse DataSpace ID from url
			const dataSpaceId = route.substring(route.indexOf('?dataSpaceId=') + 13);

			// Find and set selected DataSpace.
			const selectedDataSpace = communityDataSpacesContext.dataSpaces?.find(
				(dataSpace) => dataSpace._id === dataSpaceId,
			);

			if (selectedDataSpace) {
				communityDataSpacesContext.setSelectedDataSpace(selectedDataSpace);

				setIsDataSpacePreviewDialogOpen(true);
			}
		}
	});

	return (
		<>
			<Dialog
				open={communityDataSpacesContext.selectedDataSpace}
				fullWidth={true}
				maxWidth="lg"
				className={classes.dialog}
			>
				{communityDataSpacesContext.selectedDataSpace && (
					<>
						<div
							style={{
								width: '100%',
								height: '8rem',
								backgroundImage: communityDataSpacesContext.selectedDataSpace.dataSpaceImageUrl
									? `url(${communityDataSpacesContext.selectedDataSpace.dataSpaceImageUrl})`
									: `url(${getDataSpaceImageLink(communityDataSpacesContext.selectedDataSpace)})`,
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								padding: '1rem 1rem',
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<img
								src={getDataSpaceLogo(communityDataSpacesContext.selectedDataSpace)}
								alt="DataSpace logo."
								style={{ width: '3.25rem', height: '3.25rem', borderRadius: '0.25rem', border: '1px solid white' }}
							/>
							<DisabledByDefaultIcon
								size="medium"
								style={{ cursor: 'pointer' }}
								onClick={() => {
									communityDataSpacesContext.setSelectedDataSpace(undefined);
								}}
							/>
						</div>
						<div style={{ width: '100%', height: '100%', padding: '1rem', display: 'flex', flexDirection: 'column' }}>
							<h3 style={{ margin: '0', marginBottom: '1rem', fontSize: '2rem', fontWeight: 'normal' }}>
								{communityDataSpacesContext.selectedDataSpace.publicName}
							</h3>

							<div style={{ display: 'flex' }}>
								<img
									style={{ width: '2.5rem', height: '2.5rem', borderRadius: '50%', marginRight: '0.5rem' }}
									src={communityDataSpacesContext.selectedDataSpace.createdBy.userProfileImageUrl || DefaultProfilePng}
									alt="Proflie picture."
								/>
								<div style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
									<p style={{ margin: '0 1rem 0.5rem 0' }}>
										{communityDataSpacesContext.selectedDataSpace.createdBy.username}
									</p>
									<div>
										<span>
											{new Date(communityDataSpacesContext.selectedDataSpace.publishedDate).toLocaleDateString()}
										</span>
									</div>
								</div>
							</div>

							<div style={{ marginTop: '0.5rem', fontSize: '0.8rem', color: '#1C95E0' }}>
								{communityDataSpacesContext.selectedDataSpace.createdBy.website && (
									<span style={{ display: 'flex', alignItems: 'center', marginTop: '0.25rem' }}>
										<Icon style={{ marginRight: '0.25rem', color: 'grey', fontSize: '1.2rem' }}>
											<WebsiteIcon style={{ fontSize: '1.2rem' }} />
										</Icon>
										<a
											target="_blank"
											href={communityDataSpacesContext.selectedDataSpace.createdBy.website}
											style={{
												width: '100%',
												color: '#1C95E0',
												textDecoration: 'none',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
											}}
										>
											{communityDataSpacesContext.selectedDataSpace.createdBy.website}
										</a>
									</span>
								)}
								{communityDataSpacesContext.selectedDataSpace.createdBy.twitter && (
									<span style={{ display: 'flex', alignItems: 'center', marginTop: '0.25rem' }}>
										<Icon style={{ marginRight: '0.25rem', color: 'grey', fontSize: '1.2rem' }}>
											<TwitterIcon style={{ fontSize: '1.2rem' }} />
										</Icon>
										<a
											target="_blank"
											href={`https://x.com/${communityDataSpacesContext.selectedDataSpace.createdBy.twitter}`}
											style={{ color: '#1C95E0', textDecoration: 'none' }}
										>
											@{communityDataSpacesContext.selectedDataSpace.createdBy.twitter}
										</a>
									</span>
								)}
								{communityDataSpacesContext.selectedDataSpace.createdBy.reddit && (
									<span style={{ display: 'flex', alignItems: 'center', marginTop: '0.25rem' }}>
										<Icon style={{ marginRight: '0.25rem', color: 'grey', fontSize: '1.2rem' }}>
											<RedditIcon style={{ fontSize: '1.2rem' }} />
										</Icon>
										<a
											target="_blank"
											href={`https://www.reddit.com/user/${communityDataSpacesContext.selectedDataSpace.createdBy.reddit}`}
											style={{ color: '#1C95E0', textDecoration: 'none' }}
										>
											@{communityDataSpacesContext.selectedDataSpace.createdBy.reddit}
										</a>
									</span>
								)}
								{communityDataSpacesContext.selectedDataSpace.createdBy.linkedIn && (
									<span style={{ display: 'flex', alignItems: 'center', marginTop: '0.25rem' }}>
										<Icon style={{ marginRight: '0.25rem', color: 'grey', fontSize: '1.2rem' }}>
											<LinkedInIcon style={{ fontSize: '1.2rem' }} />
										</Icon>
										<a
											target="_blank"
											href={`https://www.linkedin.com/in/${communityDataSpacesContext.selectedDataSpace.createdBy.linkedIn}`}
											style={{ color: '#1C95E0', textDecoration: 'none' }}
										>
											@{communityDataSpacesContext.selectedDataSpace.createdBy.linkedIn}
										</a>
									</span>
								)}
								{communityDataSpacesContext.selectedDataSpace.createdBy.instagram && (
									<span style={{ display: 'flex', alignItems: 'center', marginTop: '0.25rem' }}>
										<Icon style={{ marginRight: '0.25rem', color: 'grey', fontSize: '1.2rem' }}>
											<InstagramIcon style={{ fontSize: '1.2rem' }} />
										</Icon>
										<a
											target="_blank"
											href={`https://www.instagram.com/${communityDataSpacesContext.selectedDataSpace.createdBy.instagram}`}
											style={{ color: '#1C95E0', textDecoration: 'none' }}
										>
											@{communityDataSpacesContext.selectedDataSpace.createdBy.instagram}
										</a>
									</span>
								)}
								{communityDataSpacesContext.selectedDataSpace.createdBy.tikTok && (
									<span style={{ display: 'flex', alignItems: 'center', marginTop: '0.25rem' }}>
										<SvgIcon style={{ marginRight: '0.25rem', color: 'grey', fontSize: '1.2rem' }}>
											<TikTokIcon style={{ fontSize: '1.2rem' }} />
										</SvgIcon>
										<a
											target="_blank"
											href={`https://www.tiktok.com/@${communityDataSpacesContext.selectedDataSpace.createdBy.tikTok}`}
											style={{ color: '#1C95E0', textDecoration: 'none' }}
										>
											@{communityDataSpacesContext.selectedDataSpace.createdBy.tikTok}
										</a>
									</span>
								)}
							</div>

							{communityDataSpacesContext.selectedDataSpace.description ? (
								<p>{communityDataSpacesContext.selectedDataSpace.description}</p>
							) : (
								<p style={{ color: 'grey' }}>No description provided.</p>
							)}

							<div style={{ width: '100%', display: 'flex', overflow: 'hidden', marginBottom: '1rem' }}>
								{communityDataSpacesContext.selectedDataSpace.datasets.map((dataset) => (
									<div
										key={dataset._id}
										style={{
											width: 'fit-content',
											backgroundColor: '#BDBCBC',
											padding: '0.4rem',
											borderRadius: '0.5rem',
											marginRight: '0.5rem',
											display: 'flex',
											alignItems: 'center',
											color: 'white',
											fontSize: '0.8rem',
											fontWeight: 'bold',
										}}
									>
										<img
											style={{ width: '1rem', borderRadius: '0.1rem', marginRight: '0.25rem' }}
											src={getLogo({ api: dataset.tags.api, sport: dataset.tags.sport })}
											alt="Dataset logo."
										/>
										<span style={{ whiteSpace: 'nowrap' }}>{dataset.displayName || dataset.name}</span>
									</div>
								))}
							</div>

							<div
								style={{
									marginTop: 'auto',
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<Button variant="outlined" onClick={selectDataSpaceButtonClickHandler}>
									Preview
								</Button>
								{communityDataSpacesContext.selectedDataSpace.datasets.some((dataset) =>
									['Yahoo! Fantasy Sports', 'Fantrax'].includes(dataset.tags.api),
								) ? (
									<SelectFantasyLeagueDialog dataSpace={communityDataSpacesContext.selectedDataSpace} />
								) : (
									<CreateDataSpaceButton />
								)}
							</div>
						</div>
					</>
				)}
			</Dialog>

			<DataSpacePreviewDialog
				dataSpace={communityDataSpacesContext.selectedDataSpace}
				isDataSpacePreviewDialogOpen={isDataSpacePreviewDialogOpen}
				setIsDataSpacePreviewDialogOpen={setIsDataSpacePreviewDialogOpen}
			/>
		</>
	);
};

export default DataSpaceInfoDialog;
