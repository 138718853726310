/*
This file contains the functions required for the encharge tracking.
Encharge Documentation: https://docs.encharge.io/getting-started/connecting-your-app-to-encharge/javascript-event-tracking
*/

/**
 *
 * @param {Object} userDetails
 * @param {Object} eventDetails
 * Destructured
 * @param {String} eventDetails.eventName - eventName
 * @param {String} userDetails.userId userId
 * @param {String} userDetails.email - email
 *
 */
export function trackEventEncharge(userDetails, eventDetails) {
	const { userId, email } = userDetails;
	const { eventName } = eventDetails;
	window.EncTracking.track({
		// Name of this event (required)
		name: eventName,
		// Fields for the current user performing the event (required)
		user: {
			// `email` or `userId` is required to uniquely identify this person
			userId,
			email,
		},
		//properties:{}
	});
}
/**
 * This function is used to uniquely identify the current user. It is recommended to be called
    after a user registers, logs in, or updates their account info.
 * @param {String} email 
 * @param {String} userName  
 * @param {String} firstName   
 * @param {String} lastName   
 */

// Sends identify event to Encharge with optional user tags argument.
export function identifyEncharge(email, UserName, firstName, lastName, tags) {
	tags = tags ? tags : '';
	window.EncTracking.identify({ email, UserName, firstName, lastName, tags });
}
