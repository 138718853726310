import React, { useContext } from 'react';
import nflLogo from '../../../../assets/img/NFLLogoSquare.png';
import nhlLogo from '../../../../assets/img/NHLLogoSquare.png';
import nbaLogo from '../../../../assets/img/NBALogoSquare.png';
import mlbLogo from '../../../../assets/img/MLBLogoSquare.png';
import CreateEditDataSpaceContext from '../../context/CreateEditDataSpaceContext';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { SelectButton } from 'primereact/selectbutton';

const useStyles = makeStyles((theme) => ({
	sportsLeagueSelectImage: {
		maxWidth: '25%',
		maxHeight: '100%',
		'&:hover': {
			borderRadius: '5px',
			boxShadow: 'inset 0 0 0 1px #000',
		},
	},
	sportSelectButton: {
		'& .p-button': {
			padding: '0.5rem 0.7rem',
		},
		'& .p-button.p-highlight': {
			background: '#EE9D34 !important',
			border: '1px solid #EE9D34 !important',
		},
		'& .p-button.p-component:active, .p-button.p-component:focus': {
			background: '#EE9D34 !important',
			border: '1px solid #EE9D34 !important',
			boxShadow: '0 0 0 0.2rem #ffb759 !important',
		},
	},
	unselected: {
		opacity: '50%',
	},
}));

// Allows user to select a sports league as a datasource by selecting from a set of sports league logos.
const SelectSportsLeague = (props) => {
	const classes = useStyles();

	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);

	const sportsLeagueTemplate = (sport) => {
		switch (sport) {
			case 'NBA':
				return <img src={nbaLogo} alt="NBA" style={{ width: 'clamp(2.75rem, 12vw, 6rem)', borderRadius: '0.2rem' }} />;
			case 'NHL':
				return <img src={nhlLogo} alt="NHL" style={{ width: 'clamp(2.75rem, 12vw, 6rem)', borderRadius: '0.2rem' }} />;
			case 'NFL':
				return <img src={nflLogo} alt="NFL" style={{ width: 'clamp(2.75rem, 12vw, 6rem)', borderRadius: '0.2rem' }} />;
			case 'MLB':
				return <img src={mlbLogo} alt="MLB" style={{ width: 'clamp(2.75rem, 12vw, 6rem)', borderRadius: '0.2rem' }} />;
			default:
				return <img src={nbaLogo} alt="NBA" style={{ width: 'clamp(2.75rem, 12vw, 6rem)', borderRadius: '0.2rem' }} />;
		}
	};

	// If the sports league changes, reset all the newDataSpace properties but set the dataspace name as the existing name and sports league as the newly selected sports league.
	const sportsLeagueSelectChangeHandler = (e) => {
		const sportsLeague = e.value;

		const { name, _id } = createEditDataSpaceContext.newDataSpace;
		if (sportsLeague !== createEditDataSpaceContext.newDataSpace.sportsLeague) {
			createEditDataSpaceContext.resetNewDataSpace();

			createEditDataSpaceContext.setNewDataSpace((prevState) => ({
				...prevState,
				_id,
				name,
				sportsLeague: sportsLeague,
			}));
		}
	};

	return (
		<Grid item xs={12} md style={{ marginBottom: '1rem' }}>
			<Typography variant="h6" align="left">
				Select a Sport
			</Typography>

			<SelectButton
				className={classes.sportSelectButton}
				value={createEditDataSpaceContext.newDataSpace.sportsLeague}
				disabled={props.leagueType === 'Fantasy'}
				onChange={sportsLeagueSelectChangeHandler}
				options={['NBA', 'NHL', 'NFL', 'MLB']}
				itemTemplate={sportsLeagueTemplate}
			/>
		</Grid>
	);
};

export default SelectSportsLeague;
