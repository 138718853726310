/* 
Note:
Since Segment doesn't support Encharge under its free tier, we have a separate function to handle Encharge events (trackEventEncharge, identifyEncharge). 
Every other event will go to Segment and be handled/forwarded from there
 */

import { trackEventEncharge, identifyEncharge } from './enchargeTracking';
import { trackEventSegment } from './segmentTracking';
import { appInsights } from '../utils/ApplicationInsights';

/**
 * This function is used to record actions user's take within the app.
 * The eventDetails and userDetails objects are unpacked and then forwarded to more specific handlers according
 * to the event types that are specificed in the eventDetails.types array (Encharge events only go to encharge)
 *
 * The types currently being used are Segment, Encharge, and GA4
 * If you want something to go to GA4, both Segment and GA4 must be in your event's types array
 *
 * @param {Object} eventAndUserDetails
 *
 * @param {Object} eventAndUserDetails.userDetails userDetails
 * @param {Object} eventAndUserDetails.eventDetails eventDetails
 *
 * Usage:
 * trackEvent({
 *    userDetails: {},
 *    eventDetails: {}
 * })
 */
export function trackEvent(eventAndUserDetails) {
	const { userDetails, eventDetails } = eventAndUserDetails;
	const types = eventDetails.types;

	//Record the events depending on what is included in the types array
	if (types.includes('Encharge')) {
		trackEventEncharge(userDetails, eventDetails);
	}
	if (types.includes('Segment')) trackEventSegment(userDetails, eventDetails);
	if (types.includes('AppInsights'))
		appInsights.trackEvent({ name: eventDetails.eventName, properties: { userDetails } });

	if (types.includes('KissMetrics')) window._kmq.push(['record', eventDetails.eventName]);
}

export function trackException(error) {
	appInsights.trackException(error);
}

// Azure trace function.
export const trackTrace = (message, properties) => {
	appInsights.trackTrace({
		message: message,
		properties: properties,
	});
};

/**
  This function is used to uniquely identify the current user in Encharge, Clarity, and KissMetrics. It is recommended to be called
  after a user registers, logs in, or updates their account info.
  REQUIRED
  @param email: The user's email address.
  OPTIONAL
  UserName: The user's SportWise username (custom field)
  firstName: User's first name
  lastName: User's last name
 */
export function identify(email, UserName, firstName, lastName, tags) {
	identifyEncharge(email, UserName, firstName, lastName, tags);
	window.clarity('identify', email);
	window.clarity('set', 'user_email', email);
	window._kmq.push(['identify', email]);
}
