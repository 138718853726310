import React, { useState, useEffect, useRef, useContext } from 'react';
import DataTable from '../../ui_elements/DataTable';
import { InputText } from 'primereact/inputtext';
import {
	CircularProgress,
	Backdrop,
	Button,
	Grid,
	Dialog,
	DialogTitle,
	DialogContent,
	IconButton,
	TextField,
	InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { makeStyles } from '@material-ui/core/styles';
import { isEqual, debounce } from 'lodash';
import { retrieveDataSpaceStateNew } from '../../../api_helper/api';
import FilterInitializer from './FilterInitializer';
import ColumnFactory from './ColumnFactory';
import ViewManager from './ViewManager';
import { DataSpaceViewContext } from '../../my_dataspaces/context/DataSpaceViewContext';
import getLogo from '../../../utils/getLogo';
import { trackEvent } from '../../../utils/eventTracking';
import DataSpaceViewHeader from './DataSpaceViewHeader';

import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css';

const useStyles = makeStyles((theme) => ({
	dataTable: {
		// Grey border separating frozen and non-frozen columns
		'& .p-datatable-scrollable-view.p-datatable-unfrozen-view': {
			borderLeft: '2px solid #F8F9FA',
			boxSizing: 'content-box',
		},
		'& .p-datatable-scrollable-wrapper': {
			overflowX: 'hidden',
		},
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	globalFilter: {
		marginRight: '15px',
		width: '10.5rem',
		marginLeft: '1rem',
		'& input': {
			width: '100%',
		},
	},
	inputNumber: {
		marginLeft: '1px',
		marginRight: '1px',
	},
	seeDetailsButton: {
		color: 'black',
	},
	dataSpaceHeader: {
		display: 'flex',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
	},
	dataSpaceHeaderLeftWrapper: {
		display: 'flex',
		marginBottom: '0.5rem',
	},
	dataSpaceHeaderRightWrapper: {
		display: 'flex',
	},
	dataspaceViewManagerWrapper: {
		padding: '0 0.5rem',
		justifyContent: 'space-between',
	},
	datasetField: {
		width: '100%',
		height: 'fit-content',
		padding: '0.3rem',
		backgroundColor: '#C4C4C4',
		borderRadius: '0.2rem',
		transition: '0.3s',
		'&:hover': {
			backgroundColor: '#C4C4C4',
			filter: 'brightness(0.75)',
		},
		'& > span': {
			width: '100%',
			display: 'inline',
			color: '#000000',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
	},
	datasetFieldSelected: {
		backgroundColor: '#0DBC42',
		'&:hover': {
			backgroundColor: '#0DBC42',
			filter: 'brightness(0.75)',
		},
	},
}));

export default function PrimeDataTable(props) {
	const classes = useStyles();

	const dataSpaceViewContext = useContext(DataSpaceViewContext);

	const [isSelectColumnsDialogVisible, setIsSelectColumnsDialogVisible] = useState(false);

	// Global Filter
	const [globalFilter, setGlobalFilter] = useState(null);
	const filterDelayMilliseconds = 250; // ms after last keypress filter state will be updated
	// debounce allows state to change only when user has finished typing
	const handleChangeGlobalFilter = debounce((e) => {
		setGlobalFilter(e.target.value);
	}, filterDelayMilliseconds);

	// Refs
	const dataTableRef = useRef(null); // DataTable Ref
	const internalStateRef = useRef(undefined); // Used to track internal table state (ITS) to detect changes in ITS

	// Table
	const [internalTableState, setInternalTableState] = useState();
	const [tableInitialized, setTableInitialized] = useState(false); // Flags if filters and selected column data are loaded
	const [isInternalTableStateSet, setIsInternalTableStateSet] = useState(false);
	const dataTableWillRender = isInternalTableStateSet && tableInitialized;
	const paginationOptions = [10, 25, 50, 100, 250];
	const defaultPaginationOption = 10;

	// Loading Screen
	const [isViewLoading, setIsViewLoading] = useState(false);
	const loadScreenActive = isViewLoading || !dataTableWillRender;

	// Columns and Filters
	const [selectedColumns, setSelectedColumns] = useState([]); // Used in hide/show column toggle menu
	const [columnFilterTypes, setColumnFilterTypes] = useState([]);
	const [columnFilterStates, setColumnFilterStates] = useState({});
	const filterInitializer = new FilterInitializer(props.rows, props.columns);

	// Calculated columns
	const [columnEditorOpen, setColumnEditorOpen] = useState(false);
	const [columnEditorDetails, setColumnEditorDetails] = useState({});
	const handleEditCalculatedColumn = (field, header) => {
		setColumnEditorDetails({ field, header });
		setColumnEditorOpen(true);
	};
	const [numNewCalculatedColumns, setNumNewCalculatedColumns] = useState(0);

	const [selectColumnsDialogSearchInput, setSelectColumnsDialogSearchInput] = useState('');

	// Callback passed down to column header to allow user to hide column from context menu
	const onHideColumn = (field) => {
		// Get current visible columns
		let updatedCols = [...selectedColumns];
		// Remove column to hide from current visible columns
		updatedCols.splice(
			updatedCols.findIndex((col) => col.field === field),
			1,
		);
		let updatedColumnWidths = dataSpaceViewContext.dataSpace.internalState?.columnWidths.split(',');
		updatedColumnWidths.splice(
			updatedCols.findIndex((col) => col.field === field),
			1,
		);
		updatedColumnWidths = updatedColumnWidths.join(',');
		setSelectedColumns(updatedCols);

		// Save current visible columns in database
		updatedCols = updatedCols.map((x) => x.field); // Convert to array of Strings representing fields

		dataSpaceViewContext.setDataSpace((prevState) => {
			const updatedState = { ...prevState };
			updatedState.frozenColumns =
				updatedState.frozenColumns && updatedState.frozenColumns.filter((col) => col !== field);
			updatedState.toggledColumnsState = updatedCols;
			updatedState.internalState = {
				...prevState.internalState,
				columnOrder: updatedCols,
				columnWidths: updatedColumnWidths,
			};
			return updatedState;
		});
	};

	const onFreezeColumn = (field) => {
		trackEvent({
			eventDetails: {
				types: ['KissMetrics', 'Segment', 'AppInsights', 'GA4'],
				eventName: 'User clicked pin column button.',
			},
		});

		// Get current visible columns
		let updatedCols = [...selectedColumns];
		let updatedColumnWidths = dataSpaceViewContext.dataSpace.internalState?.columnWidths.split(',');

		// Move column matching field to beginning of array.
		let index = updatedCols.findIndex((col) => col.field === field);
		if (index !== -1) {
			let column = updatedCols.splice(index, 1)[0];
			updatedCols.unshift(column);
			let columnWidth = updatedColumnWidths.splice(index, 1)[0];
			updatedColumnWidths.unshift(columnWidth);
			updatedColumnWidths = updatedColumnWidths.join(',');
		}

		// Save current visible columns in database
		setSelectedColumns(updatedCols);
		updatedCols = updatedCols.map((x) => x.field); // Convert to array of Strings representing fields

		dataSpaceViewContext.setDataSpace((prevState) => {
			const updatedState = { ...prevState };
			updatedState.frozenColumns = updatedState.frozenColumns ? [...updatedState.frozenColumns, field] : [field];
			updatedState.toggledColumnsState = updatedCols;
			updatedState.internalState = {
				...prevState.internalState,
				columnOrder: updatedCols,
				columnWidths: updatedColumnWidths,
			};
			return updatedState;
		});

		dataTableRef.current.restoreTableState({
			...dataTableRef.current.state,
			columnOrder: updatedCols,
		});
	};

	const onUnfreezeColumn = (field) => {
		trackEvent({
			eventDetails: {
				types: ['KissMetrics', 'Segment', 'AppInsights', 'GA4'],
				eventName: 'User clicked unpin column button.',
			},
		});

		// Get current visible columns
		let updatedCols = [...selectedColumns];
		let updatedColumnWidths = dataSpaceViewContext.dataSpace.internalState?.columnWidths.split(',');

		const finalIndex = dataSpaceViewContext.dataSpace.frozenColumns.length - 1;

		let index = updatedCols.findIndex((col) => col.field === field);
		if (index !== -1) {
			let column = updatedCols.splice(index, 1)[0];
			updatedCols.splice(finalIndex, 0, column);

			let columnWidth = updatedColumnWidths.splice(index, 1)[0];
			updatedColumnWidths.splice(finalIndex, 0, columnWidth);
			updatedColumnWidths = updatedColumnWidths.join(',');
		}

		// Save current visible columns in database
		setSelectedColumns(updatedCols);
		updatedCols = updatedCols.map((x) => x.field); // Convert to array of Strings representing fields

		dataSpaceViewContext.setDataSpace((prevState) => {
			const updatedState = { ...prevState };
			updatedState.frozenColumns = updatedState.frozenColumns.filter((col) => col !== field);
			updatedState.toggledColumnsState = updatedCols;
			updatedState.internalState = {
				...prevState.internalState,
				columnOrder: updatedCols,
				columnWidths: updatedColumnWidths,
			};
			return updatedState;
		});

		dataTableRef.current.restoreTableState({
			...dataTableRef.current.state,
			columnOrder: updatedCols,
			columnWidths: updatedColumnWidths,
		});
	};

	// Handles deletion of a custom column from the DataSpace frontend
	function handleDeleteColumn(field) {
		// Remove from selected columns
		setSelectedColumns((prev) => {
			const i = prev.findIndex((c) => c.field === field);
			if (i) prev.splice(i, 1);
			return prev;
		});

		// Need to remove column order from dataspace state or it will reupload to db
		const tableState = dataTableRef.current.state;
		if (tableState.columnOrder) {
			const i = tableState.columnOrder.findIndex((o) => o === field);
			if (i >= 0) tableState.columnOrder.splice(i, 1);
		}
		dataTableRef.current.restoreTableState(tableState);

		// Reloads DataSpace data
		props.deleteCustomColumn();
	}

	// Instance of ColumnFactory class which creates columns dynamically based on props
	const columnFactory = new ColumnFactory(
		dataTableRef,
		columnFilterStates,
		setColumnFilterStates,
		props.rows,
		props.renameHeader,
		dataSpaceViewContext,
		columnFilterTypes,
		dataSpaceViewContext.dataSpace.frozenColumns || [],
		onHideColumn,
		onFreezeColumn,
		onUnfreezeColumn,
		handleEditCalculatedColumn,
		handleDeleteColumn,
	);

	// Retrieve the properties representing the user's current view
	const getCurrentView = () => {
		const view = {
			internalProperties: {},
			externalProperties: {},
		};

		view.internalProperties = {
			state: dataTableRef.current.state,
		};

		view.externalProperties = {
			selectedColumns: selectedColumns,
			filterStates: columnFilterStates,
			frozenColumns: dataSpaceViewContext.dataSpace.frozenColumns || [],
		};

		return view;
	};

	// Load the properties of a saved view into DataSpace
	const loadView = (view) => {
		view.internalProperties.state.first = 0; // Reset first (page) to 0 to avoid pagination issues;
		// Set internal properties
		dataTableRef.current.restoreTableState(view.internalProperties.state);

		// Set external properties
		setSelectedColumns(view.externalProperties.selectedColumns);
		setColumnFilterStates(view.externalProperties.filterStates);
		dataSpaceViewContext.setDataSpace((prevState) => ({
			...prevState,
			frozenColumns: view.externalProperties.frozenColumns,
		}));
	};

	// Load table's internal state to memory upon mounting
	useEffect(() => {
		retrieveDataSpaceStateNew(props.dataSpaceId, 'internalState').then((res) => {
			// Obtain initial state
			let state = {};
			if (res.internalState) state = res.internalState;

			// Specify default rows/page in table state if not already defined
			// or no initial table state
			if (!state.rows || Object.keys(state).length === 0) {
				state.rows = defaultPaginationOption;
				state.first = 0;
			}

			setInternalTableState(state);
			setIsInternalTableStateSet(true);
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Initialize filter states
	// Load toggled column settings
	useEffect(() => {
		if (props.columns.length > 0 && tableInitialized === false) {
			// Initialize filter states
			setColumnFilterTypes(filterInitializer.getColumnFilterTypes());
			setColumnFilterStates(filterInitializer.getInitialFilterState());

			// Load toggled column settings
			retrieveDataSpaceStateNew(props.dataSpaceId, 'toggledColumnsState').then((res) => {
				// If no toggled column settings, all columns are selected
				if (res.toggledColumnsState.length === 0) {
					setSelectedColumns(props.columns);
				} else {
					// Set selected columns based on toggle settings
					let toggleState = res.toggledColumnsState.map((toggledCol) => {
						const column = props.columns.find((c) => c.field === toggledCol);

						if (!column) return null; // Selection not available in this Dataset

						return column;
					});

					// Remove unavailable selections
					toggleState = toggleState.filter((x) => x !== null);

					setSelectedColumns(toggleState);
				}

				// Flag successful loading of filter and toggled column data
				setTableInitialized(true);
			});
		}

		// Update selected columns to reflect any header changes
		if (props.columns.length > 0) {
			let selColsCopy = [...selectedColumns];
			selColsCopy = selColsCopy.map((sCol) => {
				const { header, dataSourceName, columnToggleLabel } = props.columns.find((col) => col.field === sCol.field);
				return {
					columnToggleLabel: columnToggleLabel,
					dataSourceName: dataSourceName,
					field: sCol.field,
					header: header,
				};
			});

			setSelectedColumns(selColsCopy);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.columns]);

	// Used in hide/show MultiSelect component. Modifies selectedColumns and state based
	// on column toggling
	const onColumnToggle = async (e) => {
		let selectedCols = e.value;

		// Table crashes if no columns selected. If user selects 0 columns,
		// the first column will automatically be selected.
		if (selectedCols.length === 0) selectedCols = [props.columns[0]];

		// Base order of selected columns on orginal order of columns
		let orderedSelectedColumns = props.columns.filter((col) => selectedCols.some((sCol) => sCol.field === col.field));
		setSelectedColumns(orderedSelectedColumns);

		dataSpaceViewContext.setDataSpace((prevState) => {
			const updatedState = { ...prevState };
			updatedState.frozenColumns =
				updatedState.frozenColumns &&
				updatedState.frozenColumns.filter((col) => orderedSelectedColumns.some((sCol) => sCol.field === col));
			updatedState.toggledColumnsState = orderedSelectedColumns.map((col) => col.field);
			updatedState.internalState = {
				...prevState.internalState,
				columnOrder: orderedSelectedColumns.map((col) => col.field),
			};
			return updatedState;
		});
	};

	// State management in DataTable
	const onCustomSaveState = (state) => {
		/*
        Note: Modifying the globalFilter activates onCustomSaveState twice.
        The reason for this is currently unknown.
        On the first iteration state.filters has the previous state's value, and thus
        the following check fails and an unneccessary backend call is made. This call
        however is not destructive, as we remove the filter property before updating the
        state in the DataBase.

        I have submitted a question to PrimeFaces regarding this
        https://forum.primefaces.org/viewtopic.php?f=57&t=65458
    */

		// Avoid unnecessary backend calls if change in state is due to filter modification,
		// or if table's state has not changed.
		// We do not persist filter modifications implicitly, only by user action
		let filtersUnchanged = isEqual(internalStateRef.current?.filters, state.filters);
		const internalStateHasChanged = !isEqual(
			{ ...internalStateRef.current, columnWidths: '' },
			{ ...state, columnWidths: '' },
		);

		if (filtersUnchanged && internalStateHasChanged) {
			delete state.filters;

			// If columns are added/removed, update state.
			if (selectedColumns?.length !== state.columnOrder?.length) {
				state.columnOrder = selectedColumns.map((column) => column.field);
			} else {
				setSelectedColumns(() =>
					state.columnOrder.map((column) => {
						return props.columns.find((e) => e.field === column);
					}),
				);
			}

			dataSpaceViewContext.setDataSpace((prevState) => ({
				...prevState,
				internalState: state,
			}));

			dataTableRef.current.restoreTableState({
				...dataTableRef.current.state,
				columnOrder: state.columnOrder,
			});
		}

		internalStateRef.current = state; // Track internal table state
	};

	// State management in DataTable
	const onCustomRestoreState = () => {
		return internalTableState;
	};

	const [isDataSpaceExpanded, setIsDataSpaceExtended] = useState(false);

	// Create datatable header containing Column Toggle and Global Filter
	const dataTableHeader = (
		<div className={classes.dataSpaceHeader}>
			<div className={classes.dataSpaceHeaderLeftWrapper}>
				<Button
					onClick={() => {
						setIsSelectColumnsDialogVisible(true);
					}}
					style={{ marginRight: '1rem' }}
					variant="outlined"
				>
					Select Columns
				</Button>
				<ViewManager
					dataSpaceId={props.dataSpaceId}
					getCurrentView={getCurrentView}
					loadView={loadView}
					setIsViewLoading={setIsViewLoading}
				/>
			</div>
			<div className={classes.dataSpaceHeaderRightWrapper}>
				<Button className={classes.seeDetailsButton} onClick={props.handleClickSeeDetails}>
					See Details
				</Button>

				<div className={classes.globalFilter}>
					<span className="p-input-icon-left">
						<i className="pi pi-search" />
						<InputText type="search" onInput={handleChangeGlobalFilter} placeholder="Global Search" />
					</span>
				</div>
			</div>
		</div>
	);

	// Map selected columns to Column components for display in table
	const columnComponents = selectedColumns.map((col) => {
		return columnFactory.getColumnComponent(col);
	});

	const columnClickHandler = (clickedColumn) => {
		const isColumnBeingAdded = !selectedColumns.some(
			(selectedColumn) => selectedColumn.field === (clickedColumn.name || clickedColumn.field),
		);

		let updatedColumns = isColumnBeingAdded
			? [...selectedColumns, clickedColumn]
			: [...selectedColumns].filter(
					(selectedColumn) => selectedColumn.field !== (clickedColumn.name || clickedColumn.field),
			  );

		// Get corresponding column values from props.columns
		updatedColumns = updatedColumns.map((updatedColumn) => {
			return props.columns.find((column) => column.field === (updatedColumn.field || updatedColumn.name));
		});

		// Pass updatedColumns to existing column select handler.
		onColumnToggle({ value: updatedColumns });
	};

	const selectAllColumnsClickHandler = () => {
		let updatedColumns = [...new Set([...selectedColumns, ...props.columns])];

		// Get corresponding column values from props.columns
		updatedColumns = updatedColumns.map((updatedColumn) => {
			return props.columns.find((column) => column.field === (updatedColumn.field || updatedColumn.name));
		});

		// Pass updatedColumns to existing column select handler.
		onColumnToggle({ value: updatedColumns });
	};

	const deselectAllColumnsClickHandler = () => {
		// Pass updatedColumns to existing column select handler.
		onColumnToggle({ value: [props.columns[0]] });
	};

	const selectColumnsDialogSearchChangeHandler = (e) => {
		setSelectColumnsDialogSearchInput(e.target.value);
	};

	// If the number of new calculated columns changes (increases), add the new calculated column
	// to the list of selected columns.
	useEffect(() => {
		// Do not act if no columns have been created
		if (numNewCalculatedColumns !== 0) {
			let updatedColumns = [...selectedColumns];
			updatedColumns.push(props.columns[props.columns.length - 1]); // The last element in props.columns will be the new calculated column
			setSelectedColumns(updatedColumns);
		}
	}, [numNewCalculatedColumns]);

	// State controls whether the DataTable wrapper's mouseover event is active.
	// DataTable mouseover event and DataTable scrol event are only used in Safari, other browsers use CSS.
	const isBrowserSafari = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
	const [isDataTableMouseOverEventActive, setIsDataTableMouseOverEventActive] = useState(isBrowserSafari);

	const dataTableMouseOverEventHandler = () => {
		const datatableWrapper = document.getElementsByClassName('p-datatable-scrollable-body')[0]; // RUNS ONCE.
		const datatableBody = document.getElementsByClassName('p-datatable-scrollable-body-table')[0]; // RUNS ONCE.

		let maxScrollLeft;

		const preventOverscroll = (e) => {
			e.stopPropagation();

			// If at either end of the DataTable horizontally and you try to keep scrolling in that direction, prevent scroll.
			if (
				(datatableWrapper.scrollLeft <= 0 && e.deltaX < 0) ||
				(datatableWrapper.scrollLeft >= maxScrollLeft && e.deltaX > 0)
			) {
				e.preventDefault();
			}
		};

		const resizeObserver = new ResizeObserver(() => {
			maxScrollLeft = datatableWrapper.scrollWidth - datatableWrapper.offsetWidth;
			datatableWrapper.removeEventListener('wheel', preventOverscroll, { passive: false });
			datatableWrapper.addEventListener('wheel', preventOverscroll, { passive: false });
		});
		resizeObserver.observe(datatableWrapper);
		resizeObserver.observe(datatableBody);

		// Ensures the addDataTableScrollEventListener function is only called once, because we only want to add the event listener once.
		setIsDataTableMouseOverEventActive(false);
	};

	const selectColumnsDatasetColumns = (dataset) => {
		let columns;
		if (dataset === 'Calculated') {
			columns = props.columns.reduce((accumulator, column) => {
				// If column doesn't match search filter, isn't a Calculated Column, or is excluded don't show column.
				if (
					(selectColumnsDialogSearchInput &&
						!(column.header || column.field).toLowerCase().includes(selectColumnsDialogSearchInput.toLowerCase())) ||
					column.dataSourceName !== 'Calculated' ||
					column.isExcluded
				) {
					return accumulator;
				}

				return [
					...accumulator,
					<Grid key={column.name} item xs={6} sm={4}>
						<Button
							className={
								classes.datasetField +
								(selectedColumns.some((selectedColumn) => selectedColumn.field === column.field)
									? ' ' + classes.datasetFieldSelected
									: '')
							}
							onClick={() => {
								columnClickHandler(column);
							}}
						>
							{column.header || column.field}
						</Button>
					</Grid>,
				];
			}, []);
		} else {
			columns = dataset.fields.reduce((accumulator, column) => {
				// If column doesn't match search filter or is excluded don't show column.
				if (
					(selectColumnsDialogSearchInput &&
						!(column.displayName || column.name)
							.toLowerCase()
							.includes(selectColumnsDialogSearchInput.toLowerCase())) ||
					column.isExcluded
				) {
					return accumulator;
				}

				return [
					...accumulator,
					<Grid key={column.name} item xs={6} sm={4}>
						<Button
							className={
								classes.datasetField +
								(selectedColumns.some((selectedColumn) => selectedColumn.field === column.name)
									? ' ' + classes.datasetFieldSelected
									: '')
							}
							onClick={() => {
								columnClickHandler(column);
							}}
						>
							{column.displayName || column.name}
						</Button>
					</Grid>,
				];
			}, []);
		}

		return columns.length ? (
			columns
		) : (
			<Grid item xs={12}>
				No columns found
			</Grid>
		);
	};

	useEffect(() => {
		const headers = document.getElementsByClassName('p-datatable-thead');

		if (headers.length > 1) {
			if (headers[0]?.children[0]?.offsetHeight > headers[1]?.children[0]?.offsetHeight) {
				headers[1].children[0].style.height = headers[0].children[0].offsetHeight + 'px';
			} else if (headers[0]?.children[0]?.offsetHeight < headers[1]?.children[0]?.offsetHeight) {
				headers[0].children[0].style.height = headers[1].children[0].offsetHeight + 'px';
			}

			if (headers[0]?.children[1]?.offsetHeight > headers[1]?.children[1]?.offsetHeight) {
				headers[1].children[1].style.height = headers[0].children[1].offsetHeight + 'px';
			} else if (headers[0]?.children[1]?.offsetHeight < headers[1]?.children[1]?.offsetHeight) {
				headers[0].children[1].style.height = headers[1].children[1].offsetHeight + 'px';
			}
		}
	});

	return (
		<div className="dataspace-wrapper">
			<Dialog
				open={isSelectColumnsDialogVisible}
				onClose={() => {
					setIsSelectColumnsDialogVisible(false);
				}}
				fullWidth
			>
				<DialogTitle>
					Select Columns
					<IconButton
						aria-label="close"
						onClick={() => {
							setIsSelectColumnsDialogVisible(false);
						}}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<DisabledByDefaultIcon size="medium" />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<TextField
						onChange={selectColumnsDialogSearchChangeHandler}
						style={{ width: '100%', marginBottom: '1rem' }}
						value={selectColumnsDialogSearchInput}
						placeholder="Search columns"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
						}}
						variant="standard"
					/>
					{dataSpaceViewContext.dataSpace.datasets &&
						dataSpaceViewContext.dataSpace.datasets.map((dataset) => (
							<div key={dataset._id} style={{ width: '100%', marginBottom: '2rem' }}>
								<div style={{ height: '5rem', width: '100%', marginBottom: '1rem', display: 'flex' }}>
									<img
										src={getLogo({ api: dataset.tags.api, sport: dataset.tags.sport })}
										alt="logo"
										style={{ height: '100%', borderRadius: '0.3rem', marginRight: '0.5rem' }}
									/>
									<div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
										<h5 style={{ margin: '0 0 0.5rem 0', fontSize: '1.5rem' }}>
											{(dataset.displayName || dataset.name) +
												(dataset.tags.timeFrame ? ` (${dataset.tags.timeFrame})` : '') +
												` (${dataset._id.substr(-3)})`}
										</h5>
										<p style={{ height: '100%', margin: '0', overflow: 'auto' }}>
											Select columns below to view them in your DataSpace
										</p>
									</div>
								</div>
								<Grid container spacing={0.5}>
									{selectColumnsDatasetColumns(dataset)}
								</Grid>
							</div>
						))}

					{/* Calculated Columns*/}
					<div style={{ width: '100%', marginBottom: '2rem' }}>
						<div style={{ height: '5rem', width: '100%', marginBottom: '1rem', display: 'flex' }}>
							<img
								src={getLogo({ api: null, sport: null })}
								alt="logo"
								style={{ height: '100%', borderRadius: '0.3rem', marginRight: '0.5rem' }}
							/>
							<div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
								<h5 style={{ margin: '0 0 0.5rem 0', fontSize: '1.5rem' }}>Calculated Columns</h5>
								<p style={{ height: '100%', margin: '0', overflow: 'auto' }}>
									Select columns below to view them in your DataSpace
								</p>
							</div>
						</div>
						<Grid container spacing={0.5}>
							{selectColumnsDatasetColumns('Calculated')}
						</Grid>
					</div>
					{selectedColumns.length === props.columns.length ? (
						<Button variant="outlined" onClick={deselectAllColumnsClickHandler}>
							Deselect All Columns
						</Button>
					) : (
						<Button variant="outlined" onClick={selectAllColumnsClickHandler}>
							Select All Columns
						</Button>
					)}
				</DialogContent>
			</Dialog>

			{dataTableWillRender && (
				<div className="card dataspace-viewmanager-wrapper">
					<DataSpaceViewHeader
						dataSpaceId={props.dataSpaceId}
						getCurrentView={getCurrentView}
						loadView={loadView}
						setIsViewLoading={setIsViewLoading}
					>
						{props.children}
					</DataSpaceViewHeader>

					<div
						className={isDataSpaceExpanded ? 'datatable-wrapper-fullscreen' : 'datatable-wrapper'}
						onMouseOver={isDataTableMouseOverEventActive ? dataTableMouseOverEventHandler : null}
					>
						<DataTable
							ref={dataTableRef}
							paginator
							paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
							currentPageReportTemplate="{currentPage}"
							paginatorDropdownAppendTo="self"
							rowsPerPageOptions={paginationOptions}
							//rows={10} // Due to table state system, default rows/page is implemented after table state retrieved from backend
							id="datatable-dataspace"
							className={classes.dataTable + ' p-datatable-sm'}
							size="small"
							value={props.rows}
							scrollable
							resizableColumns
							reorderableColumns
							columnResizeMode="expand"
							onStateSa
							stateStorage="custom"
							customSaveState={onCustomSaveState}
							customRestoreState={onCustomRestoreState}
							sortMode="multiple"
							header={dataTableHeader}
							globalFilter={globalFilter}
							frozenWidth={(() => {
								let frozenWidth = 0;
								for (let column of dataSpaceViewContext.dataSpace.frozenColumns || []) {
									const index = dataSpaceViewContext.dataSpace.internalState.columnOrder.findIndex((x) => x === column);
									const width = dataSpaceViewContext.dataSpace.internalState?.columnWidths.split(',')[index] || '160';
									frozenWidth += parseInt(width);
								}
								return frozenWidth + 'px';
							})()}
						>
							{columnComponents}
						</DataTable>
					</div>
				</div>
			)}

			{/* Load Screen */}
			<Backdrop className={classes.backdrop} open={loadScreenActive}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</div>
	);
}
