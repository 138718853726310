/*
This panel provides an interface which allows the user to design and generate custom columns.
*/

import React, { useState, useEffect, useContext, useRef } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Slide,
	TextField,
	Snackbar,
	Alert,
	makeStyles,
} from '@material-ui/core';
import { getSpecifiedDataSpaceMetadata, calculateColumnNew, getDataSpaceNew } from '../../../api_helper/api';
import { trackEvent } from '../../../utils/eventTracking';
import { UserProfileContext } from '../../../UserProfileContext';
import MyDataSpacesViewContext from '../context/MyDataSpacesViewContext';
import { DataSpaceViewContext } from '../context/DataSpaceViewContext';
import Dataset from './Dataset';
import { Mention } from 'primereact/mention';
import getLogo from '../../../utils/getLogo';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
	datasetsList: {
		margin: '0.5rem 0',
		padding: '0',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		borderRadius: '0.5rem',
		listStyle: 'none',
		'& > li': {
			width: '100%',
		},
		'& > li:last-child > button': {
			border: 'none',
		},
	},
}));

export default function CalculatedColumnEditor({
	open,
	handleClose,
	columns,
	columnDetails,
	dataSpaceId,
	applyModifiedCustomColumn,
	displayAlert,
}) {
	const classes = useStyles();

	const user = useContext(UserProfileContext)[0];

	const myDataSpacesViewContext = useContext(MyDataSpacesViewContext);
	const dataSpaceViewContext = useContext(DataSpaceViewContext);

	// expression
	const [expression, setExpression] = useState({ value: 'Loading...', selectionEnd: 0 }); // For user input
	const [originalExpression, setOriginalExpression] = useState('Loading...'); // For displaying original expression (non-editable)

	const [suggestions, setSuggestions] = useState([]);

	// snackbar
	const [snackOpen, setSnackOpen] = useState(false);
	const [snackMessage, setSnackMessage] = useState('');

	const inputRef = useRef();

	// Format columns for expression and datasets list.
	columns = columns.map((column) => {
		if (column.dataSourceName === 'Calculated') {
			return {
				...column,
				displayName: column.header ? column.header : column.field,
			};
		}
		return {
			...column,
			displayName: column.header ? column.header + `(${column.field.substr(-3)})` : column.field,
		};
	});

	// Format search query for columns mention dropdown.
	const onSearch = (event) => {
		setTimeout(() => {
			const query = event.query;
			let suggestions = [...columns];

			if (query.trim().length) {
				suggestions = columns.filter((column) => {
					return column.displayName.toLowerCase().startsWith(query.toLowerCase());
				});
			}

			setSuggestions(suggestions);
		}, 250);
	};

	// Format column names and descriptions in mentions dropdown.
	const itemTemplate = (column) => {
		let displayName = column.displayName;
		if (column.dataSourceName === 'Calculated') {
			displayName += '(calc)';
		}
		return (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<p style={{ margin: '0' }}>{displayName}</p>
				<p style={{ margin: '0', fontSize: '0.8rem', color: 'grey' }}>{column.description}</p>
			</div>
		);
	};

	// Format calculated dataset in datasets list.
	const calculatedDataset = {
		fields: columns.filter((column) => column.dataSourceName === 'Calculated') || [],
		name: 'Calculated Columns',
		displayName: 'Calculated Columns',
		tags: {
			datasetGroup: 'Calculated Columns',
		},
	};

	// Format string added to expression when mention dropdown item is clicked.
	const selectHandler = (e) => {
		const regexDisplayName =
			e.suggestion.dataSourceName === 'Calculated' ? e.suggestion.displayName + '(calc)' : e.suggestion.displayName;

		const queryStartIndex = expression.value.lastIndexOf('#', expression.selectionEnd - 1);

		const updatedExpressionValue =
			expression.value.slice(0, queryStartIndex) +
			'#' +
			regexDisplayName +
			expression.value.slice(expression.selectionEnd);

		setExpression({
			value: updatedExpressionValue,
			selectionEnd: updatedExpressionValue.length,
		});
	};

	// Retrieve the calculated column's expression
	useEffect(() => {
		const getCalculatedColumnExpression = async () => {
			if (columnDetails['field']) {
				const dataSpace = (await getDataSpaceNew(dataSpaceId)).data.dataSpace;
				const column = dataSpace.calculatedColumns.find((col) => col.field === columnDetails.field);

				// Replace column full names with their display names.
				let replacedText = column.expression;
				for (let dataset of dataSpaceViewContext.dataSpace.datasets) {
					for (let column of dataset.fields) {
						let replaceValue = column.displayName ? `#${column.displayName}(${dataset._id.substr(-3)})` : column.name;
						// Query for column name and ensure it isn't a name within another column's name (can't be surrounded by alphanumeric or underscores).
						const query = new RegExp(`(?<![a-zA-Z0-9_])${column.name}(?![a-zA-Z0-9_])`, 'g');
						replacedText = replacedText.replaceAll(query, replaceValue);
					}
				}
				// Replace calculated column full names with their display names.
				for (let column of calculatedDataset.fields) {
					let replaceValue = column.displayName ? `#${column.displayName}(calc)` : column.field;
					// Query for column name and ensure it isn't a name within another column's name (can't be surrounded by alphanumeric or underscores).
					const query = new RegExp(`(?<![a-zA-Z0-9_])${column.field}(?![a-zA-Z0-9_])`, 'g');
					replacedText = replacedText.replaceAll(query, replaceValue);
				}

				setExpression({ value: replacedText, selectionEnd: replacedText.length });
				setOriginalExpression(replacedText);
			}
		};

		getCalculatedColumnExpression();
	}, [columnDetails]);

	// Initiates calculation and application of custom column. Displays errors if required.
	const saveChangesOnClick = () => {
		// Replace column display values with their names.
		let replacedText = expression.value;
		for (let dataset of dataSpaceViewContext.dataSpace.datasets) {
			for (let column of dataset.fields) {
				let query = column.displayName ? `#${column.displayName}(${dataset._id.substr(-3)})` : column.name;
				replacedText = replacedText.replaceAll(query, column.name);
			}
		}
		// Replace calculated column column display names with their full names.
		for (let column of calculatedDataset.fields) {
			let query = column.displayName ? `#${column.displayName}(calc)` : column.field;
			replacedText = replacedText.replaceAll(query, column.field);
		}

		calculateColumnNew({
			expression: replacedText,
			colName: columnDetails.header,
			field: columnDetails.field,
			dataSpaceId,
			mode: 'edit',
		}).then((res) => {
			const hasValidationErrors = res.validationErrors && res.validationErrors.length > 0;
			if (hasValidationErrors) {
				setSnackMessage(formatErrors(res.validationErrors));
				setSnackOpen(true);
				trackEvent({
					userDetails: { userId: user._id, email: user.email },
					eventDetails: {
						types: ['Segment', 'Encharge', 'GA4', 'AppInsights'],
						eventName: 'User encountered validation errors when editing a custom column.',
					},
				});
			} else {
				applyModifiedCustomColumn(res);
				displayAlert('success', `Your changes to ${columnDetails.header} have been saved.`);
				trackEvent({
					userDetails: { userId: user._id, email: user.email },
					eventDetails: {
						types: ['Segment', 'Encharge', 'GA4', 'AppInsights'],
						eventName: 'User successfully edited a custom column.',
					},
				});
				handleClose();
			}
		});
	};

	// Format array of error messages into unordered list element
	function formatErrors(validationErrors) {
		const errors = validationErrors.map((e) => <li>{e}</li>);
		const errorList = <ul>{errors}</ul>;
		return errorList;
	}

	// Create formatted expression for display.
	const createFormattedExpression = () => {
		let text = expression.value;

		function escapeRegExp(string) {
			return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
		}

		let displayNames = [];

		// Create a list of all column display names
		for (let column of dataSpaceViewContext?.dataSpace?.columnMetadata || []) {
			let displayName;

			if (column.header) {
				displayName =
					'#' + column.header + (column.dataSourceName === 'Calculated' ? '(calc)' : `(${column.field.substr(-3)})`);
			} else {
				displayName = column.field;
			}

			displayNames.push(displayName);
		}

		const regexDisplayNames = displayNames.map((displayName) => escapeRegExp(displayName));

		const pattern = new RegExp(`(${regexDisplayNames.join('|')})`, 'gi');

		let parts = text.split(pattern);

		const innerContent = parts.map((part, index) => {
			if (displayNames.includes(part)) {
				const dataset = dataSpaceViewContext?.dataSpace?.datasets.find((dataset) =>
					dataset._id.endsWith(part.slice(-4, -1)),
				);

				return (
					<span
						key={index}
						contentEditable={false}
						style={{
							padding: '0.25rem',
							margin: '0 0.5rem',
							border: '1px solid #BABABA',
							borderRadius: '0.125rem',
							display: 'inline-flex',
							alignItems: 'center',
							color: '#0094FF',
						}}
					>
						<img
							src={getLogo({ api: dataset?.tags?.api, sport: dataset?.tags?.sport })}
							style={{ width: '1.2rem', height: '1.2rem', marginRight: '0.25rem', borderRadius: '0.125rem' }}
						/>
						{dataset ? part.slice(1, -5) : part.slice(1, -6)}
						<span
							style={{
								marginLeft: '0.25rem',
								padding: '0.1rem 0.25rem',
								borderRadius: '0.125rem',
								backgroundColor: '#E4E4E4',
								color: '#393939',
							}}
						>
							{dataset ? part.slice(-4, -1) : part.slice(-5, -1)}
						</span>
					</span>
				);
			} else {
				return part;
			}
		});

		return innerContent;
	};

	const formattedExpressionContent = createFormattedExpression();

	useEffect(() => {
		const handleKeyUp = () => {
			// if inputRef is focused and user presses a key, update expression.selectionEnd.
			if (inputRef?.current?.container?.children[0] === document.activeElement) {
				setExpression((prevState) => ({
					...prevState,
					selectionEnd: inputRef?.current?.inputRef?.current?.selectionEnd || prevState.selectionEnd,
				}));
			}
		};

		document.addEventListener('keyup', handleKeyUp, true);

		return () => {
			document.removeEventListener('keyup', handleKeyUp);
		};
	}, []);

	return (
		<div>
			<Dialog
				open={open}
				PaperProps={{
					style: {
						overflowY: 'visible',
						maxHeight: 'calc(100vh - 12rem)',
					},
				}}
				TransitionComponent={Transition}
				keepMounted
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
				className="calculated-column-dialog"
			>
				<DialogTitle id="alert-dialog-slide-title">{`Edit your Column: ${columnDetails.header}`}</DialogTitle>

				<DialogContent>
					<p style={{ margin: '0' }}>
						<b>Original Expression:</b>
					</p>
					<p style={{ margin: '0', marginTop: '0.5rem' }}>{originalExpression}</p>

					<p style={{ margin: '0', marginTop: '1.5rem' }}>
						<b>Edit your expression:</b>
					</p>
					<Mention
						ref={inputRef}
						style={{ width: '100%', marginTop: '0.5rem' }}
						rows={5}
						inputStyle={{ width: '100%' }}
						value={expression.value}
						onChange={(e) => {
							setExpression((prevState) => ({
								value: inputRef.current.container.children[0].value,
								selectionEnd: e.target.selectionEnd || prevState.selectionEnd,
							}));
						}}
						trigger="#"
						suggestions={suggestions}
						onSearch={onSearch}
						placeholder="Please enter # to mention people"
						itemTemplate={itemTemplate}
						field="displayName"
						onClick={(e) => {
							setExpression((prevState) => ({
								...prevState,
								selectionEnd: e.target.selectionEnd,
							}));
						}}
						onSelect={selectHandler}
					/>

					<p style={{ margin: '0', marginTop: '1.5rem' }}>
						<b>Your expression:</b>
					</p>

					<p
						style={{
							padding: '1rem',
							display: 'flex',
							alignItems: 'center',
							flexWrap: 'wrap',
							border: '1px solid #C4C4C4',
							borderRadius: '0.25rem',
							backgroundColor: '#F5F5F5',
						}}
					>
						{expression?.value?.length ? (
							formattedExpressionContent
						) : (
							<span style={{ color: 'grey' }}>No expression</span>
						)}
					</p>

					<p style={{ margin: '0', marginTop: '1.5rem' }}>
						<b>Available fields:</b>
					</p>
					<p style={{ margin: '0' }}>
						Copy and paste field names from the list below to add into your expression (Click to add to expression).
					</p>

					<ul className={classes.datasetsList}>
						{[...(dataSpaceViewContext.dataSpace?.datasets || []), calculatedDataset].map((dataset) => (
							<Dataset numericalColumns={columns} dataset={dataset} setExpression={setExpression} />
						))}
					</ul>
				</DialogContent>

				<DialogActions>
					<Button onClick={saveChangesOnClick}>Save Changes</Button>

					<Button
						onClick={() => {
							setExpression({ value: '', selectionEnd: 0 });
							handleClose();
						}}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>

			<Snackbar
				open={snackOpen}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				onClose={() => setSnackOpen(false)}
			>
				<Alert severity={'error'}>{snackMessage}</Alert>
			</Snackbar>
		</div>
	);
}
