import { Switch, Button, Grid } from '@material-ui/core';
import { updateDataSpace_v2 } from '../../api_helper/api';
import { useContext } from 'react';
import MyDataSpacesViewContext from './context/MyDataSpacesViewContext';
import { trackEvent } from '../../utils/eventTracking';
import { UserProfileContext } from '../../UserProfileContext';
import SnackbarAlertContext from '../../context/SnackbarAlertContext';

const AccessToggle = (props) => {
	const myDataSpacesViewContext = useContext(MyDataSpacesViewContext);
	const userProfile = useContext(UserProfileContext)[0];
	const snackbarAlertContext = useContext(SnackbarAlertContext);

	const accessToggleChangeHandler = async () => {
		props.setIsPermissionsUpdating(true);

		let isDataSpaceShared = myDataSpacesViewContext.selectedDataSpace.isShared;
		let updatedViews;

		if (myDataSpacesViewContext.selectedDataSpace._id === props.option._id) {
			isDataSpaceShared = !isDataSpaceShared;

			trackEvent({
				userDetails: { userId: userProfile._id, email: userProfile.email },
				eventDetails: {
					types: ['KissMetrics', 'AppInsights', 'Segment', 'GA4'],
					eventName: `User ${isDataSpaceShared ? 'activated' : 'deactivated'} the public link for a DataSpace.`,
				},
			});

			if (isDataSpaceShared) {
				updatedViews = myDataSpacesViewContext.selectedDataSpace.views.map((view) => ({ ...view, isShared: true }));
			} else {
				updatedViews = myDataSpacesViewContext.selectedDataSpace.views;
			}
		} else {
			updatedViews = myDataSpacesViewContext.selectedDataSpace.views.map((view) => {
				if (view.id === props.option.id) {
					trackEvent({
						userDetails: { userId: userProfile._id, email: userProfile.email },
						eventDetails: {
							types: ['KissMetrics', 'AppInsights', 'Segment', 'GA4'],
							eventName: `User ${!view.isShared ? 'activated' : 'deactivated'} the public link for a view.`,
						},
					});

					return {
						...view,
						isShared: !view.isShared,
					};
				}
				return view;
			});
		}

		await updateDataSpace_v2({
			dataSpaceId: myDataSpacesViewContext.selectedDataSpace._id,
			updatedFields: {
				views: updatedViews,
				isShared: isDataSpaceShared,
			},
		});

		myDataSpacesViewContext.setSelectedDataSpace((prevState) => ({
			...prevState,
			views: updatedViews,
			isShared: isDataSpaceShared,
		}));
		props.setIsPermissionsUpdating(false);
	};

	const copyLinkClickHandler = () => {
		// Format DataSpace name to include in URL.
		const formmattedName = myDataSpacesViewContext.selectedDataSpace.name
			.slice(0, 100)
			.replace(/ /g, '-')
			.replace(/[^\w\s-]/gi, '');

		let link =
			window.location.origin +
			'/app/dataSpaces/' +
			formmattedName +
			'-' +
			myDataSpacesViewContext.selectedDataSpace._id;

		// If we are copying a link for a view, add the view id as a query parameter.
		if (myDataSpacesViewContext.selectedDataSpace._id !== props.option._id) {
			link = link + '?v=' + props.option.id;
		}

		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: {
				types: ['KissMetrics', 'Segment', 'AppInsights', 'GA4'],
				eventName: `User copied a link for sharing a ${props.option.id ? 'view' : 'DataSpace'}.`,
			},
		});

		navigator.clipboard.writeText(link);

		snackbarAlertContext.setSnackbarAlert({
			msg: 'Link copied',
			severity: 'success',
			autoHide: 5000,
			isSnackbarOpen: true,
		});
	};

	return (
		<Grid container style={{ width: '100%', alignItems: 'center' }}>
			<Grid item xs={5} style={{ wordBreak: 'break-word' }}>
				{props.option.name + (props.option.datasets ? ' (DataSpace)' : '')}
			</Grid>
			<Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
				<Switch
					checked={props.option.isShared ? true : false}
					onChange={accessToggleChangeHandler}
					disabled={props.isPermissionsUpdating}
				/>
			</Grid>
			<Grid item xs={4} style={{ display: 'flex', justifyContent: 'end' }}>
				<Button variant="outlined" onClick={copyLinkClickHandler}>
					Copy Link
				</Button>
			</Grid>
		</Grid>
	);
};

export default AccessToggle;
