import React, { useContext } from 'react';
import SideNav, {
	Toggle,
	Nav,
	NavItem,
	NavIcon,
	NavText,
	NavHeader,
	NavTitle,
	NavSubTitle,
	LogoContainer,
	LogoWrapper,
	SideNavLogo,
} from './StyledSideNav';
import ClickOutside from 'react-click-outside';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as AccountIcon } from '../../assets/icons/user.svg';
import { ReactComponent as YourDataSpacesIcon } from '../../assets/icons/note-text.svg';
import { ReactComponent as ExploreIcon } from '../../assets/icons/globe.svg';
import { ReactComponent as MyDataIcon } from '../../assets/icons/bar-chart.svg';
import { ReactComponent as LogOut } from '../../assets/icons/ExitToApp.svg';
import SportWiseLogoSmall from '../../assets/img/SportWiseLogoSmall.png';
import PageContext from '../../context/PageContext';

export default function SideNavigation(props) {
	const pageContext = useContext(PageContext);

	const logoDisplay = (expanded) => {
		if (expanded === true) {
			return (
				<LogoContainer>
					<SideNavLogo src={SportWiseLogoSmall} alt="SportWise small logo" />
					<p>SportWise</p>
				</LogoContainer>
			);
		}
		return (
			<LogoWrapper>
				<SideNavLogo src={SportWiseLogoSmall} alt="SportWise small logo" />
			</LogoWrapper>
		);
	};

	return (
		<ClickOutside
			onClickOutside={() => {
				props.setExpanded(false);
			}}
		>
			<SideNav
				style={{ minWidth: props.expanded ? props.navWidthExpanded : props.navWidthCollapsed, position: 'absolute' }}
				onSelect={props.onSelect}
				onToggle={props.onToggle}
				expanded={props.expanded}
			>
				{/* {logoDisplay(props.expanded)} */}
				<Toggle />
				<NavHeader expanded={props.expanded}>
					<NavTitle>‎‏‏‎ ‎</NavTitle>
					<NavSubTitle>‏‏‎ ‎</NavSubTitle>
				</NavHeader>
				<Nav selected={pageContext.page}>
					{logoDisplay(props.expanded)}

					<NavItem eventKey="mydataspaces">
						<NavIcon>
							<SvgIcon style={{ verticalAlign: 'middle' }}>
								<YourDataSpacesIcon />
							</SvgIcon>
						</NavIcon>
						<NavText style={{ paddingRight: 32 }} title="Your DataSpaces">
							Your DataSpaces
						</NavText>
					</NavItem>

					<NavItem eventKey="CommunityDataSpaces">
						<NavIcon>
							<SvgIcon style={{ verticalAlign: 'middle' }}>
								<ExploreIcon />
							</SvgIcon>
						</NavIcon>
						<NavText style={{ paddingRight: 32 }} title="Community Dataspaces">
							Explore DataSpaces
						</NavText>
					</NavItem>

					<NavItem eventKey="MyData">
						<NavIcon>
							<SvgIcon style={{ verticalAlign: 'middle' }}>
								<MyDataIcon />
							</SvgIcon>
						</NavIcon>
						<NavText style={{ paddingRight: 32 }} title="My Data">
							My Data
						</NavText>
					</NavItem>

					<NavItem eventKey="account">
						<NavIcon>
							<SvgIcon style={{ verticalAlign: 'middle' }}>
								<AccountIcon />
							</SvgIcon>
						</NavIcon>
						<NavText style={{ paddingRight: 32 }} title="Account">
							Account
						</NavText>
					</NavItem>

					<NavItem eventKey="logout">
						<NavIcon>
							<SvgIcon style={{ verticalAlign: 'middle' }}>
								<LogOut />
							</SvgIcon>
						</NavIcon>
						<NavText style={{ paddingRight: 32 }} title="Logout">
							Logout
						</NavText>
					</NavItem>
				</Nav>
			</SideNav>
		</ClickOutside>
	);
}
