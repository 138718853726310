import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import SingleSelect from '../../../ui_elements/SingleSelect';
import { updateUser, updateUserLeagues } from '../../../../api_helper/api';
import { trackEvent } from '../../../../utils/eventTracking';
import { UserProfileContext } from '../../../../UserProfileContext';
import { PreloadedDataContext } from '../../../../PreloadedDataContext';
import SnackbarAlertContext from '../../../../context/SnackbarAlertContext';
import CreateEditDataSpaceContext from '../../context/CreateEditDataSpaceContext';

// Dialog allowing user to connect a new fantasy league to be used as a datasource.
// Renders when user selects "Connect new league" in first page of dataspace creation process.
const AddFantasyLeagueDialog = (props) => {
	const snackbarAlertContext = useContext(SnackbarAlertContext);
	const [apiSelection, setApiSelection] = useState(''); // API chosen by user
	const [didConnect, setDidConnect] = useState(false); // Track if connection attempt successful
	const [didSubmit, setDidSubmit] = useState(false); // Track if user has pressed Connect League so we know to poll database
	const userProfileContext = useContext(UserProfileContext)[0];
	const setUserProfileContext = useContext(UserProfileContext)[1];
	const apiOptions = useContext(PreloadedDataContext).supportedFantasyAPIs[0]; // List of supported APIs and their Auth URLs
	const [fantraxUserSecretId, setFantraxUserSecretId] = useState('');
	const [isUpdating, setIsUpdating] = useState(false);
	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);

	const handleChangeApiDropdown = (e) => {
		// Deploy custom event for Usetiful
		window.dispatchEvent(
			new CustomEvent(e.target.value === 'Fantrax' ? 'connectFantasyFantrax' : 'connectFantasyYahoo'),
		);

		setApiSelection(e.target.value);
	};

	// Extend props.handleClose to reset dialog state.
	const handleCloseExtended = () => {
		dialogCloseHandler();
		setApiSelection('');
		setDidConnect(false);
		setDidSubmit(false);
	};

	const onClickConnectLeague = async () => {
		if (apiSelection === 'Yahoo! Fantasy Sports') {
			// Redirect user to authentication portal
			const authURL = apiOptions.find((opt) => opt.api === apiSelection).authURL;
			window.location.assign(authURL + `?route=${props.callBackRoute}`);
		} else if (apiSelection === 'Fantrax') {
			try {
				setIsUpdating(true);

				// Update user document with Fantrax data
				await updateUser({
					userId: userProfileContext._id,
					updatedFields: {
						fantraxCredentials: { userSecretId: fantraxUserSecretId },
						connectedLeagueApis: Array.from(new Set([...userProfileContext.connectedLeagueApis, apiSelection])),
					},
				});

				const updateUserLeaguesRes = await updateUserLeagues({ userId: userProfileContext._id });

				const dataSpaceName = createEditDataSpaceContext.newDataSpace.name;
				createEditDataSpaceContext.resetNewDataSpace({
					_id: createEditDataSpaceContext.newDataSpace._id,
					name: dataSpaceName,
				});
				createEditDataSpaceContext.setConnectedFantasyLeagues(updateUserLeaguesRes.leagues);

				setIsUpdating(false);
				handleCloseExtended();

				snackbarAlertContext.setSnackbarAlert({
					msg: 'Successfully added your Fantrax account.',
					severity: 'success',
					autoHide: 5000,
					isSnackbarOpen: true,
				});
			} catch (e) {
				let errorMessage =
					e.response.data === 'Fantrax user secret invalid or no league selected'
						? 'The Fantrax user secret is invalid or your Fantrax account has no leagues connected.'
						: 'There was an error updating your account';

				setIsUpdating(false);
				snackbarAlertContext.setSnackbarAlert({
					msg: errorMessage,
					severity: 'error',
					autoHide: 5000,
					isSnackbarOpen: true,
				});
			}
		}
	};

	const dialogCloseHandler = () => {
		props.setIsDialogOpen(false);

		trackEvent({
			userDetails: { userId: userProfileContext._id, email: userProfileContext.email },
			eventDetails: {
				types: ['KissMetrics', 'Segment', 'Encharge', 'AppInsights'],
				eventName: 'Exited League Connection dialog.',
			},
		});
	};

	/*
  * Renders dialog contents based on status of connection to API (before submit,
    polling for connection, connection confirmed)
  * @returns {JSX} body of dialog
  */
	const displayDialogBody = () => {
		if (!didSubmit) {
			return (
				<div>
					<DialogContent>
						<p>Choose the league you want to connect to SportWise.</p>
						<SingleSelect
							style={{ width: '100%', margin: '0' }}
							handleChange={handleChangeApiDropdown}
							currentSelection={apiSelection}
							options={['Yahoo! Fantasy Sports', 'Fantrax']}
							placeHolderText="Select a Fantasy Provider"
							disabled={isUpdating}
						/>
						{userProfileContext?.connectedLeagueApis?.includes(apiSelection) && (
							<p>
								You are reconnecting to {apiSelection}. Please ensure you are reconnecting to the same {apiSelection}{' '}
								account if any of your DataSpaces use data from {apiSelection}.
							</p>
						)}
						{apiSelection === 'Fantrax' && (
							<>
								<p>Please enter your Fantrax user secret below to connect your leagues.</p>
								<TextField
									placeholder="User Secret"
									fullWidth
									value={fantraxUserSecretId}
									onChange={(e) => setFantraxUserSecretId(e.target.value)}
									disabled={isUpdating}
								/>
							</>
						)}
					</DialogContent>
					<DialogActions>
						<Button onClick={dialogCloseHandler} color="primary">
							Cancel
						</Button>

						{userProfileContext?.connectedLeagueApis?.includes(apiSelection) ? (
							<Button onClick={onClickConnectLeague} color="primary" disabled={isUpdating}>
								Reconnect League
							</Button>
						) : (
							<Button onClick={onClickConnectLeague} color="primary" disabled={!apiSelection || isUpdating}>
								Connect League
							</Button>
						)}
					</DialogActions>
				</div>
			);
		} else {
			return (
				<div>
					<DialogContent>
						{didConnect ? (
							<p>You have successfully connected to {apiSelection}. You may close this window.</p>
						) : (
							<p>Confirming successful connection with {apiSelection}...</p>
						)}
					</DialogContent>
					<DialogActions>
						<Button onClick={() => handleCloseExtended()} color="primary">
							Close
						</Button>
					</DialogActions>
				</div>
			);
		}
	};

	return (
		<div>
			<Dialog open={props.isDialogOpen} onClose={dialogCloseHandler} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Connect New League</DialogTitle>
				{displayDialogBody()}
			</Dialog>
		</div>
	);
};

export default AddFantasyLeagueDialog;
