import DataSpaceHeaderNbaPng from '../assets/img/DataSpaceHeaderNba.png';
import DataSpaceHeaderNflPng from '../assets/img/DataSpaceHeaderNfl.png';
import DataSpaceHeaderNhlPng from '../assets/img/DataSpaceHeaderNhl.png';
import DataSpaceHeaderMlbPng from '../assets/img/DataSpaceHeaderMlb.png';

const getDataSpaceImageLink = (dataSpace) => {
	switch (dataSpace?.datasets[0]?.tags?.sport) {
		case 'NBA':
			return DataSpaceHeaderNbaPng;
		case 'MLB':
			return DataSpaceHeaderMlbPng;
		case 'NHL':
			return DataSpaceHeaderNhlPng;
		case 'NFL':
			return DataSpaceHeaderNflPng;
		default:
			return DataSpaceHeaderNbaPng;
	}
};

export default getDataSpaceImageLink;
