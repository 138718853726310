/*
DetailsPane.js is a component which is used to create a details pane
in the DataSpace View.

The details pane displays included DataSources, Joins, and an editable
DataSpace Description

This file utilizes a modal to serve as a pop-up DataSpace Details panel.
The user can use this panel to view the included DataSources and Joins
in their DataSpace, as well as view and edit a description of their DataSpace.
*/

import React, { useState, useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { InputTextarea } from 'primereact/inputtextarea';
import { makeStyles } from '@material-ui/core/styles';
import { updateDataSpaceDescription, getInfoForDetailsPaneNew } from '../../../api_helper/api';
import getEditDataSpaceData from '../../../utils/GetEditDataSpaceData';
import getEditDataSpaceDataNew from '../../../utils/GetEditDataSpaceDataNew';
import { UserProfileContext } from '../../../UserProfileContext';
import CreateEditDataSpaceContext from '../../my_dataspaces/context/CreateEditDataSpaceContext';
import MyDataSpacesViewContext from '../../my_dataspaces/context/MyDataSpacesViewContext';
import { trackEvent, trackException } from '../../../utils/eventTracking';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
	list: {
		marginTop: '0',
	},
	italic: {
		fontStyle: 'italic',
		fontWeight: 'normal',
	},
	joinItem: {
		fontWeight: 'bold',
	},
	textArea: {
		width: '100%',
		minWidth: '350px',
	},
	lastRefreshedText: {
		marginTop: '0px',
		marginLeft: '20px',
	},
});

export default function DetailsPane(props) {
	const classes = useStyles();

	const userProfile = useContext(UserProfileContext);
	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);
	const myDataSpacesViewContext = useContext(MyDataSpacesViewContext);

	const [dataSources, setDataSources] = useState([]);
	const [joins, setJoins] = useState([]);
	const [description, setDescription] = useState('');
	const [initialDescription, setInitialDescription] = useState('');

	useEffect(() => {
		getInfoForDetailsPaneNew(props.dataSpaceId).then((result) => {
			const { description, dataSources, joins } = result;
			setDataSources(dataSources);

			setJoins(joins);

			setDescription(description);
			setInitialDescription(description);
		});
	}, [props.dataSpaceId]);

	const onDetailsPaneExit = () => {
		setDescription(initialDescription);
		props.onClose();
	};

	const dataSourcesList = dataSources.map((ds) => {
		return (
			<li>
				<small>
					{ds.dataSource} ({ds.api === 'DataFeedsGraphQL' ? 'DataFeeds' : ds.api})
				</small>
			</li>
		);
	});

	const joinList = joins.map((j) => {
		return (
			<li className={classes.joinItem}>
				<small>
					{j.primarySource} ({j.primarySourceApi})<span className={classes.italic}>{' joins '}</span>
					{j.foreignSource} ({j.foreignSourceApi})<span className={classes.italic}>{' on fields '}</span>
					{j.primaryField}
					<span className={classes.italic}>{' and '}</span>
					{j.foreignField}
				</small>
			</li>
		);
	});

	return (
		<Dialog open={props.open} TransitionComponent={Transition} keepMounted onClose={props.onClose}>
			<DialogTitle>{'DataSpace Details'}</DialogTitle>

			<DialogContent>
				<Typography variant="body1">Description</Typography>

				<InputTextarea className={classes.textArea} value={description} rows={5} autoResize spellCheck={false} />

				<Typography sx={{ marginTop: 1 }} variant="body1">
					Included DataSources
				</Typography>
				<ul className={classes.list}>{dataSourcesList}</ul>

				<Typography variant="body1">Included Joins</Typography>
				<ul className={classes.list}>{joinList}</ul>

				<Typography variant="body1">Last Refreshed</Typography>
				<p className={classes.lastRefreshedText}>
					<small>{props.lastRefreshDateTime}</small>
				</p>
			</DialogContent>

			<DialogActions style={{ justifyContent: 'space-between' }}>
				<Button onClick={onDetailsPaneExit} color="primary">
					Exit
				</Button>
			</DialogActions>
		</Dialog>
	);
}
