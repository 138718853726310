import React from 'react';
import Button from '@material-ui/core/Button';

export default function Billing(props) {
	const { product, state, billingPortalURL } = props.details;
	const onClickChangePlan = () => {
		window.open(billingPortalURL, '_blank');
	};

  return (
    <div>
      <h3>Subscription Type</h3>
      <p>{product}</p>
      <h3>Subscription State</h3>
      <p>{state}</p>
      <h3>Billing Portal</h3>
      <p>From the Billing Portal you can manage your subscription details and review your payment history.</p>
      <Button
        variant="contained"
        color="secondary"
        margin="normal"
        onClick={onClickChangePlan}
      >
        Enter Billing Portal
      </Button>
    </div>
  )
}
