import React, { useEffect, useState, useContext } from 'react';
import { SvgIcon, Box, Grid, Button, TextField } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import InputAdornment from '@material-ui/core/InputAdornment';
import TwitterIcon from '@material-ui/icons/Twitter';
import WebsiteIcon from '@material-ui/icons/Language';
import RedditIcon from '@material-ui/icons/Reddit';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import { ReactComponent as TikTokIcon } from '../../assets/icons/tiktok.svg';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { getUserProfile, updateUserProfile } from '../../api_helper/api';
import { isEmpty, validateEmail } from '../../utils/util';
import { identify } from '../../utils/eventTracking';
import { UserProfileContext } from '../../UserProfileContext';
import ImageCrop from './ImageCrop';
import DefaultProfilePng from '../../assets/img/DefaultProfile.png';

const useStyles = makeStyles((theme) => ({
	form: {
		width: '100%',
	},
}));

export function UserProfileForm() {
	const [userProfile, setUserProfile] = useState({
		firstname: '',
		lastname: '',
		username: '',
		email: '',
		phone: '',
		website: '',
		twitter: '',
		reddit: '',
		linkedIn: '',
		instagram: '',
		tikTok: '',
		uncroppedProfileImage: undefined,
		croppedProfileImage: undefined,
	});

	//Event Tracking
	const user = useContext(UserProfileContext)[0];

	const [errors, setErrors] = useState([]);
	//Manage the state for a successful response and a server side exception
	const [success, setSuccess] = useState('');
	const [postError, setPostError] = useState('');
	const classes = useStyles();

	useEffect(() => {
		// Retrieve the userprofile for the logged in user
		getUserProfile().then((user) => {
			//Set the state of the userProfile
			setUserProfile({
				firstname: user.firstname,
				lastname: user.lastname,
				username: user.username,
				email: user.email,
				phone: user.phone,
				website: user.website,
				twitter: user.twitter,
				reddit: user.reddit,
				linkedIn: user.linkedIn,
				instagram: user.instagram,
				tikTok: user.tikTok,
				uncroppedProfileImage: user.uncroppedProfileImage,
				croppedProfileImage: user.uncroppedProfileImage,
				userProfileImageUrl: user.userProfileImageUrl,
			});
		});
	}, []);

	//Handle the change of values on each textfield
	const handleChange = (e) => {
		setUserProfile((prevState) => {
			prevState[e.target.name] = e.target.value;
			return { ...prevState };
		});
	};
	// This function verifies that all fields are entered correctly
	// and that username or email are not previously registered.
	const verifyFields = async () => {
		let fieldErrors = [];
		// Synchronous error checks
		// Check required fields are entered
		for (var key of Object.keys(userProfile)) {
			//The phone number is not a required field
			if (['firstname', 'lastname', 'email', 'username'].includes(key)) {
				//Check to see if the value is empty
				if (isEmpty(userProfile[key])) {
					fieldErrors.push({
						field: key,
						msg: 'Please enter text into this field.',
					});
				}
			}
		}

		if (
			userProfile.website?.length &&
			!userProfile.website.startsWith('https://') &&
			!userProfile.website.startsWith('http://')
		) {
			fieldErrors.push({
				field: key,
				msg: 'Website needs to start with https:// or http://',
			});
		}

		// Check if valid e-mail
		if (!validateEmail(userProfile.email)) fieldErrors.push({ field: 'email', msg: 'Invalid email.' });

		//Set the state of the errors with the new field error group
		// This is a hack to force the useState object to update with the errors so that it will trigger a render and update the array
		Promise.resolve().then(() => {
			setErrors(fieldErrors);
		});
		return fieldErrors;
	};

	// Find the object in an array by key
	const search = (fieldKey, myArray) => {
		for (var i = 0; i < myArray.length; i++) {
			if (myArray[i].field === fieldKey) {
				return myArray[i];
			}
		}
	};

	// Handle close when the snackbar closes
	const handleClose = () => {
		//Reset the success and postError message
		setPostError('');
		setSuccess('');
	};

	//Submit the payload to the server
	const submit = async (e) => {
		//Set the errors after verifying the fields
		let errors = await verifyFields();

		if (errors.length) {
			setPostError(errors[0].msg);
		}

		await updateUserProfile(userProfile);
		identify(userProfile.email, userProfile.username, userProfile.firstname, userProfile.lastname); // Update Encharge info
		setSuccess('Successfully updated user profile');

		setUserProfile(userProfile);
	};

	const handleFileChange = (e) => {
		if (!e.target.files[0]) return;

		setUserProfile((prevState) => ({
			...prevState,
			uncroppedProfileImage: URL.createObjectURL(e.target.files[0]),
		}));
	};

	return (
		<>
			<Grid
				container
				style={{
					width: '100%',
					maxWidth: '40rem',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					margin: '0 auto',
				}}
			>
				<label
					for="user-profile-image-input"
					style={{
						width: 'fit-content',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						cursor: 'pointer',
					}}
				>
					<img
						src={userProfile.croppedProfileImage || userProfile.userProfileImageUrl || DefaultProfilePng}
						style={{ width: '10rem', borderRadius: '50%' }}
					/>
					<span
						style={{
							marginTop: '0.5rem',
							color: 'grey',
							textDecoration: 'underline',
							fontSize: '0.8rem',
						}}
					>
						Change profile image
					</span>
				</label>
				<input
					id="user-profile-image-input"
					type="file"
					onChange={handleFileChange}
					accept="image/*"
					style={{ display: 'none' }}
				/>

				<form className={classes.form}>
					{/* First Name */}
					<Grid item>
						<TextField
							required
							label="First Name"
							name="firstname"
							value={userProfile.firstname || ''}
							variant="outlined"
							margin="normal"
							fullWidth={true}
							onChange={handleChange}
							FormHelperTextProps={{
								component: 'div',
							}}
							helperText={search('firstname', errors)?.msg}
							error={!isEmpty(search('firstname', errors)?.msg)}
						/>
					</Grid>
					{/* Last Name */}
					<Grid item>
						<TextField
							required
							label="Last Name"
							name="lastname"
							value={userProfile.lastname || ''}
							variant="outlined"
							margin="normal"
							fullWidth={true}
							onChange={handleChange}
							FormHelperTextProps={{
								component: 'div',
							}}
							helperText={search('lastname', errors)?.msg}
							error={!isEmpty(search('lastname', errors)?.msg)}
						/>
					</Grid>
					{/* Email */}
					<Grid item>
						<TextField
							required
							label="E-Mail"
							name="email"
							value={userProfile.email || ''}
							variant="outlined"
							margin="normal"
							fullWidth={true}
							onChange={handleChange}
							FormHelperTextProps={{
								component: 'div',
							}}
							helperText={search('email', errors)?.msg}
							error={!isEmpty(search('email', errors)?.msg)}
						/>
					</Grid>
					{/* Username */}
					<Grid item>
						<TextField
							required
							label="Username"
							name="username"
							value={userProfile.username || ''}
							variant="outlined"
							margin="normal"
							fullWidth={true}
							onChange={handleChange}
							FormHelperTextProps={{
								component: 'div',
							}}
							helperText={search('username', errors)?.msg}
							error={!isEmpty(search('username', errors)?.msg)}
						/>
					</Grid>
					{/* Phone */}
					<Grid item>
						<TextField
							label="Phone Number"
							name="phone"
							value={userProfile.phone || ''}
							variant="outlined"
							margin="normal"
							fullWidth={true}
							onChange={handleChange}
						/>
					</Grid>
					{/* Website */}
					<Grid item>
						<TextField
							label="Website"
							name="website"
							value={userProfile.website || ''}
							variant="outlined"
							margin="normal"
							fullWidth={true}
							onChange={handleChange}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<WebsiteIcon />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* Twitter */}
					<Grid item>
						<TextField
							label="Twitter Username"
							name="twitter"
							value={userProfile.twitter || ''}
							variant="outlined"
							margin="normal"
							fullWidth={true}
							onChange={handleChange}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<TwitterIcon />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* Reddit */}
					<Grid item>
						<TextField
							label="Reddit Username"
							name="reddit"
							value={userProfile.reddit || ''}
							variant="outlined"
							margin="normal"
							fullWidth={true}
							onChange={handleChange}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<RedditIcon />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* LinkedIn */}
					<Grid item>
						<TextField
							label="LinkedIn Username"
							name="linkedIn"
							value={userProfile.linkedIn || ''}
							variant="outlined"
							margin="normal"
							fullWidth={true}
							onChange={handleChange}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<LinkedInIcon />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* Instagram */}
					<Grid item>
						<TextField
							label="Instagram Username"
							name="instagram"
							value={userProfile.instagram || ''}
							variant="outlined"
							margin="normal"
							fullWidth={true}
							onChange={handleChange}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<InstagramIcon />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* TikTok */}
					<Grid item>
						<TextField
							label="TikTok Username"
							name="tikTok"
							value={userProfile.tikTok || ''}
							variant="outlined"
							margin="normal"
							fullWidth={true}
							onChange={handleChange}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SvgIcon>
											<TikTokIcon />
										</SvgIcon>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* Submission Button */}
					<Box m={2} pt={3}>
						<Grid item align="center">
							<Button
								variant="contained"
								color="secondary"
								margin="normal"
								onClick={() => {
									submit();
									// const eventName = "Updated User Information";
									// enchargeOnClick(eventName);
								}}
							>
								Save
							</Button>
						</Grid>
					</Box>
				</form>
			</Grid>
			<ImageCrop userProfile={userProfile} setUserProfile={setUserProfile} />
			<Snackbar
				open={!isEmpty(postError) || !isEmpty(success)}
				autoHideDuration={6000}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				onClose={handleClose}
			>
				<Alert severity={postError ? 'error' : 'success'}>{postError ? postError : success}</Alert>
			</Snackbar>
		</>
	);
}
