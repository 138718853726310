/* Function: LogOut
 *   Remove the JWT and redirect the user to the main login page
 */

import { useContext } from 'react';
import { Dialog, DialogActions, DialogContent, Button } from '@material-ui/core';
import MyDataSpacesViewContext from '../my_dataspaces/context/MyDataSpacesViewContext';

const LogoutModal = () => {
	const myDataSpacesViewContext = useContext(MyDataSpacesViewContext);

	return (
		<Dialog
			open={myDataSpacesViewContext.isLogoutModalOpen}
			onClose={() => myDataSpacesViewContext.setIsLogoutModalOpen(false)}
		>
			<DialogContent>Are you sure you want to logout?</DialogContent>
			<DialogActions>
				<Button
					color="primary"
					onClick={() => {
						localStorage.removeItem('jwt');
						// Redirect the user to the main login page
						window.location.href = '/';
					}}
				>
					Log Out
				</Button>
				<Button color="primary" onClick={() => myDataSpacesViewContext.setIsLogoutModalOpen(false)}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default LogoutModal;
