import { useContext, useState } from 'react';
import { Grid, Typography, makeStyles, Button } from '@material-ui/core';
import CreateEditDataSpaceContext from '../../context/CreateEditDataSpaceContext';
import EditSelectedDataSourceFieldsDialog from './EditSelectedDataSourceFieldsDialog';

const useStyles = makeStyles((theme) => ({
	datasetWrapper: {
		width: '100%',
		marginBottom: '1rem',
	},
	datasetHeader: {
		margin: '0 0 0.5rem 0',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderBottom: '1px solid black',
	},
	datasetHeaderButton: {
		textTransform: 'lowercase',
	},
	datasetField: {
		height: 'fit-content',
		marginBottom: '0.25rem',
		padding: '0.3rem',
		backgroundColor: '#393939',
		borderRadius: '0.2rem',
		boxSizing: 'border-box',
		color: '#FFFFFF',
		overflow: 'hidden',
		textAlign: 'center',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
}));

// Component showing a selected datasource name and its selected fields.
const SelectedDataset = (props) => {
	const classes = useStyles();

	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);
	const [isEditSelectedDatasetFieldsDialogOpen, setIsEditSelectedDatasetFieldsDialogOpen] = useState(false);

	const editDatasetClickHandler = () => {
		setIsEditSelectedDatasetFieldsDialogOpen(true);
	};

	// Remove deleted dataset from joinData as well as all joins it has with other datasources in joinData.
	// All remove datasource from selectedDatasets in CreateEditDataSpaceContext.
	const deleteDatasetClickHandler = () => {
		createEditDataSpaceContext.setNewDataSpace((prevState) => {
			// Remove any views that depend on the deleted datasource and default view.
			const updatedViews = prevState.views.filter((view) => {
				if (view.name === 'Default') {
					return false;
				}
				for (let column of view.externalProperties.selectedColumns) {
					if (column.dataSourceName === props.selectedDataset.name) {
						return false;
					}
				}
				return true;
			});

			const updatedSelectedDatasets = prevState.selectedDatasets.filter(
				(dataset) => dataset._id !== props.selectedDataset._id,
			);

			let updatedJoinData = prevState.joinData.filter(
				(datasource) => datasource.primarySourceId !== props.selectedDataset._id,
			);

			updatedJoinData = updatedJoinData.map((datasource) => ({ ...datasource, joins: [] }));

			return {
				...prevState,
				selectedDatasets: updatedSelectedDatasets,
				joinData: updatedJoinData,
				views: updatedViews,
			};
		});
	};

	// Only show fields that have been selected.
	const selectedFields = props.selectedDataset.dataSpaceFields.filter((field) => field.isSelected);
	const selectedFieldComponents = selectedFields.map((selectedField) => (
		<Grid item xs={12} sm={5.9}>
			<div className={classes.datasetField}>{selectedField.header}</div>
		</Grid>
	));

	return (
		<>
			<div className={classes.datasetWrapper}>
				<p className={classes.datasetHeader}>
					{props.selectedDataset.name}
					{props.selectedDataset.season ? ` (${props.selectedDataset.season})` : ''}
					<div>
						<Button className={classes.datasetHeaderButton} onClick={editDatasetClickHandler}>
							edit
						</Button>
						<Button className={classes.datasetHeaderButton} onClick={deleteDatasetClickHandler}>
							delete
						</Button>
					</div>
				</p>
				<Grid item container xs={12} justifyContent="space-between">
					{selectedFieldComponents.length ? selectedFieldComponents : 'No fields selected'}
				</Grid>
			</div>

			{/* If user clicks "edit" on a selected datasource, render a dialog to edit its selected fields */}
			<EditSelectedDataSourceFieldsDialog
				dataset={props.selectedDataset}
				isEditSelectedDatasetFieldsDialogOpen={isEditSelectedDatasetFieldsDialogOpen}
				setIsEditSelectedDatasetFieldsDialogOpen={setIsEditSelectedDatasetFieldsDialogOpen}
			/>
		</>
	);
};

export default SelectedDataset;
