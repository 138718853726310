import { Grid, Typography, makeStyles } from '@material-ui/core';
import NBALogoPNG from '../../assets/img/NBALogoSquare.png';
import MLBLogoPNG from '../../assets/img/MLBLogoSquare.png';
import NFLLogoPNG from '../../assets/img/NFLLogoSquare.png';
import NHLLogoPNG from '../../assets/img/NHLLogoSquare.png';
import YahooFantasyLogoPNG from '../../assets/img/YahooFantasyLogo.png';
import CommunityDataSpacesContext from '../../context/CommunityDataSpacesContext';
import getLogo from '../../utils/getLogo';
import getDataSpaceImageLink from '../../utils/getDataSpaceImageLink';
import DefaultProfilePng from '../../assets/img/DefaultProfile.png';
import { useContext } from 'react';

const useStyles = makeStyles((theme) => ({
	datasetWrapper: {
		width: '100%',
		height: '6.5rem',
		padding: '0.5rem 0',
		display: 'flex',
		backgroundColor: 'transparent',
		border: 'none',
		borderBottom: '1px solid black',
		borderRadius: '0',
		boxShadow: 'none',
		textAlign: 'left',
		'&:hover': {
			backgroundColor: 'rgba(0,0,0,0.1)',
			cursor: 'pointer',
		},
	},
	datasetLogo: {
		height: '100%',
		marginRight: '0.8rem',
		borderRadius: '0.5rem',
	},
	datasetInfoWrapper: {
		width: '100%',
		height: '100%',
		overflow: 'hidden',
		fontSize: '0.8125rem',
		color: '#000000',
	},
	datasetInfoHeader: {
		margin: '0',
		fontSize: '0.875rem',
		textTransform: 'uppercase',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
	datasetFieldsWrapper: {
		height: '100%',
		width: '100%',
		overflow: 'hidden',
		border: '1px solid black',
	},
	datasetField: {
		width: '100%',
		height: 'fit-content',
		padding: '0.3rem',
		backgroundColor: '#C4C4C4',
		borderRadius: '0.2rem',
		boxSizing: 'border-box',
		color: '#000000',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
}));

const getDataSpaceLogo = (dataSpace) => {
	switch (dataSpace.datasets[0].tags.sport) {
		case 'NBA':
			return NBALogoPNG;
		case 'MLB':
			return MLBLogoPNG;
		case 'NFL':
			return NFLLogoPNG;
		case 'NHL':
			return NHLLogoPNG;
		case 'YahooNBA':
		case 'YahooMLB':
		case 'YahooNHL':
		case 'YahooNFL':
			return YahooFantasyLogoPNG;
	}
};

// DataSpace list item.
const DataSpace = (props) => {
	const classes = useStyles();

	const communityDataSpacesContext = useContext(CommunityDataSpacesContext);

	const dataSpaceClickHandler = () => {
		communityDataSpacesContext.setSelectedDataSpace(props.dataSpace);
	};

	return (
		<div
			onClick={dataSpaceClickHandler}
			style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				borderRadius: '0.5rem',
				backgroundColor: 'white',
				overflow: 'hidden',
				boxShadow: '0 0 0.25rem 0 rgba(0, 0, 0, 0.25)',
				cursor: 'pointer',
			}}
		>
			<img
				style={{ width: '100%', height: '5rem', objectFit: 'cover' }}
				src={props.dataSpace.dataSpaceImageUrl || getDataSpaceImageLink(props.dataSpace)}
			/>
			<div style={{ padding: '1rem', display: 'flex', flexDirection: 'column' }}>
				<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.25rem' }}>
					<span
						style={{
							marginRight: '0.5rem',
							fontSize: '1.5rem',
							display: '-webkit-box',
							WebkitLineClamp: '2',
							WebkitBoxOrient: 'vertical',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							height: 'fit-content',
							wordBreak: 'break-word',
						}}
					>
						{props.dataSpace.publicName}
					</span>
					<img
						style={{ width: '3rem', height: '3rem', borderRadius: '0.2rem' }}
						src={getDataSpaceLogo(props.dataSpace)}
						alt="DataSpace logo."
					/>
				</div>

				<div style={{ width: '100%', display: 'flex', marginBottom: '0.5rem' }}>
					<img
						style={{ width: '2.5rem', height: '2.5rem', borderRadius: '50%', marginRight: '0.5rem' }}
						src={props.dataSpace.createdBy.userProfileImageUrl || DefaultProfilePng}
						alt="Proflie picture."
					/>
					<div style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
						<p style={{ margin: '0', marginBottom: '0.5rem' }}>{props.dataSpace.createdBy.username}</p>
						<p style={{ margin: '0' }}>{new Date(props.dataSpace.publishedDate).toLocaleDateString()}</p>
					</div>
				</div>

				<div style={{ width: '100%', display: 'flex', overflow: 'hidden' }}>
					{props.dataSpace.datasets.map((dataset) => (
						<div
							key={dataset._id}
							style={{
								width: 'fit-content',
								backgroundColor: '#BDBCBC',
								padding: '0.4rem',
								borderRadius: '0.5rem',
								marginRight: '0.25rem',
								display: 'flex',
								alignItems: 'center',
								color: 'white',
								fontSize: '0.8rem',
								fontWeight: 'bold',
							}}
						>
							<img
								style={{ width: '1rem', borderRadius: '0.1rem', marginRight: '0.25rem' }}
								src={getLogo({ api: dataset.tags.api, sport: dataset.tags.sport })}
								alt="Dataset logo."
							/>
							<span style={{ whiteSpace: 'nowrap' }}>{dataset.displayName || dataset.name}</span>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default DataSpace;
