import { useContext, useState } from 'react';
import { Button, makeStyles, CircularProgress, Backdrop } from '@material-ui/core';
import MyDataSpacesViewContext from '../../context/MyDataSpacesViewContext';
import CreateEditDataSpaceContext from '../../context/CreateEditDataSpaceContext';
import { createDataSpace } from '../../../../api_helper/api';
import SnackbarAlertContext from '../../../../context/SnackbarAlertContext';
import { appInsights } from '../../../../utils/ApplicationInsights';
import { UserProfileContext } from '../../../../UserProfileContext';
import { updateDataSpace } from '../../../../api_helper/api';
import { trackEvent } from '../../../../utils/eventTracking';
import { identifySegment } from '../../../../utils/segmentTracking';
import { identify } from '../../../../utils/eventTracking';

const useStyles = makeStyles((theme) => ({
	loadingOverlay: {
		width: '100vw',
		height: '100vh',
		zIndex: '100',
		color: '#FFFFFF',
	},
}));

// Button which creates dataspace when clicked.
const CreateDataSpaceButton = () => {
	const classes = useStyles();

	// User Profile Data
	const userProfile = useContext(UserProfileContext)[0];

	const myDataSpacesViewContext = useContext(MyDataSpacesViewContext);
	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);
	const snackbarAlertContext = useContext(SnackbarAlertContext);

	// Used to determine when to render circular loading animation overlay.
	const [isLoading, setIsLoading] = useState(false);

	const validateNewDataSpace = () => {
		// Check that an anchor is selected for the dataspace.
		if (createEditDataSpaceContext.newDataSpace.joinData.every((dataset) => dataset.isAnchor === false)) {
			return {
				isNewDataSpaceValid: false,
				msg: 'Please select an anchor for your data space.',
			};
		}

		// If there is more than one selected dataset, check that all selected datasources have at least one join.
		if (createEditDataSpaceContext.newDataSpace.selectedDatasets.length > 1) {
			for (let dataset of createEditDataSpaceContext.newDataSpace.joinData) {
				if (dataset.joins.length === 0) {
					return {
						isNewDataSpaceValid: false,
						msg:
							'Please create a join for "' +
							`${dataset.displayName} ${dataset.tags.timeFrame || ''}` +
							` (${dataset._id.substring(21)})` +
							'" or remove the dataset.',
					};
				}
			}
		}

		return {
			isNewDataSpaceValid: true,
		};
	};

	const createDataSpaceClickHandler = async () => {
		try {
			// Render circular loading animation overlay while dataspace is being created.
			setIsLoading(true);

			trackEvent({
				userDetails: { userId: userProfile._id, email: userProfile.email },
				eventDetails: {
					types: ['KissMetrics', 'AppInsights', 'Segment', 'GA4'],
					eventName: 'WebApp_User Clicks Create DataSpace Button In Create DataSpace Form',
				},
			});

			const validateNewDataSpaceRes = validateNewDataSpace();

			if (!validateNewDataSpaceRes.isNewDataSpaceValid) {
				snackbarAlertContext.setSnackbarAlert({
					isSnackbarOpen: true,
					autohide: 5000,
					severity: 'error',
					msg: validateNewDataSpaceRes.msg,
				});
				setIsLoading(false);
				return;
			}

			const dataSpaceId = await createDataSpace({
				name: createEditDataSpaceContext.newDataSpace.name,
				createdBy: userProfile._id,
				datasets: createEditDataSpaceContext.newDataSpace.selectedDatasets,
				joinData: createEditDataSpaceContext.newDataSpace.joinData,
			});

			myDataSpacesViewContext.setMyDataSpacesView((prevState) => ({
				...prevState,
				selectedDataSpace: { name: createEditDataSpaceContext.newDataSpace.name, id: dataSpaceId },
			}));

			createEditDataSpaceContext.resetNewDataSpace();
			setIsLoading(false);
			myDataSpacesViewContext.changeViewHandler('dataSpaceViewNew');
		} catch (e) {
			snackbarAlertContext.setSnackbarAlert({
				isSnackbarOpen: true,
				autohide: 5000,
				severity: 'error',
				msg: e.response?.data?.config?.url?.endsWith('get_token')
					? 'Failed to fetch Yahoo data. Please reconnect your Yahoo account.'
					: 'Error generating dataspace. Please try again later. If this issue persists, please contact support@rolling-insights.com',
			});
			setIsLoading(false);
			return;
		}
	};

	// When save dataspace is clicked.
	const updateDataSpaceClickHandler = async () => {
		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: {
				types: ['KissMetrics', 'AppInsights', 'Segment', 'GA4'],
				eventName: 'WebApp_User Clicks Save DataSpace Button In Edit DataSpace Form',
			},
		});

		const validateNewDataSpaceRes = validateNewDataSpace();

		if (!validateNewDataSpaceRes.isNewDataSpaceValid) {
			snackbarAlertContext.setSnackbarAlert({
				isSnackbarOpen: true,
				autohide: 5000,
				severity: 'error',
				msg: validateNewDataSpaceRes.msg,
			});
			return;
		}

		// Render circular loading animation overlay while dataspace is being created.
		setIsLoading(true);

		// Remove default view so that it can be reset.
		createEditDataSpaceContext.newDataSpace.views = createEditDataSpaceContext.newDataSpace.views.filter(
			(view) => view.name !== 'Default',
		);

		const updatedDataSpace = await updateDataSpace(
			userProfile._id,
			createEditDataSpaceContext.newDataSpace._id,
			createEditDataSpaceContext.newDataSpace.name,
			createEditDataSpaceContext.newDataSpace.views,
			createEditDataSpaceContext.newDataSpace.selectedDatasets,
			createEditDataSpaceContext.newDataSpace.joinData,
			Date.now(),
			createEditDataSpaceContext.newDataSpace.calculatedColumns,
			createEditDataSpaceContext.newDataSpace.headerNamesState,
			null,
		);

		myDataSpacesViewContext.setMyDataSpacesView((prevState) => ({
			...prevState,
			selectedDataSpace: {
				name: updatedDataSpace.name,
				id: updatedDataSpace._id,
			},
		}));

		createEditDataSpaceContext.resetNewDataSpace();
		setIsLoading(false);
		myDataSpacesViewContext.changeViewHandler('dataSpaceViewNew');

		return;
	};

	return (
		<>
			<Button
				variant="contained"
				margin="normal"
				color="primary"
				onClick={
					createEditDataSpaceContext.createEditDataSpaceMode === 'edit'
						? updateDataSpaceClickHandler
						: createDataSpaceClickHandler
				}
			>
				{createEditDataSpaceContext.createEditDataSpaceMode === 'edit' ? 'Save DataSpace' : 'Create DataSpace'}
			</Button>
			<Backdrop open={isLoading} className={classes.loadingOverlay}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	);
};

export default CreateDataSpaceButton;
