// This file contains configuration used when creating the ChargifyJS form

const chargifyJSConfig = {
	publicKey: process.env.REACT_APP_CHARGIFYJS_PUBLIC_KEY, // React environment variables wasnt working properly **need to test**
	serverHost: process.env.REACT_APP_CHARGIFYJS_SERVER_HOST,
	type: 'card',
	hideCardImage: false,
	optionalLabel: ' ',
	requiredLabel: '*',
	addressDropdowns: true,
	style: {
		field: {
			borderRadius: '.25rem',
			maxWidth: '100%',
		},
		input: {
			fontSize: '16px',
			border: '1px solid #ced4da',
			width: '100%',
			padding: '8px 18px',
		},
		label: {
			backgroundColor: '#ffffff',
			paddingTop: '2px',
			paddingBottom: '1px',
			fontSize: '16px',
			fontWeight: '400',
		},
		message: {
			paddingTop: '2px',
			paddingBottom: '1px',
		},
	},
	fields: {
		firstName: {
			selector: '#first_name',
			label: 'First Name on Card',
			placeholder: 'John',
			required: true,
			message: 'Invalid First Name',
			maxlength: '30',
		},
		lastName: {
			selector: '#last_name',
			label: 'Last Name on Card',
			placeholder: 'Smith',
			required: true,
			message: 'Invalid Last Name',
			maxlength: '30',
		},
		number: {
			selector: '#cc_number',
			label: 'Card Number',
			placeholder: 'Card Number',
			message: 'Invalid Card',
			required: true,
			style: {
				input: {
					padding: '8px 48px',
				},
			},
		},
		month: {
			selector: '#cc_month',
			label: '',
			placeholder: 'MM',
			message: 'Invalid Month',
			required: true,
		},
		year: {
			selector: '#cc_year',
			label: '',
			placeholder: 'YYYY',
			message: 'Invalid Year',
			required: true,
		},
		cvv: {
			selector: '#cc_cvv',
			label: 'CVC',
			placeholder: 'CVC',
			message: 'Invalid CVC',
			required: true,
		},
	},
};

module.exports.chargifyJSConfig = chargifyJSConfig;
