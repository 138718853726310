import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

import PasswordStrengthMeter from '../../utils/PasswordStrengthMeter';
import { isEmpty } from '../../utils/util';
import { updatePassword } from '../../api_helper/api';

const useStyles = makeStyles((theme) => ({
	form: {
		width: 600,
	},
}));

export function InternalResetPasswordForm() {
	const [fields, setFields] = useState([]);
	const [errors, setErrors] = useState([]);
	const [postError, setPostError] = useState();
	const [success, setSuccess] = useState();
	const classes = useStyles();

	//When the value of the a field changes handle the change
	const handleChange = ({ target }) => {
		const { name, value } = target;
		//Create a copy of the array
		let arr = Array.from(fields);
		//Find the index of the key you are looking to create
		let ind = arr.findIndex((e) => e.key === name);
		//Remove the old value and add the new one
		if (ind >= 0) {
			arr.splice(ind, 1, { key: name, value: value });
		} else {
			arr.push({ key: name, value: value });
		}
		setFields(arr);
	};

	// This function verifies that all fields are entered correctly
	// and that new password matches the confirmation
	const verifyFields = async () => {
		let fieldErrors = [];
		const currentPassword = fields[fields.findIndex((e) => e.key === 'currentPassword')]?.value;
		const newPassword = fields[fields.findIndex((e) => e.key === 'newPassword')]?.value;
		const passwordResetConfirmation = fields[fields.findIndex((e) => e.key === 'passwordResetConfirmation')]?.value;

		if (isEmpty(currentPassword)) {
			fieldErrors.push({ key: 'currentPassword', msg: 'Please enter text into this field.' });
		}

		if (newPassword !== passwordResetConfirmation) {
			fieldErrors.push({
				key: 'passwordResetConfirmation',
				msg: 'The passwords enter do not match.  Please try to re-enter your password confirmation',
			});
		}
		//Set the state of the errors with the new field error group
		// This is a hack to force the useState object to update with the errors so that it will trigger a render and update the array
		Promise.resolve().then(() => {
			setErrors(fieldErrors);
		});

		return fieldErrors;
	};

	// Handle close when the snackbar closes
	const handleClose = () => {
		//Reset the success and postError message
		setPostError('');
		setSuccess('');
	};

	// Submit the new password
	const submit = async (e) => {
		let errors = await verifyFields();

		//Check to see if there are any errors to correct
		// If no errors submit, but if there are errors update the interface
		if (errors.length === 0) {
			//Prepare and send the post request to update the user profile
			await updatePassword(fields)
				.then((result) => {
					setSuccess(result);
					//Reset the fields in the form after a successful save
					setFields([]);
				})
				.catch((err) => {
					setPostError(err.data);
				});
		}
	};

	return (
		<Grid container>
			<Snackbar
				open={!isEmpty(postError) || !isEmpty(success)}
				autoHideDuration={6000}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				onClose={handleClose}
			>
				<Alert severity={postError ? 'error' : 'success'}>{postError ? postError : success}</Alert>
			</Snackbar>

			<form className={classes.form}>
				<Grid item>
					<TextField
						required
						name="currentPassword"
						label="Enter your current password"
						value={fields.find((e) => e.key === 'currentPassword')?.value || ''}
						variant="outlined"
						margin="normal"
						fullWidth={true}
						type="password"
						onChange={handleChange}
						FormHelperTextProps={{
							component: 'div',
						}}
						helperText={errors.find((x) => x.key === 'currentPassword')?.msg}
						error={!isEmpty(errors.find((x) => x.key === 'currentPassword')?.msg)}
					/>
				</Grid>
				<Grid item>
					<TextField
						required
						name="newPassword"
						label="Enter a new strong password"
						value={fields.find((e) => e.key === 'newPassword')?.value || ''}
						variant="outlined"
						margin="normal"
						fullWidth={true}
						type="password"
						onChange={handleChange}
					/>
				</Grid>
				<Grid item>
					<PasswordStrengthMeter
						password={
							fields.find((e) => e.key === 'newPassword') ? fields.find((e) => e.key === 'newPassword').value : ''
						}
					/>
				</Grid>
				<Grid item>
					<TextField
						required
						name="passwordResetConfirmation"
						label="Re-enter your new password"
						value={fields.find((e) => e.key === 'passwordResetConfirmation')?.value || ''}
						variant="outlined"
						margin="normal"
						fullWidth={true}
						type="password"
						onChange={handleChange}
						FormHelperTextProps={{
							component: 'div',
						}}
						helperText={errors.find((x) => x.key === 'passwordResetConfirmation')?.msg}
						error={!isEmpty(errors.find((x) => x.key === 'passwordResetConfirmation')?.msg)}
					/>
				</Grid>
				{/* Submission Button */}
				<Box m={2} pt={3}>
					<Grid item align="center">
						<Button variant="contained" color="secondary" margin="normal" onClick={submit}>
							Reset Password
						</Button>
					</Grid>
				</Box>
			</form>
		</Grid>
	);
}
