import React, { useState, useContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import SingleSelect from '../../../ui_elements/SingleSelect';
import { checkThirdPartyAuthenticationStatus, yahooUpdateLeagues } from '../../../../api_helper/api';
import { trackEvent } from '../../../../utils/eventTracking';
import { UserProfileContext } from '../../../../UserProfileContext';
import { PreloadedDataContext } from '../../../../PreloadedDataContext';
import { retryPromise } from '../../../../utils/util';
import SnackbarAlertContext from '../../../../context/SnackbarAlertContext';
import CreateEditDataSpaceContext from '../../context/CreateEditDataSpaceContext';
import { useHistory } from 'react-router-dom';

// Dialog allowing user to connect a new fantasy league to be used as a datasource.
// Renders when user selects "Connect new league" in first page of dataspace creation process.
const AddFantasyLeagueDialog = (props) => {
	let history = useHistory();
	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);
	const snackbarAlertContext = useContext(SnackbarAlertContext);
	const [apiSelection, setApiSelection] = useState(''); // API chosen by user
	const [didConnect, setDidConnect] = useState(false); // Track if connection attempt successful
	const [didSubmit, setDidSubmit] = useState(false); // Track if user has pressed Connect League so we know to poll database
	const userProfile = useContext(UserProfileContext)[0]; // For event tracking ID
	const apiOptions = useContext(PreloadedDataContext).supportedFantasyAPIs[0]; // List of supported APIs and their Auth URLs
	const [connectedApis, setConnectedApis] = useContext(PreloadedDataContext).connectedFantasyAPIs; // APIs already connected to by user

	const handleChangeApiDropdown = (e) => setApiSelection(e.target.value);

	// Configuration for SnackbarAlert depending on alert type.
	const displayAlert = (alertType, customAlert) => {
		let alertConfigs = {
			refreshSuccess: {
				msg: 'Leagues refreshed successfully.',
				severity: 'success',
				autoHide: 5000,
			},
			refreshInProgress: {
				msg: 'Working on refreshing leagues...',
				severity: 'info',
				autoHide: null,
			},
			refreshError: {
				msg: 'Error refreshing leagues. Please try again later',
				severity: 'error',
				autoHide: 5000,
			},
		};

		if (customAlert) {
			snackbarAlertContext.setSnackbarAlert({ ...customAlert, isSnackbarOpen: true });
		} else {
			snackbarAlertContext.setSnackbarAlert({ ...alertConfigs[alertType], isSnackbarOpen: true });
		}
	};

	// Extend props.handleClose to reset dialog state.
	const handleCloseExtended = () => {
		dialogCloseHandler();
		setApiSelection('');
		setDidConnect(false);
		setDidSubmit(false);
	};

	// Logic check for when Connect League button should be disabled.
	const connectLeagueButtonDisabled = connectedApis.includes(apiSelection);

	const onClickConnectLeague = async () => {
		// Redirect user to authentication portal
		const authURL = apiOptions.find((opt) => opt.api === apiSelection).authURL;
		window.location.assign(authURL);
	};

	const dialogCloseHandler = () => {
		props.setIsDialogOpen(false);
		const eventName =
			createEditDataSpaceContext.createEditDataSpaceMode === 'edit'
				? `Exited League Connection dialog in Edit DataSpace Form`
				: `Exited League Connection dialog in Create DataSpace Form`;

		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: { types: ["KissMetrics", 'Segment', 'Encharge', 'AppInsights'], eventName: 'Exited League Connection dialog.' },
		});
	};

	/*
  * Renders dialog contents based on status of connection to API (before submit,
    polling for connection, connection confirmed)
  * @returns {JSX} body of dialog
  */
	const displayDialogBody = () => {
		if (!didSubmit) {
			return (
				<div>
					<DialogContent>
						<p>
							Choose the league you want to connect to SportWise. Once you submit your choice a new tab will open in
							your browser. Please follow the authentication instructions and return to this screen.
							<br />
							<br />
							If a new tab does not open, please disable your pop-up blocker and try again.
						</p>
						<SingleSelect
							handleChange={handleChangeApiDropdown}
							currentSelection={apiSelection}
							options={apiOptions.map((opt) => opt.api)}
							placeHolderText="Select a Fantasy Provider"
						/>
						{connectedApis.includes(apiSelection) && <p>You are already connected to {apiSelection}.</p>}
					</DialogContent>
					<DialogActions>
						<Button onClick={dialogCloseHandler} color="primary">
							Cancel
						</Button>

						<Button onClick={onClickConnectLeague} color="primary" disabled={connectLeagueButtonDisabled}>
							Connect League
						</Button>
					</DialogActions>
				</div>
			);
		} else {
			return (
				<div>
					<DialogContent>
						{didConnect ? (
							<p>You have successfully connected to {apiSelection}. You may close this window.</p>
						) : (
							<p>Confirming successful connection with {apiSelection}...</p>
						)}
					</DialogContent>
					<DialogActions>
						<Button onClick={() => handleCloseExtended()} color="primary">
							Close
						</Button>
					</DialogActions>
				</div>
			);
		}
	};

	return (
		<div>
			<Dialog open={props.isDialogOpen} onClose={dialogCloseHandler} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Connect New League</DialogTitle>
				{displayDialogBody()}
			</Dialog>
		</div>
	);
};

export default AddFantasyLeagueDialog;
