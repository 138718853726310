import { useContext } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import DatasetsList from './DatasetsList';
import SelectedDataList from './SelectedDataList';
import BackButton from './BackButton';
import CreateDataSpaceButton from '../ui_components/CreateDataSpaceButton';
import NewDataSpacePageContentWrapper from '../ui_components/NewDataSpacePageContentWrapper';
import CreateEditDataSpaceContext from '../../context/CreateEditDataSpaceContext';

const useStyles = makeStyles((theme) => ({
	dataDirectoryWrapper: {
		width: '100%',
		height: '75%',
		flexGrow: '1',
		justifyContent: 'space-between',
	},
	editWarningWrapper: {
		width: '100%',
		'& > p': {
			color: 'red',
		},
	},
	cardFooterWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
}));

// New dataspace datasource and fields selection page.
const NewDataSpaceSelectDataNew = () => {
	const classes = useStyles();

	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);

	return (
		<NewDataSpacePageContentWrapper
			header={
				createEditDataSpaceContext.createEditDataSpaceMode === 'edit' ? 'Edit Your Selected Data' : 'Select Your Data'
			}
		>
			<Grid container className={classes.dataDirectoryWrapper}>
				<DatasetsList />
				<SelectedDataList />
			</Grid>

			{createEditDataSpaceContext.createEditDataSpaceMode === 'edit' ? (
				<Grid container className={classes.editWarningWrapper}>
					<p>* You are editing a dataspace. Deleting an existing datasource will reset your dataspace views.</p>
				</Grid>
			) : null}

			<Grid item container className={classes.cardFooterWrapper} xs={12}>
				<BackButton />
				<CreateDataSpaceButton />
			</Grid>
		</NewDataSpacePageContentWrapper>
	);
};

export default NewDataSpaceSelectDataNew;
