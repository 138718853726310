/*
This class is used to generate each filter's prop dependencies.
These props can include filterElement and filterFunction.

Generating the filterElement includes generating the filterElement's
onChange and potentially a list of unique column values (options) if
that filter is of dropdown type.
*/

import React from 'react';
import uniq from 'lodash/uniq';
import { DateTime } from 'luxon';
import { MultiSelect } from 'primereact/multiselect';
import { InputNumber } from 'primereact/inputnumber';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import DateTimePicker from '@material-ui/lab/DateTimePicker';
import DatePicker from '@material-ui/lab/DatePicker';
import { filter } from 'lodash';

const withStyles = makeStyles((theme) => ({
	dateRangeInput: {
		width: '80%',
		height: '50px',
		padding: '6px 12px',
		fontSize: '16px',
		border: '1px solid #ccc',
		borderRadius: '4px',
	},
	dropDownInput: {
		width: '80%',
	},
	dropdownPanel: {
		zIndex: '1300 !important',
	},
	startDate: {
		paddingBottom: '10px',
		width: '80%',
	},
	endDate: {
		width: '80%',
	},
}));

export default class FilterFactory {
	constructor(dataTableReference, state, setState, rows, columnFilterTypes) {
		this.rowData = rows;
		this.state = state;
		this.setState = setState;
		this.dt = dataTableReference;
		this.columnFilterTypes = columnFilterTypes;
		this.classes = withStyles();
	}

	isValueInArray(items, value) {
		let startIndex = 0;
		let stopIndex = items.length - 1;
		let middle = Math.floor((stopIndex + startIndex) / 2);

		while (items[middle] != value && startIndex < stopIndex) {
			// Adjust search area
			if (value < items[middle]) {
				stopIndex = middle - 1;
			} else if (value > items[middle]) {
				startIndex = middle + 1;
			}

			//recalculate middle
			middle = Math.floor((stopIndex + startIndex) / 2);
		}

		//make sure it's the right value
		return items[middle] === value;
	}

	// Returns custom filter function depending on filterType
	// filterFunction contains custom logic used for filtering rows against filter values
	getFilterFunction(field) {
		const filterType = this.getFilterType(field);
		let filterFunction;

		const isTruthyOrZero = (value) => {
			return value || value === 0;
		};

		switch (
			filterType // Use switch case to allow for expansion of options in future
		) {
			case 'numericRange':
				filterFunction = (value, filter) => {
					if (this.state[field]) {
						const min = this.state[field].min;
						const max = this.state[field].max;
						if (isTruthyOrZero(min) && !isTruthyOrZero(max) && value >= min) return true;
						else if (isTruthyOrZero(max) && !isTruthyOrZero(min) && value <= max) return true;
						else if (isTruthyOrZero(max) && isTruthyOrZero(min) && value <= max && value >= min) return true;
						return false;
					}
					return true;
				};
				break;

			case 'dateRange': // Stack dateRange and dateTimeRange cases to avoid code duplication
			case 'dateTimeRange':
				filterFunction = (value, filter) => {
					if (this.state[field]) {
						const min = this.state[field].startDate;
						const max = this.state[field].endDate;
						let date = value;

						if (!date) return false;

						// Remove _DATE/_DATETIME flag suffix from value
						if (date.slice(-5) === '_DATE') {
							date = date.slice(0, -5);
						} else if (date.slice(-9) === '_DATETIME') {
							date = date.slice(0, -9);
						} else {
							throw new Error('Invalid date format: ' + date);
						}
						console.log('Broke: ' + field);

						date = DateTime.fromISO(date);

						if (min && !max && date >= min) return true;
						else if (max && !min && date <= max) return true;
						else if (max && min && date <= max && date >= min) return true;
						return false;
					}
					return true;
				};
				break;

			case 'dropdown':
				filterFunction = (value, filters) => {
					value = value ?? '--'; // If value is undefined, set value to '--'.

					//Ensure that the selected items are sorted alphabetically before passing into the filter logic
					var selectedItems = filters.sort();
					return this.isValueInArray(selectedItems, value);
				};
				break;

			default:
				throw new Error('Invalid filter type specified:' + filterType);
		}

		return filterFunction;
	}

	// Returns custom filter element depending on filterType
	// Filter element represents the actual component used to choose filter values
	getFilterElement(field) {
		const options = this.getUniqueColumnOptions(field);
		const filterType = this.getFilterType(field);
		const onChange = this.getOnChange(field, filterType);

		let filterElement;

		switch (filterType) {
			case 'dropdown':
				// Map strings to objects so that we can use filter search functionality
				filterElement = (
					<MultiSelect
						name={field}
						value={this.state[field]}
						options={options.map((field) => ({ value: field }))}
						onChange={onChange}
						filter
						optionLabel="value"
						optionValue="value"
						className={this.classes.dropDownInput}
						panelClassName={this.classes.dropdownPanel}
						appendTo={document.body}
					/>
				);
				break;

			case 'numericRange':
				filterElement = (
					<React.Fragment>
						<InputNumber
							size={1}
							value={this.state[field]?.min}
							onValueChange={onChange}
							name="min"
							placeholder="Min"
							mode="decimal"
							maxFractionDigits={3}
							style={{ width: '100%', maxWidth: '4rem' }}
						/>
						<InputNumber
							size={1}
							value={this.state[field]?.max}
							onValueChange={onChange}
							name="max"
							placeholder="Max"
							mode="decimal"
							maxFractionDigits={3}
							style={{ width: '100%', maxWidth: '4rem' }}
						/>
					</React.Fragment>
				);
				break;

			case 'dateRange':
				filterElement = (
					<React.Fragment>
						<DatePicker
							label="Start Date"
							value={this.state[field].startDate}
							onChange={onChange[0]}
							renderInput={(params) => <TextField className={this.classes.startDate} size="small" {...params} />}
						/>
						<br />
						<DatePicker
							label="End Date"
							value={this.state[field].endDate}
							onChange={onChange[1]}
							renderInput={(params) => <TextField className={this.classes.endDate} size="small" {...params} />}
						/>
					</React.Fragment>
				);
				break;

			case 'dateTimeRange':
				filterElement = (
					<React.Fragment>
						<DateTimePicker
							clearable
							renderInput={(props) => <TextField className={this.classes.startDate} size="small" {...props} />}
							label="Start Date"
							value={this.state[field].startDate}
							onChange={onChange[0]}
						/>
						<br />
						<DateTimePicker
							renderInput={(props) => <TextField className={this.classes.endDate} size="small" {...props} />}
							label="End Date"
							value={this.state[field].endDate}
							onChange={onChange[1]}
						/>
					</React.Fragment>
				);
				break;

			default:
				throw new Error('Invalid filter type specified: ' + filterType);
		}

		return filterElement;
	}

	// Used to gather unique values for dropdown filters
	getUniqueColumnOptions(field) {
		// If cell's value is undefined, return '--' as the option so the dropdown has a visible value to select.
		let options = this.rowData.map((row) => (row[field] === undefined ? '--' : row[field]));
		options = uniq(options); // remove dupes
		options = options.sort();
		options = this.handleFeetInchesSorting(options);

		return options;
	}

	/*
  This function detects if a column consists of feet inches height values. If so, it sorts them
  correctly in ascending order.
  */
	handleFeetInchesSorting(options) {
		// Determine if column is imperial measurements, if not, return value with no modifications
		if (!isColumnFeetInches(options)) return options;

		options = parseFeetInches(options);
		options = convertImperialToMetric(options);
		options = sortByMetricHeight(options);

		return options;

		function isColumnFeetInches(options) {
			// Regex detects strings the represent feet inches measurements.
			// eg 5'6" or 5' 6" (space between feet and inches)

			// **NOTE** May compile with warning of unnecessary escape character. Do not modify escape character
			// or it may break.
			const feetInchesRegex = new RegExp(/^(?!$|.*\'[^\x22]+$)(?:([0-9]+)\')?(?:( ?[0-9]+)\x22)?$/);
			for (const opt of options) {
				if (!feetInchesRegex.test(opt)) return false;
			}
			return true;
		}

		function parseFeetInches(options) {
			let parsedHeights = [];
			for (const opt of options) {
				const parts = opt.split("'");
				for (const i in parts) parts[i] = parseInt(parts[i].replace(/\D/g, ''), 10);
				parsedHeights.push({
					original: opt,
					splitHeight: parts,
				});
			}

			return parsedHeights;
		}

		function convertImperialToMetric(options) {
			for (const obj of options) {
				obj.heightInCm = (obj.splitHeight[0] * 12 + obj.splitHeight[1]) * 2.54;
			}
			return options;
		}

		function sortByMetricHeight(options) {
			options = options.sort((a, b) => (a.heightInCm > b.heightInCm ? 1 : -1));
			options = options.map((opt) => opt.original);
			return options;
		}
	}

	// Returns onChange required for filterElement
	getOnChange(field, filterType) {
		let onChange;

		switch (filterType) {
			case 'dropdown':
				onChange = (e) => {
					const { name, value } = e.target;
					this.dt.current.filter(value, field, 'custom');

					this.setState((prevState) => ({
						...prevState,
						[name]: value,
					}));
				};
				break;

			case 'numericRange':
				onChange = (e) => {
					const { name, value } = e.target;

					this.dt.current.filter(value, field, 'custom');

					this.setState((prevState) => {
						prevState[field][name] = value;
						return {
							...prevState,
						};
					});
				};
				break;

			case 'dateRange':
			case 'dateTimeRange':
				const startDateOnChange = (startDate) => {
					this.dt.current.filter(startDate, field, 'custom');

					this.setState((prevState) => {
						prevState[field].startDate = startDate;
						return {
							...prevState,
						};
					});
				};

				const endDateOnChange = (endDate) => {
					this.dt.current.filter(endDate, field, 'custom');

					this.setState((prevState) => {
						prevState[field].endDate = endDate;
						return {
							...prevState,
						};
					});
				};

				onChange = [startDateOnChange, endDateOnChange];
				break;

			default:
				throw new Error('Invalid filter type specified: ' + filterType);
		}

		return onChange;
	}

	// Look up the filter type
	getFilterType(field) {
		const filter = this.columnFilterTypes.find((col) => col.field === field);
		return filter ? filter.filterType : 'noFilter';
	}
}
