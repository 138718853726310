/*
Header component for Datatable columns. Each column has a header.

Functions:
Context Menu with Hide, Rename, Edit
Display header name
Display Datasource name
*/

import React, { useState, useContext } from 'react';
import { DataSpaceViewContext } from '../context/DataSpaceViewContext';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as ContextMenuIcon } from '../../../assets/icons/more-vertical.svg';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { deleteCustomColumnNew } from '../../../api_helper/api';
import { GlobalAlertContext } from '../../../GlobalAlertContext';
import { trackEvent } from '../../../utils/eventTracking';
import { UserProfileContext } from '../../../UserProfileContext';

const useStyles = makeStyles(() => ({
	// Browser compatibility for pointerEvents may not be widespread
	// Review at https://caniuse.com/pointer-events
	root: {
		pointerEvents: 'none',
	},
	menu: {
		pointerEvents: 'auto',
		float: 'right',
	},
}));

export default function ColumnHeader(props) {
	const classes = useStyles();

	const user = useContext(UserProfileContext)[0];

	const { header, field, dataSourceName, description } = props.column;
	const dataSpaceViewContext = useContext(DataSpaceViewContext);
	const dataSpaceId = dataSpaceViewContext.dataSpaceId[0];
	const setAlertOpen = useContext(GlobalAlertContext).globalAlertOpen[1];
	const setAlertSettings = useContext(GlobalAlertContext).globalAlertSettings[1];

	const dataset = dataSpaceViewContext.dataSpace.datasets.find((dataset) => dataset._id === field.substr(-24));

	// Context Menu
	const [anchorEl, setAnchorEl] = useState(null);
	const handleContextMenuClick = (e) => setAnchorEl(e.currentTarget);
	const handleContextMenuClose = () => setAnchorEl(null);

	// Rename Dialog
	const [renameDialogOpen, setRenameDialogOpen] = useState(false);
	const [headerName, setHeaderName] = useState();
	const handleClickRenameDialogOpen = () => {
		setRenameDialogOpen(true);
		handleContextMenuClose();
	};

	// Help Description Dialog
	const [showHelpDialogOpen, setShowHelpDialogOpen] = useState(false);
	const handleClickShowHelpDialogOpen = () => {
		setShowHelpDialogOpen(true);
		handleContextMenuClose();
	};

	// Delete Dialog
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const handleClickDeleteDialogOpen = () => {
		setDeleteDialogOpen(true);
		handleContextMenuClose();
	};

	// Context menu functions
	const handleClickRename = () => {
		props.renameHeader(field, headerName);
		setRenameDialogOpen(false);
	};

	// To edit calculated columns
	const handleClickEdit = () => {
		trackEvent({
			userDetails: { userId: user._id, email: user.email },
			eventDetails: {
				types: ['Segment', 'Encharge', 'GA4', 'AppInsights'],
				eventName: 'User opened calculated column editor.',
			},
		});
		props.handleEditCalculatedColumn(field, header);
		handleContextMenuClose();
		setRenameDialogOpen(false);
	};

	// To delete a calculated column
	const handleClickDelete = () => {
		// Delete from backend
		deleteCustomColumnNew(field, dataSpaceId)
			.then((res) => {
				// Case in which column is referenced by other columns
				if (res.isDependency) {
					trackEvent({
						userDetails: { userId: user._id, email: user.email },
						eventDetails: {
							types: ['Segment', 'Encharge', 'GA4', 'AppInsights'],
							eventName:
								'User attempted to delete custom column but column was referenced by one or more additional custom columns.',
						},
					});
					setAlertSettings({
						autoHideDuration: 10000,
						severity: 'error',
						msg: `You can not delete the column "${header}" as it is referenced by the following columns:
						 ${res.dependents.join(', ')}. You can edit the noted columns to remove references
						  to ${header} and try again, or hide this column.`,
					});
					setAlertOpen(true);
					return;
				}

				// Case in which column can be deleted without issue
				trackEvent({
					userDetails: { userId: user._id, email: user.email },
					eventDetails: {
						types: ['Segment', 'Encharge', 'GA4', 'AppInsights'],
						eventName: 'User successfully deleted a custom column.',
					},
				});
				props.deleteCustomColumn(field);
				setAlertSettings({
					autoHideDuration: 5000,
					severity: 'success',
					msg: `The column "${header}" was successfully deleted`,
				});
				setAlertOpen(true);
				setDeleteDialogOpen(false);
			})
			.catch((err) => {
				setAlertSettings({
					autoHideDuration: 10000,
					severity: 'error',
					msg: 'There was an error deleting your column. Please try again later.',
				});
				setAlertOpen(true);
			});
	};
	// End context menu functions

	return (
		<div className={classes.root}>
			<Typography variant="body1">{header}</Typography>
			<Typography variant="caption">
				{dataset
					? `${dataset.displayName} ${dataset.tags.timeFrame || ''}` + ` (${dataset._id.substring(21)})`
					: 'Calculated'}
			</Typography>
			<ContextMenuIcon className={classes.menu} onClick={handleContextMenuClick} fill="#70cde5" />

			<Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleContextMenuClose}>
				<MenuItem onClick={handleClickRenameDialogOpen}>Rename</MenuItem>
				{dataSourceName === 'Calculated' && (
					<div>
						<MenuItem onClick={handleClickEdit}>Edit Formula</MenuItem>
						<MenuItem onClick={handleClickDeleteDialogOpen}>Delete</MenuItem>
					</div>
				)}
				<MenuItem
					onClick={() => {
						props.onHideColumn(field);
					}}
				>
					Hide
				</MenuItem>
				{props.frozenColumns.includes(field) ? (
					<MenuItem
						onClick={() => {
							props.onUnfreezeColumn(field);
						}}
					>
						Unpin
					</MenuItem>
				) : (
					<MenuItem
						onClick={() => {
							props.onFreezeColumn(field);
						}}
					>
						Pin
					</MenuItem>
				)}
				{description && <MenuItem onClick={handleClickShowHelpDialogOpen}>What's this?</MenuItem>}
			</Menu>

			{/* RENAME DIALOG */}
			<Dialog open={renameDialogOpen} onClose={handleContextMenuClose} aria-labelledby="form-dialog-title">
				<DialogContent>
					<TextField
						autoFocus
						margin="dense"
						id="name"
						label="Rename Column"
						onChange={(e) => setHeaderName(e.target.value)}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setRenameDialogOpen(false)} color="primary">
						Cancel
					</Button>
					<Button onClick={handleClickRename} color="primary">
						Rename
					</Button>
				</DialogActions>
			</Dialog>

			{/* DELETE DIALOG */}
			<Dialog open={deleteDialogOpen} onClose={handleContextMenuClose} aria-labelledby="form-dialog-title">
				<DialogContent>
					<p>Are you sure you wish to delete {header}?</p>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setDeleteDialogOpen(false)} color="primary">
						Cancel
					</Button>
					<Button onClick={handleClickDelete} color="primary">
						Delete Column
					</Button>
				</DialogActions>
			</Dialog>

			{/* HELP DIALOG */}
			<Dialog open={showHelpDialogOpen} onClose={handleContextMenuClose} aria-labelledby="form-dialog-title">
				<DialogTitle id="alert-dialog-title">{'Definition of ' + header + ' Statistic'}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<p>{description}</p>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setShowHelpDialogOpen(false)} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
