/*
This file contains the functions required for the Segment tracking.

Segment tracking (Analytics.js) Documentation: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/
*/

/**
 *
 * @param {Object} userDetails userDetails
 * @param {Object} eventDetails eventDetails
 */
export function trackEventSegment(userDetails, eventDetails) {
	if (!window.analytics) return;
	const name = eventDetails.eventName || '';
	// because of the way Segment views property values, I have to transform the types
	// array into a space-delimited string such as "GA4 Segment".
	// This is because Segment triggers don't seem to allow array searching.
	const eventDetailsString = eventDetails.types.join(' ');
	eventDetails = { ...eventDetails, types: eventDetailsString };
	window.analytics.track(name, {
		eventDetails: eventDetails,
		userDetails: userDetails,
	});
}

export const identifySegment = (userId, traits) => {
	if (!window.analytics) return;
	window.analytics.identify(userId, {
		...traits,
	});
};
