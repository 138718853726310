import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, IconButton, SvgIcon, Typography } from '@material-ui/core';
import { DataSpaceViewContext } from '../../my_dataspaces/context/DataSpaceViewContext';
import { UserProfileContext } from '../../../UserProfileContext';
import getDataSpaceImageLink from '../../../utils/getDataSpaceImageLink';
import MyDataSpacesViewContext from '../../my_dataspaces/context/MyDataSpacesViewContext';
import getDataSpaceLogo from '../../../utils/getDataSpaceLogo';
import TwitterIcon from '@material-ui/icons/Twitter';
import WebsiteIcon from '@material-ui/icons/Language';
import RedditIcon from '@material-ui/icons/Reddit';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import { ReactComponent as TikTokIcon } from '../../../assets/icons/tiktok.svg';
import DefaultProfilePng from '../../../assets/img/DefaultProfile.png';
import { trackEvent } from '../../../utils/eventTracking';

const DataSpaceViewHeader = () => {
	const dataSpaceViewContext = useContext(DataSpaceViewContext);

	const userProfile = useContext(UserProfileContext)[0];

	let author = dataSpaceViewContext.dataSpace.originalAuthor || dataSpaceViewContext.dataSpace.createdBy;

	let history = useHistory();

	return (
		<div
			style={{
				padding: '0.5rem',
				backgroundColor: '#47A8BD',
				position: 'relative',
			}}
		>
			<img
				src={
					dataSpaceViewContext.dataSpace.dataSpaceImageUrl
						? dataSpaceViewContext.dataSpace.dataSpaceImageUrl
						: getDataSpaceImageLink(dataSpaceViewContext.dataSpace)
				}
				style={{
					maskImage: 'linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 50%)',
					position: 'absolute',
					top: 0,
					right: 0,
					height: '100%',
					zIndex: '1',
				}}
			/>
			<div style={{ width: '100%', display: 'flex', alignItems: 'center', position: 'relative', zIndex: '2' }}>
				<img
					src={getDataSpaceLogo(dataSpaceViewContext.dataSpace)}
					alt="DataSpace Logo"
					style={{
						width: '3rem',
						height: '3rem',
						marginRight: '0.5rem',
						borderRadius: '0.25rem',
						border: '1px solid white',
					}}
				/>

				<Box
					sx={{
						backgroundColor: 'rgba(255, 255, 255, 0.8)',
						marginRight: 'auto',
						padding: '0.25rem 0.5rem',
						borderRadius: '0.5rem',
						display: 'none',
						flexWrap: 'wrap',
						'@media (min-width:600px)': {
							display: 'flex',
						},
					}}
				>
					<div style={{ display: 'flex', marginRight: '0.5rem' }}>
						<img
							style={{ width: '2.5rem', height: '2.5rem', borderRadius: '50%', marginRight: '0.5rem' }}
							src={author.userProfileImageUrl || DefaultProfilePng}
							alt="Proflie picture."
						/>
						<div
							style={{
								height: '100%',
								display: 'flex',
								flexDirection: 'column',
								fontSize: '0.8rem',
								fontWeight: 'bold',
								justifyContent: 'space-around',
							}}
						>
							<span>{author.username}</span>

							{dataSpaceViewContext.dataSpace.publishedDate && (
								<span>{new Date(dataSpaceViewContext.dataSpace.publishedDate).toLocaleDateString()}</span>
							)}
						</div>
					</div>

					<div style={{ height: 'fit-content', marginTop: '0.5rem' }}>
						{author.website && (
							<IconButton
								style={{
									backgroundColor: '#E9E9E9',
									padding: '0.25rem',
									margin: '0 0.25rem',
									color: '#1C95E0',
								}}
								onClick={() => window.open(author.website, '_blank')}
							>
								<WebsiteIcon style={{ fontSize: '1.2rem' }} />
							</IconButton>
						)}
						{author.twitter && (
							<IconButton
								style={{
									backgroundColor: '#E9E9E9',
									padding: '0.25rem',
									margin: '0 0.25rem',
									color: '#1C95E0',
								}}
								onClick={() => window.open(`https://x.com/${author.twitter}`, '_blank')}
							>
								<TwitterIcon style={{ fontSize: '1.2rem' }} />
							</IconButton>
						)}
						{author.reddit && (
							<IconButton
								style={{
									backgroundColor: '#E9E9E9',
									padding: '0.25rem',
									margin: '0 0.25rem',
									color: '#1C95E0',
								}}
								onClick={() => window.open(`https://www.reddit.com/user/${author.reddit}`, '_blank')}
							>
								<RedditIcon style={{ fontSize: '1.2rem' }} />
							</IconButton>
						)}
						{author.linkedIn && (
							<IconButton
								style={{
									backgroundColor: '#E9E9E9',
									padding: '0.25rem',
									margin: '0 0.25rem',
									color: '#1C95E0',
								}}
								onClick={() => window.open(`https://www.linkedin.com/in/${author.linkedIn}`, '_blank')}
							>
								<LinkedInIcon style={{ fontSize: '1.2rem' }} />
							</IconButton>
						)}
						{author.instagram && (
							<IconButton
								style={{
									backgroundColor: '#E9E9E9',
									padding: '0.25rem',
									color: '#1C95E0',
								}}
								onClick={() => window.open(`https://www.instagram.com/${author.instagram}`, '_blank')}
							>
								<InstagramIcon style={{ fontSize: '1.2rem' }} />
							</IconButton>
						)}
						{author.tikTok && (
							<IconButton
								style={{
									backgroundColor: '#E9E9E9',
									padding: '0.25rem',
									margin: '0 0.25rem',
									color: '#1C95E0',
								}}
								onClick={() => window.open(`https://www.tiktok.com/@${author.tikTok}`, '_blank')}
							>
								<SvgIcon style={{ fontSize: '1.2rem' }}>
									<TikTokIcon style={{ fontSize: '1.2rem' }} />
								</SvgIcon>
							</IconButton>
						)}
					</div>
				</Box>

				<Typography
					variant="h3"
					sx={{
						display: '-webkit-box',
						lineClamp: '2',
						WebkitLineClamp: 2,
						WebkitBoxOrient: 'vertical',
						wordBreak: 'break-all',
						fontSize: '1.4rem',
						color: 'white',
						fontWeight: 'lighter',
						textShadow: '1px 1px 5px rgba(0, 0, 0, 0.4)',
						overflow: 'hidden',
						'@media (min-width:600px)': {
							display: 'none',
						},
					}}
				>
					{dataSpaceViewContext.dataSpace.name}
				</Typography>

				<Button
					color="success"
					variant="contained"
					style={{ width: 'fit-content', height: 'fit-content', marginLeft: 'auto', color: 'white' }}
					onClick={() => {
						trackEvent({
							userDetails: { userId: userProfile?._id, email: userProfile?.email },
							eventDetails: {
								types: ['KissMetrics', 'Segment', 'AppInsights', 'GA4'],
								eventName: 'User clicked Sign Up button from shared DataSpace.',
							},
						});

						// Kissmetrics A/B test to open signup link in new tab or same tab
						window.kmval.openSignupLink === 'newTab' ? window.open('/register', '_blank') : history.push('/register');
					}}
				>
					Sign Up
				</Button>
			</div>

			<Typography
				variant="h3"
				sx={{
					position: 'relative',
					zIndex: '2',
					display: 'none',
					marginTop: '0.5rem',
					lineClamp: '2',
					WebkitLineClamp: 2,
					WebkitBoxOrient: 'vertical',
					wordBreak: 'break-all',
					fontSize: '2rem',
					color: 'white',
					fontWeight: 'lighter',
					textShadow: '1px 1px 5px rgba(0, 0, 0, 0.4)',
					overflow: 'hidden',
					'@media (min-width:600px)': {
						display: '-webkit-box',
					},
				}}
			>
				{dataSpaceViewContext.dataSpace.name}
			</Typography>
		</div>
	);
};

export default DataSpaceViewHeader;
