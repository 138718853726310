/*
This component returns a Material UI SingleSelect component populated with the 
desired options. It has an optional mode which allows you to supply strings
with each select value to be appended to that value when viewed in the Select dropdown.

Modes:
REGULAR
- omit props.mode
- Use an array of string as props.options

OBJECTS:
- allows options to be passed in as objects. Objects can display entries which correspond to different values
	ie {
		displayName: "Homer Simpson",
		userId: "654654988"
	}
- specify props.value and props.label as the object properties which satisfy those fields
	ie (from example above)
	props.value = 'userId'		// Value of dropdown selection
	props.label = 'displayName' // Displayed in dropdown

APPEND TO END MODE
- Appends string to the end of each dropdown option
- set props.mode to "appendToEnd"
- Use an array of objects as props.options with the properties:
  {
    value {String} : The value used in the select
    toAppend {String} : A string to append to the end of the value when displayed 
    to user in the Select dropdown
  }

*/

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		width: '90%',
	},
}));

export default function SingleSelect(props) {
	const classes = useStyles();

	const menuItems = () => {
		if (props.mode === 'appendToEnd') {
			return props.options.map((option, index) => (
				<MenuItem key={option.value} value={option.value}>
					{option.value} ({option.toAppend})
				</MenuItem>
			));
		} else if (props.mode === 'object') {
			const { label, value } = props;
			return props.options.map((option, index) => {
				return (
					<MenuItem key={option[value]} value={option[value]}>
						{option[label]}
					</MenuItem>
				);
			});
		} else if (props.mode === 'dataset') {
			return props.options.map((option, index) => {
				return (
					<MenuItem key={option._id} value={option}>
						{option.name}
					</MenuItem>
				);
			});
		} else if (props.mode === 'labelValue') {
			return props.options.map((option, index) => {
				return (
					<MenuItem key={option.value._id} value={option.value}>
						{option.label}
					</MenuItem>
				);
			});
		} else {
			return props.options.map((option, index) => (
				<MenuItem key={option} value={option}>
					{option}
				</MenuItem>
			));
		}
	};

	return (
		<FormControl className={classes.formControl + (props.className ? ' ' + props.className : '')} style={props.style}>
			<Select
				value={props.currentSelection}
				onChange={props.handleChange}
				displayEmpty
				className={classes.select}
				inputProps={{ 'aria-label': 'Without label' }}
				fullWidth={true}
			>
				<MenuItem value="" disabled>
					{props.placeHolderText}
				</MenuItem>
				{menuItems()}
			</Select>
		</FormControl>
	);
}
