/*
This component presents plans to the user in card format and allows them 
to purchase a plan. It is triggered on log-in when the user's trial is over or if their
subscription has been cancelled.
*/

import React, { useState } from 'react';
import { CssBaseline, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { allTiers } from './Tiers';
import TierCard from './TierCard';
import ChargifyForm from './ChargifyForm';

const useStyles = makeStyles((theme) => ({
	heroContent: {
		padding: theme.spacing(8, 0, 6),
	},
	cardPricing: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'stretch',
		marginBottom: theme.spacing(2),
	},
}));

export function Pricing(props) {
	const classes = useStyles();

	const [renderChargify, setRenderChargify] = useState(false);
	const [chosenTier, setChosenTier] = useState('FAN');

	// Callback passed to each plan card to render the appropriate payment page
	// when a tier is selected.
	const tierOnClick = (tier) => {
		setChosenTier(tier);
		setRenderChargify(true);
	};

	// Callback passed to each plan card which allows the user to return to pricing.
	const goBackToPricing = () => {
		setRenderChargify(false);
	};

	return (
		<React.Fragment>
			{renderChargify ? (
				<ChargifyForm chosenTier={chosenTier} goBackOnClick={goBackToPricing} />
			) : (
				<div>
					<CssBaseline />
					{/* Hero unit */}
					<Container fullwidth="true" width="xl" component="div" className={classes.heroContent}>
						<Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
							Select a Plan
						</Typography>
						<Typography variant="h5" align="center" color="textSecondary" component="p">
							To continue to access SportWise, please select a plan that meets your needs
						</Typography>
					</Container>
					{/* End hero unit */}
					<Container maxWidth="lg" component="main">
						<div className={classes.cardPricing}>
							{allTiers.map((tier) => (
								// Pro card is full width at sm breakpoint
								<TierCard tier={tier} cardActions={true} user={props.user} tierOnClick={tierOnClick} />
							))}
						</div>
					</Container>
				</div>
			)}
		</React.Fragment>
	);
}
