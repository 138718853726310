/*
This file contains the details for all available SportWise plans.
*/

const lite = {
	title: 'LITE',
	altTitle: 'Lite',
	price: '0',
	description: [
		'One DataSpace',
		'One connected league',
		'Access to Basic Sports Data',
		'Access to Advanced Data',
		'Customize and use Community DataSpaces',
		'Publish unlimited Community DataSpaces',
	],
	buttonText: 'Sign up for free',
	buttonVariant: 'outlined',
};
const fan = {
	title: 'FAN',
	altTitle: 'Fan',
	subheader: 'Popular',
	price: '9',
	description: [
		'Up to 4 DataSpaces',
		'Four connected leagues',
		'Access to Basic Sports Data',
		'Access to Advanced Data',
		'Customize and use Community DataSpaces',
		'Publish unlimited Community DataSpaces',
	],
	buttonText: 'Upgrade',
	buttonVariant: 'contained',
};
const pro = {
	title: 'PRO',
	altTitle: 'Pro',
	price: '50',
	description: [
		'Unlimited DataSpaces',
		'Unlimited connected leagues',
		'Access to All Sports Data',
		'Access to Odds Data',
		'Publish, customize and use Community DataSpaces',
		'Publish unlimited Community DataSpaces',
		'Early access to new features',
		'SportWise team members will do initial setup',
	],
	buttonText: 'Upgrade',
	buttonVariant: 'outlined',
};

module.exports = {
	lite: lite,
	fan: fan,
	pro: pro,
	allTiers: [lite, fan, pro],
};
