import { Dialog, makeStyles, Icon, IconButton, SvgIcon, Typography, Box } from '@material-ui/core';
import { useContext } from 'react';
import NBALogoPNG from '../../assets/img/NBALogoSquare.png';
import MLBLogoPNG from '../../assets/img/MLBLogoSquare.png';
import NFLLogoPNG from '../../assets/img/NFLLogoSquare.png';
import NHLLogoPNG from '../../assets/img/NHLLogoSquare.png';
import YahooFantasyLogoPNG from '../../assets/img/YahooFantasyLogo.png';
import MyDataSpacesViewContext from '../my_dataspaces/context/MyDataSpacesViewContext';
import _ from 'lodash';
import { DataSpaceViewProvider } from '../my_dataspaces/context/DataSpaceViewContext';
import DataSpaceViewNew from './dataspace_view_new/DataSpaceView';
import CreateDataSpaceButton from './CreateDataSpaceButton';
import SelectFantasyLeagueDialog from './SelectFantasyLeagueDialog';
import CommunityDataSpacesContext from '../../context/CommunityDataSpacesContext';
import { trackEvent } from '../../utils/eventTracking';
import { UserProfileContext } from '../../UserProfileContext';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import getDataSpaceImageLink from '../../utils/getDataSpaceImageLink';
import DefaultProfilePng from '../../assets/img/DefaultProfile.png';
import TwitterIcon from '@material-ui/icons/Twitter';
import WebsiteIcon from '@material-ui/icons/Language';
import RedditIcon from '@material-ui/icons/Reddit';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import { ReactComponent as TikTokIcon } from '../../assets/icons/tiktok.svg';

const useStyles = makeStyles((theme) => ({
	dialog: {
		overflow: 'auto',
		'& .MuiDialog-container': {
			minHeight: '40rem',
			padding: '2rem 0',
		},
		'& .MuiDialog-paper': {
			width: '95%',
			height: '100%',
			maxHeight: '60rem',
			margin: 0,
		},
	},
}));

const getDataSpaceLogo = (dataSpace) => {
	switch (dataSpace?.datasets[0]?.tags.sport) {
		case 'NBA':
			return NBALogoPNG;
		case 'MLB':
			return MLBLogoPNG;
		case 'NFL':
			return NFLLogoPNG;
		case 'NHL':
			return NHLLogoPNG;
		case 'YahooNBA':
		case 'YahooMLB':
		case 'YahooNHL':
		case 'YahooNFL':
			return YahooFantasyLogoPNG;
	}
};

const DataSpacePreviewDialog = (props) => {
	const classes = useStyles();

	const userProfile = useContext(UserProfileContext)[0];
	const myDataSpacesViewContext = useContext(MyDataSpacesViewContext);
	const communityDataSpacesContext = useContext(CommunityDataSpacesContext);

	return (
		<Dialog
			open={props.isDataSpacePreviewDialogOpen}
			fullWidth={true}
			maxWidth="lg"
			onClose={() => {
				props.setIsDataSpacePreviewDialogOpen(false);
			}}
			className={classes.dialog}
		>
			{communityDataSpacesContext.selectedDataSpace && (
				<Box
					sx={{
						width: '100%',
						backgroundImage: communityDataSpacesContext.selectedDataSpace.dataSpaceImageUrl
							? `url(${communityDataSpacesContext.selectedDataSpace.dataSpaceImageUrl})`
							: `url(${getDataSpaceImageLink(communityDataSpacesContext.selectedDataSpace)})`,
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						padding: '0.5rem',
						display: 'flex',
						flexDirection: 'column',
						'@media (min-width:600px)': {
							padding: '1rem',
						},
					}}
				>
					<Box
						sx={{
							width: '100%',
							display: 'flex',
							justifyContent: 'space-between',
							'@media (min-width:600px)': {
								display: 'none',
							},
						}}
					>
						<div style={{ display: 'flex' }}>
							<img
								src={getDataSpaceLogo(communityDataSpacesContext.selectedDataSpace)}
								alt="DataSpace logo."
								style={{
									width: '3.5rem',
									height: '3.5rem',
									marginRight: '0.25rem',
									borderRadius: '0.25rem',
									border: '1px solid white',
								}}
							/>

							<Typography
								variant="h3"
								sx={{
									margin: '0.25rem 0.5rem -0.5rem 0',
									display: '-webkit-box',
									lineClamp: '2',
									WebkitLineClamp: 2,
									WebkitBoxOrient: 'vertical',
									wordBreak: 'break-all',
									fontSize: '1.5rem',
									color: 'white',
									fontWeight: 'lighter',
									textShadow: '1px 1px 5px rgba(0, 0, 0, 0.4)',
									'@media (min-width:600px)': {
										fontSize: '2.5rem',
										marginTop: '1rem',
									},
								}}
							>
								{communityDataSpacesContext.selectedDataSpace.publicName}
							</Typography>
						</div>

						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
							<DisabledByDefaultIcon
								size="medium"
								style={{ cursor: 'pointer' }}
								onClick={() => {
									props.setIsDataSpacePreviewDialogOpen(false);
								}}
							/>

							{communityDataSpacesContext.selectedDataSpace?.datasets.some((dataset) =>
								['Yahoo! Fantasy Sports', 'Fantrax'].includes(dataset.tags.api),
							) ? (
								<SelectFantasyLeagueDialog />
							) : (
								<CreateDataSpaceButton />
							)}
						</div>
					</Box>

					<Box
						sx={{
							width: '100%',
							display: 'none',
							justifyContent: 'space-between',
							'@media (min-width:600px)': {
								display: 'flex',
							},
						}}
					>
						<div style={{ display: 'flex' }}>
							<img
								src={getDataSpaceLogo(communityDataSpacesContext.selectedDataSpace)}
								alt="DataSpace logo."
								style={{
									width: '3.5rem',
									height: '3.5rem',
									marginRight: '1rem',
									borderRadius: '0.25rem',
									border: '1px solid white',
								}}
							/>
							<div
								style={{
									backgroundColor: 'rgba(255, 255, 255, 0.8)',
									padding: '0.5rem',
									borderRadius: '0.5rem',
									display: 'flex',
									flexWrap: 'wrap',
								}}
							>
								<div style={{ display: 'flex', marginRight: '1rem' }}>
									<img
										style={{ width: '2.5rem', height: '2.5rem', borderRadius: '50%', marginRight: '0.5rem' }}
										src={
											communityDataSpacesContext.selectedDataSpace.createdBy.userProfileImageUrl || DefaultProfilePng
										}
										alt="Proflie picture."
									/>
									<div style={{ fontSize: '0.8rem', fontWeight: 'bold', height: 'fit-content' }}>
										<div style={{ marginBottom: '0.5rem', display: 'flex', flexWrap: 'wrap' }}>
											<p style={{ margin: '0 1rem 0.1rem 0' }}>
												{communityDataSpacesContext.selectedDataSpace.createdBy.username}
											</p>
										</div>
										<div>
											<span>
												{new Date(communityDataSpacesContext.selectedDataSpace.publishedDate).toLocaleDateString()}
											</span>
										</div>
									</div>
								</div>

								<div style={{ height: 'fit-content', marginTop: '0.5rem' }}>
									{communityDataSpacesContext.selectedDataSpace.createdBy.website && (
										<IconButton
											style={{
												backgroundColor: '#E9E9E9',
												padding: '0.25rem',
												margin: '0 0.25rem',
												color: '#1C95E0',
											}}
											onClick={() =>
												window.open(communityDataSpacesContext.selectedDataSpace.createdBy.website, '_blank')
											}
										>
											<WebsiteIcon style={{ fontSize: '1.2rem' }} />
										</IconButton>
									)}
									{communityDataSpacesContext.selectedDataSpace.createdBy.twitter && (
										<IconButton
											style={{
												backgroundColor: '#E9E9E9',
												padding: '0.25rem',
												margin: '0 0.25rem',
												color: '#1C95E0',
											}}
											onClick={() =>
												window.open(
													`https://x.com/${communityDataSpacesContext.selectedDataSpace.createdBy.twitter}`,
													'_blank',
												)
											}
										>
											<TwitterIcon style={{ fontSize: '1.2rem' }} />
										</IconButton>
									)}
									{communityDataSpacesContext.selectedDataSpace.createdBy.reddit && (
										<IconButton
											style={{
												backgroundColor: '#E9E9E9',
												padding: '0.25rem',
												margin: '0 0.25rem',
												color: '#1C95E0',
											}}
											onClick={() =>
												window.open(
													`https://www.reddit.com/user/${communityDataSpacesContext.selectedDataSpace.createdBy.reddit}`,
													'_blank',
												)
											}
										>
											<RedditIcon style={{ fontSize: '1.2rem' }} />
										</IconButton>
									)}
									{communityDataSpacesContext.selectedDataSpace.createdBy.linkedIn && (
										<IconButton
											style={{
												backgroundColor: '#E9E9E9',
												padding: '0.25rem',
												margin: '0 0.25rem',
												color: '#1C95E0',
											}}
											onClick={() =>
												window.open(
													`https://www.linkedin.com/in/${communityDataSpacesContext.selectedDataSpace.createdBy.linkedIn}`,
													'_blank',
												)
											}
										>
											<LinkedInIcon style={{ fontSize: '1.2rem' }} />
										</IconButton>
									)}
									{communityDataSpacesContext.selectedDataSpace.createdBy.instagram && (
										<IconButton
											style={{
												backgroundColor: '#E9E9E9',
												padding: '0.25rem',
												color: '#1C95E0',
											}}
											onClick={() =>
												window.open(
													`https://www.instagram.com/${communityDataSpacesContext.selectedDataSpace.createdBy.instagram}`,
													'_blank',
												)
											}
										>
											<InstagramIcon style={{ fontSize: '1.2rem' }} />
										</IconButton>
									)}
									{communityDataSpacesContext.selectedDataSpace.createdBy.tikTok && (
										<IconButton
											style={{
												backgroundColor: '#E9E9E9',
												padding: '0.25rem',
												margin: '0 0.25rem',
												color: '#1C95E0',
											}}
											onClick={() =>
												window.open(
													`https://www.tiktok.com/@${communityDataSpacesContext.selectedDataSpace.createdBy.tikTok}`,
													'_blank',
												)
											}
										>
											<SvgIcon style={{ fontSize: '1.2rem' }}>
												<TikTokIcon style={{ fontSize: '1.2rem' }} />
											</SvgIcon>
										</IconButton>
									)}
								</div>
							</div>
						</div>

						<DisabledByDefaultIcon
							size="medium"
							style={{ cursor: 'pointer' }}
							onClick={() => {
								props.setIsDataSpacePreviewDialogOpen(false);
							}}
						/>
					</Box>

					<Box
						sx={{
							width: '100%',
							display: 'none',
							justifyContent: 'space-between',
							alignItems: 'end',
							'@media (min-width:600px)': {
								display: 'flex',
							},
						}}
					>
						<Typography
							variant="h3"
							sx={{
								margin: '0',
								display: '-webkit-box',
								lineClamp: '2',
								WebkitLineClamp: 2,
								WebkitBoxOrient: 'vertical',
								wordBreak: 'break-all',
								fontSize: '1.8rem',
								color: 'white',
								fontWeight: 'lighter',
								textShadow: '1px 1px 5px rgba(0, 0, 0, 0.3)',
								'@media (min-width:600px)': {
									fontSize: '2.5rem',
									marginTop: '1rem',
								},
							}}
						>
							{communityDataSpacesContext.selectedDataSpace.publicName}
						</Typography>

						{communityDataSpacesContext.selectedDataSpace?.datasets.some((dataset) =>
							['Yahoo! Fantasy Sports', 'Fantrax'].includes(dataset.tags.api),
						) ? (
							<SelectFantasyLeagueDialog />
						) : (
							<CreateDataSpaceButton />
						)}
					</Box>
				</Box>
			)}
			<DataSpaceViewProvider>
				{communityDataSpacesContext.selectedDataSpace && (
					<DataSpaceViewNew
						dataSpaceName={
							communityDataSpacesContext.selectedDataSpace?.name ||
							myDataSpacesViewContext.myDataSpacesView.selectedDataSpace.name
						}
						dataSpaceId={
							communityDataSpacesContext.selectedDataSpace?._id ||
							myDataSpacesViewContext.myDataSpacesView.selectedDataSpace.id
						}
						handleDataSpaceLoadingError={() => {}}
					/>
				)}
			</DataSpaceViewProvider>
		</Dialog>
	);
};

export default DataSpacePreviewDialog;
