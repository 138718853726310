import React, { useState, createContext } from 'react';

export const DataSpaceViewContext = createContext();

export const DataSpaceViewProvider = (props) => {
	//DataSelectionUpload
	const [dataSpaceId, setDataSpaceId] = useState('');

	const [dataSpace, setDataSpace] = useState([]);

	const values = {
		dataSpaceId: [dataSpaceId, setDataSpaceId],
		dataSpace,
		setDataSpace,
	};

	return <DataSpaceViewContext.Provider value={values}>{props.children}</DataSpaceViewContext.Provider>;
};
