import { useState } from 'react';
import { Dialog, Button } from '@material-ui/core';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage';

const ImageCrop = (props) => {
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

	const onCropComplete = (croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	};

	const cancelClickHandler = () => {
		props.setUserProfile((prevState) => ({
			...prevState,
			uncroppedProfileImage: null,
		}));
		setZoom(1);
		setCrop({ x: 0, y: 0 });
	};

	const saveImage = async () => {
		const croppedImage = await getCroppedImg(props.userProfile.uncroppedProfileImage, croppedAreaPixels, 200, 200);
		props.setUserProfile((prevState) => ({
			...prevState,
			croppedProfileImage: croppedImage,
			uncroppedProfileImage: undefined,
		}));
		setZoom(1);
		setCrop({ x: 0, y: 0 });
	};

	return (
		<Dialog open={props.userProfile.uncroppedProfileImage}>
			<div
				style={{
					width: '20rem',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					padding: '3rem',
				}}
			>
				<p style={{ margin: '0 0 1rem 0' }}>Edit profile picture</p>
				<div style={{ width: '100%', aspectRatio: '1', position: 'relative' }}>
					<Cropper
						image={props.userProfile.uncroppedProfileImage}
						crop={crop}
						zoom={zoom}
						aspect={1}
						cropSize={{ width: 200, height: 200 }}
						onCropChange={setCrop}
						onCropComplete={onCropComplete}
						onZoomChange={setZoom}
						showGrid={false}
						cropShape="round"
						objectFit="cover"
						style={{
							cropAreaStyle: {
								border: '1px solid black',
								color: 'rgba(255, 255, 255, 0.9)',
								border: '1px solid rgba(0, 0, 0, 0.2)',
							},
						}}
					/>
				</div>
				<div style={{ display: 'flex', width: '80%' }}>
					<span style={{ margin: '0.5rem' }}>-</span>
					<input
						style={{ width: '100%' }}
						type="range"
						value={zoom}
						min={1}
						max={3}
						step={0.1}
						onChange={(e) => {
							setZoom(e.target.value);
						}}
					/>
					<span style={{ margin: '0.5rem' }}>+</span>
				</div>
				<div style={{ display: 'flex' }}>
					<Button
						variant="contained"
						color="primary"
						style={{ marginTop: '1rem', marginRight: '1rem' }}
						onClick={cancelClickHandler}
					>
						Cancel
					</Button>
					<Button variant="contained" color="secondary" style={{ marginTop: '1rem' }} onClick={saveImage}>
						Save
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default ImageCrop;
