import { useContext, useState } from 'react';
import { makeStyles, Card, Typography, Grid } from '@material-ui/core';
import NBALogoPNG from '../../../../assets/img/NBALogoSquare.png';
import MLBLogoPNG from '../../../../assets/img/MLBLogoSquare.png';
import NFLLogoPNG from '../../../../assets/img/NFLLogoSquare.png';
import NHLLogoPNG from '../../../../assets/img/NHLLogoSquare.png';
import YahooFantasyLogoPNG from '../../../../assets/img/YahooFantasyLogo.png';
import CreateEditDataSpaceContext from '../../context/CreateEditDataSpaceContext';
import SelectDatasetFieldsDialog from './SelectDatasetFieldsDialog';
import SnackbarAlertContext from '../../../../context/SnackbarAlertContext';

const useStyles = makeStyles((theme) => ({
	datasetWrapper: {
		width: '100%',
		height: '7rem',
		padding: '0.5rem 0',
		display: 'flex',
		backgroundColor: 'transparent',
		border: 'none',
		borderBottom: '1px solid black',
		borderRadius: '0',
		boxShadow: 'none',
		textAlign: 'left',
		'&:hover': {
			backgroundColor: 'rgba(0,0,0,0.1)',
			cursor: 'pointer',
		},
	},
	datasetLogo: {
		height: '100%',
		marginRight: '0.8rem',
		borderRadius: '0.5rem',
	},
	datasetInfoWrapper: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	datasetInfoHeader: {
		marginBottom: '0.5rem',
		textTransform: 'uppercase',
	},
	datasetFieldsWrapper: {
		height: '100%',
		width: '100%',
		overflow: 'hidden',
	},
	datasetField: {
		height: 'fit-content',
		padding: '0.3rem',
		backgroundColor: '#C4C4C4',
		borderRadius: '0.2rem',
		boxSizing: 'border-box',
		color: '#000000',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
}));

// Datasource card showing datasource name, image showing datasource sport, and some examples of its fields.
const Dataset = (props) => {
	const classes = useStyles();

	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);
	const snackbarAlertContext = useContext(SnackbarAlertContext);
	const [isSelectDatasetFieldsDialogOpen, setIsSelectDatasetFieldsDialogOpen] = useState(false);

	const datasetFieldComponents = props.dataset.dataSpaceFields.map((field) => (
		<Grid key={props.dataset._id} item xs={12} sm={4} md={3}>
			<div className={classes.datasetField}>{field.header}</div>
		</Grid>
	));

	const getDatasetLogoSource = () => {
		switch (props.dataset.api) {
			case 'Reliant':
				switch (props.dataset.sport) {
					case 'NBA':
						return NBALogoPNG;
					case 'MLB':
						return MLBLogoPNG;
					case 'NFL':
						return NFLLogoPNG;
					case 'NHL':
						return NHLLogoPNG;
					default:
						return NBALogoPNG;
				}
			case 'Yahoo! Fantasy Sports':
				return YahooFantasyLogoPNG;
		}
	};

	// If datasource is clicked, open field selection dialog if the datasource has not been already selected.
	const datasetClickHandler = () => {
		setIsSelectDatasetFieldsDialogOpen(true);
	};

	return (
		<>
			<button className={classes.datasetWrapper} onClick={datasetClickHandler}>
				<img src={getDatasetLogoSource()} alt="Dataset logo." className={classes.datasetLogo} />
				<div className={classes.datasetInfoWrapper}>
					<Typography className={classes.datasetInfoHeader}>{props.dataset.name}</Typography>
					<Grid container spacing={0.5} className={classes.datasetFieldsWrapper}>
						{datasetFieldComponents}
					</Grid>
				</div>
			</button>
			{isSelectDatasetFieldsDialogOpen ? (
				<SelectDatasetFieldsDialog
					datasource={props.dataset}
					isSelectDatasetFieldsDialogOpen={isSelectDatasetFieldsDialogOpen}
					setIsSelectDatasetFieldsDialogOpen={setIsSelectDatasetFieldsDialogOpen}
				/>
			) : null}
		</>
	);
};

export default Dataset;
