/*
This component provides an interface for the user to save and load views.
*/

import React, { useState, useEffect, useContext } from 'react';
import { Dropdown } from 'primereact/dropdown';
import ViewNamingDialog from './ViewNamingDialog';
import { DateTime } from 'luxon';
import { appendToDataSpaceViews, getDataSpaceViews, getSelectedDataSpaceView } from '../../../api_helper/api';
import { makeStyles, Button, Grid } from '@material-ui/core';
import { trackEvent } from '../../../utils/eventTracking';
import { UserProfileContext } from '../../../UserProfileContext';

const useStyles = makeStyles((theme) => ({
	button: {
		height: 'fit-content',
		paddingTop: '0.4rem',
		paddingBottom: '0.4rem',
		color: 'white',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	viewMenuWrapper: {
		display: 'flex',
		maxWidth: '17rem',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	dropdown: {
		minWidth: '8rem',
	},
}));

export default function ViewManager(props) {
	const classes = useStyles();

	const { dataSpaceId, loadView, setIsViewLoading, getCurrentView } = props;

	// View Dropdown
	const [availableViews, setAvailableViews] = useState([]);
	const [selectedView, setSelectedView] = useState(null);

	// View Naming Modal
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [viewNameInput, setViewNameInput] = useState('');
	const [viewListRefreshRequired, setViewListRefreshRequired] = useState(true);
	const [dialogMessage, setDialogMessage] = useState('Please enter a name for your custom view');

	// User Profile Data
	const user = useContext(UserProfileContext)[0];

	const handleChangeViewNameInput = (event) => setViewNameInput(event.target.value);

	const handleDialogClose = () => {
		setIsDialogOpen(false);
		setDialogMessage('Please enter a name for your custom view');
		setViewNameInput('');
	};

	// Triggered when user clicks "Save View" button. Opens naming dialog
	const onClickSaveView = () => {
		setIsDialogOpen(true);
		trackEvent({
			userDetails: { userId: user._id, email: user.email },
			eventDetails: { types: ['KissMetrics', 'Segment', 'GA4'], eventName: 'Save View Clicked' },
		});
	};

	const onViewChange = async (e) => {
		// Check view is selected
		if (e.value === null || e.value.length === 0) return;

		// Initiate loading screen
		setIsViewLoading(true);

		// Retrieve view properties
		const res = await getSelectedDataSpaceView({ dataSpaceId: dataSpaceId, viewId: e.value.id }).catch((e) => {
			// On failure, exit load screen and log error
			setIsViewLoading(false);
		});

		// DateTime objects are converted to ISO strings when stored in Mongo.
		// Reconvert ISO strings in filterStates to DateTimes for compatibility with Date/DateTime filter logic
		res.view.externalProperties.filterStates = convertISOStringsToDateTimes(res.view.externalProperties.filterStates);

		// Load view into table
		loadView(res.view);

		// Exit load screen upon successful loading of view
		setIsViewLoading(false);
		setSelectedView({ name: e.value.name, id: e.value.id });
	};

	// This function detects Date/DateTime filterStates and converts their ISO String value to DateTime objects
	const convertISOStringsToDateTimes = (filterStates) => {
		for (const [key, value] of Object.entries(filterStates)) {
			// filterStates with properties startDate and endDate belong to Date based columns.
			if (value.startDate) {
				if (value.startDate !== null) filterStates[key].startDate = DateTime.fromISO(value.startDate);
				if (value.endDate !== null) filterStates[key].endDate = DateTime.fromISO(value.endDate);
			}
		}

		return filterStates;
	};

	// Triggered when user submits view name
	const onSubmitSaveView = async () => {
		if (isViewNameValid(viewNameInput)) {
			const view = getCurrentView();
			view.name = viewNameInput;
			const viewId = await saveView(view);
			handleDialogClose();
			onViewChange({ value: { ...view, id: viewId } });
		}
	};

	// Check user has entered a valid name for their view
	const isViewNameValid = (viewName) => {
		// Check user has entered a value
		if (viewName === null || viewName.length === 0) {
			return false;
		}

		// Check value not already used as a view name
		const existingNames = availableViews.map((av) => av.name);
		if (existingNames.includes(viewName)) {
			if (viewName !== 'Default') {
				setDialogMessage(`The name ${viewName} already exists. Please choose a different name.`);
			}
			return false;
		}

		return true;
	};

	// Send view to backend to be saved to database
	const saveView = async (view) => {
		const payload = {
			dataSpaceId: dataSpaceId,
			view: view,
		};

		const appendToDataSpaceViewsRes = await appendToDataSpaceViews(payload);
		setViewListRefreshRequired(true);
		return appendToDataSpaceViewsRes;
	};

	// Reload list of user's saved views if user successfully saves a view
	useEffect(() => {
		const refreshViewList = async () => {
			if (viewListRefreshRequired) {
				try {
					const res = await getDataSpaceViews(dataSpaceId);
					const views = res.views;
					setAvailableViews(views);
					setViewListRefreshRequired(false);
				} catch (e) {
					console.log(e);
				}
			}
		};
		refreshViewList();
	}, [viewListRefreshRequired, dataSpaceId]);

	// If there is no default view on dataspace render, create one.
	useEffect(() => {
		const saveDefaultView = async () => {
			const view = getCurrentView();
			view.name = 'Default';
			view.internalProperties.state.filters = {};
			view.internalProperties.state.multiSortMeta = [];
			const viewId = await saveView(view);
			onViewChange({ value: { ...view, id: viewId } });
			trackEvent({
				userDetails: { userId: user._id, email: user.email },
				eventDetails: { types: ['KissMetrics', 'Segment', 'GA4'], eventName: 'WebApp_System Creates Default View' },
			});
		};

		if (!viewListRefreshRequired && isViewNameValid('Default')) {
			saveDefaultView().catch(console.error);
		}
	}, [viewListRefreshRequired, dataSpaceId]);

	return (
		<>
			<Grid item xs={12} md={5} className={classes.viewMenuWrapper}>
				{/* Saved Views Dropdown */}
				<Dropdown
					className={classes.dropdown}
					value={selectedView}
					options={availableViews}
					onChange={(e) => {
						onViewChange(e);
						trackEvent({
							userDetails: { userId: user._id, email: user.email },
							eventDetails: {
								types: ['KissMetrics', 'Segment', 'GA4'],
								eventName: 'WebApp_User Clicks View From Dropdown',
							},
						});
					}}
					optionLabel="name"
					placeholder="Select a View"
				/>

				{/* Save View Button */}
				<Button
					className={classes.button}
					size="small"
					variant="contained"
					color="secondary"
					margin="normal"
					onClick={onClickSaveView}
				>
					Save View
				</Button>
			</Grid>

			<ViewNamingDialog
				open={isDialogOpen}
				onClose={handleDialogClose}
				handleChangeNewViewName={handleChangeViewNameInput}
				dialogMessage={dialogMessage}
				onSubmitNewView={onSubmitSaveView}
				nameValue={viewNameInput}
			/>
		</>
	);
}
