import nbaLogo from '../assets/img/NBALogoSquare.png';
import mlbLogo from '../assets/img/MLBLogoSquare.png';
import nhlLogo from '../assets/img/NHLLogoSquare.png';
import nflLogo from '../assets/img/NFLLogoSquare.png';
import yahooLogo from '../assets/img/YahooFantasyLogo.png';
import fantraxLogo from '../assets/img/FantraxLogo.png';
import calculatorLogo from '../assets/img/CalculatorLogo.png';

const getLogo = ({ api, sport }) => {
	if (api === 'Yahoo! Fantasy Sports') {
		return yahooLogo;
	} else if (api === 'Fantrax') {
		return fantraxLogo;
	}

	switch (sport) {
		case 'NBA':
			return nbaLogo;
		case 'MLB':
			return mlbLogo;
		case 'NHL':
			return nhlLogo;
		case 'NFL':
			return nflLogo;
		default:
			return calculatorLogo;
	}
};

export default getLogo;
