/*
This panel provides an interface which allows the user to design and generate custom columns.
*/

import React, { useState, useEffect, useContext } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Slide,
	TextField,
	Snackbar,
	Alert,
} from '@material-ui/core';
import { getSpecifiedDataSpaceMetadata, calculateColumn } from '../../../api_helper/api';
import { trackEvent } from '../../../utils/eventTracking';
import { UserProfileContext } from '../../../UserProfileContext';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function CalculatedColumnEditor({
	open,
	handleClose,
	columns,
	columnDetails,
	dataSpaceId,
	applyModifiedCustomColumn,
	displayAlert,
}) {
	const user = useContext(UserProfileContext)[0];

	// expression
	const [expression, setExpression] = useState('Loading...'); // For user input
	const handleChangeExpression = (e) => setExpression(e.target.value);
	const [originalExpression, setOriginalExpression] = useState('Loading...'); // For displaying original expression (non-editable)

	// snackbar
	const [snackOpen, setSnackOpen] = useState(false);
	const [snackMessage, setSnackMessage] = useState('');

	// Retrieve the calculated column's expression
	useEffect(() => {
		if (columnDetails['field']) {
			getSpecifiedDataSpaceMetadata({ dataSpaceId, metadataFields: ['calculatedColumns'] }).then((res) => {
				const column = res.calculatedColumns.find((col) => col.field === columnDetails.field);
				setExpression(column.expression);
				setOriginalExpression(column.expression);
			});
		}
	}, [columnDetails]);

	// Initiates calculation and application of custom column. Displays errors if required.
	const saveChangesOnClick = () => {
		calculateColumn({
			expression,
			colName: columnDetails.header,
			field: columnDetails.field,
			dataSpaceId,
			mode: 'edit',
		}).then((res) => {
			const hasValidationErrors = res.validationErrors && res.validationErrors.length > 0;
			if (hasValidationErrors) {
				setSnackMessage(formatErrors(res.validationErrors));
				setSnackOpen(true);
				trackEvent({
					userDetails: { userId: user._id, email: user.email },
					eventDetails: {
						types: ['Segment', 'Encharge', 'GA4', 'AppInsights'],
						eventName: 'User encountered validation errors when editing a custom column.',
					},
				});
			} else {
				applyModifiedCustomColumn(res);
				displayAlert('success', `Your changes to ${columnDetails.header} have been saved.`);
				trackEvent({
					userDetails: { userId: user._id, email: user.email },
					eventDetails: {
						types: ['Segment', 'Encharge', 'GA4', 'AppInsights'],
						eventName: 'User successfully edited a custom column.',
					},
				});
				handleClose();
			}
		});
	};

	// Format array of error messages into unordered list element
	function formatErrors(validationErrors) {
		const errors = validationErrors.map((e) => <li>{e}</li>);
		const errorList = <ul>{errors}</ul>;
		return errorList;
	}

	// Copies string argument to user's clipboard
	function handleCopy(text) {
		navigator.clipboard.writeText(text);
	}

	return (
		<div>
			<Dialog
				open={open}
				PaperProps={{
					style: {
						overflowY: 'visible',
					},
				}}
				TransitionComponent={Transition}
				keepMounted
				// onClose={handleClose} // Comment to disable click away to close
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title">{`Edit your Column: ${columnDetails.header}`}</DialogTitle>

				<DialogContent>
					<p>
						<b>Rules and Tips:</b>
						<ul>
							<li>
								This interface in no way represents how the final interface will look or behave. It is a stub while
								working on backend functionality.
							</li>
							<li>Currently supported functions include: min, max, sum, mean, std (standard deviation), median</li>
							<li>
								Function arguments must be within square brackets.
								<br /> ex: sum([goals, assists])
							</li>
							<li>
								Vertical calculations can be performed by specifying only one parameter when using a function.
								<br /> ex: sum([goals])
							</li>
						</ul>
					</p>
					<b>Original Expression:</b>
					<br />
					{originalExpression}
					<br />
					<br />
					<b>Edit your expression:</b>
					<TextField
						autoFocus
						margin="dense"
						label="Expression"
						type="text"
						multiline
						InputProps={{ spellCheck: false }}
						onChange={handleChangeExpression}
						value={expression}
						fullWidth
					/>
					<p>
						<b>Available fields:</b> <br />
						<br />
						Copy and paste field names from the list below to add into your expression (Click to copy to clipboard).
					</p>
					<ul>
						{columns.map((c) => {
							return (
								<li key={c.field} onClick={() => handleCopy(c.field)}>
									{c.field}
								</li>
							);
						})}
					</ul>
				</DialogContent>

				<DialogActions>
					<Button onClick={saveChangesOnClick}>Save Changes</Button>

					<Button onClick={handleClose}>Close</Button>
				</DialogActions>
			</Dialog>

			<Snackbar
				open={snackOpen}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				onClose={() => setSnackOpen(false)}
			>
				<Alert severity={'error'}>{snackMessage}</Alert>
			</Snackbar>
		</div>
	);
}
