import { Checkbox, ListItemText, MenuItem, Select } from '@material-ui/core';
import { useContext } from 'react';
import CommunityDataSpacesContext from '../../context/CommunityDataSpacesContext';
import { trackEvent } from '../../utils/eventTracking';
import { UserProfileContext } from '../../UserProfileContext';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import { ReactComponent as SortIcon } from '../../assets/icons/sort.svg';

// Inputs for filtering DataSpaces list by sports, datasets, and fantasy platforms.
const FilterSortInputs = () => {
	const userProfile = useContext(UserProfileContext)[0];
	const communityDataSpacesContext = useContext(CommunityDataSpacesContext);

	const inputChangeHandler = (e) => {
		communityDataSpacesContext.setFilters((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));

		// Event tracking
		let eventName;
		switch (e.target.name) {
			case 'sortValue':
				if (e.target.value === 'alphanumeric') {
					eventName = 'User sorted Community DataSpaces catalogue alphabetically.';
				} else if (e.target.value === 'recent') {
					eventName = 'User sorted Community DataSpaces catalogue by most recent.';
				}
				break;
			case 'sports':
				eventName = 'User filtered Community DataSpaces catalogue by sport.';
				break;
			case 'datasets':
				eventName = 'User filtered Community DataSpaces catalogue by dataset.';
				break;
			case 'fantasyApis':
				eventName = 'User filtered Community DataSpaces catalogue by fantasy platform.';
				break;
		}
		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: {
				types: ['KissMetrics', 'AppInsights', 'Segment', 'GA4'],
				eventName,
			},
		});
	};

	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
				justifyContent: 'space-between',
				flexWrap: 'wrap',
			}}
		>
			<div
				style={{
					padding: '0.75rem',
					backgroundColor: 'white',
					display: 'flex',
					borderRadius: '0.2rem',
					marginBottom: '1rem',
					overflow: 'auto',
				}}
			>
				<FilterIcon style={{ minWidth: '1rem', width: '1rem', stroke: '#009CC6', marginRight: '0.75rem' }} />

				<Select
					variant="standard"
					style={{ width: '4rem', marginRight: '0.75rem' }}
					multiple
					onChange={inputChangeHandler}
					value={communityDataSpacesContext.filters.sports}
					renderValue={() => 'Sport'}
					displayEmpty
					name="sports"
				>
					{communityDataSpacesContext.filters.sportOptions.map((sport) => (
						<MenuItem key={sport} value={sport}>
							<Checkbox checked={communityDataSpacesContext.filters.sports.indexOf(sport) > -1} />
							<ListItemText primary={sport} />
						</MenuItem>
					))}
				</Select>

				<Select
					variant="standard"
					style={{ width: '5.5rem', marginRight: '0.75rem' }}
					multiple
					onChange={inputChangeHandler}
					value={communityDataSpacesContext.filters.datasets}
					displayEmpty
					renderValue={() => 'Dataset'}
					name="datasets"
				>
					{communityDataSpacesContext.filters.datasetOptions.map((dataset) => (
						<MenuItem key={dataset} value={dataset}>
							<Checkbox checked={communityDataSpacesContext.filters.datasets.indexOf(dataset) > -1} />
							<ListItemText primary={dataset} />
						</MenuItem>
					))}
				</Select>

				<Select
					variant="standard"
					style={{ width: '9.5rem' }}
					multiple
					onChange={inputChangeHandler}
					value={communityDataSpacesContext.filters.fantasyApis}
					displayEmpty
					renderValue={() => 'Fantasy Platform'}
					name="fantasyApis"
				>
					{communityDataSpacesContext.filters.fantasyApiOptions.map((fantasyApi) => (
						<MenuItem key={fantasyApi} value={fantasyApi}>
							<Checkbox checked={communityDataSpacesContext.filters.fantasyApis.indexOf(fantasyApi) > -1} />
							<ListItemText primary={fantasyApi} />
						</MenuItem>
					))}
				</Select>
			</div>

			<div
				style={{
					padding: '0.75rem',
					backgroundColor: 'white',
					display: 'flex',
					borderRadius: '0.2rem',
					marginBottom: '1rem',
				}}
			>
				<SortIcon fill="#009CC6" style={{ width: '1rem', fill: '#009CC6', marginRight: '0.75rem' }} />

				<Select
					variant="standard"
					style={{ width: '5rem' }}
					onChange={inputChangeHandler}
					value={communityDataSpacesContext.filters.sortValue}
					renderValue={() => 'Sort by'}
					displayEmpty
					name="sortValue"
				>
					<MenuItem value="alphanumeric">
						<Checkbox checked={communityDataSpacesContext.filters.sortValue === 'alphanumeric'} />
						<ListItemText primary="Name" />
					</MenuItem>
					<MenuItem value="recent">
						<Checkbox checked={communityDataSpacesContext.filters.sortValue === 'recent'} />
						<ListItemText primary="Newest" />
					</MenuItem>
				</Select>
			</div>
		</div>
	);
};

export default FilterSortInputs;
