import { useContext, useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import {
	makeStyles,
	Grid,
	Typography,
	IconButton,
	Button,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	LinearProgress,
} from '@material-ui/core';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import CreateEditDataSpaceContext from '../../context/CreateEditDataSpaceContext';
import MyDataSpacesViewContext from '../../context/MyDataSpacesViewContext';
import NBALogoPNG from '../../../../assets/img/NBALogoSquare.png';
import MLBLogoPNG from '../../../../assets/img/MLBLogoSquare.png';
import NFLLogoPNG from '../../../../assets/img/NFLLogoSquare.png';
import NHLLogoPNG from '../../../../assets/img/NHLLogoSquare.png';
import YahooFantasyLogoPNG from '../../../../assets/img/YahooFantasyLogo.png';
import { trackEvent } from '../../../../utils/eventTracking';
import { UserProfileContext } from '../../../../UserProfileContext';
import { getDatasourceObjectTypeDatasources, getDataSourcesAvailableFields } from '../../../../api_helper/api';
import SnackbarAlertContext from '../../../../context/SnackbarAlertContext';

const useStyles = makeStyles((theme) => ({
	dialogWrapper: {
		width: '100%',
		padding: '1.5rem',
		display: 'flex',
		flexDirection: 'column',
	},
	dialogHeaderWrapper: {
		width: '100%',
		marginBottom: '2rem',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	dialogHeaderText: {
		fontSize: '1.5rem',
		fontWeight: 'bold',
		marginLeft: 'auto',
	},
	dialogExitButton: {
		marginLeft: 'auto',
	},
	datasetWrapper: {
		display: 'flex',
	},
	datasetLogo: {
		height: '100px',
		marginRight: '0.8rem',
		borderRadius: '0.5rem',
	},
	datasetInfoWrapper: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'nowrap',
	},
	seasonSelectForm: {
		margin: '0.5rem 0',
	},
	datasetField: {
		width: '100%',
		height: 'fit-content',
		padding: '0.3rem',
		backgroundColor: '#C4C4C4',
		borderRadius: '0.2rem',
		transition: '0.3s',
		'&:hover': {
			backgroundColor: '#C4C4C4',
			filter: 'brightness(0.75)',
		},
		'& > span': {
			width: '100%',
			display: 'inline',
			color: '#000000',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
	},
	datasetFieldSelected: {
		backgroundColor: '#0DBC42',
		'&:hover': {
			backgroundColor: '#0DBC42',
			filter: 'brightness(0.75)',
		},
	},
	nextButton: {
		alignSelf: 'end',
	},
	loadingSeasonsWrapper: {
		margin: '0.75rem 0',
		'& > span': {
			margin: '0.7rem 0',
		},
	},
}));

// Dialog (modal) allowing user to select fields for each selected datasource.
const SelectDatasetFieldsDialog = (props) => {
	const classes = useStyles();
	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);

	// User Profile Data
	const userProfile = useContext(UserProfileContext)[0];
	const snackbarAlertContext = useContext(SnackbarAlertContext);

	const myDataSpacesViewContext = useContext(MyDataSpacesViewContext);

	const [selectedDatasource, setSelectedDatasource] = useState(props.datasource);
	const [datasources, setDatasources] = useState([]);
	const [isDatasourcesLoading, setIsDatasourcesLoading] = useState(true);

	const getDatasetLogoSource = () => {
		switch (selectedDatasource.api) {
			case 'Reliant':
				switch (selectedDatasource.sport) {
					case 'NBA':
						return NBALogoPNG;
					case 'MLB':
						return MLBLogoPNG;
					case 'NFL':
						return NFLLogoPNG;
					case 'NHL':
						return NHLLogoPNG;
					default:
						return NBALogoPNG;
				}
			case 'Yahoo! Fantasy Sports':
				return YahooFantasyLogoPNG;
		}
	};

	const datasetFieldClickHandler = (e, clickedField) => {
		setSelectedDatasource((prevState) => {
			const updatedDataSpaceFields = selectedDatasource.dataSpaceFields.map((field) => {
				if (field.extendedFieldName === clickedField.extendedFieldName) {
					field.isSelected = !field.isSelected;
				}
				return field;
			});

			return {
				...prevState,
				dataSpaceFields: updatedDataSpaceFields,
			};
		});
	};

	// Sets isSelected property of all fields in datasource to false.
	const resetDatasource = () => {
		setSelectedDatasource((prevState) => {
			const updatedDataSpaceFields = prevState.dataSpaceFields.map((field) => ({ ...field, isSelected: false }));
			return {
				...prevState,
				dataSpaceFields: updatedDataSpaceFields,
			};
		});
	};

	const dialogCloseButtonClickHandler = () => {
		resetDatasource();
		props.setIsSelectDatasetFieldsDialogOpen(false);
	};

	const nextButtonClickHandler = () => {
		// If datasource has already been selected, inform user they cannot select it more than once.
		if (
			createEditDataSpaceContext.newDataSpace.selectedDatasets.some(
				(datasource) => datasource._id === selectedDatasource._id,
			)
		) {
			snackbarAlertContext.setSnackbarAlert({
				isSnackbarOpen: true,
				autoHide: 5000,
				severity: 'error',
				msg: `"${selectedDatasource.name} (${selectedDatasource.season})"  has already been selected.`,
			});
			return;
		}

		// If another datasource of the same type (e.g., NFL Player Stats) has already been selected, inform user they cannot select the datasource..
		if (
			createEditDataSpaceContext.newDataSpace.selectedDatasets.some(
				(datasource) => datasource.dataSourceObjectType === selectedDatasource.dataSourceObjectType,
			)
		) {
			snackbarAlertContext.setSnackbarAlert({
				isSnackbarOpen: true,
				autoHide: 5000,
				severity: 'error',
				msg: `Cannot have two datasources of the same type in a DataSpace.`,
			});
			return;
		}

		createEditDataSpaceContext.setNewDataSpace((prevContext) => ({
			...prevContext,
			selectedDatasets: [...prevContext.selectedDatasets, selectedDatasource],
			views: prevContext.views.filter((view) => view.name !== 'Default'),
		}));

		const isFirstDatasource = createEditDataSpaceContext.newDataSpace.selectedDatasets.length === 0;

		createEditDataSpaceContext.newDataSpace.joinData.push({
			anchor: isFirstDatasource,
			api: selectedDatasource.api,
			joins: [],
			primarySource: selectedDatasource.name,
			primarySourceId: selectedDatasource._id,
		});

		resetDatasource();

		// Event tracking for successfully adding a datasource to new dataspace.
		const eventName =
			createEditDataSpaceContext.createEditDataSpaceMode === 'edit'
				? 'Added a Datasource in Edit Dataspace Form'
				: 'Added a Datasource in New Dataspace Form';
		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: { types: ["KissMetrics", 'Segment', 'Encharge', 'AppInsights'], eventName },
		});

		if (isFirstDatasource) {
			props.setIsSelectDatasetFieldsDialogOpen(false);
		} else {
			myDataSpacesViewContext.changeViewHandler('datasetJoin');

			// Event tracking for advancing to joins page of new dataspace form via adding a datasource.
			const eventName =
				createEditDataSpaceContext.createEditDataSpaceMode === 'edit'
					? 'Advanced to Joins Page in Edit Dataspace Form'
					: 'Advanced to Joins Page in New Dataspace Form';
			trackEvent({
				userDetails: { userId: userProfile._id, email: userProfile.email },
				eventDetails: { types: ["KissMetrics", 'Segment', 'Encharge', 'AppInsights'], eventName },
			});
		}
	};

	const datasetFieldComponents = selectedDatasource.dataSpaceFields.map((field) => (
		<Grid key={field.extendedFieldName} item xs={12} sm={6}>
			<Button
				className={classes.datasetField + (field.isSelected ? ' ' + classes.datasetFieldSelected : '')}
				onClick={(e) => {
					datasetFieldClickHandler(e, field);
				}}
			>
				{field.header}
			</Button>
		</Grid>
	));

	useEffect(() => {
		const fetchData = async () => {
			const getDatasourceObjectTypeDatasourcesRes = await getDatasourceObjectTypeDatasources(
				props.datasource.dataSourceObjectType,
			);
			// Creates an array of datasources with their available fields populated under the dataSpaceFields property.
			const datasetsWithFields = [];
			for (let dataset of [...getDatasourceObjectTypeDatasourcesRes.data.datasources]) {
				const fieldsRes = await getDataSourcesAvailableFields(dataset.dataSourceObjectType);
				if (fieldsRes.length !== 0) {
					datasetsWithFields.push({ ...dataset, dataSpaceFields: fieldsRes });
				}
			}
			setDatasources(datasetsWithFields);
			setIsDatasourcesLoading(false);
		};

		try {
			fetchData();
		} catch (e) {
			console.log(e);
		}
	}, [props.isSelectDatasetFieldsDialogOpen]);

	const seasonSelectChangeHandler = (e) => {
		setSelectedDatasource(e.target.value);
	};

	return (
		<Dialog fullWidth open={props.isSelectDatasetFieldsDialogOpen}>
			<div className={classes.dialogWrapper}>
				<div className={classes.dialogHeaderWrapper}>
					<Typography className={classes.dialogHeaderText} variant="h2">
						Select Your Fields
					</Typography>
					<IconButton className={classes.dialogExitButton} size="medium" onClick={dialogCloseButtonClickHandler}>
						<DisabledByDefaultIcon fontSize="inherit" />
					</IconButton>
				</div>
				<div className={classes.datasetWrapper}>
					<img src={getDatasetLogoSource()} alt="Dataset logo." className={classes.datasetLogo} />
					<Grid container className={classes.datasetInfoWrapper}>
						<Typography variant="h6">{selectedDatasource.name}</Typography>
						<Typography>
							Select the fields from this dataset you would like to include in you dataspace. You can always add more
							from within your dataspace.
						</Typography>

						{selectedDatasource.season ? (
							isDatasourcesLoading ? (
								<Grid item xs={6} className={classes.loadingSeasonsWrapper}>
									<Typography variant="h8">Loading Available Seasons...</Typography>
									<LinearProgress color="inherit" />
								</Grid>
							) : (
								<>
									<Grid item xs={6}>
										<FormControl fullWidth className={classes.seasonSelectForm}>
											<InputLabel id="season-select-input-label">Season</InputLabel>
											<Select
												labelId="season-select-label"
												id="season-select-input"
												value={selectedDatasource}
												renderValue={(datasource) => datasource.season}
												label={'Season'}
												onChange={seasonSelectChangeHandler}
											>
												{datasources.map((datasource) => (
													<MenuItem key={datasource._id} value={datasource}>
														{datasource.season}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid container spacing={0.5} className={classes.datasetFieldsWrapper}>
										{datasetFieldComponents}
									</Grid>
								</>
							)
						) : (
							<Grid container spacing={0.5} className={classes.datasetFieldsWrapper}>
								{datasetFieldComponents}
							</Grid>
						)}
					</Grid>
				</div>
				<Button className={classes.nextButton} onClick={nextButtonClickHandler}>
					Next
				</Button>
			</div>
		</Dialog>
	);
};

export default SelectDatasetFieldsDialog;
