import { useContext } from 'react';
import { Grid, Typography, makeStyles, Button } from '@material-ui/core';
import CreateEditDataSpaceContext from '../../context/CreateEditDataSpaceContext';
import SelectedDataset from './SelectedDataset';
import MyDataSpacesViewContext from '../../context/MyDataSpacesViewContext';
import { trackEvent } from '../../../../utils/eventTracking';
import { UserProfileContext } from '../../../../UserProfileContext';

const useStyles = makeStyles((theme) => ({
	selectedDataListWrapper: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '8rem',
		marginBottom: '1rem',
	},
	selectedDataHeader: {
		marginBottom: '0.5rem',
		fontSize: '1.1rem',
		fontWeight: 'bold',
	},
	selectedDatasetsList: {
		marginBottom: '1rem',
		maxHeight: '28rem',
		overflow: 'scroll',
	},
	joinPageButton: {
		width: '8rem',
	},
}));

// List of selected datasources and their selected fields.
const SelectedDataList = () => {
	const classes = useStyles();

	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);
	// User Profile Data
	const userProfile = useContext(UserProfileContext)[0];

	const myDataSpacesViewContext = useContext(MyDataSpacesViewContext);

	const selectedDatasetComponents = createEditDataSpaceContext.newDataSpace.selectedDatasets.map((selectedDataset) => (
		<SelectedDataset key={selectedDataset._id} selectedDataset={selectedDataset} />
	));

	const joinPageButtonClickHandler = () => {
		myDataSpacesViewContext.changeViewHandler('datasetJoinNew');
		let eventName =
			createEditDataSpaceContext.createEditDataSpaceMode === 'edit'
				? 'Clicked Joins Page Button in Data Selection Page in Edit Dataspace Form'
				: 'Clicked Joins Page Button in Data Selection Page in New Dataspace Form';
		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: { types: ['KissMetrics', 'Segment', 'Encharge', 'AppInsights'], eventName },
		});
	};

	return (
		<Grid container item xs={12} md={4.25} className={classes.selectedDataListWrapper}>
			<Typography variant="h3" className={classes.selectedDataHeader}>
				What will be included in this Dataspace
			</Typography>
			<div className={classes.selectedDatasetsList}>
				{selectedDatasetComponents.length ? selectedDatasetComponents : 'No datasets selected'}
			</div>
			{/* If there is isn't two or more datasources selected, do not render the join page button. */}
			{createEditDataSpaceContext.newDataSpace.selectedDatasets.length > 1 ? (
				<Button
					className={classes.joinPageButton}
					variant="contained"
					margin="normal"
					color="primary"
					onClick={joinPageButtonClickHandler}
				>
					Join Page
				</Button>
			) : null}
		</Grid>
	);
};

export default SelectedDataList;
