import React, { useContext, useState, useEffect } from 'react';
import { DataSpaceViewProvider } from './context/DataSpaceViewContext';
import MyDataSpacesHome from './MyDataSpacesHome';
import DataSpaceView from './dataspace_view/DataSpaceView';
import DataSpaceViewNew from './dataspace_view_new/DataSpaceView';
import ConfigureDataSpace from './create_edit_dataspace_form/configure_dataspace';
import ConfigureDataSpaceNew from './create_edit_dataspace_form_new/configure_dataspace';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarAlert from '../ui_elements/SnackbarAlert';
import { Alert } from '@material-ui/lab';
import MyDataSpacesViewContext from './context/MyDataSpacesViewContext';
import NewDataSpaceSelectData from './create_edit_dataspace_form/select_data';
import NewDataSpaceSelectDataNew from './create_edit_dataspace_form_new/select_data';
import DatasetJoin from './create_edit_dataspace_form/dataset_join';
import DatasetJoinNew from './create_edit_dataspace_form_new/dataset_join';
import ErrorBoundary from '../ui_elements/ErrorBoundary';
import { useLocation, useHistory } from 'react-router-dom';
import { CommunityDataSpacesContextProvider } from '../../context/CommunityDataSpacesContext';
import { UserProfileContext } from '../../UserProfileContext';

export default function MyDataSpacesContainer() {
	let history = useHistory();
	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const myDataSpacesViewContext = useContext(MyDataSpacesViewContext);
	const user = useContext(UserProfileContext)[0];
	const [dataSpaceName, setDataSpaceName] = useState('');
	const [dataSpaceId, setDataSpaceId] = useState();

	// Event based handleChange for DataSpace name used in TextFields
	const handleChangeDataSpaceName = (event) => setDataSpaceName(event.target.value);
	// Non-event based funtion to change DataSpace name (for loading DataSpaces)
	const changeDataSpaceName = (name) => setDataSpaceName(name);
	const handleChangeDataSpaceId = (id) => setDataSpaceId(id);

	const [snackOpen, setSnackOpen] = useState(false);
	const onSnackClose = () => setSnackOpen(false);
	const [snackMessage, setSnackMessage] = useState('');

	const handleDataSpaceLoadingError = () => {
		myDataSpacesViewContext.changeViewHandler('home');
		setSnackMessage(
			<div>
				Whoops! It looks like something went wrong, please try again.
				<br />
				If the issue persists please report it with our&nbsp;
				<a
					href="https://rolling-wave.atlassian.net/servicedesk/customer/portal/1/group/1/create/1"
					rel="noreferrer"
					target="_blank"
				>
					service desk
				</a>
				.
			</div>,
		);
		setSnackOpen(true);
	};

	const displayComponent = () => {
		switch (myDataSpacesViewContext.myDataSpacesView.view) {
			case 'home':
				return (
					<CommunityDataSpacesContextProvider>
						<MyDataSpacesHome
							key={myDataSpacesViewContext.myDataSpacesView.refreshKey}
							handleChangeDataSpaceName={handleChangeDataSpaceName}
							changeDataSpaceName={changeDataSpaceName}
							handleChangeDataSpaceId={handleChangeDataSpaceId}
							dataSpaceId={dataSpaceId}
							dataSpaceName={dataSpaceName}
						/>
					</CommunityDataSpacesContextProvider>
				);
			case 'configureNewDataSpace':
				return (
					<ErrorBoundary onError={handleDataSpaceLoadingError}>
						<ConfigureDataSpace />
					</ErrorBoundary>
				);
			case 'configureNewDataSpaceNew':
				return (
					<ErrorBoundary onError={handleDataSpaceLoadingError}>
						<ConfigureDataSpaceNew />
					</ErrorBoundary>
				);
			case 'newDataSpaceSelectData':
				return <NewDataSpaceSelectData />;
			case 'newDataSpaceSelectDataNew':
				return (
					<ErrorBoundary onError={handleDataSpaceLoadingError}>
						<NewDataSpaceSelectDataNew />
					</ErrorBoundary>
				);
			case 'datasetJoin':
				return <DatasetJoin />;
			case 'datasetJoinNew':
				return (
					<ErrorBoundary onError={handleDataSpaceLoadingError}>
						<DatasetJoinNew />
					</ErrorBoundary>
				);
			case 'dataSpaceView':
				return (
					<ErrorBoundary onError={handleDataSpaceLoadingError}>
						<DataSpaceViewProvider>
							<DataSpaceView
								dataSpaceName={dataSpaceName || myDataSpacesViewContext.myDataSpacesView.selectedDataSpace.name}
								dataSpaceId={dataSpaceId || myDataSpacesViewContext.myDataSpacesView.selectedDataSpace.id}
								handleDataSpaceLoadingError={handleDataSpaceLoadingError}
							/>
						</DataSpaceViewProvider>
					</ErrorBoundary>
				);
			case 'dataSpaceViewNew':
				return (
					<ErrorBoundary onError={handleDataSpaceLoadingError}>
						<DataSpaceViewProvider>
							<DataSpaceViewNew
								dataSpaceName={dataSpaceName || myDataSpacesViewContext.myDataSpacesView.selectedDataSpace.name}
								dataSpaceId={dataSpaceId || myDataSpacesViewContext.myDataSpacesView.selectedDataSpace.id}
								handleDataSpaceLoadingError={handleDataSpaceLoadingError}
							/>
						</DataSpaceViewProvider>
					</ErrorBoundary>
				);
			default:
				return <MyDataSpacesHome />;
		}
	};

	useEffect(() => {
		if (params.get('route') === 'dataSpaceForm') {
			myDataSpacesViewContext.changeViewHandler('configureNewDataSpaceNew');
		}
	}, []);

	return (
		<div className="my-dataspaces-container">
			{displayComponent()}
			<Snackbar
				open={snackOpen}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				onClose={onSnackClose}
				autoHideDuration={10000}
			>
				<Alert severity={'error'}>{snackMessage}</Alert>
			</Snackbar>
			<SnackbarAlert />
		</div>
	);
}
