import React, { useState } from 'react';

const PageContext = React.createContext();

// PageContextProvider is used to give all children components access to the PageContext context.
// Wrap components that need access to the PageContext with the PageContextProvider context.
// Sets which page is being rendered.
export const PageContextProvider = (props) => {
	const [page, setPage] = useState('mydataspaces');

	return <PageContext.Provider value={{ page, setPage }}>{props.children}</PageContext.Provider>;
};

export default PageContext;
