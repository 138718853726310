import React, { Component } from 'react';
import { TextField, Button, Grid, Box, Snackbar, FormGroup, FormControlLabel, Checkbox, Card } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { checkEmailRegistered, checkUsernameRegistered, verifyRecaptchaToken, createUser } from '../../api_helper/api';
import PasswordStrengthMeter from '../../utils/PasswordStrengthMeter';
import { removeEmptySpacesFromArray, validateEmail, isEmpty } from '../../utils/util';
import { withStyles } from '@material-ui/core/styles';
import sportWiseLogo from '../../assets/img/SportWiseLogoLarge.png';
import nbaLogo from '../../assets/img/image_select_images/NBA.png';
import mlbLogo from '../../assets/img/image_select_images/MLB.png';
import nhlLogo from '../../assets/img/image_select_images/NHL.png';
import nflLogo from '../../assets/img/image_select_images/NFL.png';
import yahooLogo from '../../assets/img/YahooLogo.png';
import fantraxLogo from '../../assets/img/FantraxLogo.png';
import signupBackground from '../../assets/img/SignupBackground.jpg';

import { identify, trackEvent } from '../../utils/eventTracking';
import './Register.css';
// Images
import LeagueLogos from '../../assets/img/image_select_images/LeagueLogos.png';

const styles = (theme) => ({
	formControlLabel: {
		margin: '0 0 0 0.5rem',
	},
	logos: {
		maxHeight: '5rem',
		maxWidth: '6rem',
		margin: '0 0.25rem',
		objectFit: 'contain',
	},
});

class Register extends Component {
	state = {
		firstname: '',
		lastname: '',
		email: '',
		username: '',
		phone: '',
		password: '',
		confirmpassword: '',
		errors: [],
		postError: '',
		termsOfService: false,
		privacyPolicy: false,
		recaptchaToken: '',
	};

	handleChange = ({ target }) => {
		const { name, value } = target;
		//Create a copy of the array
		let arr = Array.from(this.state.errors);
		//Find the index of the key you are looking to create
		let ind = arr.findIndex((e) => e.key === name);
		//Remove the old value and add the new one
		if (ind >= 0) {
			arr.splice(ind, 1, { key: name, value: value });
		} else {
			arr.push({ key: name, value: value });
		}
		// Reset the state with the update value and errors to render
		this.setState({ [name]: value, errors: arr });
	};

	handleChangeCheckBox = (event) => {
		this.setState({ [event.target.name]: event.target.checked });
	};

	getRecaptchaToken = () => {
		return new Promise((resolve, reject) => {
			try {
				window.grecaptcha.ready(() => {
					window.grecaptcha.execute('6LcTxT4cAAAAALD3H4x45D6sN5fHlrqUY1p5UoWj', { action: 'submit' }).then((token) => {
						resolve(token);
					});
				});
			} catch (err) {
				reject(err);
			}
		});
	};

	submit = async (e) => {
		let errors = await this.verifyFields();
		// errors = this.cleanErrors(errors);
		errors = removeEmptySpacesFromArray(errors);

		// Upload user to database if there are no errors.
		if (errors.length === 0) {
			const payload = {
				firstname: this.state.firstname,
				lastname: this.state.lastname,
				username: this.state.username,
				email: this.state.email,
				phone: this.state.phone,
				password: this.state.password,
				userHasAgreedToTermsOfService: this.state.termsOfService,
				userHasReadPrivacyPolicy: this.state.privacyPolicy,
			};

			// Encharge tracking
			identify(this.state.email, this.state.username, this.state.firstname, this.state.lastname);
			trackEvent({
				userDetails: { userId: null, email: this.state.email },
				eventDetails: {
					types: ['KissMetrics', 'Segment', 'Encharge', 'GA4', 'AppInsights'],
					eventName: 'New User Registered!',
				},
			});
			await createUser(payload)
				.then((result) => {
					if (result.token) {
						// Set the JWT Token and redirect to the app
						localStorage.setItem('jwt', result.token);
						this.props.history.push('/app');
					} else {
						this.setState({
							postError:
								'Your account was created successfully but there was an issue logging you in.  Manually login through the Login page.',
						});
					}
				})
				.catch((err) => {
					this.setState({ postError: err.data });
				});
		} else {
			this.setState({ errors: errors });
		}
	};

	// This function verifies that all fields are entered correctly
	// and that username or email are not previously registered.
	verifyFields = async () => {
		try {
			const { email, password, confirmpassword } = this.state;
			const errors = [];

			// Checkboxes
			if (!this.state.termsOfService) {
				errors.push({
					key: 'termsOfService',
					msg: <p class={'register-checkbox-warning'}>You must agree to the Terms of Service.</p>,
				});
			}
			if (!this.state.privacyPolicy) {
				errors.push({
					key: 'privacyPolicy',
					msg: <p class={'register-checkbox-warning'}>You must indicate you have read the Policy Policy.</p>,
				});
			}

			// Synchronous error checks
			// Check required fields are entered
			const excludeFromChecks = ['phone', 'errors', 'postError', 'termsOfService', 'privacyPolicy', 'recaptchaToken'];
			for (var key of Object.keys(this.state)) {
				//The phone number is not a required field
				//Check to see if the value is empty
				if (!excludeFromChecks.includes(key) && isEmpty(this.state[key])) {
					errors.push({ key: key, msg: 'Please enter text into this field.' });
				}
			}

			// Check if valid e-mail
			if (!validateEmail(email)) errors.push({ key: 'email', msg: 'Invalid email.' });

			// Check passwords match
			if (password !== confirmpassword) errors.push({ key: 'confirmpassword', msg: 'Passwords do not match.' });

			// Asynchronous error checks
			// Check username and email not previously registered.
			await checkUsernameRegistered(this.state.username).then((result) => {
				if (!isEmpty(result)) {
					errors.push({ key: 'username', msg: result.msg });
				}
			});

			await checkEmailRegistered(this.state.email).then((result) => {
				if (!isEmpty(result)) {
					errors.push({ key: 'email', msg: result.msg });
				}
			});

			const token = await this.getRecaptchaToken();
			const recaptchaVerificationDetails = await verifyRecaptchaToken(token, 0.5);
			if (recaptchaVerificationDetails.passedRecaptchaCheck === false) {
				errors.push({
					key: 'recaptcha',
					msg: <p class={'register-checkbox-warning'}>{recaptchaVerificationDetails.msg}</p>,
				});
			}

			return errors;
		} catch (e) {
			this.setState({
				postError:
					'There was an issue creating your account. Please try again later. If this issue persists, please contact support@rolling-insights.com',
			});
			const errors = [
				{
					key: 'accountCreationError',
				},
			];
			return errors;
		}
	};

	// Handle close when the snackbar closes
	handleClose = () => {
		//Reset the postError message
		this.setState({ postError: '' });
	};

	//Event tracking
	registerButtonOnClick = async () => {
		this.submit();
	};

	render() {
		const { classes } = this.props;

		return (
			<>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						background: `url(${signupBackground})`,
						backgroundSize: 'cover',
						backgroundPosition: 'top right 20%',
					}}
				>
					{/* <GoogleReCaptchaProvider reCaptchaKey="6LdXCzwcAAAAAM9KeWadIL-XRIVLlvfXxetnQXQU">
					<GoogleReCaptcha onVerify={this.handleRecaptchaVerify} />
				</GoogleReCaptchaProvider> */}
					<Card
						style={{
							width: '96%',
							maxWidth: '40rem',
							margin: '4rem 0',
							padding: '3rem 1rem',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<img style={{ height: '4.5rem' }} src={sportWiseLogo} />
						<h1
							style={{
								margin: '1.5rem 0',
								fontSize: '2.5rem',
								fontWeight: '900',
								textAlign: 'center',
							}}
						>
							Create Account
						</h1>
						<form style={{ width: '100%', maxWidth: '25rem' }}>
							<TextField
								required
								label="First Name"
								name="firstname"
								variant="outlined"
								margin="normal"
								fullWidth={true}
								onChange={this.handleChange}
								FormHelperTextProps={{
									component: 'div',
								}}
								helperText={this.state.errors.find((x) => x.key === 'firstname')?.msg}
								error={!isEmpty(this.state.errors.find((x) => x.key === 'firstname')?.msg)}
							/>
							<TextField
								required
								label="Last Name"
								name="lastname"
								variant="outlined"
								margin="normal"
								fullWidth={true}
								onChange={this.handleChange}
								FormHelperTextProps={{
									component: 'div',
								}}
								helperText={this.state.errors.find((x) => x.key === 'lastname')?.msg}
								error={!isEmpty(this.state.errors.find((x) => x.key === 'lastname')?.msg)}
							/>
							<TextField
								required
								label="E-Mail"
								name="email"
								variant="outlined"
								margin="normal"
								fullWidth={true}
								onChange={this.handleChange}
								FormHelperTextProps={{
									component: 'div',
								}}
								helperText={this.state.errors.find((x) => x.key === 'email')?.msg}
								error={!isEmpty(this.state.errors.find((x) => x.key === 'email')?.msg)}
							/>
							<TextField
								required
								label="Username"
								name="username"
								variant="outlined"
								margin="normal"
								fullWidth={true}
								onChange={this.handleChange}
								FormHelperTextProps={{
									component: 'div',
								}}
								helperText={this.state.errors.find((x) => x.key === 'username')?.msg}
								error={!isEmpty(this.state.errors.find((x) => x.key === 'username')?.msg)}
							/>
							<TextField
								label="Phone Number"
								name="phone"
								variant="outlined"
								margin="normal"
								fullWidth={true}
								onChange={this.handleChange}
							/>
							<TextField
								required
								label="Password"
								name="password"
								type="password"
								variant="outlined"
								margin="normal"
								fullWidth={true}
								onChange={this.handleChange}
								FormHelperTextProps={{
									component: 'div',
								}}
								helperText={this.state.errors.find((x) => x.key === 'password')?.msg}
								error={!isEmpty(this.state.errors.find((x) => x.key === 'password')?.msg)}
							/>
							{this.state.password.length > 0 && (
								<Grid item>
									<PasswordStrengthMeter password={this.state.password} />
								</Grid>
							)}
							<TextField
								required
								label="Confirm Password"
								name="confirmpassword"
								type="password"
								variant="outlined"
								margin="normal"
								fullWidth={true}
								onChange={this.handleChange}
								FormHelperTextProps={{
									component: 'div',
								}}
								helperText={this.state.errors.find((x) => x.key === 'confirmpassword')?.msg}
								error={!isEmpty(this.state.errors.find((x) => x.key === 'confirmpassword')?.msg)}
							/>
							<FormGroup>
								{this.state.errors.length > 0 && (
									<div>
										{!this.state.recaptchaVerified && (
											<p>{this.state.errors.find((x) => x.key === 'recaptcha')?.msg}</p>
										)}
										{!this.state.termsOfService && (
											<p>{this.state.errors.find((x) => x.key === 'termsOfService')?.msg}</p>
										)}
										{!this.state.privacyPolicy && (
											<p>{this.state.errors.find((x) => x.key === 'privacyPolicy')?.msg}</p>
										)}
									</div>
								)}
								<FormControlLabel
									className={classes.formControlLabel}
									label={
										<span>
											I have read and agree to the{' '}
											<a
												href="https://rolling-insights.com/terms-of-services/"
												target="_blank"
												rel="noopener noreferrer"
											>
												Terms of Service.
											</a>
										</span>
									}
									control={
										<Checkbox
											name={'termsOfService'}
											checked={this.state.termsOfService}
											onChange={this.handleChangeCheckBox}
											color="primary"
										/>
									}
								/>
								<FormControlLabel
									className={classes.formControlLabel}
									label={
										<span>
											I have read the{' '}
											<a href="https://rolling-insights.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
												Privacy Policy.
											</a>
										</span>
									}
									control={
										<Checkbox
											name={'privacyPolicy'}
											checked={this.state.privacyPolicy}
											onChange={this.handleChangeCheckBox}
											color="primary"
										/>
									}
								/>
							</FormGroup>
							<Button
								style={{ marginTop: '1rem', position: 'relative', left: '50%', transform: 'translateX(-50%)' }}
								variant="contained"
								color="secondary"
								margin="normal"
								onClick={this.registerButtonOnClick}
							>
								Sign Up
							</Button>
						</form>
						<Button
							style={{ marginTop: '1rem' }}
							onClick={() => {
								this.props.history.push('/');
							}}
						>
							Already have an account? Log in.
						</Button>
						<div
							style={{
								marginTop: '1rem',
								display: 'flex',
								flexWrap: 'wrap',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<img className={classes.logos} src={yahooLogo} />
							<img className={classes.logos} style={{ height: '4rem', borderRadius: '0.2rem' }} src={fantraxLogo} />
							<img className={classes.logos} src={nflLogo} />
							<img className={classes.logos} src={nhlLogo} />
							<img className={classes.logos} src={nbaLogo} />
							<img className={classes.logos} src={mlbLogo} />
						</div>
					</Card>
				</div>
				<Snackbar
					open={!isEmpty(this.state.postError)}
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					autoHideDuration={6000}
					onClose={this.handleClose}
				>
					<Alert severity="error">{this.state.postError}</Alert>
				</Snackbar>
			</>
		);
	}
}

export default withStyles(styles, { withTheme: true })(Register);
