import { Button, Backdrop, CircularProgress } from '@material-ui/core';
import SnackbarAlertContext from '../../context/SnackbarAlertContext';
import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { UserProfileContext } from '../../UserProfileContext';
import { createDataSpace } from '../../api_helper/api';
import MyDataSpacesViewContext from '../my_dataspaces/context/MyDataSpacesViewContext';
import PageContext from '../../context/PageContext';
import CommunityDataSpacesContext from '../../context/CommunityDataSpacesContext';
import { trackEvent } from '../../utils/eventTracking';

const CreateDataSpaceButton = (props) => {
	const snackbarAlertContext = useContext(SnackbarAlertContext);
	const myDataSpacesViewContext = useContext(MyDataSpacesViewContext);
	const pageContext = useContext(PageContext);
	const userProfile = useContext(UserProfileContext)[0];
	const communityDataSpacesContext = useContext(CommunityDataSpacesContext);

	const [isLoading, setIsLoading] = useState(false);

	const createDataSpaceButtonClickHandler = async () => {
		try {
			setIsLoading(true);

			const dataSpace = _.cloneDeep(communityDataSpacesContext.selectedDataSpace);

			dataSpace.creationDate = Date.now();
			dataSpace.lastRefreshedDate = Date.now();
			dataSpace.originalAuthor = dataSpace.createdBy;
			dataSpace.createdBy = userProfile._id;

			// Reset public properties
			dataSpace.isPublic = false;
			dataSpace.name = dataSpace.publicName;
			delete dataSpace.likes;
			delete dataSpace.impressions;
			delete dataSpace.publicName;

			// Replace fantasy league (if applicable)
			if (
				communityDataSpacesContext.selectedDataSpace.datasets.some((dataset) =>
					['Yahoo! Fantasy Sports', 'Fantrax'].includes(dataset.tags.api),
				)
			) {
				if (!props.selectedFantasyLeague) {
					snackbarAlertContext.setSnackbarAlert({
						isSnackbarOpen: true,
						autoHide: 5000,
						severity: 'error',
						msg: 'Please select a fantasy league to use with the DataSpace. If none are available, please connect a fantasy league with the appropriate sport to continue.',
					});
					setIsLoading(false);
					return;
				}
			}

			if (true) {
				// Update datasets with new fantasy league data
				dataSpace.datasets = dataSpace.datasets.map((dataset) => {
					if (dataset.tags.api === 'Yahoo! Fantasy Sports') {
						dataset.userId = userProfile._id;
						dataset.gameKey = props.selectedFantasyLeague.league_key.substr(0, 3);
						dataset.leagueKey = props.selectedFantasyLeague.league_key;
						dataset.leagueName = props.selectedFantasyLeague.name;
					}
					return dataset;
				});

				// Update joinData with new fantasy league data
				dataSpace.joinData = dataSpace.joinData.map((dataset) => {
					if (dataset.tags.api === 'Yahoo! Fantasy Sports') {
						dataset.userId = userProfile._id;
						dataset.gameKey = props.selectedFantasyLeague.league_key.substr(0, 3);
						dataset.leagueKey = props.selectedFantasyLeague.league_key;
					}
					return dataset;
				});
			}

			const dataSpaceId = await createDataSpace({
				name: dataSpace.name,
				creationDate: dataSpace.creationDate,
				lastRefreshedDate: dataSpace.lastRefreshedDate,
				toggledColumnsState: dataSpace.toggledColumnsState,
				views: dataSpace.views,
				calculatedColumns: dataSpace.calculatedColumns,
				headerNamesState: dataSpace.headerNamesState,
				createdBy: dataSpace.createdBy,
				joinData: dataSpace.joinData,
				isPublic: dataSpace.isPublic,
				datasets: dataSpace.datasets,
				columnMetadata: dataSpace.columnMetadata,
				internalState: dataSpace.internalState,
				originalDataSpace: communityDataSpacesContext.selectedDataSpace._id,
				dataSpaceImageUrl: communityDataSpacesContext.selectedDataSpace.dataSpaceImageUrl,
				originalAuthor: dataSpace.originalAuthor,
				publishedDate: dataSpace.publishedDate,
			});

			myDataSpacesViewContext.setMyDataSpacesView((prevState) => ({
				...prevState,
				selectedDataSpace: { name: dataSpace.name, id: dataSpaceId },
			}));

			setIsLoading(false);
			myDataSpacesViewContext.changeViewHandler('dataSpaceViewNew');
			pageContext.setPage('mydataspaces');
		} catch (e) {
			snackbarAlertContext.setSnackbarAlert({
				isSnackbarOpen: true,
				autoHide: 5000,
				severity: 'error',
				msg: 'There was an issue creating the DataSpace. If this issue persists, please contact support@rolling-insights.com',
			});
			setIsLoading(false);
		}
	};

	useEffect(() => {
		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: {
				types: ['KissMetrics', 'Segment', 'AppInsights', 'GA4'],
				eventName: 'User selected a Community DataSpace.',
			},
		});
	}, []);

	return (
		<>
			<Button
				onClick={createDataSpaceButtonClickHandler}
				variant="contained"
				color="secondary"
				style={{ height: 'fit-content', padding: '0.3rem 0.75rem' }}
			>
				Clone
			</Button>
			<Backdrop open={isLoading} style={{ zIndex: 1 }}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	);
};

export default CreateDataSpaceButton;
