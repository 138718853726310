import React, { useState, useEffect, useRef } from 'react';
import DataTable from '../../ui_elements/DataTable';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { CircularProgress, Backdrop, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isEqual, debounce } from 'lodash';
import { updateDataSpaceState, retrieveDataSpaceState } from '../../../api_helper/api';
import FilterInitializer from './FilterInitializer';
import ColumnFactory from './ColumnFactory';
import ViewManager from './ViewManager';
import CalculatedColumnPanel from './CalculatedColumnPanel';
import CalculatedColumnEditor from './CalculatedColumnEditor';
import { Prompt } from 'react-router-dom';

import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	globalFilter: {
		marginRight: '15px',
	},
	inputNumber: {
		marginLeft: '1px',
		marginRight: '1px',
	},
	seeDetailsButton: {
		color: 'black',
	},
	dataSpaceHeader: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	dataSpaceHeaderRightWrapper: {
		display: 'flex',
	},
	dataspaceViewManagerWrapper: {
		padding: '0 0.5rem',
		justifyContent: 'space-between',
	},
}));

export default function PrimeDataTable(props) {
	const classes = useStyles();

	// Global Filter
	const [globalFilter, setGlobalFilter] = useState(null);
	const filterDelayMilliseconds = 250; // ms after last keypress filter state will be updated
	// debounce allows state to change only when user has finished typing
	const handleChangeGlobalFilter = debounce((e) => {
		setGlobalFilter(e.target.value);
	}, filterDelayMilliseconds);

	// Refs
	const dataTableRef = useRef(null); // DataTable Ref
	const internalStateRef = useRef(undefined); // Used to track internal table state (ITS) to detect changes in ITS

	// Table
	const [internalTableState, setInternalTableState] = useState();
	const [tableInitialized, setTableInitialized] = useState(false); // Flags if filters and selected column data are loaded
	const [isInternalTableStateSet, setIsInternalTableStateSet] = useState(false);
	const dataTableWillRender = isInternalTableStateSet && tableInitialized;
	const paginationOptions = [10, 25, 50, 100, 250];
	const defaultPaginationOption = 10;

	// Loading Screen
	const [isViewLoading, setIsViewLoading] = useState(false);
	const loadScreenActive = isViewLoading || !dataTableWillRender;

	// Columns and Filters
	const [selectedColumns, setSelectedColumns] = useState([]); // Used in hide/show column toggle menu
	const [columnFilterTypes, setColumnFilterTypes] = useState([]);
	const [columnFilterStates, setColumnFilterStates] = useState({});
	const filterInitializer = new FilterInitializer(props.rows, props.columns);

	// Calculated columns
	const [columnEditorOpen, setColumnEditorOpen] = useState(false);
	const [columnEditorDetails, setColumnEditorDetails] = useState({});
	const handleEditCalculatedColumn = (field, header) => {
		setColumnEditorDetails({ field, header });
		setColumnEditorOpen(true);
	};
	const [numNewCalculatedColumns, setNumNewCalculatedColumns] = useState(0);

	// Callback passed down to column header to allow user to hide column from context menu
	const onHideColumn = (field) => {
		// Get current visible columns
		let updatedCols = [...selectedColumns];
		// Remove column to hide from current visible columns
		updatedCols.splice(
			updatedCols.findIndex((col) => col.field === field),
			1,
		);
		setSelectedColumns(updatedCols);

		// Save current visible columns in database
		updatedCols = updatedCols.map((x) => x.field); // Convert to array of Strings representing fields
		updateToggledColumnsState(updatedCols);
	};

	/*
  Updates database with new ToggledColumnsState
  @param [String] visibleColumns: Array of strings representing fields corresponding to visible cols
  */
	function updateToggledColumnsState(visibleColumns) {
		const payload = {
			mode: 'toggledColumnsState',
			dataSpaceId: props.dataSpaceId,
			toggledColumnsState: visibleColumns,
		};

		updateDataSpaceState(payload);
	}

	// Handles deletion of a custom column from the DataSpace frontend
	function handleDeleteColumn(field) {
		// Remove from selected columns
		setSelectedColumns((prev) => {
			const i = prev.findIndex((c) => c.field === field);
			if (i) prev.splice(i, 1);
			return prev;
		});

		// Need to remove column order from dataspace state or it will reupload to db
		const tableState = dataTableRef.current.state;
		if (tableState.columnOrder) {
			const i = tableState.columnOrder.findIndex((o) => o === field);
			if (i >= 0) tableState.columnOrder.splice(i, 1);
		}
		dataTableRef.current.restoreTableState(tableState);

		// Reloads DataSpace data
		props.deleteCustomColumn();
	}

	// Instance of ColumnFactory class which creates columns dynamically based on props
	const columnFactory = new ColumnFactory(
		dataTableRef,
		columnFilterStates,
		setColumnFilterStates,
		props.rows,
		props.renameHeader,
		columnFilterTypes,
		onHideColumn,
		handleEditCalculatedColumn,
		handleDeleteColumn,
	);

	// Retrieve the properties representing the user's current view
	const getCurrentView = () => {
		const view = {
			internalProperties: {},
			externalProperties: {},
		};

		view.internalProperties = {
			state: dataTableRef.current.state,
		};

		view.externalProperties = {
			selectedColumns: selectedColumns,
			filterStates: columnFilterStates,
		};

		return view;
	};

	// Load the properties of a saved view into DataSpace
	const loadView = (view) => {
		// Set internal properties
		dataTableRef.current.restoreTableState(view.internalProperties.state);

		// Set external properties
		setSelectedColumns(view.externalProperties.selectedColumns);
		setColumnFilterStates(view.externalProperties.filterStates);
	};

	// Load table's internal state to memory upon mounting
	useEffect(() => {
		retrieveDataSpaceState(props.dataSpaceId, 'internalState').then((res) => {
			// Obtain initial state
			let state = {};
			if (res.internalState) state = res.internalState;

			// Specify default rows/page in table state if not already defined
			// or no initial table state
			if (!state.rows || Object.keys(state).length === 0) {
				state.rows = defaultPaginationOption;
				state.first = 0;
			}

			setInternalTableState(state);
			setIsInternalTableStateSet(true);
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Initialize filter states
	// Load toggled column settings
	useEffect(() => {
		if (props.columns.length > 0 && tableInitialized === false) {
			// Initialize filter states
			setColumnFilterTypes(filterInitializer.getColumnFilterTypes());
			setColumnFilterStates(filterInitializer.getInitialFilterState());

			// Load toggled column settings
			retrieveDataSpaceState(props.dataSpaceId, 'toggledColumnsState').then((res) => {
				// If no toggled column settings, all columns are selected
				if (res.toggledColumnsState.length === 0) {
					setSelectedColumns(props.columns);
				} else {
					// Set selected columns based on toggle settings
					let toggleState = res.toggledColumnsState.map((toggledCol) => {
						const column = props.columns.find((c) => c.field === toggledCol);

						if (!column) return null; // Selection not available in this Dataset

						return column;
					});

					// Remove unavailable selections
					toggleState = toggleState.filter((x) => x !== null);

					setSelectedColumns(toggleState);
				}

				// Flag successful loading of filter and toggled column data
				setTableInitialized(true);
			});
		}

		// Update selected columns to reflect any header changes
		if (props.columns.length > 0) {
			let selColsCopy = [...selectedColumns];
			selColsCopy = selColsCopy.map((sCol) => {
				const { header, dataSourceName, columnToggleLabel } = props.columns.find((col) => col.field === sCol.field);
				return {
					columnToggleLabel: columnToggleLabel,
					dataSourceName: dataSourceName,
					field: sCol.field,
					header: header,
				};
			});

			setSelectedColumns(selColsCopy);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.columns]);

	// Used in hide/show MultiSelect component. Modifies selectedColumns and state based
	// on column toggling
	const onColumnToggle = async (e) => {
		let selectedCols = e.value;

		// Table crashes if no columns selected. If user selects 0 columns,
		// the first column will automatically be selected.
		if (selectedCols.length === 0) selectedCols = [props.columns[0]];

		// Base order of selected columns on orginal order of columns
		let orderedSelectedColumns = props.columns.filter((col) => selectedCols.some((sCol) => sCol.field === col.field));
		setSelectedColumns(orderedSelectedColumns);

		// Save selected (toggled) columns to DataSpaceSchema object
		// Stored as an array of Strings
		const columnPayload = orderedSelectedColumns.map((c) => {
			return c.field;
		});
		updateToggledColumnsState(columnPayload);
	};

	// State management in DataTable
	const onCustomSaveState = (state) => {
		/*
        Note: Modifying the globalFilter activates onCustomSaveState twice.
        The reason for this is currently unknown.
        On the first iteration state.filters has the previous state's value, and thus
        the following check fails and an unneccessary backend call is made. This call
        however is not destructive, as we remove the filter property before updating the
        state in the DataBase.

        I have submitted a question to PrimeFaces regarding this
        https://forum.primefaces.org/viewtopic.php?f=57&t=65458
    */

		// Avoid unnecessary backend calls if change in state is due to filter modification,
		// or if table's state has not changed.
		// We do not persist filter modifications implicitly, only by user action
		let filtersUnchanged = isEqual(internalStateRef.current?.filters, state.filters);
		let internalStateHasChanged = !isEqual(internalStateRef.current, state);

		if (filtersUnchanged && internalStateHasChanged) {
			delete state.filters;
			const payload = {
				mode: 'internalState',
				dataSpaceId: props.dataSpaceId,
				internalState: state,
			};
			updateDataSpaceState(payload);
		}

		internalStateRef.current = state; // Track internal table state
	};

	// State management in DataTable
	const onCustomRestoreState = () => {
		return internalTableState;
	};

	const [isDataSpaceExpanded, setIsDataSpaceExtended] = useState(false);
	const expandDataSpaceClickHandler = () => {
		setIsDataSpaceExtended((state) => !state);
	};

	// Create datatable header containing Column Toggle and Global Filter
	const dataTableHeader = (
		<div className={classes.dataSpaceHeader} style={{ textAlign: 'left' }}>
			<MultiSelect
				value={selectedColumns}
				options={props.columns}
				optionLabel="columnToggleLabel"
				onChange={onColumnToggle}
				style={{ width: '20em' }}
			/>

			<div className={classes.dataSpaceHeaderRightWrapper}>
				<Button className={classes.seeDetailsButton} onClick={props.handleClickSeeDetails}>
					See Details
				</Button>

				<div className={classes.globalFilter}>
					<span className="p-input-icon-left">
						<i className="pi pi-search" />
						<InputText type="search" onInput={handleChangeGlobalFilter} placeholder="Global Search" />
					</span>
				</div>

				{/* Toggled Columns MultiSelect */}
				<Button onClick={expandDataSpaceClickHandler}>
					{isDataSpaceExpanded ? (
						<FullscreenExitIcon style={{ fontSize: 26 }} />
					) : (
						<FullscreenIcon style={{ fontSize: 26 }} />
					)}
				</Button>
			</div>
		</div>
	);

	// Map selected columns to Column components for display in table
	const columnComponents = selectedColumns.map((col) => {
		return columnFactory.getColumnComponent(col);
	});

	// Extract numeric columns for use in Calculated Column Panel
	const numericalColumns = (function getNumericalColumns() {
		const numericalColumnsByField = columnFilterTypes
			.filter((x) => x.filterType === 'numericRange')
			.map((x) => x.field);
		return props.columns.filter((x) => numericalColumnsByField.includes(x.field));
	})();

	// This function is used to integrate a new Calculated Column returned from the CalculatedColumnPanel into
	// the DataSpace.
	// It initializes filters for the new column and calls another function passed down from the parent component to
	// which integrates the column and row data into the DataSpace
	const handleAddCalculatedColumn = (colDetails) => {
		const { field } = colDetails.calcColDetails;
		initializeFilterForNewColumn(field);
		props.appendCalculatedColumn(colDetails);

		function initializeFilterForNewColumn(field) {
			setColumnFilterTypes((prev) => {
				prev.push({ field, filterType: 'numericRange' });
				return prev;
			});
			setColumnFilterStates((prev) => {
				prev[field] = { min: null, max: null };
				return prev;
			});
		}

		// Initiate logic to add new column to selected columns.
		// Cannot perform this logic in this function as it relies on the parent's state to change
		// and for logic to be done by the parent based on that change. The result of that logic
		// is not available within this function call.
		// See useEffect below.
		setNumNewCalculatedColumns((prev) => {
			return prev + 1;
		});
	};

	// If the number of new calculated columns changes (increases), add the new calculated column
	// to the list of selected columns.
	useEffect(() => {
		// Do not act if no columns have been created
		if (numNewCalculatedColumns !== 0) {
			let updatedColumns = [...selectedColumns];
			updatedColumns.push(props.columns[props.columns.length - 1]); // The last element in props.columns will be the new calculated column
			setSelectedColumns(updatedColumns);

			updateToggledColumnsState(updatedColumns.map((c) => c.field)); // Update toggled columns in database (uses list of fields)
		}
	}, [numNewCalculatedColumns]);

	// State controls whether the DataTable wrapper's mouseover event is active.
	// DataTable mouseover event and DataTable scrol event are only used in Safari, other browsers use CSS.
	const isBrowserSafari = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
	const [isDataTableMouseOverEventActive, setIsDataTableMouseOverEventActive] = useState(isBrowserSafari);

	const dataTableMouseOverEventHandler = () => {
		const datatableWrapper = document.getElementsByClassName('p-datatable-scrollable-body')[0]; // RUNS ONCE.
		const datatableBody = document.getElementsByClassName('p-datatable-scrollable-body-table')[0]; // RUNS ONCE.

		let maxScrollLeft;

		const preventOverscroll = (e) => {
			e.stopPropagation();

			// If at either end of the DataTable horizontally and you try to keep scrolling in that direction, prevent scroll.
			if (
				(datatableWrapper.scrollLeft <= 0 && e.deltaX < 0) ||
				(datatableWrapper.scrollLeft >= maxScrollLeft && e.deltaX > 0)
			) {
				e.preventDefault();
			}
		};

		const resizeObserver = new ResizeObserver(() => {
			maxScrollLeft = datatableWrapper.scrollWidth - datatableWrapper.offsetWidth;
			datatableWrapper.removeEventListener('wheel', preventOverscroll, { passive: false });
			datatableWrapper.addEventListener('wheel', preventOverscroll, { passive: false });
		});
		resizeObserver.observe(datatableWrapper);
		resizeObserver.observe(datatableBody);

		// Ensures the addDataTableScrollEventListener function is only called once, because we only want to add the event listener once.
		setIsDataTableMouseOverEventActive(false);
	};

	return (
		<div className="dataspace-wrapper">
			<CalculatedColumnEditor
				open={columnEditorOpen}
				handleClose={() => setColumnEditorOpen(false)}
				columns={numericalColumns}
				columnDetails={columnEditorDetails}
				dataSpaceId={props.dataSpaceId}
				applyModifiedCustomColumn={props.applyModifiedCustomColumn}
				displayAlert={props.displayAlert}
			/>

			<CalculatedColumnPanel
				open={props.calcColPanelOpen}
				handleClose={props.handleCloseCalcColPanel}
				columns={numericalColumns}
				dataSpaceId={props.dataSpaceId}
				handleAddCalculatedColumn={handleAddCalculatedColumn}
				displayAlert={props.displayAlert}
			/>
			{dataTableWillRender && (
				<div className="card dataspace-viewmanager-wrapper">
					<Grid container spacing={1} className={classes.dataspaceViewManagerWrapper}>
						<ViewManager
							dataSpaceId={props.dataSpaceId}
							getCurrentView={getCurrentView}
							loadView={loadView}
							setIsViewLoading={setIsViewLoading}
						/>
						{/* The "Add New Column/Refresh Dataspace" section is added as props.children so that it can be rendered in the 
            same component as "Save View/Load View" so that those sections can be rendered in the same row. */}
						{props.children}
					</Grid>

					<div
						className={isDataSpaceExpanded ? 'datatable-wrapper-fullscreen' : 'datatable-wrapper'}
						onMouseOver={isDataTableMouseOverEventActive ? dataTableMouseOverEventHandler : null}
					>
						<DataTable
							ref={dataTableRef}
							paginator
							paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
							currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
							rowsPerPageOptions={paginationOptions}
							//rows={10} // Due to table state system, default rows/page is implemented after table state retrieved from backend
							id="datatable-dataspace"
							className="p-datatable-sm"
							size="small"
							value={props.rows}
							scrollable
							resizableColumns
							reorderableColumns
							columnResizeMode="expand"
							onStateSa
							stateStorage="custom"
							customSaveState={onCustomSaveState}
							customRestoreState={onCustomRestoreState}
							sortMode="multiple"
							header={dataTableHeader}
							globalFilter={globalFilter}
						>
							{columnComponents}
						</DataTable>
					</div>
				</div>
			)}

			{/* Load Screen */}
			<Backdrop className={classes.backdrop} open={loadScreenActive}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</div>
	);
}
