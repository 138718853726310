/*
This context is used to store data which requires preloading to avoid introducing 
unnecessary load times during user interaction.
*/

import React, { useState, createContext } from 'react';

export const PreloadedDataContext = createContext();

export const PreloadedDataProvider = (props) => {
	// Used in AddLeaguesDialog.js
	const [supportedFantasyAPIs, setSupportedFantasyAPIs] = useState([]);
	const [connectedFantasyAPIs, setConnectedFantasyAPIs] = useState([]);

	return (
		<PreloadedDataContext.Provider
			value={{
				supportedFantasyAPIs: [supportedFantasyAPIs, setSupportedFantasyAPIs],
				connectedFantasyAPIs: [connectedFantasyAPIs, setConnectedFantasyAPIs],
			}}
		>
			{props.children}
		</PreloadedDataContext.Provider>
	);
};
