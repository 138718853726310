/*
This class is used to generate and return Column components for use in the PrimeReact
DataTable. 

The process determines the filter type, generates the dependencies of the filter
using FilterFactory, and returns a Column component with the correct props.
*/

import React from 'react';
import { Column } from 'primereact/column';
import FilterFactory from './FilterFactory';
import ColumnHeader from './ColumnHeader';
import { DateTime } from 'luxon';

export default class ColumnFactory {
	constructor(
		dataTableReference,
		state,
		setState,
		rows,
		renameHeaderFunction,
		dataSpaceViewContext,
		columnFilterTypes,
		frozenColumns,
		onHideColumn,
		onFreezeColumn,
		onUnfreezeColumn,
		handleEditCalculatedColumn,
		handleDeleteColumn,
	) {
		this.renameHeader = renameHeaderFunction;
		this.columnFilterTypes = columnFilterTypes;
		this.frozenColumns = frozenColumns;
		this.onHideColumn = onHideColumn;
		this.dataSpaceViewContext = dataSpaceViewContext;
		this.onFreezeColumn = onFreezeColumn;
		this.onUnfreezeColumn = onUnfreezeColumn;
		this.handleEditCalculatedColumn = handleEditCalculatedColumn;
		this.handleDeleteColumn = handleDeleteColumn;
		this.filterFactory = new FilterFactory(dataTableReference, state, setState, rows, columnFilterTypes);
	}

	// Returns column component to be rendered in Data Table
	getColumnComponent(col) {
		const { field } = col;
		const filterType = this.getFilterType(field);
		const dynamicProps = this.getDefaultColumnProps(col);
		let filterElement, filterFunction, bodyTemplate;

		switch (filterType) {
			case 'numericRange':
				filterElement = this.filterFactory.getFilterElement(field);
				filterFunction = this.filterFactory.getFilterFunction(field);
				dynamicProps.filter = true;
				dynamicProps.filterElement = filterElement;
				dynamicProps.filterFunction = filterFunction;
				break;
			case 'dropdown':
				filterElement = this.filterFactory.getFilterElement(field);
				filterFunction = this.filterFactory.getFilterFunction(field);
				dynamicProps.filter = true;
				dynamicProps.filterElement = filterElement;
				dynamicProps.filterFunction = filterFunction;
				break;
			case 'dateRange':
			case 'dateTimeRange':
				filterElement = this.filterFactory.getFilterElement(field);
				filterFunction = this.filterFactory.getFilterFunction(field);
				bodyTemplate = this.getBodyTemplate(field);
				dynamicProps.filter = true;
				dynamicProps.filterElement = filterElement;
				dynamicProps.filterFunction = filterFunction;
				dynamicProps.body = bodyTemplate;
				break;
			case 'noFilter':
				break;
			default:
				throw new Error('Invalid filter type specified: ' + filterType);
		}

		if (this.dataSpaceViewContext.dataSpace.frozenColumns?.includes(col.field)) {
			dynamicProps.frozen = true;
		}

		return <Column {...dynamicProps} />;
	}

	// Returns a function which transforms raw data before displaying to user
	getBodyTemplate(field) {
		const filterType = this.getFilterType(field);
		let template;
		switch (filterType) {
			case 'dateRange':
			case 'dateTimeRange':
				template = (rowData) => {
					let trimmedDate, luxonDate, displayDate;
					if ([undefined, null].includes(rowData[field])) {
						return '--'; // In case of no data in cell
					}
					if (rowData[field].slice(-5) === '_DATE') {
						trimmedDate = rowData[field].slice(0, -5); // Trim _DATE
						luxonDate = DateTime.fromISO(trimmedDate);
						displayDate = luxonDate.toLocaleString(DateTime.DATE_FULL);
					} else {
						trimmedDate = rowData[field].slice(0, -9); // Trim _DATETIME
						luxonDate = DateTime.fromISO(trimmedDate);
						displayDate = luxonDate.toLocaleString(DateTime.DATETIME_FULL);
					}

					return displayDate;
				};
				break;
			default:
				// In case of no data in cell. If filterType has its own template, be sure to
				// add a "no data" case in that template. (see dateTimeRange)
				template = (rowData) => {
					if (typeof rowData[field] === 'undefined') {
						return '--';
					}
					return rowData[field];
				};
		}
		return template;
	}

	// Returns an object with list of props included in all columns regardless of filter
	getDefaultColumnProps(col) {
		const { header, field } = col;
		const width = this.getColumnWidth(header);
		const bodyTemplate = this.getBodyTemplate(field);

		return {
			key: field,
			field: field,
			header: (
				<ColumnHeader
					column={col}
					renameHeader={this.renameHeader}
					onHideColumn={this.onHideColumn}
					frozenColumns={this.frozenColumns}
					onFreezeColumn={this.onFreezeColumn}
					onUnfreezeColumn={this.onUnfreezeColumn}
					handleEditCalculatedColumn={this.handleEditCalculatedColumn}
					deleteCustomColumn={this.handleDeleteColumn}
				/>
			),
			style: { minWidth: '4rem', width: '160px', overflow: 'hidden', whiteSpace: 'nowrap' },
			sortable: true,
			body: bodyTemplate,
		};
	}

	// Get width of the column based on the header
	getColumnWidth(header) {
		// Base original width of header based on # characters in header
		// + 50 px to accomodate context menu icon.
		// It would be nice to size this more accurately according to text and font
		//const width = header.length * 11 + 50;
		const width = header.length * 11 + 50;
		return width;
	}

	// Look up the filter type
	getFilterType(field) {
		const filter = this.columnFilterTypes.find((col) => col.field === field);
		return filter ? filter.filterType : 'noFilter';
	}
}
