import { useContext } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import MyDataSpacesViewContext from '../../context/MyDataSpacesViewContext';
import { trackEvent } from '../../../../utils/eventTracking';
import { UserProfileContext } from '../../../../UserProfileContext';
import CreateEditDataSpaceContext from '../../context/CreateEditDataSpaceContext';

// Redirects user from Data Directory page to Dataspace Configuration page.
const BackButton = () => {
	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);
	// User Profile Data
	const userProfile = useContext(UserProfileContext)[0];

	const myDataSpacesViewContext = useContext(MyDataSpacesViewContext);

	const returnToDataDirectory = () => {
		myDataSpacesViewContext.changeViewHandler('newDataSpaceSelectData');

		const eventName =
			createEditDataSpaceContext.createEditDataSpaceMode === 'edit'
				? 'Returned to Data Selection Page from Joins Page in Edit Dataspace Form'
				: 'Returned to Data Selection Page from Joins Page in New Dataspace Form';
		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: { types: ["KissMetrics", 'Segment', 'Encharge', 'GA4'], eventName },
		});
	};

	return (
		<Button variant="contained" margin="normal" color="primary" onClick={returnToDataDirectory}>
			Add More Data
		</Button>
	);
};

export default BackButton;
