/*
This class is used to determine the filter types of each column being displayed
and initialize the state for those column's filters depending on 
which filter type they utilize.
*/

export default class FilterInitializer {
	constructor(data, columns) {
		this.data = data;
		this.columns = columns;
	}

	// Get an object representing the initial state of each filter.
	// Some filters will crash without an initial state.
	getInitialFilterState() {
		const initialState = {};
		const columnFilterTypes = this.getColumnFilterTypes();
		columnFilterTypes.forEach((type) => {
			const { field, filterType } = type;
			switch (filterType) {
				case 'dropdown':
					initialState[field] = [];
					break;
				case 'numericRange':
					initialState[field] = { min: null, max: null };
					break;
				case 'dateTimeRange':
				case 'dateRange':
					//initialState[field] = { startDate: new Date(), endDate: new Date()}
					initialState[field] = { startDate: null, endDate: null };

					break;
				case 'noFilter':
					break;
				default:
					throw new Error('Invalid filter type for column ' + field + ' specified: ' + filterType);
			}
		});
		return initialState;
	}

	// Determine and create a mapping between column fields and filter types.
	// Returns an array of objects {field: String, filterType: String}
	getColumnFilterTypes() {
		const fieldArray = this.columns.map((x) => x.field);
		const dataTypes = fieldArray.map((field) => {
			let dataType = '';
			let i = 0;

			while (i < this.data.length && dataType === '') {
				const rowValue = this.data[i][field];
				if (rowValue !== null && typeof rowValue !== 'undefined') {
					dataType = typeof rowValue;
					// Determine if rowValue is a date type.
					if (dataType === 'string') dataType = this.identifyStringDataRepresentation(rowValue); // Change func name?
				}
				i++;
				// There should not be a case where there is not at least one entry.
			}

			return { field: field, dataType: dataType };
		});

		// Specify filter types corresponding with dataTypes

		const filterMappings = {
			number: 'numericRange',
			string: 'dropdown',
			date: 'dateRange',
			dateTime: 'dateTimeRange',
			'': 'noFilter', // See below, but was with null values
			object: 'noFilter', // Some columns get picked up as object types. For example
			// a Transactions__players was an empty array. These problem fields need to be determined
			// and dealt with. For now they receive no filter.
		};

		let columnFilterTypes = dataTypes.map((dataType) => {
			let filterType = filterMappings[dataType.dataType];
			// Handle unlikely case in which there is no matching datatype in filterMappings
			if (typeof filterType === 'undefined') filterType = 'noFilter';

			return {
				field: dataType.field,
				filterType: filterType,
			};
		});

		// Override specific columns
		columnFilterTypes = this.applyFilterOverrides(columnFilterTypes);

		return columnFilterTypes;
	}

	identifyStringDataRepresentation(rowValue) {
		if (rowValue.slice(-5) === '_DATE') {
			return 'date';
		} else if (rowValue.slice(-9) === '_DATETIME') {
			return 'dateTime';
		}
		return 'string';
	}

	// Override any filters determined by getColumnFilterTypes based on field
	applyFilterOverrides(columnFilterTypes) {
		// This is a stub and can be modified in the future to incorporate our system of cherry picking
		// particular columns (identified by field) to use specific filter types on which do not
		// fit the filter type chosen by heuristics
		const fieldSpecificFilterMappings = {
			Player_Info__college: 'dropdown',
		};

		columnFilterTypes.forEach((columnFilterPair) => {
			if (columnFilterPair.field in fieldSpecificFilterMappings) {
				columnFilterPair.filterType = fieldSpecificFilterMappings[columnFilterPair.field];
			}
		});

		return columnFilterTypes;
	}
}
