import React, { useState, createContext } from 'react';

// Controls the view of the app within the "My DataSpaces" tab.
const MyDataSpacesViewContext = createContext();

// MyDataSpacesViewContextProvider is used to give all children components access to the MyDataSpacesViewContext context.
// Wrap components that need access to the MyDataSpacesViewContext with the MyDataSpacesViewContextProvider context.
export const MyDataSpacesViewContextProvider = (props) => {
	const [myDataSpacesView, setMyDataSpacesView] = useState({ view: 'home', refreshKey: 0, selectedDataSpace: {} });
	const [selectedDataSpace, setSelectedDataSpace] = useState();
	const [isShareDataSpaceDialogOpen, setIsShareDataSpaceDialogOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

	// Sets the view in the context and increments the refreshKey value.
	// The refresh key is used for the key of the MyDataSpacesHome component.
	const changeViewHandler = (view) => {
		setMyDataSpacesView((prevState) => ({
			...prevState,
			view,
			refreshKey: prevState.refreshKey + 1,
		}));
	};

	return (
		<MyDataSpacesViewContext.Provider
			value={{
				myDataSpacesView,
				changeViewHandler,
				setMyDataSpacesView,
				selectedDataSpace,
				setSelectedDataSpace,
				isShareDataSpaceDialogOpen,
				setIsShareDataSpaceDialogOpen,
        isLogoutModalOpen,
        setIsLogoutModalOpen
			}}
		>
			{props.children}
		</MyDataSpacesViewContext.Provider>
	);
};

export default MyDataSpacesViewContext;
