import { getDataSpaceNew, getUsersLeagues } from '../api_helper/api';
import { trackException } from './eventTracking';

const getEditDataSpaceDataNew = async (dataSpaceId, userId) => {
	try {
		const dataSpace = (await getDataSpaceNew(dataSpaceId)).data.dataSpace;

		// Initialize object used to populate createEditDataSpaceContext.
		const createEditDataSpaceContextData = {
			_id: dataSpaceId,
			selectedDatasets: dataSpace.datasets,
			name: dataSpace.name,
			joinData: dataSpace.joinData,
			views: dataSpace.views,
			calculatedColumns: dataSpace.calculatedColumns,
			allDatasets: [],
			headerNamesState: dataSpace.headerNamesState,
		};

		const fantasyDataset = dataSpace.datasets.find(
			(dataset) => dataset.tags.api === 'Yahoo! Fantasy Sports',
			'Fantrax',
		);

		if (fantasyDataset) {
			let userLeagues = (await getUsersLeagues(userId)).leagues;
			const fantasyLeague = userLeagues.find((fantasyLeague) => fantasyLeague.league_key === fantasyDataset.leagueKey);
			createEditDataSpaceContextData.fantasyLeague = fantasyLeague;
		} else {
			createEditDataSpaceContextData.sportsLeague = dataSpace.datasets[0].tags.sport;
		}

		const defaultView = dataSpace.views.find((view) => view.name === 'Default');

		const selectedFieldsNames = defaultView.externalProperties.selectedColumns.map((column) => column.field);

		// Add isSelected properties to selected datasource fields.
		createEditDataSpaceContextData.selectedDatasets = createEditDataSpaceContextData.selectedDatasets.map((dataset) => {
			dataset.fields = dataset.fields.map((field) => {
				field.isSelected = selectedFieldsNames.includes(field.name);
				return field;
			});

			return dataset;
		});

		return createEditDataSpaceContextData;
	} catch (e) {
		trackException(e);
		throw e;
	}
};

export default getEditDataSpaceDataNew;
