import { useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import getDataSpaceImageLink from '../../../utils/getDataSpaceImageLink';
import ImageCrop from './ImageCrop';
import MyDataSpacesViewContext from '../context/MyDataSpacesViewContext';
import { updateDataSpace_v2 } from '../../../api_helper/api';
import { DataSpaceViewContext } from '../context/DataSpaceViewContext';

const SelectDataSpaceImage = () => {
	const dataSpaceViewContext = useContext(DataSpaceViewContext);
	const myDataSpacesViewContext = useContext(MyDataSpacesViewContext);

	const handleFileChange = (e) => {
		if (!e.target.files[0]) return;

		myDataSpacesViewContext.setSelectedDataSpace((prevState) => ({
			...prevState,
			uncroppedDataSpaceImage: URL.createObjectURL(e.target.files[0]),
		}));

		dataSpaceViewContext.setDataSpace((prevState) => ({
			...prevState,
			uncroppedDataSpaceImage: URL.createObjectURL(e.target.files[0]),
		}));
	};

	const removeDataSpaceImageClickHandler = async (e) => {
		e.stopPropagation();
		e.preventDefault();

		await updateDataSpace_v2({
			dataSpaceId: myDataSpacesViewContext.selectedDataSpace._id,
			updatedFields: { croppedDataSpaceImage: null },
		});

		myDataSpacesViewContext.setSelectedDataSpace((prevState) => ({
			...prevState,
			dataSpaceImageUrl: undefined,
		}));

		dataSpaceViewContext.setDataSpace((prevState) => ({
			...prevState,
			dataSpaceImageUrl: undefined,
		}));
	};

	return (
		<>
			<div style={{ width: '100%', marginBottom: '2rem' }}>
				<label
					for="user-profile-image-input"
					style={{
						width: 'fit-content',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						cursor: 'pointer',
					}}
				>
					<img
						src={
							myDataSpacesViewContext.selectedDataSpace.dataSpaceImageUrl ||
							getDataSpaceImageLink(myDataSpacesViewContext.selectedDataSpace)
						}
						style={{ width: '100%', borderRadius: '0.5rem', border: '1px solid rgba(0, 0, 0, 0.2)' }}
					/>
					<div
						style={{
							width: '100%',
							marginTop: '0.5rem',
							textDecoration: 'underline',
							display: 'flex',
						}}
					>
						<span
							style={{
								color: 'grey',
								textDecoration: 'underline',
								fontSize: '0.8rem',
							}}
						>
							Change DataSpace image
						</span>
						{myDataSpacesViewContext.selectedDataSpace.dataSpaceImageUrl && (
							<span
								style={{
									color: '#BB0000',
									fontSize: '0.8rem',
									marginLeft: 'auto',
								}}
								onClick={removeDataSpaceImageClickHandler}
							>
								Remove DataSpace image
							</span>
						)}
					</div>
				</label>
				<input
					id="user-profile-image-input"
					type="file"
					onChange={handleFileChange}
					accept="image/*"
					style={{ display: 'none' }}
				/>
			</div>
			<ImageCrop />
		</>
	);
};

export default SelectDataSpaceImage;
