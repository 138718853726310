import { useContext, useState, useEffect } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Button,
	Backdrop,
	CircularProgress,
	makeStyles,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	DialogContentText,
} from '@material-ui/core';
import SnackbarAlertContext from '../../context/SnackbarAlertContext';
import { getUsersLeagues, updateUserLeagues } from '../../api_helper/api';
import _ from 'lodash';
import { UserProfileContext } from '../../UserProfileContext';
import CreateDataSpaceButton from './CreateDataSpaceButton';
import CommunityDataSpacesContext from '../../context/CommunityDataSpacesContext';
import AddFantasyLeagueDialog from '../my_dataspaces/create_edit_dataspace_form_new/configure_dataspace/AddFantasyLeagueDialog';
import { useLocation, useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
	loadingOverlay: {
		width: '100vw',
		height: '100vh',
		zIndex: '1500',
		color: '#FFFFFF',
	},
	menu: {
		zIndex: '10',
	},
}));

const SelectFantasyLeagueDialog = () => {
	const classes = useStyles();

	let history = useHistory();
	const { search } = useLocation();
	const params = new URLSearchParams(search);

	const userProfileContext = useContext(UserProfileContext);
	const userProfile = userProfileContext[0];
	const snackbarAlertContext = useContext(SnackbarAlertContext);
	const communityDataSpacesContext = useContext(CommunityDataSpacesContext);

	const [isLoading, setIsLoading] = useState(false);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [connectedFantasyLeagues, setConnectedFantasyLeagues] = useState();
	const [selectedFantasyLeague, setSelectedFantasyLeague] = useState();
	const [isSelectFantasyLeagueDialogOpen, setIsSelectFantasyLeagueDialogOpen] = useState(false);

	const selectedLeagueChangeHandler = (e) => {
		setSelectedFantasyLeague(e.target.value);
	};

	const selectFantasyLeagueDialogCloseHandler = () => {
		setIsSelectFantasyLeagueDialogOpen(false);
	};

	const createDataSpaceClickHandler = () => {
		if (
			communityDataSpacesContext.selectedDataSpace.datasets.some((dataset) =>
				['Yahoo! Fantasy Sports', 'Fantrax'].includes(dataset.tags.api),
			)
		) {
			setIsSelectFantasyLeagueDialogOpen(true);
		}
	};

	// Calls the Azure function GetUsersLeagues and updates the
	const updateLeagues = async () => {
		let res = await getUsersLeagues();
		setConnectedFantasyLeagues(res.leagues);
	};

	const handleClickOpenDialog = () => {
		setIsDialogOpen(true);
	};

	// Stores any newly connected leagues to the database which can then be accessed via the updateLeagues function.
	const handleClickRefreshLeagues = async () => {
		try {
			setConnectedFantasyLeagues(undefined);
			const updateUserLeaguesRes = await updateUserLeagues({ userId: userProfileContext._id });
			setConnectedFantasyLeagues(updateUserLeaguesRes.leagues);
		} catch (e) {
			snackbarAlertContext.setSnackbarAlert({
				isSnackbarOpen: true,
				autoHide: 5000,
				severity: 'error',
				msg: e.response?.data?.config?.url?.endsWith('get_token')
					? 'Failed to refresh leagues. Please reconnect your Yahoo account.'
					: 'An error occured while refreshing your leagues. Please try again later.',
			});
			return; // Do not update leagues
		}

		// Retrieve updated leagues and update dropdown.
		await updateLeagues();
	};

	const leagueDatasets = communityDataSpacesContext.selectedDataSpace.datasets.filter((dataset) =>
		['Yahoo! Fantasy Sports', 'Fantrax'].includes(dataset.tags.api),
	);

	// Filter fantasy leagues for those with the same api and sport.
	const matchingFantasyLeagues = connectedFantasyLeagues?.filter(
		(connectedFantasyLeague) =>
			connectedFantasyLeague.api === leagueDatasets[0].tags.api &&
			connectedFantasyLeague.sport === leagueDatasets[0].tags.sport,
	);

	// Populate User League dropdown.
	useEffect(() => {
		if (params.get('route')?.startsWith('communityDataSpacePreview')) {
			try {
				setIsSelectFantasyLeagueDialogOpen(true);
				handleClickRefreshLeagues();
				history.replace('/app');
			} catch (e) {}
		} else {
			if (communityDataSpacesContext.selectedDataSpace) {
				updateLeagues();
			}
		}
	}, []);

	return (
		<>
			<Dialog fullWidth open={isSelectFantasyLeagueDialogOpen} onClose={selectFantasyLeagueDialogCloseHandler}>
				<DialogTitle style={{ paddingBottom: '0' }}>Select Fantasy League</DialogTitle>
				<DialogContent style={{ paddingTop: '0.5rem' }}>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<DialogContentText>
							"{communityDataSpacesContext.selectedDataSpace?.publicName}" uses {leagueDatasets[0]?.tags.api} league
							data. To use this DataSpace you'll need to select the {leagueDatasets[0]?.tags.api} league you want to
							use.
						</DialogContentText>
						<FormControl fullWidth style={{ margin: '1rem 0' }}>
							<InputLabel id="dataspace-form-fantasy-league-select">Select Fantasy League</InputLabel>
							<Select
								label="Select Fantasy League"
								labelId="dataspace-form-fantasy-league-select"
								onChange={selectedLeagueChangeHandler}
								value={selectedFantasyLeague}
							>
								{matchingFantasyLeagues ? (
									matchingFantasyLeagues.length ? (
										matchingFantasyLeagues.map((connectedFantasyLeague) => (
											<MenuItem value={connectedFantasyLeague}>{connectedFantasyLeague.displayName}</MenuItem>
										))
									) : (
										<MenuItem disabled={true}>No leagues found</MenuItem>
									)
								) : (
									<MenuItem disabled={true}>Loading...</MenuItem>
								)}
							</Select>
						</FormControl>

						<div>
							<Button
								variant="contained"
								color="secondary"
								onClick={handleClickOpenDialog}
								style={{ width: 'fit-content' }}
							>
								Connect New League
							</Button>
							<Button
								variant="outlined"
								color="primary"
								onClick={handleClickRefreshLeagues}
								style={{ width: 'fit-content', marginLeft: '0.5rem' }}
							>
								Refresh Leagues
							</Button>
						</div>

						<AddFantasyLeagueDialog
							isDialogOpen={isDialogOpen}
							setIsDialogOpen={setIsDialogOpen}
							updateLeagues={updateLeagues}
							callBackRoute={`communityDataSpacePreview?dataSpaceId=${communityDataSpacesContext.selectedDataSpace._id}`}
						/>
						<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
							<Button variant="outlined" onClick={selectFantasyLeagueDialogCloseHandler}>
								Cancel
							</Button>
							<CreateDataSpaceButton
								dataSpace={communityDataSpacesContext.selectedDataSpace}
								selectedFantasyLeague={selectedFantasyLeague}
							/>
						</div>
					</div>
				</DialogContent>
			</Dialog>
			<Button
				onClick={createDataSpaceClickHandler}
				variant="contained"
				color="secondary"
				style={{ height: 'fit-content', padding: '0.3rem 0.75rem' }}
			>
				Clone
			</Button>
			<Backdrop open={isLoading} className={classes.loadingOverlay}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	);
};

export default SelectFantasyLeagueDialog;
