import React from 'react';
import { getUserIdFromJwt } from '../api_helper/api';
import { identify } from '../utils/eventTracking';

//Authorization HOC
const Authorization = (allowedRoles) => (WrappedComponent) => {
	return class WithAuthorization extends React.Component {
		constructor(props) {
			super(props);

			this.state = {
				permission: false,
			};
		}

		async componentDidMount() {
			// If there is no JWT redirect to login
			const jwt = localStorage.getItem('jwt');
			if (!jwt) this.props.history.push('/');

			// Check JWT and role is valid, allow access or return to login
			try {
				const user = await getUserIdFromJwt(jwt);
				if (user) {
					this.setState({ permission: true });

					// Set user email for tracking
					identify(user.email);

					// If user does not have access redirect to login.
					if (!allowedRoles.includes(user.role)) {
						localStorage.removeItem('jwt');
						this.props.history.push('/');
					}
				}
			} catch {
				// If token is invalid remove and redirect to login.
				localStorage.removeItem('jwt');
				this.props.history.push('/');
			}
		}

		render() {
			// If user has not yet been fetched displaying Loading message.
			if (this.state.permission === false) {
				return <h1>Loading</h1>;
			}

			// if (allowedRoles.includes(role)) {
			//   return <WrappedComponent {...this.props} />
			// } else {
			//   return <h1>No page for you!</h1>
			// }

			// We are authorized, render wrapped component.
			return <WrappedComponent {...this.props} />;
		}
	};
};

export default Authorization;
