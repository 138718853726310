/*
This file acts as a confirmation dialog when the user chooses to delete
a DataSpace.
*/

import React, { useContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';
import { trackEvent } from '../../utils/eventTracking';
import { UserProfileContext } from '../../UserProfileContext';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
	//Event Tracking
	const userProfile = useContext(UserProfileContext)[0];

	const yesButtonOnClick = async () => {
		props.handleDataSpaceDeletion();
		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: {
				types: ['Segment', 'Encharge', 'GA4', 'AppInsights'],
				eventName: 'Successfully Deleted A DataSpace',
			},
		});
	};

	const noButtonOnClick = async () => {
		props.onClose();
		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: {
				types: ['Segment', 'Encharge', 'GA4', 'AppInsights'],
				eventName: 'Sucessfully Did Not Delete a DataSpace',
			},
		});
	};

	return (
		<div>
			<Dialog
				open={props.open}
				TransitionComponent={Transition}
				keepMounted
				onClose={props.handleClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title">{'Warning'}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Are you sure you wish to delete {props.dataSpaceName}?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={yesButtonOnClick} color="primary">
						Yes
					</Button>
					<Button onClick={noButtonOnClick} color="primary">
						No
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
