import React, { useState, useEffect, useContext } from 'react';
import { HeaderContext } from '../../../HeaderContext';
import { DataSpaceViewContext } from '../context/DataSpaceViewContext';
import { UserProfileContext } from '../../../UserProfileContext';
import DataTable from './DataTable';
import { Alert } from '@material-ui/lab';
import LuxonAdapter from '@material-ui/lab/AdapterLuxon';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { DateTime } from 'luxon';
import DetailsPane from './DetailsPane';
import CSVExporter from './CSVExporter';
import { makeStyles, CircularProgress, Backdrop, Snackbar, Button, Grid } from '@material-ui/core/';
import { trackEvent } from '../../../utils/eventTracking';
import MyDataSpacesViewContext from '../context/MyDataSpacesViewContext';
import {
	retrieveJoinedDataSpaceData,
	updateDataSpaceState,
	yahooUploadUserSelectedData,
	generateJoinedDataSpace,
	getSpecifiedDataSpaceMetadata,
	getDataSpaceRows,
	getDataSpaceNew,
} from '../../../api_helper/api';
import './DataSpaceView.css';

const useStyles = makeStyles((theme) => ({
	rightside_component_group: {
		maxWidth: '25rem',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	button: {
		height: 'fit-content',
		paddingTop: '0.4rem',
		paddingBottom: '0.4rem',
		color: 'white',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

const dateRegexes = [
	{
		// "game_time": "Wed, 02 Mar 2019 22:00:00 GMT" Leading 0 on single digit days.
		dateType: 'httpStandard',
		regex: new RegExp('[A-z]{3}, [0-9]{2} [A-z]{3} [0-9]{4} [0-9]{2}:[0-9]{2}:[0-9]{2} GMT'),
	},
	{
		// "age": "May 5, 1991" (No leading 0 on single digit day)
		dateType: 'mon_dd,_yyyy',
		regex: new RegExp(
			'((?:January|February|March|April|May|June|July|August|September|October|November|December) [0-9]{1,2}, [0-9]{4})',
		),
	},
	{
		// "date_injured": "2019-9-27" (No leading 0 on single digit month/day)
		dateType: 'yyyy-mm-dd',
		regex: new RegExp('^[0-9]{4}-([0-9]{2}|[0-9]{1})-([0-9]{2}|[0-9]{1})$'),
	},
	{
		//"timestamp": "1568948695"
		dateType: 'unixTimestamp',
		regex: new RegExp('^[0-9]{8}([0-9]{1,2})?$'),
	},
];

// Converts all date values to predefined uniform formats
const applyCustomDateFormats = (dataSpaceData) => {
	let { data } = dataSpaceData;
	const dateColumns = identifyDateColumnsAndType(dataSpaceData);
	dateColumns.forEach((dateCol) => {
		const { field, dateType } = dateCol;
		data.forEach((row) => {
			if (field in row) {
				row[field] = convertDateToCustomISOFormat(dateType, row[field]);
			}
		});
	});
	return data;
};

// Converts date or datetime to ISO value and adds suffix
// identifying Date type
const convertDateToCustomISOFormat = (dateType, actualDate) => {
	let luxonDate;
	let isoDate;
	switch (dateType) {
		case 'yyyy-mm-dd':
			luxonDate = DateTime.fromFormat(actualDate, 'yyyy-M-d');
			isoDate = luxonDate.toISO(luxonDate) + '_DATE';
			break;
		case 'httpStandard':
			luxonDate = DateTime.fromHTTP(actualDate);
			isoDate = luxonDate.toISO(luxonDate) + '_DATETIME';
			break;
		case 'mon_dd,_yyyy':
			luxonDate = DateTime.fromFormat(actualDate, 'LLLL d, yyyy');
			isoDate = luxonDate.toISO(luxonDate) + '_DATE';
			break;
		case 'unixTimestamp':
			luxonDate = DateTime.fromSeconds(parseInt(actualDate));
			isoDate = luxonDate.toISO(luxonDate) + '_DATETIME';
			break;
		default:
			throw new Error('Invalid date format specified: ' + dateType);
	}

	return isoDate;
};

// Identify columns containing Date values and their initial formats
const identifyDateColumnsAndType = (dataSpaceData) => {
	const { columns, data } = dataSpaceData;

	const fieldArray = columns.map((col) => col.field);
	const dateColumns = [];

	fieldArray.forEach((field) => {
		let dataType = '';
		let value;
		let i = 0;
		while (i < data.length && dataType === '') {
			if (typeof data[i][field] !== 'undefined') {
				dataType = typeof data[i][field];
				value = data[i][field];
			}
			i++;
		}

		i = 0;
		if (dataType === 'string') {
			// Check if it is a date and output console info to check method
			let isDateTypeFound = false;
			while (!isDateTypeFound && i < dateRegexes.length) {
				isDateTypeFound = dateRegexes[i].regex.test(value);
				if (isDateTypeFound) {
					dateColumns.push({
						field: field,
						dateType: dateRegexes[i].dateType,
					});
				}
				i++;
			}
		}
	});

	return dateColumns;
};

export default function DataSpaceView(props) {
	const classes = useStyles();

	// To modify SportWise header
	const setHeaderContent = useContext(HeaderContext)[1];

	// To store and use view variables without prop drilling
	const setDataSpaceId = useContext(DataSpaceViewContext).dataSpaceId[1];

	const myDataSpacesViewContext = useContext(MyDataSpacesViewContext);

	// Loading Backdrop
	const [loadingOpen, setLoadingOpen] = useState(false);

	// Details Pane
	const [detailsOpen, setDetailsOpen] = useState(false);
	const handleClickSeeDetails = () => setDetailsOpen(true);
	const onDetailsClose = () => setDetailsOpen(false);

	// Snackbar and alert
	const [snackOpen, setSnackOpen] = useState(false);
	const onSnackClose = () => setSnackOpen(false);
	const [snackMessage, setSnackMessage] = useState('');
	const [alertSeverity, setAlertSeverity] = useState('');
	const [snackAutoHideMs, setSnackAutoHideMs] = useState(5000);

	const user = useContext(UserProfileContext)[0];

	const displayAlert = (severity, message) => {
		setAlertSeverity(severity);
		setSnackMessage(message);
		setSnackOpen(true);
	};

	// Hold table data
	const [columns, setColumns] = useState([]);
	const [rows, setRows] = useState([]);

	// Hold name of LMAPI (if any). Used when refreshing user's DataSpace
	const [lmapiName, setLmapiName] = useState();

	// Calculated Column Panel
	const [calcColPanelOpen, setCalcColPanelOpen] = useState(false);
	const handleCloseCalcColPanel = () => setCalcColPanelOpen(false);

	// Refresh user's LMAPI data, reload DataSpace
	const [lastRefreshDateTime, setLastRefreshDateTime] = useState('');
	const refreshDataSpace = async () => {
		// Reliant will not be refreshed here as it should be on a schedule or some other system.

		setLoadingOpen(true); // Initiates loading backdrop

		try {
			// Update user's LMAPI DataSources if DataSpace is connected to a league.
			if (lmapiName && lmapiName === 'Yahoo! Fantasy Sports') {
				try {
					await yahooUploadUserSelectedData({ dataSpaceId: props.dataSpaceId });
				} catch (e) {
					// Snack config
					setAlertSeverity('info');
					setSnackMessage(
						'There was an issue updating your data from Yahoo! Fantasy Sports. Your DataSpace will be refreshed using existing Yahoo! Fantasy Sports data. ' +
							'Please try refreshing your DataSpace again shortly.',
					);
					setSnackAutoHideMs(null);
				}
			}

			// Generates and saves refreshed data. Returned data is displayed in table.
			let joinedDataSpaceData = await generateJoinedDataSpace(props.dataSpaceId, 'refresh');
			joinedDataSpaceData = joinedDataSpaceData.data.output;

			const rows = applyCustomDateFormats(joinedDataSpaceData);
			const columns = generateAndAppendColumnToggleLabels(joinedDataSpaceData.columns);
			setRows(rows);
			setColumns(columns);

			setLastRefreshDateTime(new Date().toLocaleString());

			trackEvent({
				userDetails: { userId: user._id, email: user.email },
				eventDetails: { types: ["KissMetrics", 'Segment', 'Encharge', 'GA4'], eventName: 'User refreshed DataSpace.' },
			});
			setAlertSeverity('success');
			setSnackMessage('Your DataSpace has been refreshed.');
			setSnackAutoHideMs(5000);
		} catch (e) {
			// If something goes wrong alert the user and have them retry
			trackEvent({
				userDetails: { userId: user._id, email: user.email },
				eventDetails: {
					types: ['Segment', 'Encharge', 'GA4'],
					eventName: 'User encountered issue when refreshing DataSpace.',
				},
			});
			setAlertSeverity('error');
			setSnackMessage('Something went wrong. Please try again in a few seconds.');
			setSnackAutoHideMs(5000);
		}

		// Close loading screen, show snackbar alert
		setLoadingOpen(false);
		setSnackOpen(true);
	};

	// Generate labels for column dropdown toggle
	const generateAndAppendColumnToggleLabels = (columns) => {
		return columns.map((c) => {
			return {
				...c,
				columnToggleLabel: c.header + ' - ' + c.dataSourceName,
			};
		});
	};

	// This function updates the column header in local and database states when the user renames
	// a column in the DataSpace. It is used as a callback in the ColumnHeader component.
	const renameColumnHeader = (field, newHeader) => {
		// Validation
		const errors = validateNewHeaderName(newHeader);
		if (errors) {
			displayAlert('error', errors);
			return;
		}

		// Rename column header locally
		let columnsCopy = [...columns];
		columnsCopy.find((x) => x.field === field).header = newHeader;
		columnsCopy = generateAndAppendColumnToggleLabels(columnsCopy);
		setColumns(columnsCopy);

		// Rename column header in DB
		const payload = {
			dataSpaceId: props.dataSpaceId,
			mode: 'headerNamesState',
			headerNamesState: {
				field: field,
				header: newHeader,
			},
		};
		updateDataSpaceState(payload);
	};

	// Store dataSpaceId in context so it can be used later without prop drilling
	useEffect(() => {
		setDataSpaceId(props.dataSpaceId);
	}, []);

	// When renaming a column, validates the new header name. Returns an unordered list
	// of errors for display in alert. (eg <ul><li>{error message}</li></ul>)
	function validateNewHeaderName(newHeader) {
		const errors = [];
		let alertContent;
		// Check header name is unique
		if (columns.find((c) => c.header.toLowerCase() === newHeader.toLowerCase())) {
			errors.push(`There is already a column with the name "${newHeader}". Please choose a new column name.`);
			// displayAlert('error', `There is already a column with the name "${newHeader}". Please choose a new column name.`);
		}
		// Check new header is not empty string
		if (!/\S/.test(newHeader) || newHeader === '') {
			errors.push('Please enter a name that is not whitespace.');
		}
		// Create unordered list of errors
		if (errors.length > 0) {
			alertContent = (
				<ul>
					{errors.map((a) => (
						<li>{a}</li>
					))}
				</ul>
			);
		}
		return alertContent;
	}

	// Get lmapiName for DataSpace refresh, set last refresh date, build and set header, populate DataSpace
	useEffect(() => {
		// Get DataSpace metadata
		const metadataFields = ['lmapiName', 'selectedSport', 'selectedLeague', 'lastRefreshedDate'];
		const payload = {
			dataSpaceId: props.dataSpaceId,
			metadataFields: metadataFields,
		};

		getSpecifiedDataSpaceMetadata(payload).then((result) => {
			const { lmapiName, selectedSport, selectedLeague, lastRefreshedDate } = result;

			setLastRefreshDateTime(new Date(lastRefreshedDate).toLocaleString());

			// Set LMAPI name (Used when DataSpace is refreshed)
			if (lmapiName) setLmapiName(lmapiName);

			// Build string to display in header below DataSpace Name
			let headerSubtitle;
			let emoji;

			// Get emoji
			switch (selectedSport) {
				case 'NFL':
					emoji = '🏈';
					break;
				case 'NHL':
					emoji = '🏒';
					break;
				case 'MLB':
					emoji = '⚾';
					break;
				case 'NBA':
					emoji = '🏀';
					break;
				default:
					emoji = '😀';
			}

			// Build header subtitle
			if (selectedLeague) headerSubtitle = `${selectedSport}, ${selectedLeague} ${emoji}`;
			else headerSubtitle = `${selectedSport} ${emoji}`;

			// Set header
			setHeaderContent(
				<div>
					<p>
						{myDataSpacesViewContext.myDataSpacesView.selectedDataSpace.name} <br /> <small>{headerSubtitle}</small>
					</p>
				</div>,
			);
		});

		// Populate DataSpace
		retrieveJoinedDataSpaceData(props.dataSpaceId)
			.then((dataSpaceData) => {
				setJoinedDataSpaceData(dataSpaceData);
			})
			.catch((e) => {
				// // Display error, send user back to My DataSpaces
				props.handleDataSpaceLoadingError();
			});

		// Clear header when exiting view
		return function clearHeader() {
			setHeaderContent(<p>SportWise</p>);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function setJoinedDataSpaceData(dataSpaceData) {
		let { columns } = dataSpaceData;
		const data = applyCustomDateFormats(dataSpaceData);

		// Populate table
		setRows(data);
		columns = generateAndAppendColumnToggleLabels(columns);
		setColumns(columns);
	}

	// This function is used to append new Calculated Column data (row data and column details) to the existing row and column state.
	const appendCalculatedColumn = (columnDetails) => {
		// Process and append column
		let col = [];
		col.push(columnDetails.calcColDetails);
		col = generateAndAppendColumnToggleLabels(col);
		setColumns((prev) => {
			const toSet = prev.concat(col);
			return toSet;
		});

		// Append (join) calculated row data
		const { calculatedRows } = columnDetails;
		setRows((rows) => {
			for (const i in rows) {
				if (Object.keys(calculatedRows[i]).length > 0) {
					rows[i] = {
						...rows[i],
						...calculatedRows[i],
					};
				}
			}
			return rows;
		});

		// Filter Initialization for new calculated column occurs in DataTable
	};

	// This function is used to replace the values of a custom column after it has been edited and recalculated
	const applyModifiedCustomColumn = (columnDetails) => {
		// If column is dependency of other column, use retrieveJoinedDataSpaceData
		// so dependent columns are recalculated as well
		if (columnDetails.calcColDetails.isDependency) {
			retrieveJoinedDataSpaceData(props.dataSpaceId)
				.then((dataSpaceData) => setJoinedDataSpaceData(dataSpaceData))
				.catch((e) => props.handleDataSpaceLoadingError());
		}
		// If no dependent columns, replace existing column values with new values
		else {
			const { calculatedRows } = columnDetails;
			const { field } = columnDetails.calcColDetails;
			setRows((rows) => {
				for (const i in rows) {
					if (calculatedRows[i][field]) rows[i][field] = calculatedRows[i][field];
					else delete rows[i][field];
				}
				return rows;
			});
		}
	};

	// Deletes a custom column from the frontend. Works by re-retrieving and reloading
	// DataSpace data. This method avoids clashing with filter system (rather than removing
	// the column data  explicitly from the rows and columns).
	const deleteCustomColumn = async () => {
		try {
			const dataSpace = (await getDataSpaceNew(props.dataSpaceId)).data.dataSpace;
			const columns = dataSpace.columnMetadata.map((column) => ({
				_id: column._id,
				field: column.field,
				header: column.header,
				dataSourceName: column.dataSourceName,
				description: column.description,
			}));
			const rows = (await getDataSpaceRows(props.dataSpaceId)).data.rows;
			setJoinedDataSpaceData({ columns, data: rows });
		} catch (e) {
			// // Display error, send user back to My DataSpaces
			props.handleDataSpaceLoadingError();
		}
	};

	const openCalculatedColumnPanelOnClick = () => {
		setCalcColPanelOpen(true);
		trackEvent({
			userDetails: { userId: user._id, email: user.email },
			eventDetails: { types: ["KissMetrics", 'Segment', 'Encharge', 'GA4'], eventName: 'User opened Calculated Column Panel.' },
		});
	};

	return (
		<div className="dataspace-view">
			<LocalizationProvider dateAdapter={LuxonAdapter}>
				<DataTable
					columns={columns}
					rows={rows}
					dataSpaceId={props.dataSpaceId}
					renameHeader={renameColumnHeader}
					handleClickSeeDetails={handleClickSeeDetails}
					appendCalculatedColumn={appendCalculatedColumn}
					applyModifiedCustomColumn={applyModifiedCustomColumn}
					deleteCustomColumn={deleteCustomColumn}
					calcColPanelOpen={calcColPanelOpen}
					handleCloseCalcColPanel={handleCloseCalcColPanel}
					displayAlert={displayAlert}
				>
					<Grid item xs={12} md={5} className={classes.rightside_component_group}>
						<CSVExporter
							dataSpaceId={props.dataSpaceId}
							dataSpaceName={myDataSpacesViewContext.myDataSpacesView.selectedDataSpace.name}
							displayAlert={displayAlert}
						/>

						<Button
							className={classes.button}
							size="small"
							variant="contained"
							color="secondary"
							margin="normal"
							onClick={openCalculatedColumnPanelOnClick}
						>
							Add New Column
						</Button>

						<Button
							className={classes.button}
							size="small"
							variant="contained"
							color="secondary"
							margin="normal"
							onClick={refreshDataSpace}
						>
							Refresh DataSpace
						</Button>
					</Grid>
				</DataTable>
			</LocalizationProvider>

			<Backdrop className={classes.backdrop} open={loadingOpen}>
				<CircularProgress color="inherit" />
			</Backdrop>

			<Snackbar
				open={snackOpen}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				onClose={onSnackClose}
				autoHideDuration={snackAutoHideMs}
			>
				<Alert severity={alertSeverity}>{snackMessage}</Alert>
			</Snackbar>

			<DetailsPane
				open={detailsOpen}
				onClose={onDetailsClose}
				dataSpaceId={props.dataSpaceId}
				lastRefreshDateTime={lastRefreshDateTime}
				setLoadingOpen={setLoadingOpen}
			/>
		</div>
	);
}
