import { useContext, useEffect, useState } from 'react';
import { makeStyles, Grid, Typography, CircularProgress } from '@material-ui/core';
import CreateEditDataSpaceContext from '../../context/CreateEditDataSpaceContext';
import {
	getDataSourcesAvailableFields,
	getLatestSeasonDatasources,
} from '../../../../api_helper/api';
import Dataset from './Dataset';

const useStyles = makeStyles((theme) => ({
	datasetsListWrapper: {
		height: '100%',
		marginBottom: '1rem',
		display: 'flex',
		flexDirection: 'column',
	},
	selectedDataHeader: {
		fontSize: '1.1rem',
		fontWeight: 'bold',
	},
	datasetsList: {
		maxHeight: '30rem',
		flexGrow: '1',
		margin: '0',
		padding: '1rem',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: '#ECECEC',
		borderRadius: '0.5rem',
		overflowY: 'scroll',
		listStyle: 'none',
		'& > li': {
			width: '100%',
		},
		'& > li:last-child > button': {
			border: 'none',
		},
	},
}));

// List of available datasources to choose from.
const DatasetsList = () => {
	const classes = useStyles();

	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);
	const [datasets, setDatasets] = useState([]);

	const getAvailableDataSources = async () => {
		try {
			const getDatasourcesApisArray = createEditDataSpaceContext.newDataSpace.fantasyLeague
				? [createEditDataSpaceContext.newDataSpace.fantasyLeague.api, 'Reliant']
				: ['Reliant'];

			const getLatestSeasonDatasourcesRes = await getLatestSeasonDatasources(
				createEditDataSpaceContext.newDataSpace.fantasyLeague?.sport || createEditDataSpaceContext.newDataSpace.sportsLeague,
				getDatasourcesApisArray,
			);

			// Creates an array of datasources with their available fields populated under the dataSpaceFields property.
			const datasetsWithFields = [];
			for (let dataset of [...getLatestSeasonDatasourcesRes.data.datasources]) {
				const fieldsRes = await getDataSourcesAvailableFields(dataset.dataSourceObjectType);
				if (fieldsRes.length !== 0) {
					datasetsWithFields.push({ ...dataset, dataSpaceFields: fieldsRes });
				}
			}

			setDatasets(datasetsWithFields);
		} catch (e) {
			console.log('ERROR: ', e);
		}
	};

	useEffect(() => {
		getAvailableDataSources();
	}, []);

	const datasetComponents = datasets.map((dataset) => (
		<li key={dataset._id}>
			<Dataset dataset={dataset} />
		</li>
	));

	return (
		<Grid item xs={12} md={7.5} className={classes.datasetsListWrapper}>
			<Typography className={classes.selectedDataHeader}>
				Choose the datasets that will serve as the foundation for your dataspace.
			</Typography>
			<ul className={classes.datasetsList}>
				{datasets.length ? datasetComponents : <CircularProgress className={classes.datasetsListLoadingIcon} />}
			</ul>
		</Grid>
	);
};

export default DatasetsList;
