import { makeStyles, Typography, Card, Grid } from '@material-ui/core';

const useStyles = makeStyles(() => ({
	pageWrapper: {
		width: '100%',
		height: '100%',
		overflowY: 'scroll',
	},
	card: {
		width: '97%',
		maxWidth: '80rem',
		margin: '1rem auto 5.5rem auto',
		padding: '3%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: '#FAFAFA',
	},
	cardHeaderWrapper: {
		width: '100%',
		marginBottom: '2rem',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	cardHeaderText: {
		fontSize: '1.5rem',
		fontWeight: 'bold',
		textAlign: 'center',
	},
}));

// Page wrapper for each page of the new dataspace form.
// Contains page wrapper and card containing page content.
const NewDataSpacePageContentWrapper = (props) => {
	const classes = useStyles();

	return (
		<div className={classes.pageWrapper}>
			<Card className={classes.card}>
				<Grid container>
					<Grid item xs={12} className={classes.cardHeaderWrapper}>
						<Typography className={classes.cardHeaderText} variant="h2">
							{props.header}
						</Typography>
					</Grid>
					{props.children}
				</Grid>
			</Card>
		</div>
	);
};

export default NewDataSpacePageContentWrapper;
