import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useContext } from 'react';
import MyDataSpacesViewContext from '../context/MyDataSpacesViewContext';
import { updateDataSpace_v2 } from '../../../api_helper/api';
import SnackbarAlertContext from '../../../context/SnackbarAlertContext';

const DeleteViewDialog = (props) => {
	const myDataSpacesViewContext = useContext(MyDataSpacesViewContext);
	const snackbarAlertContext = useContext(SnackbarAlertContext);

	const deleteViewClickHandler = async () => {
		try {
			// Update views array with selected view removed.
			const updatedViews = myDataSpacesViewContext.selectedDataSpace.views.filter(
				(view) => view.id !== props.editDeleteSelectedView.id,
			);

			// Update views property of DataSpace document.
			await updateDataSpace_v2({
				dataSpaceId: myDataSpacesViewContext.selectedDataSpace._id,
				updatedFields: {
					views: updatedViews,
				},
			});

			myDataSpacesViewContext.setSelectedDataSpace((prevState) => ({
				...prevState,
				views: updatedViews,
			}));

			props.setIsDeleteViewDialogOpen(false);

			props.setViewListRefreshRequired(true);
		} catch (e) {
			props.setIsDeleteViewDialogOpen(false);
			snackbarAlertContext.setSnackbarAlert({
				isSnackbarOpen: true,
				autoHide: 5000,
				severity: 'error',
				msg: 'There was an error deleting your view. Please try again later.',
			});
		}
	};

	return (
		<Dialog
			open={props.isDeleteViewDialogOpen}
			onClose={() => {
				props.setIsDeleteViewDialogOpen(false);
			}}
		>
			<DialogTitle>Rename View</DialogTitle>
			<DialogContent>Are you sure you want to delete the view "{props.editDeleteSelectedView.name}"</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						props.setIsDeleteViewDialogOpen(false);
					}}
				>
					Cancel
				</Button>
				<Button onClick={deleteViewClickHandler}>Delete</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteViewDialog;
