import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import logo from '../../assets/img/SportWiseLogoLarge.png';

import { ResetPasswordRequest } from '../../api_helper/api';
import PasswordStrengthMeter from '../../utils/PasswordStrengthMeter';

export class ResetPassword extends Component {
	//Object to bind the state of the form to
	state = {
		newPassword: '',
		passwordResetToken: '',
		redirect: null,
		errors: [],
		viewId: 1, //ViewId and msgId are utilized for passing props through the redirect for nextView
		msgId: 2,
	};

	handleChange = ({ target }) => {
		const { name, value } = target;
		this.setState({ [name]: value });
	};

	submit = async (e) => {
		e.preventDefault();

		let errors = await this.verifyFields();

		// Send password reset request to function
		if (errors.length === 0) {
			console.log('Number of errors: ', errors.length);

			const payload = {
				newPassword: this.state.passwordresetconfirmation,
				passwordResetToken: this.props.match.params.resettoken,
			};

			await ResetPasswordRequest(payload)
				.then((result) => {
					// Redirect to sign in page with appropriate message
					this.setState({ redirect: '/' });
				})
				.catch((err) => {
					errors.push({ msg: err.data });
					this.setState({ errors });
					this.setState({
						viewId: 1,
						msgId: 2,
					});

					// Redirect to sign in page with appropriate message
					// Why would we redirect here on an error? *****************
					this.setState({ redirect: '/ForgottenPassword' });
				});
		} else {
			this.setState({ errors });
		}
	};

	// This function verifies that all fields are entered correctly
	// and that username or email are not previously registered.
	verifyFields = async () => {
		const newPassword = this.state.newPassword;
		const passwordresetconfirmation = this.state.passwordresetconfirmation;
		const errors = [];

		if (newPassword !== passwordresetconfirmation) {
			errors.push({ msg: 'The passwords enter do not match.  Please try to re-enter your password confirmation' });
		}
		return errors;
	};

	// This function returns a div containing a list of form errors
	displayErrors = (errors) => {
		if (!errors.length) return null;

		// index is required for mapping
		return errors.map((error, index) => (
			<div key={index}>
				<p>{error.msg}</p>
			</div>
		));
	};

	//This function identifies an error has been triggered
	isErrors = (errors) => {
		if (errors.length) return true;
	};

	render() {
		if (this.state.redirect) {
			return <Redirect to={{ pathname: this.state.redirect, state: { errors: this.state.errors } }} />;
		}

		return (
			<div>
				<Grid container direction="column" alignItems="center" justify="center">
					<Grid item style={{ display: 'flex', justifyContent: 'center' }}>
						<img
							src={logo}
							alt="SportWise logo."
							style={{
								width: '60%',
								maxWidth: '25rem',
								alignSelf: 'center',
								display: 'block',
								padding: '4rem 0 2rem 0',
							}}
						/>
					</Grid>

					<Grid item>
						<h1>Update Password</h1>
					</Grid>
					<Grid item>
						<p>
							To reset your password, Enter a strong password below. To confirm your new password, re-enter the same
							password in the confirmation field{' '}
						</p>
					</Grid>
					<Grid item>
						<form>
							<Grid item>
								<TextField
									required
									name="newPassword"
									label="Enter a new strong password"
									variant="outlined"
									margin="normal"
									type="password"
									style={{ width: 480 }}
									onChange={this.handleChange}
								/>
							</Grid>
							<Grid item>
								<PasswordStrengthMeter password={this.state.newPassword} />
							</Grid>
							<Grid item>
								<TextField
									required
									name="passwordresetconfirmation"
									label="Re-enter your new password"
									variant="outlined"
									margin="normal"
									type="password"
									style={{ width: 480 }}
									onChange={this.handleChange}
									FormHelperTextProps={{
										component: 'div',
									}}
									helperText={this.displayErrors(this.state.errors)}
									error={this.isErrors(this.state.errors)}
								/>
							</Grid>
							{/* Error Display */}
							<Box m={2} pt={3}>
								<Grid item>
									<div className="errors">{this.displayErrors(this.state.errors)}</div>
								</Grid>
							</Box>
							{/* Submit button for the form */}
							<Box m={2} pt={3}>
								<Grid item align="center">
									<Button variant="contained" color="primary" margin="normal" onClick={this.submit}>
										Save New Password
									</Button>
								</Grid>
							</Box>

							<Grid item align="center">
								<Button
									onClick={() => {
										this.props.nextView('mainLogin', 1);
									}}
								>
									Nevermind, go back.
								</Button>
							</Grid>
						</form>
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default ResetPassword;
