/*
 *   Function: SubscriptionManagementDialog
 *
 *   This function will return a dialog box with either the pricing plan or billing management link.
 *   Depending on the state of the current user, different elements will be displayed
 *   @Param {boolean} open - boolean to determine if dialog should open
 *   @Param {String}  eventRequired - Event require status
 *   @Param {User} currentUser - User profile for the logged in user
 */
import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Alert,
	Snackbar,
} from '@material-ui/core';
import { Pricing } from './Pricing';
import { pollUserAccountChargifySubscription } from '../../../api_helper/api';

export function SubscriptionManagementDialog(open, eventRequired, userProfile, handleClose) {
	const [billingResolved, setBillingResolved] = useState(false);

	// Snackbar
	const [snackOpen, setSnackOpen] = useState(false);
	const [snackSeverity, setSnackSeverity] = useState('error');
	const [snackMessage, setSnackMessage] = useState('');
	function displaySnackBar(severity, message) {
		setSnackSeverity(severity); // info, warning, or success
		setSnackMessage(message);
		setSnackOpen(true);
	}

	// Opens a URL in a new tab
	const openInNewTab = (url) => {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
		if (newWindow) newWindow.opener = null;
	};

	// How we want to display states verbally to the user.
	const friendlyStates = {
		on_hold: 'on hold',
		past_due: 'past due',
		unpaid: 'unpaid',
	};

	/*
  Open Chargify Billing Portal in new tab. Begin polling for user's subscription to turn active 
  (anticipating user enters correct Billing info)
  */
	const correctInfoOnClick = async () => {
		openInNewTab(userProfile.subscriptionUrl);
		pollUserAccountChargifySubscription({
			pollingDurationSeconds: 300, // 5 minutes
			pollingIntervalSeconds: 2,
			propertyKey: 'state',
			expectedValue: 'active',
		})
			.then((result) => {
				if (result.expectedValueFound) {
					setBillingResolved(true);
				} else {
					displaySnackBar(
						'error',
						'Your subscription information was not updated. Please contact support if you think this in error.',
					);
				}
			})
			.catch((err) => {
				displaySnackBar(
					'error',
					'There was an error accessing your account. Please try again later. If the issue persists, please contact support.',
				);
			});
	};

	return (
		<Dialog open={open} aria-labelledby="account-update" maxWidth="xl" scroll="body" component="div" fullwidth="true">
			{eventRequired === 'billingPortal' && <DialogTitle id="form-dialog-title">Subscription Issue</DialogTitle>}
			<DialogContent>
				<DialogContentText component="div">
					{eventRequired === 'sign-up' && <Pricing user={userProfile} />}
					{eventRequired === 'billingPortal' && (
						<div>
							{billingResolved ? (
								<p>Your subscription details have been successfully updated.</p>
							) : (
								<p>
									Your subscription is currently <b>{friendlyStates[userProfile.subscriptionState]}.</b>
									<br />
									To restore your access to SportWise, please click on the button below and update your
									subscription details.
									<br />
									<b>This window will update when your new subscription details have been validated.</b>
								</p>
							)}
						</div>
					)}
				</DialogContentText>
				{eventRequired === 'billingPortal' && (
					<DialogActions>
						{billingResolved ? (
							<Button variant="contained" color="primary" onClick={handleClose}>
								Return to SportWise
							</Button>
						) : (
							<Button variant="contained" color="primary" onClick={correctInfoOnClick}>
								Correct Subscription Info
							</Button>
						)}
					</DialogActions>
				)}
			</DialogContent>
			<Snackbar
				open={snackOpen}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				onClose={() => {
					setSnackOpen(false);
				}}
			>
				<Alert severity={snackSeverity}>{snackMessage}</Alert>
			</Snackbar>
		</Dialog>
	);
}
