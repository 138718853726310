/*
This hook is used to fetch data for the PreloadedDataContext. It is used in the AppContainer
to populate the context after the user logs in or if the user has already logged in and visits the app.
*/

import { useContext, useEffect } from 'react';
import { PreloadedDataContext } from '../../PreloadedDataContext';
import { getSupportedFantasyAPIData, getUsersConnectedFantasyProviders } from '../../api_helper/api';

export function usePreloadedData() {
	const setSupportedFantasyAPIs = useContext(PreloadedDataContext).supportedFantasyAPIs[1];
	const setConnectedFantasyAPIs = useContext(PreloadedDataContext).connectedFantasyAPIs[1];

	useEffect(() => {
		getSupportedFantasyAPIData().then((res) => {
			setSupportedFantasyAPIs(res);
		});
		getUsersConnectedFantasyProviders().then((res) => setConnectedFantasyAPIs(res.connectedFantasyProviders));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}
