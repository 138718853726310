import { debounce } from 'lodash';
import { Grid, TextField, Typography } from '@material-ui/core';
import { useContext, useRef } from 'react';
import CreateEditDataSpaceContext from '../../context/CreateEditDataSpaceContext';

// Input for the new dataspac name.
const NameDataSpaceInput = () => {
	const dataSpaceNameInputRef = useRef(null);
	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);

	// Sets the name property of the CreateEditDataSpaceContext context to the value in the TextField input.
	const dataSpaceNameInputChangeHandler = (input) => {
		createEditDataSpaceContext.setNewDataSpace((prevState) => ({
			...prevState,
			name: input,
		}));
	};

	return (
		<Grid item container xs={12}>
			<Grid item xs={12}>
				<Typography variant="h6" align="left">
					Name your DataSpace
				</Typography>
			</Grid>

			<Grid item xs={12}>
				<TextField
					inputRef={dataSpaceNameInputRef}
					onChange={(e) => {
						dataSpaceNameInputChangeHandler(e.target.value);
					}}
					value={createEditDataSpaceContext.newDataSpace.name || ''}
					autoFocus
					margin="dense"
					id="name"
					label="Name"
					type="text"
					fullWidth
				/>
			</Grid>
		</Grid>
	);
};

export default NameDataSpaceInput;
